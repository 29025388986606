Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var _default = function _default(url, paramName) {
  var urlParams = {};

  if (url && url.indexOf('?') === 0) {
    urlParams = _lodash["default"].chain(url).replace('?', '').split('&').map(_lodash["default"].partial(_lodash["default"].split, _lodash["default"], '=', 2)).fromPairs().value();
  } else {
    var questionIndex = _lodash["default"].indexOf(url, '?'),
        params = url.substring(questionIndex, url.length);

    urlParams = _lodash["default"].chain(params).replace('?', '').split('&').map(_lodash["default"].partial(_lodash["default"].split, _lodash["default"], '=', 2)).fromPairs().value();
  }

  urlParams = _lodash["default"].each(urlParams, function (value, key) {
    urlParams[key] = decodeURIComponent(value);

    if (!_lodash["default"].isNaN(_lodash["default"].parseInt(value))) {
      urlParams[key] = _lodash["default"].parseInt(value);
    }
  });

  if (paramName) {
    if (_lodash["default"].has(urlParams, paramName)) {
      urlParams = urlParams[paramName];
    }
  }

  return urlParams;
};

exports["default"] = _default;