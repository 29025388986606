import ModalBase from '../../../../components/ModalBase'
import { useCartContext } from '../../../../../hooks/useCartContext'
import { safeLocalStorage } from '../../../../../utils/safeStorage'
import deleteCartItems from '../../../../apis/deleteCartItems'
import { DeleteItemsModalContainer } from './styles'

const DeleteItemsModal = ({setToastMessage}) => {
    const userData = safeLocalStorage.getJSONItem('userData')
    const { state, clearSelectedItem, setCart, hideDeleteItemsModal } = useCartContext()
    const { selectedItems, cartItemTotal } = state

    const totalQuantity = selectedItems.reduce((acc, item) => acc + item.Quantity, 0);
    
    const onClear = () => {
        clearSelectedItem()
    }

    const onDeleteItems = async () => {
        const inititalCartItemTotal = cartItemTotal
        const updatedCart = await deleteCartItems({ userData, items: selectedItems })

        if (updatedCart.CartItemTotal < inititalCartItemTotal) {

            const totalItemsDeleted = inititalCartItemTotal - updatedCart.CartItemTotal

            setToastMessage([
                {
                    id: 1,
                    title: `${totalItemsDeleted} item${totalItemsDeleted > 1 ? '(s)' : ''
                        } deleted.`,
                    type: 'success',
                    undoAction: null,
                    action: () => { }
                }
            ]);
        }

        setCart(updatedCart)
        hideDeleteItemsModal()
        onClear()
    }

    return (
        <ModalBase onClose={hideDeleteItemsModal}>
            <DeleteItemsModalContainer>
                <div className="modal-header">
                    <h3>Delete</h3>
                    <i className="fas fa-times close-icon" onClick={hideDeleteItemsModal} />
                </div>
                <p>Please confirm that you would like to delete <b>{totalQuantity}</b> item/s from your Cart.</p>
                <div className='buttons-wrapper'>
                    <button onClick={hideDeleteItemsModal}>Cancel</button>
                    <button className='delete-btn' onClick={onDeleteItems}>Delete</button>
                </div>
            </DeleteItemsModalContainer>
        </ModalBase>
    )
}

export default DeleteItemsModal
