import styled from 'styled-components'

const CartSummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 100%;
`

const ContractsWarning = styled.div`
  top: 256px;
  display: flex;
  align-items: center;
  gap: 4px;
  color: #ff3d00;
  font-family: Rubik;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin: .75rem 0 1.25rem 0;
`;

const CartSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 16.875rem;
  margin: 1.5rem 1.5rem 0 0;
  border-radius: 0.25rem;
  box-shadow:
    0px 0px 2px 0px rgba(0, 0, 0, 0.14),
    0px 2px 2px 0px rgba(0, 0, 0, 0.12),
    0px 1px 3px 0px rgba(0, 0, 0, 0.2);


  .cart-summary-header {
    display: flex;
    padding: 1rem;
    background-color: #f4f5f6;
    border-bottom: 1px solid #dedfe2;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    h3 {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }

  .cart-suppliers-container {
    display: flex;
    flex-direction: column;
    padding: 1rem 0.75rem;

    .supplier-summary {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.5rem;
      max-height: 1000px;

      &.removed {
        display: none;
      }

      &:last-child {
        margin: 0;
      }

      i {
        color: #ffc107;
        margin-right: 0.5rem;
      }

      .supplier-items-summary {
        display: flex;
        align-items: center;

        img {
          height: 1.25rem;
          width: 1.25rem;
          border-radius: 0.25rem;
          object-fit: contain;
          margin-right: 0.5rem;
        }
      }
    }
  }

  .cart-summary-total {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0.75rem;
    border-top: 1px solid #dedfe2;

    .cart-total {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 1rem;
    }

    button {
      width: calc(100% - 1rem);
      margin: 0 0.5rem;
      font-size: 0.75rem;
      line-height: 1rem;
      font-weight: normal;

      &:hover {
        opacity: 0.9;
      }

      &:focus {
        outline: none;
      }
    }
  }

  .currency {
    display: flex;
    justify-content: flex-end;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.25rem;
  }
`

export default { CartSummaryWrapper, CartSummaryContainer, ContractsWarning}
