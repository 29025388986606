"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var ModalContainer = _styledComponents["default"].div.withConfig({
  displayName: "styles__ModalContainer",
  componentId: "zrpfq3-0"
})(["display:flex;flex-direction:column;overflow:auto;min-height:0;height:100%;max-height:22rem;margin:1rem 0;padding-right:0.75rem;.location-container{display:flex;justify-content:space-between;align-items:center;box-shadow:inset 0px -1px 0px 0px rgb(237,238,239);padding:0.75rem 0;cursor:pointer;min-height:3rem;&:hover{opacity:0.8;}&:last-child{box-shadow:none;}.location{span{font-size:0.875rem;color:rgba(62,65,72,0.7);}}i{color:#3963fe;}}"]);

var _default = {
  ModalContainer: ModalContainer
};
exports["default"] = _default;