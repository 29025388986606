import styled from 'styled-components'

const CartHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;

  .mutted-text {
    color: rgba(0, 0, 0, 0.5);
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .checkbox-container {
    display: flex;
    gap: 8px;
    align-items: center;
    cursor: pointer;
  }
`

export default { CartHeaderContainer }
