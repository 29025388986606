import { fetchData, getCartData } from '../../apis';
import { safeLocalStorage } from '../../../utils/safeStorage';

const isBrowser = typeof window !== 'undefined' && window.env;
const apiRoot = isBrowser ? window.env.apiRoot : process.env.RAZZLE_RUNTIME_API_ROOT;

export const getUserInfoObject = userData => ({
  ...userData,
  activeNetworkIndex: userData.activeNetworkIndex,
  activeNetworkId: userData.activeNetworkId,
  activeTheme: userData.Network[userData.activeNetworkIndex].Theme
    ? JSON.parse(userData.Network[userData.activeNetworkIndex].Theme)
        : {},
  activeUserSettings: userData.Network[userData.activeNetworkIndex].Setting
        ? JSON.parse(userData.Network[userData.activeNetworkIndex].Setting)
    : {},
  activeCorpSettings: userData.Network[userData.activeNetworkIndex].CompanySetting
        ? JSON.parse(userData.Network[userData.activeNetworkIndex].CompanySetting)
    : {},
});

export const getCartCount = async ({ userData, setCartItemTotal }) => {
  const cartData = await getCartData({ userData });
  setCartItemTotal(cartData.CartItemTotal);
};

export const handleUpdateActiveNetwork = async (event, setUserData) => {
  const newActiveNetworkIndex = event.target.closest('.dropdown-item').value;
  let userData = JSON.parse(window.localStorage.getItem('userData'));
  const modifiedUserData = {
    ...userData,
    activeNetworkId: userData.Network[newActiveNetworkIndex].Id,
    activeNetworkIndex: newActiveNetworkIndex,
  };
  const updatedUserInfo = getUserInfoObject(modifiedUserData);
  console.log('handleUpdateActiveNetwork :: userInfo :: ', updatedUserInfo);
  window.localStorage.setItem('userData', JSON.stringify(updatedUserInfo));
  const UserActivityEvent = new CustomEvent('UserActivityEvent', {
    detail: { activeNetwork: userData.Network[newActiveNetworkIndex] },
  });
  document.dispatchEvent(UserActivityEvent);
  window.postMessage({ type: 'updateUser', detail: updatedUserInfo }, '*');

  const bearerToken = window.localStorage.getItem('token');
  const suppliersOptions = {
    root: apiRoot,
    version: 'v1',
    url: `Procurement/User/Contract/Suppliers/${
      updatedUserInfo.Network[updatedUserInfo.activeNetworkIndex].UserToken
    }`,
    method: 'GET',
    bearerToken,
  };
  const supplierData = await fetchData(suppliersOptions);
  // const suppliersOptions = {
  //   method: 'GET',
  //   headers: {
  //     "Content-Type": "application/json",
  //     "Accept": "application/json",
  //     "Authorization": 'bearer ' + bearerToken,
  //     "Ocp-Apim-Subscription-Key": apiKey,
  //   }
  // }
  // const supplierData = await fetchData(`${apiRoot}/v1/Procurement/User/Contract/Suppliers/${userToken}`, suppliersOptions)
  // window.localStorage.setItem('suppliers', JSON.stringify(supplierData.Suppliers))
  console.log('Header :: handleUpdateActiveNetwork :: supplierData', supplierData);
  if (supplierData && supplierData.Suppliers) {
    window.localStorage.setItem('suppliers', JSON.stringify(supplierData.Suppliers));
    const SupplierEvent = new CustomEvent('SupplierEvent', {
      detail: supplierData.Suppliers,
    });
    document.dispatchEvent(SupplierEvent);
    window.postMessage({ type: 'updateSuppliers', detail: supplierData.Suppliers }, '*');
  }

  setUserData(updatedUserInfo);
  window.location.reload();
};

export const setUserTheme = async (theme) => {
  if (theme && theme.backgroundColor) {
    let { style } = document.documentElement;

    style.setProperty('--aurora-theme-background-color', `${theme.backgroundColor}`);
    style.setProperty('--aurora-theme-cta-color', `${theme.buttonColor}`);
    if (theme.theme === 'light') {
      style.setProperty('--aurora-theme-text-color', '0,0,0');
      style.setProperty('--aurora-text-opacity', '0.8');
      style.setProperty(' --aurora-deempasized-text-opacity', '0.6');
      style.setProperty('--aurora-loading-text-opacity', '0.4');
      style.setProperty('--aurora-active-icon-opacity', '0.3');
      style.setProperty('--aurora-inactive-icon-opacity', '0.2');
    }
  }
};

export const setDefaulttheme = async () => {
  let { style } = document.documentElement;
  style.setProperty('--aurora-theme-background-color', '#000000');
  style.setProperty('--aurora-theme-cta-color', '#2f55fe');
  style.setProperty('--aurora-theme-text-color', '248,248,248');
  style.setProperty('--aurora-text-opacity', '1');
  style.setProperty(' --aurora-deempasized-text-opacity', '0.7');
  style.setProperty('--aurora-loading-text-opacity', '0.5');
  style.setProperty('--aurora-active-icon-opacity', '0.6');
  style.setProperty('--aurora-inactive-icon-opacity', '0.3');
};

const isEqlView = requestedView => requestedView === '/marketplace'
    || requestedView === '/quotes'
    || requestedView === '/requsitions'
    || requestedView === '/orders';

export const handleNavigation = (requestedView, props) => {
  console.log('Header :: handleNavigation :: requestedView :: ', requestedView);
  if (requestedView.includes(props.currentView) || isEqlView(requestedView)) {
    window.location.href = requestedView;
  }
  props.history.push(requestedView);
};

export const getLocalCart = async ({ userData, setCart }) => {
  await getCartData({ userData })
    .then((cart) => {
      safeLocalStorage.setJSONItem('cart', cart);
      setCart(cart);
    })
    .catch((error) => {
      console.log('SetCartError: ', error);
    });
};
