import _ from 'lodash';

const model = {
    ExpirationDate: '',
    Id: '',
    IsDefault: '',
    Name: '',
    Number: '',
    Token: '',
    Type: '',
};

export const formatCard = card => {
    let name;
    let type;
    let number;
    let expirationDate;

    const formattedCard = {
        ...model,
        ...card,
    };

  const {
 Name, Type, Number, ExpirationDate 
} = formattedCard;

    if (!_.isEmpty(Name)) {
        name = Name;
    }

    if (!_.isEmpty(Type)) {
        type = Type;
    }

    if (!_.isEmpty(Number)) {
        number = Number;
    }

    if (!_.isEmpty(ExpirationDate)) {
        console.log('expiration', ExpirationDate);
        expirationDate = ExpirationDate;
    }

    return {
        name,
        type,
        number,
        expirationDate,
    };
};
