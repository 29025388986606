import styled from 'styled-components';

const AddPCardModalContainer = styled.div`
    .addPCardModal {
        display: flex;
        padding: 1rem;
        margin: 0px;
        min-height: 24rem;
        max-height: 100vh;
        width: 100%;

        h3 {
            font-size: 1rem;
            line-height: 1.25rem;
        }

        .addFormField {
            margin-top: 1rem;
            width: 100%;

            .input-field {
                max-width: 34.5rem;
            }

            .masked {
                max-width: 99%;
            }
        }

        .input-container {
            width: 100%;
            details-container {
                margin-top: 1rem;
            }
        }

        .expireSection {
            margin-top: 1rem;
            color: #3e4148;
            font-family: Lato;
            font-size: 14px;
            font-weight: normal;
        }

        .expireField {
            display: flex;
            flex-direction: row;
            height: 56px;
            width: 100%;
            .fieldContain {
                .maskInput-container {
                    .maskInput-field {
                        max-width: 1.8rem;
                    }
                    .details-container {
                        visibility: hidden;
                        p.error-field {
                            visibility: hidden;
                        }
                    }
                }
            }

            .addDash {
                margin: 1rem 0.25rem;
                color: #3e4148;
                font-family: Lato;
                font-size: 16px;
                font-weight: normal;
            }
        }

        .dateError {
            display: flex;
            align-items: center;
            color: #ff5722;

            i {
                font-size: 13.4px;
                margin-right: 4px;
            }

            p {
                font-size: 14px;
                font-family: Lato;
                color: #ff5722;
                font-style: italic;
            }
        }

        .buttonContainer {
            display: flex;
            flex-direction: row;
            margin-top: 2rem;
            justify-content: space-between;
            button {
                margin: 0;
                width: 276px;
            }
        }
    }

    .modal-children {
        padding: 0px 0.5rem 0.5rem 0.5rem;
    }
`;

export default { AddPCardModalContainer };
