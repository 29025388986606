"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _globalStyledComponents = require("../../globalStyledComponents");

var Root = _styledComponents["default"].div.withConfig({
  displayName: "styles__Root",
  componentId: "chvbfc-0"
})(["", " ", " .availibility-stores{height:100%;width:100%;margin:", ";}.search-selections{display:flex;align-items:center;flex-wrap:wrap;max-width:36.75rem;margin:0 0.5rem 3rem;justify-content:center;.search-card{margin:0.5rem;cursor:pointer;.card-container{display:flex;padding:0.75rem;img{height:6rem;width:6rem;object-fit:contain;margin-right:0.75rem;}h4{display:flex;align-items:center;flex:1;}}}}"], _globalStyledComponents.RootCSS, function (_ref) {
  var mobile = _ref.mobile;
  return !mobile && (0, _styledComponents.css)(["height:100%;width:100%;padding:0 5%;.title{margin-bottom:3rem;}.availibility-stores{justify-content:center;}"]);
}, function (_ref2) {
  var mobile = _ref2.mobile;
  return mobile ? '1rem 0 auto' : '6.5rem 0 3rem';
});

var SupplierImages = _styledComponents["default"].div.withConfig({
  displayName: "styles__SupplierImages",
  componentId: "chvbfc-1"
})(["display:flex;flex-wrap:wrap;margin-top:1.5rem;max-width:32rem;min-height:5.5rem;img{border-radius:0.75rem;height:4.5rem;width:4.5rem;object-fit:contain;margin:0 1rem 1rem 0;}", ""], function (_ref3) {
  var mobile = _ref3.mobile;
  return mobile && (0, _styledComponents.css)(["img:nth-child(4){margin:0 0 1rem;}"]);
});

var _default = {
  Root: Root,
  Row: _globalStyledComponents.Row,
  Column: _globalStyledComponents.Column,
  SupplierImages: SupplierImages,
  Card: _globalStyledComponents.Card
};
exports["default"] = _default;