import React, { useState, useContext } from 'react'
import Button from '../Button'
import RequestModal from '../../components/RequestModal'
import { MobileLayout } from '../../App';
import styles from './style.module.css'

function RequestRejectModal({ onClose, onReject, selectedRequest }) {
  const isMobile = useContext(MobileLayout)
  const [comment, setComment] = useState('')

  const closingFunction = (isCancel = false) => {
    if (isMobile) {
      const modalOverlay = document.querySelector('div.src-common-components-RequestModal-__style-module___mobileModalOverlay')
      const modalDiv = document.querySelector('div.src-common-components-RequestModal-__style-module___modal.src-common-components-RequestModal-__style-module___fadeInBottom.src-common-components-RequestModal-__style-module___mobileStyleModal')
      modalDiv && modalDiv.classList.remove('src-common-components-RequestModal-__style-module___fadeInBottom')
      modalDiv && modalDiv.classList.add('src-common-components-RequestModal-__style-module___fadeOutBottom')
      modalOverlay && (modalOverlay.style.transition = "250ms")
      modalOverlay && (modalOverlay.style.backgroundColor = "unset")
      setTimeout(() => {
        isCancel ? onClose('hideRejectModal') : onReject('rejectRequest', { ...selectedRequest, comment })
      }, 800)
    } else {
      isCancel ? onClose('hideRejectModal') : onReject('rejectRequest', { ...selectedRequest, comment })
    }
  }

  return (
    <RequestModal
      width={600}
      onClose={() => onClose('hideRejectModal')}
      header={
        <h3
          style={{
            flex: 1,
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 800,
            fontSize: 24,
            lineHeight: '32px',
            color: '#000000',
            height: isMobile && '44px',
            display: 'inline-flex',
            alignItems: 'center',
          }}
        >
          Reject
        </h3>
      }
      footer={
        <div style={{ display: 'flex', gap: 16, padding: isMobile ? '16px 24px 34px 24px' : '16px 24px' }}>
          <Button onClick={() => closingFunction(true)} styles={{ fontSize: isMobile && '16px', lineHeight: isMobile && '20px', letterSpacing: isMobile && '0.02em' }} fullWidth text="Cancel" resize="lg" variant="default" />
          <Button onClick={() => closingFunction()} styles={{ fontSize: isMobile && '16px', lineHeight: isMobile && '20px', letterSpacing: isMobile && '0.02em' }} fullWidth text="Reject" resize="lg" variant="reject" />
        </div>
      }
      showMobileStyleModal={isMobile}
    >
      <div
        style={{
          paddingTop: isMobile ? 16 : 24,
          paddingBottom: isMobile ? 44 : 28,
          paddingLeft: 24,
          paddingRight: 24,
        }}
      >
        <label
          style={{
            display: 'flex',
            gap: 4,
            alignItems: 'center',
            marginBottom: 4,
          }}
        >
          Comment
          <span style={{ color: 'rgba(0, 0, 0, 0.5)' }}> — Optional</span>
        </label>
          <textarea className={styles.textArea} maxLength="250" value={comment} placeholder="Visible to the requester" cols="2" onChange={(e) => {setComment(e.target.value)}}></textarea>
      </div>
    </RequestModal>
  )
}

export default RequestRejectModal
