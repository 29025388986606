import styled from 'styled-components'

export const ModalBaseContainer = styled.div`
  position: fixed;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  overflow-x: hidden;
  overflow-y: auto;

  inset: 0px;

  z-index: 9999;

  outline: 2px solid transparent;

  .modal {
    background-color: white;
    border-radius: 8px;
    border: 0px;
    width: fit-content;
    height: fit-content;
  }
`

export const ModalBaseBackground = styled.div`
  opacity: 0.25;
  position: fixed;
  inset: 0px;
  z-index: 9998;
  background-color: rgba(24, 28, 36, 0.85);
`
