"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _basicStyles = _interopRequireDefault(require("./basicStyles"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _templateObject9() {
  const data = _taggedTemplateLiteral(["\n    &.mini-cart-footer {\n        display: flex;\n        align-items: center;\n        background: rgba(255, 255, 255, 0.75);\n        backdrop-filter: blur(0.5rem);\n        border-radius: 0px 0px 0.5rem 0.5rem;\n        height: 3.5rem;\n        padding: 0.5rem 1rem;\n\n        .footer-btn {\n            display: flex;\n            justify-content: center;\n            align-items: center;\n            border-radius: 0.5rem;\n            height: 2.5rem;\n            width: 100%;\n            margin: 0 0.5rem;\n            font-size: 0.75rem;\n            /* font-weight: bold; */\n            letter-spacing: 1px;\n            line-height: 1rem;\n\n            &.checkout-btn {\n                color: #ffffff;\n                background: linear-gradient(-180deg, rgb(57, 99, 254) 0%, rgb(47, 85, 254) 100%);\n                border: 1px solid rgb(47, 85, 254);\n\n                &:hover {\n                    opacity: 0.9;\n                }\n            }\n\n            &.view-cart-btn {\n                color: #3963fe;\n                background: linear-gradient(-180deg, rgb(255, 255, 255) 0%, rgb(250, 250, 250) 100%);\n                border: 1px solid rgb(244, 245, 246);\n\n                &:hover {\n                    background: #fafafa;\n                }\n            }\n        }\n\n        button.checkout-btn {\n            padding: 0;\n        }\n    }\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  const data = _taggedTemplateLiteral(["\n    display: flex;\n    width: 100%;\n    height: 100%;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    margin: auto;\n    min-height: 13rem;\n\n    svg,\n    img {\n        width: 4.625rem;\n        margin-bottom: 1.5rem;\n    }\n\n    p {\n        font-size: 1rem;\n        color: rgba(62, 65, 72, 0.7) !important;\n    }\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  const data = _taggedTemplateLiteral(["\n    display: flex;\n    flex-direction: column;\n    flex: 1;\n    align-items: center;\n    justify-content: center;\n\n    .loader-icon {\n        animation: spin 0.8s linear infinite;\n        color: #b9bbc1;\n        height: 2rem;\n        margin-bottom: 1.5rem;\n    }\n\n    @keyframes spin {\n        0% {\n            transform: rotate(0deg);\n        }\n        100% {\n            transform: rotate(360deg);\n        }\n    }\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  const data = _taggedTemplateLiteral(["\n    display: flex;\n    flex-direction: column;\n    min-height: 15rem;\n    max-height: 50vh;\n    overflow-y: auto;\n    flex: 1;\n\n    p.punchout-message {\n        display: flex;\n        color: #006064;\n        background: rgba(178, 235, 242, 0.95);\n        margin: 0.5rem 0.75rem;\n        font-family: Lato;\n        font-size: 0.835rem;\n        line-height: 1.25rem;\n        padding: 0.5rem 0.75rem;\n\n        i {\n            font-size: 1rem;\n            color: #00bcd4;\n            margin-right: 0.5rem;\n            line-height: 20px;\n        }\n    }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  const data = _taggedTemplateLiteral(["\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background: linear-gradient(-180deg, rgb(57, 99, 254) 0%, rgb(47, 85, 254) 100%);\n    border-radius: 0.5rem 0.5rem 0px 0px;\n    height: 2rem;\n    width: 100%;\n\n    p.mini-cart-title {\n        color: #ffffff;\n        letter-spacing: 0.25px;\n        /* font-weight: bold; */\n        margin: auto;\n    }\n\n    svg {\n        height: 0.75rem;\n        width: 0.75rem;\n        margin-right: 0.5rem;\n        color: #23419e;\n        cursor: pointer;\n    }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  const data = _taggedTemplateLiteral(["\n                    display: none;\n                    animation: popupOut 0.3s ease;\n                    transition: display 0.3s;\n                    animation-fill-mode: forwards;\n                "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  const data = _taggedTemplateLiteral(["\n                    display: flex;\n                    animation: popupIn 0.3s ease;\n                    transition: display 0.3;\n                    animation-fill-mode: forwards;\n                "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  const data = _taggedTemplateLiteral(["\n                border-radius: 0.5rem;\n                box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14), 0px 4px 5px 0px rgba(0, 0, 0, 0.12),\n                    0px 1px 10px 0px rgba(0, 0, 0, 0.2);\n            "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  const data = _taggedTemplateLiteral(["\n    ", "\n\n    &.mini-cart-component {\n        display: ", ";\n        opacity: ", ";\n        flex-direction: column;\n        width: 25rem;\n        background: #ffffff;\n        ", "\n    }\n\n    &.animate {\n        ", "\n    }\n\n    @keyframes popupIn {\n        from {\n            display: none;\n            opacity: 0;\n        }\n        to {\n            display: flex;\n            opacity: 1;\n        }\n    }\n    @keyframes popupOut {\n        from {\n            display: flex;\n            opacity: 1;\n        }\n        to {\n            display: none;\n            opacity: 0;\n        }\n    }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

const MiniCartContainer = _styledComponents.default.div(_templateObject(), _basicStyles.default, (_ref) => {
  let {
    popup
  } = _ref;
  return popup ? 'none' : 'flex';
}, (_ref2) => {
  let {
    popup
  } = _ref2;
  return popup && 0;
}, (_ref3) => {
  let {
    popup
  } = _ref3;
  return popup && (0, _styledComponents.css)(_templateObject2());
}, (_ref4) => {
  let {
    toggle
  } = _ref4;

  if (toggle) {
    return (0, _styledComponents.css)(_templateObject3());
  } else {
    return (0, _styledComponents.css)(_templateObject4());
  }
});

const Header = _styledComponents.default.div(_templateObject5());

const Body = _styledComponents.default.div(_templateObject6());

const Loader = _styledComponents.default.div(_templateObject7());

const EmptyCart = _styledComponents.default.div(_templateObject8());

const Footer = _styledComponents.default.div(_templateObject9());

var _default = {
  MiniCartContainer,
  Header,
  Body,
  Loader,
  EmptyCart,
  Footer
};
exports.default = _default;