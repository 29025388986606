"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var Button = _styledComponents["default"].button.withConfig({
  displayName: "styles__Button",
  componentId: "sc-1n49d9y-0"
})(["background:linear-gradient(-180deg,rgb(57,99,254) 0%,rgb(47,85,254) 100%);font-size:0.75rem;font-weight:bold;line-height:1rem;border-radius:8px;border:1px solid rgb(47,85,254);color:#ffffff;padding:0;text-transform:none;height:", ";width:", ";cursor:pointer;.dots{background-color:#ffffff;height:6px;width:6px;opacity:0.9;display:inline-block;border-radius:3px;margin:0 2px;animation:1.5s ease 0s infinite normal none running pulse;}.dot-2{opacity:0.5;animation-delay:0.2s;}.dot-3{animation-delay:0.4s;opacity:0.2;}@keyframes pulse{0%{opacity:0.9;}100%{opacity:0.2;}}", ""], function (_ref) {
  var height = _ref.height;
  return height ? height : '2rem';
}, function (_ref2) {
  var width = _ref2.width;
  return width ? width : '6rem';
}, function (_ref3) {
  var added = _ref3.added;
  return added && (0, _styledComponents.css)(["background:rgb(255,255,255);border:1px solid rgb(222,223,226);color:#3e4148;"]);
});

var _default = {
  Button: Button
};
exports["default"] = _default;