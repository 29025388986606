import _ from 'lodash';
import fetchData from './fetchData';
import config from './apiConfig';

export default userData => {
    const userToken = userData.Network[userData.activeNetworkIndex].UserToken;

    return new Promise((resolve, reject) => {
        const options = {
            ...config.getAddressCheckOut,
            url: `${config.getAddressCheckOut.url}/${userToken}`,
        };
        return fetchData(options)
            .then(data => {
                if (data && !data.ErrorCode) {
                    let stateObject = {};
                    if (_.isArray(data)) {
                        stateObject = {
                            addresses: data,
                            fetchError: !!(data && data.ErrorCode),
                        };
                    } else {
                        stateObject = {
                            ...data,
                            fetchError: !!(data && data.ErrorCode),
                        };
                    }
                    resolve(stateObject);
                } else {
                    reject({ fetchError: true, details: data });
                }
            })
            .catch(error => {
                reject(error);
            });
    });
};
