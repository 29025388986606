import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom'
// import Spendbridge from './features/Spendbridge'
import cookieMonster, { eraseCookie, getRawCookie, setCookie } from '../../utils/cookies'
import { jsonFetch } from '../../utils/fetch2'
import getDefaultCheckout from '../../utils/getDefaultCheckout'

//Routes
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { CartProvider } from '../../providers/CartProvider'
import { safeLocalStorage } from '../../utils/safeStorage'
import { EnvContext } from '../App'
import { getSupplierContractData } from '../apis'
import Modal from '../components/Modal'
import NotFound from '../components/NotFound'
import NotFoundUserScreen from '../components/NotFoundUserScreen'
import Account from '../features/Account'
import AdminPortal from '../features/AdminPortal'
import RequestActionByEmail from '../features/Approvals/RequestActionByemail'
import TabsManager from '../features/Approvals/Tabs'
import Cart from '../features/Cart'
import Checkout from '../features/Checkout'
import EQLSearch from '../features/EQLSearch'
import EqlPages from '../features/EqlPages'
import Error from '../features/Error'
import Logout from '../features/Logout'
import Manage from '../features/Manage'
import Marketplace from '../features/Marketplace'
import OnBoarding from '../features/OnBoarding'
import OrderEdit from '../features/OrderEdit'
import PoPages from '../features/PoPages'
import PoRedirects from '../features/PoRedirects'
import Spendbridge from '../features/Spendbridge'
import Stores from '../features/Stores'
import SubmittedOrders from '../features/SubmittedOrders'

const isBrowser = typeof window !== 'undefined' && window.env
// const urlPath = typeof window !== 'undefined' && window.location.href
// const eqlRoot = isBrowser ? window.env.eqlRoot : process.env.RAZZLE_RUNTIME_EQL_ROOT
// const apiRoot = isBrowser ? window.env.apiRoot : process.env.RAZZLE_RUNTIME_API_ROOT
// const apiKey = isBrowser ? window.env.apiKey : process.env.RAZZLE_RUNTIME_API_KEY
// const harmonyRootUrl = isBrowser ? window.env.harmonyRootUrl : process.env.RAZZLE_RUNTIME_HARMONY_ROOT_URL
const blobUrl = isBrowser ? window.env.blobUrl : process.env.RAZZLE_RUNTIME_BLOB_URL
const MFE_ADMIN_PORTAL_PATH = isBrowser ? window.env.apiRoot : process.env.RAZZLE_RUNTIME_MFE_ADMIN_PORTAL_PATH
const REDIRECT_DOMAIN = isBrowser ? window.env.redirectDomain : process.env.RAZZLE_RUNTIME_REDIRECT_DOMAIN

const UNSECURE_PATHS = ['/user-not-found', '/error', '/approvals/email']

export const UserDataContext = React.createContext()

const getCachedUserData = async (apiRoot, apiKey, token, history) => {
  const exisitingUserData = safeLocalStorage.getItem('userData')
  if (exisitingUserData) {
    var userData = JSON.parse(exisitingUserData)
    return await updateUserSettings(apiRoot, apiKey, token, userData)
  }
  return await getAdditionalLoginInfo(apiRoot, apiKey, token, history)
}

const updateUserSettings = async (apiRoot, apiKey, token, userData) => {
    try {
        const settingsUrl = `${apiRoot}/v1/Procurement/UserSettings/${userData.activeNetwork.Id}`;

        const settingsOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Ocp-Apim-Subscription-Key': apiKey,
                'Authorization': `Bearer ${token}`
            }
        };    

        const response = await fetch(settingsUrl, settingsOptions);
        const settingsData = await response.json();

        if (response.ok) {
            const { Setting, CompanySetting } = settingsData;

            //update all the places these settings are stored in userData
            userData.activeNetwork.Setting = Setting;
            userData.activeNetwork.CompanySetting = CompanySetting;
            userData.activeCorpSettings = CompanySetting ? JSON.parse(CompanySetting) : {};
            userData.activeUserSettings = Setting ? JSON.parse(Setting) : {};

            userData.Network[userData.activeNetworkIndex].Setting = Setting;
            userData.Network[userData.activeNetworkIndex].CompanySetting = CompanySetting;

            const activeUserNetwork = userData.userNetworks.find(x => x.Id == userData.activeNetwork.Id)
            if (activeUserNetwork) {
                activeUserNetwork.Setting = Setting;
                activeUserNetwork.CompanySetting = CompanySetting;
            }
            safeLocalStorage.setItem('userData', JSON.stringify(userData));
        }
        else{            
            console.log('Unable to get latest user settings.')
        }
        return userData;

    } catch (error) {
        console.log('Unable to update latest user settings.', error)
        return userData;
    }
};


const getAdditionalLoginInfo = async (apiRoot, apiKey, token, history) => {
  console.log('ProcurementRoutes :: getAdditionalLoginInfo :: ')
  const LoginOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Ocp-Apim-Subscription-Key': `${apiKey}`,
    },
  }
  try {
    const userInfo = await jsonFetch(`${apiRoot}/v1/Procurement/Login`, LoginOptions, token)
    if (userInfo.error) {
      //forwrad user to no user found page
      console.log('getAdditionalLoginInfo :: userInfo :: REDIRECT')
      location.href = '/user-not-found'
      return { error: userInfo }
    }
    const cookieSafelimitedNetwork = userInfo.Network.length > 6 ? userInfo.Network.slice(0, 6) : userInfo.Network
    const userData = {
      ...userInfo,
      activeNetwork: {
        ...userInfo.Network[0],
        Setting:
          userInfo.Network[0].Setting && userInfo.Network[0].Setting.length > 1
            ? JSON.parse(userInfo.Network[0].Setting)
            : userInfo.Network[0].Setting,
      },
      userNetworks: [...cookieSafelimitedNetwork],
      userInfo: {
        Email: userInfo.Email,
        FirstName: userInfo.FirstName,
        ImageUrl: userInfo.ImageUrl,
        LastName: userInfo.LastName,
        Setting: {
          PriceLimit: userInfo.Setting ? JSON.parse(userInfo.Setting).PriceLimit : 1.1,
        },
      },
    }
    console.log('Login :: logging in user with following info :: ', userData, userInfo)
    let { updatedUserData } = setActiveSettings(userData)
    safeLocalStorage.setItem('userData', JSON.stringify(updatedUserData))
    const UserTokenEvent = new CustomEvent('UserActivityEvent', {
      detail: { activeNetwork: userData.activeNetwork },
    })
    document.dispatchEvent(UserTokenEvent)
    cookieMonster.setCookie('userData', JSON.stringify(userData), 0, 10, 1)
    cookieMonster.setCookie('loggedIn', true, 0, 10, 1)
    return userData
  } catch (error) {
    return { error }
  }
}

const setActiveSettings = (userData) => {
  let updatedUserData = {}
  let activeNetworkIndex = userData.activeNetworkIndex || 0

  const activeNetworkUserSettings = userData.Network[activeNetworkIndex]
  updatedUserData = {
    ...userData,
    activeNetworkId: activeNetworkUserSettings.Id,
    activeNetworkIndex: activeNetworkIndex,
    activeTheme: activeNetworkUserSettings.Theme ? JSON.parse(activeNetworkUserSettings.Theme) : {},
    activeUserSettings: activeNetworkUserSettings.Setting ? JSON.parse(activeNetworkUserSettings.Setting) : {},
    activeCorpSettings: activeNetworkUserSettings.CompanySetting
      ? JSON.parse(activeNetworkUserSettings.CompanySetting)
      : {},
  }

  let userToken = activeNetworkUserSettings.UserToken

  return {
    updatedUserData,
    userToken,
  }
}

export const fetchCompanyId = async (officeId) => {
  try {
    const isBrowser = typeof window !== 'undefined' && window.env
    const url = isBrowser ? window.env.apiRoot : process.env.RAZZLE_RUNTIME_API_ROOT
    const apiKey = isBrowser ? window.env.apiKey : process.env.RAZZLE_RUNTIME_API_KEY
    const token = localStorage.getItem('token')

    const myHeaders = new Headers()
    myHeaders.append('Ocp-Apim-Subscription-Key', apiKey)
    myHeaders.append('Authorization', `Bearer ${token}`)

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    const request = await fetch(`${url}/v1/CorporateOffice?AdminPortalCorporateOfficeId=${officeId}`, requestOptions)
    const response = await request.json()

    const companyID = `${response[0].Id}`

    setCookie('companyId', companyID)
    return { companyID }
  } catch (error) {
    console.error(`setCompanyId :: ${error}`)
    return { error }
  }
}

const getComapnyId = async (userData) => {
  const companyId = getRawCookie('companyId')
  if (!companyId) {
    const corporateId = userData.Network[userData.activeNetworkIndex].Id
    fetchCompanyId(corporateId)
  }
}

// Spendbridge
const SpendbridgeRoutes = (cookies, userData, defaultCheckout, blobUrl) => {
  return (
    <Switch>
      <div id="spendBridgeLoader" className="spendBridgeLoader">
        <img src={`${blobUrl}/procurement/branding/R_Loader.gif`} alt="loader" />
      </div>
      <Route
        exact
        path="/spendbridge/history"
        render={(routeProps) => (
          <Spendbridge
            {...routeProps}
            {...{
              cookies: { ...cookies },
              userData,
              currentView: '/spendbridge/history',
              defaultCheckout,
            }}
          />
        )}
      />
      <Route
        exact
        path="/spendbridge/approvals"
        render={(routeProps) => (
          <Spendbridge
            {...routeProps}
            {...{
              cookies: { ...cookies },
              userData,
              currentView: '/spendbridge/approvals',
              defaultCheckout,
            }}
          />
        )}
      />
      <Route
        exact
        path="/spendbridge/checkout"
        render={(routeProps) => (
          <Spendbridge
            {...routeProps}
            {...{
              cookies: { ...cookies },
              userData,
              currentView: '/spendbridge/checkout',
              defaultCheckout,
            }}
          />
        )}
      />
    </Switch>
  )
}

const isUnsecured = UNSECURE_PATHS.some((path) => typeof window !== 'undefined' && location.pathname.includes(path))

const unAuthedProcurementRoutes = (props) => {
  const { cookies } = props
  const { getAccessTokenSilently, logout } = useAuth0()
  const ldClient = useLDClient()
  const env = useContext(EnvContext)
  let history = useHistory()
  let location = useLocation()
  const eqlRoot = env.eqlRoot
  const apiRoot = env.apiRoot
  const apiKey = env.apiKey
  const suppliers = safeLocalStorage.getJSONItem('suppliers')
  const stores = safeLocalStorage.getJSONItem('stores')
  const hasExtension = document.getElementById('aurora-ext-enabled') ? true : false
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)

  const [userData, setUserData] = useState({})
  const [defaultCheckout, setDefaultCheckout] = useState({})
  const [preloadingComplete, setPreloadingComplete] = useState(false)
  const [isRedirect, setIsRedirect] = useState(
    new URL(REDIRECT_DOMAIN).hostname === new URL(window.location.origin).hostname &&
      queryString.length > 0 &&
      urlParams.get('redirected') === 'true'
  )

  useEffect(() => {
    const origin = window.location.origin
    const path = window.location.pathname
    const isLocalhost = origin.includes('localhost')

    console.log('REDIRECT_CHECK :: ff :: ', props.featureFlags.f6853QmeritRedirect)

    if (
      !isLocalhost &&
      props.featureFlags.f6853QmeritRedirect &&
      REDIRECT_DOMAIN &&
      new URL(REDIRECT_DOMAIN).hostname !== new URL(origin).hostname
    ) {
      console.log('REDIRECT_CHECK :: REDIRECT_DOMAIN :: ', REDIRECT_DOMAIN)
      console.log(
        'REDIRECT_CHECK :: REDIRECT_DOMAIN.hostname !== origin.hostname) :: ',
        new URL(REDIRECT_DOMAIN).hostname !== new URL(origin).hostname
      )
      if (!path.includes('/approvals/email')) {
        safeLocalStorage.removeItem('userData')
        window.location.replace(REDIRECT_DOMAIN + path + '?redirected=true')
      }
    }

    const refetchSuppliers = async (userData) => {
      const supplierData = await getSupplierContractData({ userData })
      safeLocalStorage.setItem('suppliers', JSON.stringify(supplierData.Suppliers))
      window.postMessage({ type: 'updateUser', detail: userData }, '*')
      const SupplierEvent = new CustomEvent('SupplierEvent', {
        detail: supplierData.Suppliers,
      })
      document.dispatchEvent(SupplierEvent)
      window.postMessage({ type: 'SupplierEvent', detail: supplierData.Suppliers }, '*')
    }

    const getCachedSuppliers = async (updatedUserData) => {
      const cachedSuppliers = safeLocalStorage.getJSONItem('suppliers')
      refetchSuppliers(userData)
      return cachedSuppliers
    }

    const getUserData = async () => {
      try {
        const token = await getAccessTokenSilently()

        safeLocalStorage.setItem('token', token)
        const userData = await getCachedUserData(apiRoot, apiKey, token, history)
        console.log('getCachedUserData :: userData :: ', userData)
        if (userData.error) return false

        const identityData = { email: userData?.Email, key: userData?.Id }
        await ldClient?.identify(identityData)

        let { updatedUserData } = setActiveSettings(userData)
        getComapnyId(updatedUserData)
        const supplierData = await getCachedSuppliers(updatedUserData)
        if (supplierData) {
          refetchSuppliers(updatedUserData)
        } else {
          await refetchSuppliers(updatedUserData)
        }

        const UserTokenEvent = new CustomEvent('UserActivityEvent', {
          detail: { activeNetwork: userData.activeNetwork },
        })
        document.dispatchEvent(UserTokenEvent)

        const defaultCheckout = await getDefaultCheckout(userData)
        setUserData(updatedUserData)
        setDefaultCheckout(defaultCheckout)
        setPreloadingComplete(true)
      } catch (e) {
        console.error('ERROR :: useEffect try/catch :: logout :: ', e)
        // logout({
        //     logoutParams: {
        //         returnTo: window.location.origin
        //     }
        // })
      }
    }

    safeLocalStorage.setItem('currentPath', window.location.pathname)

    if (!UNSECURE_PATHS.some((path) => location.pathname.includes(path))) {
      getUserData()
    } else {
      setPreloadingComplete(true)
    }
  }, [props.featureFlags])

  useEffect(() => {
    if (isRedirect) {
      window.history.replaceState({}, document.title, window.location.pathname)
      if (getRawCookie('redirected')) eraseCookie('redirected')
    }
  }, [isRedirect])

  useEffect(() => {
    if (
      !isRedirect &&
      new URL(REDIRECT_DOMAIN).hostname === new URL(window.location.origin).hostname &&
      getRawCookie('redirected')
    ) {
      setIsRedirect(true)
      eraseCookie('redirected')
    }
  }, [location.search])

  return (
    <Fragment>
      <UserDataContext.Provider value={userData}>
        {isRedirect && (
          <Modal
            isRedirect={true}
            setEnabled={true}
            setToggle={setIsRedirect}
            maxWidth="600px"
            width="auto"
            height="auto"
          />
        )}
        {preloadingComplete && (
          <Switch>
            <Route exact path="/" render={(routeProps) => <Redirect to="/marketplace" {...routeProps} />} />
            <Route exact path="/punchout-redirect" render={(routeProps) => <Redirect to="/cart" {...routeProps} />} />
            <Route
              exact
              path="/marketplace"
              render={(routeProps) => (
                <Fragment>
                  <Marketplace
                    {...routeProps}
                    {...{
                      cookies: { ...cookies },
                      userData,
                      suppliers,
                      stores,
                      currentView: '/marketplace',
                      eqlUrl: eqlRoot + '/',
                      hasExtension,
                      defaultCheckout,
                    }}
                  />
                </Fragment>
              )}
            />
            <Route
              exact
              path="/user-not-found"
              render={(routeProps) => (
                <NotFoundUserScreen
                  {...routeProps}
                  {...{
                    cookies: { ...cookies },
                    userData,
                    currentView: '/user-not-found',
                  }}
                />
              )}
            />
            <Route
              exact
              path="/user/profile"
              render={(routeProps) => (
                <Redirect
                  to="/account"
                  {...routeProps}
                  {...{
                    userData,
                    currentView: '/user/profile',
                    defaultCheckout,
                  }}
                />
              )}
            />
            <Route
              exact
              path="/marketplace"
              render={(routeProps) => (
                <MarketplaceComponent
                  {...routeProps}
                  {...{
                    cookies: { ...cookies },
                    userData,
                    suppliers,
                    stores,
                    currentView: '/marketplace',
                    eqlUrl: eqlRoot + '/',
                    hasExtension,
                    defaultCheckout,
                  }}
                />
              )}
            />
            <Route
              exact
              path="/e-marketplace"
              render={(routeProps) => (
                <EqlPages
                  {...routeProps}
                  {...{
                    cookies: { ...cookies },
                    userData,
                    currentView: '/marketplace',
                    eqlUrl: eqlRoot,
                    skipWarning: true,
                    hasExtension,
                  }}
                />
              )}
            />
            <Route
              path="/spendbridge"
              render={(routeProps) => <SpendbridgeRoutes {...{ cookies, userData, defaultCheckout, blobUrl }} />}
            />
            <Route
              exact
              path="/orders"
              render={(routeProps) => (
                <EqlPages
                  {...routeProps}
                  {...{
                    cookies: { ...cookies },
                    userData,
                    currentView: '/orders',
                    eqlUrl: eqlRoot + '/purchase_orders',
                    hasExtension,
                  }}
                />
              )}
            />
            <Route
              exact
              path="/quotes"
              render={(routeProps) => (
                <EqlPages
                  {...routeProps}
                  {...{
                    cookies: { ...cookies },
                    userData,
                    currentView: '/quotes',
                    eqlUrl: eqlRoot + '/requests',
                    hasExtension,
                  }}
                />
              )}
            />
            <Route
              exact
              path="/standing"
              render={(routeProps) => (
                <EqlPages
                  {...routeProps}
                  {...{
                    cookies: { ...cookies },
                    userData,
                    currentView: '/standing',
                    eqlUrl: eqlRoot + '/standing',
                    hasExtension,
                  }}
                />
              )}
            />
            <Route
              exact
              path="/requisitions"
              render={(routeProps) => (
                <EqlPages
                  {...routeProps}
                  {...{
                    cookies: { ...cookies },
                    userData,
                    currentView: '/requisitions',
                    eqlUrl: eqlRoot + '/requisitions',
                    hasExtension,
                  }}
                />
              )}
            />
            <Route
              exact
              path="/cart"
              render={(routeProps) => (
                <CartProvider>
                  <Cart
                    {...routeProps}
                    {...{
                      cookies: { ...cookies },
                      userData,
                      currentView: '/cart',
                      defaultCheckout,
                    }}
                  />
                </CartProvider>
              )}
            />
            <Route
              exact
              path="/stores"
              render={(routeProps) => (
                <Redirect to="/stores/covid-19-essentials" {...routeProps} {...{ defaultCheckout }} />
              )}
            />
            <Route
              exact
              path="/stores/:storeName?"
              render={(routeProps) => (
                <Stores
                  {...routeProps}
                  {...{
                    cookies: { ...cookies },
                    userData,
                    currentView: '/stores',
                    defaultCheckout,
                  }}
                  to="/marketplace"
                />
              )}
            />

            <Route
              exact
              path="/checkout"
              render={(routeProps) => (
                <Checkout
                  {...routeProps}
                  {...{
                    cookies: { ...cookies },
                    userData,
                    currentView: '/checkout',
                    defaultCheckout,
                    featureFlags: JSON.parse(JSON.stringify(props.featureFlags)),
                  }}
                />
              )}
            />

            <Route
              exact
              path="/submittedOrders"
              render={(routeProps) => (
                <SubmittedOrders
                  {...routeProps}
                  {...{
                    cookies: { ...cookies },
                    userData,
                    currentView: '/submittedOrders',
                  }}
                />
              )}
            />

            {MFE_ADMIN_PORTAL_PATH && MFE_ADMIN_PORTAL_PATH.length > 0 && (
              <Route
                path="/admin-portal"
                render={(routeProps) =>
                  userData?.activeUserSettings.Role === 'Admin' ? (
                    <AdminPortal {...{ ...routeProps, defaultCheckout }} {...{ userData }} />
                  ) : (
                    <Redirect to="/" {...routeProps} />
                  )
                }
              />
            )}

            <Route
              exact
              path="/manage"
              render={(routeProps) => (
                <Manage
                  {...routeProps}
                  {...{
                    cookies: { ...cookies },
                    userData,
                    currentView: '/manage',
                  }}
                />
              )}
            />

            <Route
              exact
              path="/product-view"
              render={(routeProps) => (
                <PoPages
                  {...routeProps}
                  {...{
                    cookies: { ...cookies },
                    userData,
                    currentView: '/product-view',
                    hasExtension,
                  }}
                />
              )}
            />
            <Route
              exact
              path="/supplier-search"
              render={(routeProps) => (
                <PoPages
                  {...routeProps}
                  {...{
                    cookies: { ...cookies },
                    userData,
                    currentView: '/supplier-search',
                    hasExtension,
                  }}
                />
              )}
            />
            <Route
              exact
              path="/update"
              render={(routeProps) => (
                <UpdateView
                  {...routeProps}
                  {...{
                    cookies: { ...cookies },
                    userData,
                    currentView: '/update',
                  }}
                />
              )}
            />
            <Route
              exact
              path="/account"
              render={(routeProps) => (
                <Account
                  {...routeProps}
                  {...{
                    cookies: { ...cookies },
                    userData,
                    currentView: '/account',
                    defaultCheckout,
                  }}
                />
              )}
            />

            <Route
              exact
              path="/onboarding"
              render={(routeProps) => (
                <OnBoarding
                  {...routeProps}
                  {...{
                    cookies: { ...cookies },
                    userData,
                    currentView: '/onboarding',
                    defaultCheckout,
                  }}
                />
              )}
            />
            <Route
              exact
              path="/punchout"
              render={(routeProps) => (
                <PoRedirects
                  {...routeProps}
                  {...{
                    cookies: { ...cookies },
                    userData,
                    currentView: '/Punchout',
                    hasExtension,
                  }}
                />
              )}
            />
            {props.featureFlags.f2098PoMarket2 && (
              <Route
                path="/requests/:page?"
                render={(routeProps) => (
                  <TabsManager
                    {...routeProps}
                    {...{
                      userData,
                      currentView: 'pending',
                      defaultCheckout,
                    }}
                  />
                )}
              />
            )}
            {(
              <Route
                path="/approvals/email/:token?"
                render={(routeProps) => (
                  <RequestActionByEmail
                    {...routeProps}
                    {...{
                      userData,
                      defaultCheckout,
                    }}
                  />
                )}
              />
            )}
            <Route
              path="/OrderEdit"
              render={(routeProps) => (
                <OrderEdit
                  {...routeProps}
                  {...{
                    userData,
                    defaultCheckout,
                  }}
                />
              )}
            />
            <Route
              exact
              path="/spendbridge/history"
              render={(routeProps) => (
                <Spendbridge
                  {...routeProps}
                  {...{
                    cookies: { ...cookies },
                    userData,
                    currentView: '/spendbridge/history',
                    defaultCheckout,
                  }}
                />
              )}
            />
            <Route
              exact
              path="/spendbridge/approvals"
              render={(routeProps) => (
                <Spendbridge
                  {...routeProps}
                  {...{
                    cookies: { ...cookies },
                    userData,
                    currentView: '/spendbridge/approvals',
                    defaultCheckout,
                  }}
                />
              )}
            />
            <Route
              exact
              path="/spendbridge/checkout"
              render={(routeProps) => (
                <Spendbridge
                  {...routeProps}
                  {...{
                    cookies: { ...cookies },
                    userData,
                    currentView: '/spendbridge/checkout',
                    defaultCheckout,
                  }}
                />
              )}
            />
            <Route
              exact
              path="/search/:searchTerm"
              render={(routeProps) => (
                <EQLSearch
                  {...routeProps}
                  {...{
                    cookies: { ...cookies },
                    userData,
                    currentView: '/marketplace',
                    hasExtension,
                  }}
                />
              )}
            />
            <Route
              exact
              path="/iframe/mini-cart"
              render={(routeProps) => (
                <MiniCartIframe
                  {...routeProps}
                  {...{
                    cookies: { ...cookies },
                    userData,
                    currentView: '/iframe-minicart',
                  }}
                />
              )}
            />
            <Route
              exact
              path="/logout"
              render={(routeProps) => (
                <Logout
                  {...routeProps}
                  {...{
                    cookies: { ...cookies },
                    userData,
                    currentView: '/logout',
                  }}
                />
              )}
            />
            <Route
              exact
              path="/login"
              render={(routeProps) => {
                getAdditionalLoginInfo(logout)
                return <Redirect to="/marketplace" {...routeProps} />
              }}
            />
            <Route
              exact
              path="/error"
              render={(routeProps) => (
                <Error
                  {...routeProps}
                  featureFlags={featureFlags}
                  {...{
                    featureFlags: JSON.parse(JSON.stringify(props.featureFlags)),
                  }}
                />
              )}
            />

            <Route
              path="/*"
              render={(routeProps) => (
                <NotFound
                  {...routeProps}
                  {...{
                    cookies: { ...cookies },
                    currentView: '/not found',
                    defaultCheckout,
                  }}
                />
              )}
            />
          </Switch>
        )}
      </UserDataContext.Provider>
    </Fragment>
  )
}

export const ProcurementRoutes = isUnsecured
  ? unAuthedProcurementRoutes
  : withAuthenticationRequired(unAuthedProcurementRoutes)
// function LogoutButton() {
//     const {
//         isAuthenticated,
//         logout,
//     } = useAuth0();

//     return isAuthenticated && (
//         <button onClick={() => {
//             logout({
//                 logoutParams: {
//                     returnTo: window.location.origin
//                 }
//             });
//         }}>Log out</button>
//     );
// }

export default {
  // SpendbridgeRoutes,
  ProcurementRoutes,
}
