export default [
    {
        title: 'COVID-19 Essentials',
        tagLine: 'All your COVID-19 essentials suppliers, all in one place',
        apiVersion: 'v1',
        suppliers: [1, 2, 31],
        categories: [
            {
                imageUrl: 'https://storeqmtprd.blob.core.windows.net/procurement/stores/ppe/Face-Masks-Web.jpg',
                queries: {
                    1: 'https://biz.officedepot.com/catalog/search.do;jsessionid=00009dFqzQ93etc2MJU5W-IkUe-:17ir6mhtn?N=1458952&recordsPerPageNumber=72&No=0',
                    '2':
                        'https://www.grainger.com/category/safety/respiratory-protection/procedural-masks?filters=shopItemsBy&shopItemsBy=Available+for+Purchase&cm_sp=Content_Spotlight-_-Procedural_Masks-_-P1-_-Availability_For_Purchase',
                    31: 'https://hdsupplysolutions.com/SearchDisplay?categoryId=&storeId=10051&catalogId=10054&langId=-1&sType=SimpleSearch&resultCatEntryType=2&showResultsPage=true&searchSource=Q&pageView=grid&beginIndex=0&pageSize=24&searchTerm=ear+loop+face+mask',
                },
                subFilters: [
                    {
                        name: 'Type',
                        options: [
                            {
                                value: 'Any Type',
                            },
                            {
                                value: 'Cloth',
                                queries: {
                                    1: 'https://biz.officedepot.com/catalog/catalogSku.do;jsessionid=0000c0ieC88ubIsLSKkJzXOsElF:17ir6mgqi?id=9885276&customerEnteredSku=&pr=',
                                    '2':
                                        'https://www.grainger.com/category/safety/respiratory-protection/reusable-respirators-masks-replacement-filters-cartridges/cloth-face-masks?attrs=Mask+Style%7CEarloop+Mask&filters=attrs',
                                    '31':
                                        'https://hdsupplysolutions.com/c/masks-00-57-5-10-20?facetName_1=100%2FPack&fromPage=catalogEntryList&beginIndex=0&pageSize=96&facet_1=ads_f21067_ntk_cs%253A%2522100%252FPack%2522&pageView=grid',
                                },
                            },
                            {
                                value: 'Disposable Respirators',
                                queries: {
                                    '1':
                                        'https://biz.officedepot.com/catalog/search.do;jsessionid=0000c0ieC88ubIsLSKkJzXOsElF:17ir6mgqi?N=654568&recordsPerPageNumber=72&No=0',
                                    '2':
                                        'https://www.grainger.com/category/safety/respiratory-protection/disposable-respirators-masks/disposable-respirators-and-dust-masks',
                                    '31':
                                        'https://hdsupplysolutions.com/c/disposable-respirators-00-90-65-10?fromPage=catalogEntryList&beginIndex=0&pageSize=96&pageView=grid',
                                },
                            },
                            {
                                value: 'Procedural',
                                queries: {
                                    1: 'https://biz.officedepot.com/catalog/browseCatalog.do;jsessionid=0000c0ieC88ubIsLSKkJzXOsElF:17ir6mgqi?N=1464295',
                                    '2':
                                        'https://www.grainger.com/category/safety/respiratory-protection/disposable-respirators-masks/healthcare-face-masks',
                                    '31':
                                        'https://hdsupplysolutions.com/c/masks-00-57-5-10-20?facetName_1=Between+%2425+and+%2449.99&fromPage=catalogEntryList&beginIndex=0&pageSize=96&facet_1=price_USD%253A%2528%257B25%2B49.99%257D%2B49.99%2529&pageView=grid',
                                },
                            },
                        ],
                    },
                ],
                name: 'Face Masks',
            },
            {
                imageUrl: 'https://storeqmtprd.blob.core.windows.net/procurement/stores/ppe/Gloves-Web.jpg',
                queries: {
                    '1':
                        'https://biz.officedepot.com/catalog/search.do;jsessionid=00009dFqzQ93etc2MJU5W-IkUe-:17ir6mhtn?N=696178&seoText=&cbxRefine=315248&cbxRefine=315251&cbxRefine=315252&recordsPerPageNumber=72&No=0',
                    '2':
                        'https://www.grainger.com/category/safety/hand-arm-protection/safety-gloves/disposable-gloves/general-purpose-disposable-gloves?filters=shopItemsBy&shopItemsBy=Available+for+Purchase',
                    '31':
                        'https://hdsupplysolutions.com/c/disposable-gloves-00-90-40-30?fromPage=catalogEntryList&beginIndex=0&pageSize=96&orderBy=5&pageView=grid',
                },
                multiSelect: true,
                subFilters: [
                    {
                        name: 'default',
                        options: [
                            {
                                value: 'Any size and material',
                            },
                        ],
                    },
                    {
                        name: 'Glove Size',
                        options: [
                            {
                                value: 'X-Small',
                                queries: {
                                    '1':
                                        'https://biz.officedepot.com/catalog/search.do;jsessionid=0000KdH07PCIW16i31JgvSQ0Ffr:17ir6mhtn?N=696178&seoText=&cbxRefine=315248&cbxRefine=315251&cbxRefine=315252&cbxRefine=905724&recordsPerPageNumber=72&No=0',
                                    '2':
                                        'https://www.grainger.com/category/safety/hand-arm-protection/safety-gloves/disposable-gloves/general-purpose-disposable-gloves?attrs=Glove+Sizes%7CXS%2F6&filters=attrs,shopItemsBy&shopItemsBy=Available+for+Purchase',
                                    '31': '',
                                },
                            },
                            {
                                value: 'Small',
                                queries: {
                                    1: 'https://biz.officedepot.com/catalog/browseCatalog.do;jsessionid=0000KdH07PCIW16i31JgvSQ0Ffr:17ir6mhtn?N=696178&seoText=&cbxRefine=315248&cbxRefine=315251&cbxRefine=315252&cbxRefine=632075',
                                    2: 'https://www.grainger.com/category/safety/hand-arm-protection/safety-gloves/disposable-gloves/general-purpose-disposable-gloves?attrs=Glove+Sizes%7CS%2F7&filters=attrs,shopItemsBy&shopItemsBy=Available+for+Purchase',
                                    '31':
                                        'https://hdsupplysolutions.com/c/disposable-gloves-00-90-40-30?facetName_1=Small&fromPage=catalogEntryList&beginIndex=0&pageSize=96&orderBy=5&facet_1=ads_f10123_ntk_cs%253A%2522Small%2522&pageView=grid',
                                },
                            },
                            {
                                value: 'Medium',
                                queries: {
                                    1: 'https://biz.officedepot.com/catalog/browseCatalog.do;jsessionid=0000KdH07PCIW16i31JgvSQ0Ffr:17ir6mhtn?N=696178&seoText=&cbxRefine=315248&cbxRefine=315251&cbxRefine=315252&cbxRefine=632038',
                                    '2':
                                        'https://www.grainger.com/category/safety/hand-arm-protection/safety-gloves/disposable-gloves/general-purpose-disposable-gloves?attrs=Glove+Sizes%7CM%2F8&filters=attrs,shopItemsBy&shopItemsBy=Available+for+Purchase',
                                    '31':
                                        'https://hdsupplysolutions.com/c/disposable-gloves-00-90-40-30?facetName_1=Medium&fromPage=catalogEntryList&beginIndex=0&pageSize=96&orderBy=5&facet_1=ads_f10123_ntk_cs%253A%2522Medium%2522&pageView=grid',
                                },
                            },
                            {
                                value: 'Large',
                                queries: {
                                    '1':
                                        'https://biz.officedepot.com/catalog/browseCatalog.do;jsessionid=0000KdH07PCIW16i31JgvSQ0Ffr:17ir6mhtn?N=696178&seoText=&cbxRefine=315248&cbxRefine=315251&cbxRefine=315252&cbxRefine=632023',
                                    2: 'https://www.grainger.com/category/safety/hand-arm-protection/safety-gloves/disposable-gloves/general-purpose-disposable-gloves?attrs=Glove+Sizes%7CL%2F9&filters=attrs,shopItemsBy&shopItemsBy=Available+for+Purchase',
                                    '31':
                                        'https://hdsupplysolutions.com/c/disposable-gloves-00-90-40-30?facetName_1=Large&fromPage=catalogEntryList&beginIndex=0&pageSize=96&orderBy=5&facet_1=ads_f10123_ntk_cs%253A%2522Large%2522&pageView=grid',
                                },
                            },
                            {
                                value: 'X-Large',
                                queries: {
                                    '1':
                                        'https://biz.officedepot.com/catalog/browseCatalog.do;jsessionid=0000KdH07PCIW16i31JgvSQ0Ffr:17ir6mhtn?N=696178&seoText=&cbxRefine=315248&cbxRefine=315251&cbxRefine=315252&cbxRefine=632091',
                                    2: 'https://www.grainger.com/category/safety/hand-arm-protection/safety-gloves/disposable-gloves/general-purpose-disposable-gloves?attrs=Glove+Sizes%7CXL%2F10&filters=attrs,shopItemsBy&shopItemsBy=Available+for+Purchase',
                                    '31':
                                        'https://hdsupplysolutions.com/c/disposable-gloves-00-90-40-30?facetName_3=XL&facetName_2=X-Large&facetName_1=Extra+Large&pageSize=96&orderBy=5&facet_1=ads_f10123_ntk_cs%253A%2522Extra%2BLarge%2522&facet_2=ads_f10123_ntk_cs%253A%2522X-Large%2522&pageView=grid&facet_3=ads_f10123_ntk_cs%253A%2522XL%2522&fromPage=catalogEntryList&beginIndex=0',
                                },
                            },
                            {
                                value: 'XX-Large',
                                queries: {
                                    1: 'https://biz.officedepot.com/catalog/search.do;jsessionid=0000KdH07PCIW16i31JgvSQ0Ffr:17ir6mhtn?N=696178&seoText=&cbxRefine=315248&cbxRefine=315251&cbxRefine=315252&cbxRefine=632098&recordsPerPageNumber=72&No=0',
                                    '2':
                                        'https://www.grainger.com/category/safety/hand-arm-protection/safety-gloves/disposable-gloves/general-purpose-disposable-gloves?attrs=Glove+Sizes%7C2XL%2F11&filters=attrs,shopItemsBy&shopItemsBy=Available+for+Purchase',
                                    '31':
                                        'https://hdsupplysolutions.com/c/disposable-gloves-00-90-40-30?facetName_1=XX-Large&fromPage=catalogEntryList&beginIndex=0&pageSize=96&orderBy=5&facet_1=ads_f10123_ntk_cs%253A%2522XX-Large%2522&pageView=grid',
                                },
                            },
                        ],
                    },
                    {
                        name: 'Glove Material',
                        options: [
                            {
                                value: 'Latex',
                                queries: {
                                    1: 'https://biz.officedepot.com/catalog/search.do;jsessionid=0000hkr888eoNYdt0pEozO_Pmnx:17ir6mgqi?Nty=1&Ntx=mode+matchpartialmax&Ntk=all&Ntt=latex+disposable+gloves&N=0&cbxRefine=1459683&cbxRefine=723115',
                                    2: 'https://www.grainger.com/category/safety/hand-arm-protection/safety-gloves/disposable-gloves/general-purpose-disposable-gloves?attrs=Glove+Materials%7CLatex&filters=attrs,shopItemsBy&shopItemsBy=Available+for+Purchase',
                                    '31':
                                        'https://hdsupplysolutions.com/c/disposable-gloves-00-90-40-30?facetName_1=Latex&fromPage=catalogEntryList&beginIndex=0&pageSize=96&orderBy=5&facet_1=ads_f10153_ntk_cs%253A%2522Latex%2522&pageView=grid',
                                },
                            },
                            {
                                value: 'Neoprene',
                                queries: {
                                    1: 'https://biz.officedepot.com/catalog/search.do;jsessionid=0000KdH07PCIW16i31JgvSQ0Ffr:17ir6mhtn?Ntt=disposable+gloves+neoprene',
                                    2: 'https://www.grainger.com/category/safety/hand-arm-protection/safety-gloves/disposable-gloves/general-purpose-disposable-gloves?attrs=Glove+Materials%7CNeoprene&filters=attrs,shopItemsBy&shopItemsBy=Available+for+Purchase',
                                    '31': '',
                                },
                            },
                            {
                                value: 'Nitrile',
                                queries: {
                                    '1':
                                        'https://biz.officedepot.com/catalog/search.do;jsessionid=0000KdH07PCIW16i31JgvSQ0Ffr:17ir6mhtn?Nty=1&Ntx=mode+matchpartialmax&Ntk=all&Ntt=disposable+gloves+nitrile&N=0&recordsPerPageNumber=72&No=0',
                                    '2':
                                        'https://www.grainger.com/category/safety/hand-arm-protection/safety-gloves/disposable-gloves?refineSearchString=nitrile',
                                    '31':
                                        'https://hdsupplysolutions.com/c/disposable-gloves-00-90-40-30?facetName_1=Nitrile&fromPage=catalogEntryList&beginIndex=0&pageSize=96&orderBy=5&facet_1=ads_f10153_ntk_cs%253A%2522Nitrile%2522&pageView=grid',
                                },
                            },
                            {
                                value: 'Vinyl',
                                queries: {
                                    '1':
                                        'https://biz.officedepot.com/catalog/search.do;jsessionid=0000KdH07PCIW16i31JgvSQ0Ffr:17ir6mhtn?Ntt=disposable+gloves+vinyl',
                                    2: 'https://www.grainger.com/category/safety/hand-arm-protection/safety-gloves/disposable-gloves/general-purpose-disposable-gloves?attrs=Glove+Materials%7CVinyl&filters=attrs,shopItemsBy&shopItemsBy=Available+for+Purchase',
                                    '31':
                                        'https://hdsupplysolutions.com/c/disposable-gloves-00-90-40-30?facetName_1=Vinyl&fromPage=catalogEntryList&beginIndex=0&pageSize=96&orderBy=5&facet_1=ads_f10153_ntk_cs%253A%2522Vinyl%2522&pageView=grid',
                                },
                            },
                        ],
                    },
                ],
                name: 'Gloves',
            },
            {
                imageUrl: 'https://storeqmtprd.blob.core.windows.net/procurement/stores/ppe/Face-Shields-Web.jpg',
                queries: {
                    '1':
                        'https://biz.officedepot.com/catalog/search.do;jsessionid=00009dFqzQ93etc2MJU5W-IkUe-:17ir6mhtn?N=654552&recordsPerPageNumber=72&No=0',
                    '2': 'https://www.grainger.com/category/safety/face-protection/faceshield-assemblies',
                    31: 'https://hdsupplysolutions.com/SearchDisplay?categoryId=20733&storeId=10051&catalogId=10054&langId=-1&sType=SimpleSearch&resultCatEntryType=2&showResultsPage=true&searchSource=Q&pageView=grid&beginIndex=0&pageSize=24&searchTerm=clear+face+shield&addQuantity_531095=1&addQuantity_121782=1&addQuantity_116276=1&selectedCategoryIds=20733&parentCat=20733',
                },
                name: 'Face Shields',
            },
            {
                imageUrl: 'https://storeqmtprd.blob.core.windows.net/procurement/stores/ppe/disinfectants-Web.jpg',
                queries: {
                    1: 'https://biz.officedepot.com/catalog/search.do;jsessionid=00006sX0d-FW9mbaTxAheGdjI2K:17ir6mibu?Ntt=disinfectant',
                    '2':
                        'https://www.grainger.com/category/cleaning-and-janitorial/cleaning-chemicals/disinfectants-and-sanitizers',
                    31: 'https://hdsupplysolutions.com/c/disinfectants-00-85-5-25?fromPage=catalogEntryList&beginIndex=0&pageSize=96&pageView=grid',
                },
                name: 'Disinfectants',
            },
            {
                imageUrl: 'https://storeqmtprd.blob.core.windows.net/procurement/stores/ppe/Hand-Sanitizer-Web.jpg',
                queries: {
                    1: 'https://biz.officedepot.com/catalog/browseCatalog.do;jsessionid=0000sK1iUy-mB418am-73PRmEmN:17ir6mibu?N=1170286&seoText=&cbxRefine=1183179',
                    '2':
                        'https://www.grainger.com/category/cleaning-and-janitorial/hand-and-personal-care/hand-care/hand-sanitizer-and-dispensers/hand-sanitizer-and-wipes',
                    '31':
                        'https://hdsupplysolutions.com/c/hand-sanitizers-00-85-20-5?fromPage=catalogEntryList&beginIndex=0&pageSize=96&orderBy=5&pageView=grid',
                },
                subFilters: [
                    {
                        name: 'Type',
                        options: [
                            {
                                value: 'Any Type',
                            },
                            {
                                value: 'Pump',
                                queries: {
                                    '1':
                                        'https://biz.officedepot.com/catalog/search.do;jsessionid=0000FCMMG6vUQfYmmlwbaYhBYxd:17ir6mhtn?Nty=1&Ntx=mode+matchpartialmax&Ntk=all&Ntt=hand+sanitizer+pump&N=0&recordsPerPageNumber=72&No=0',
                                    '2':
                                        'https://www.grainger.com/category/cleaning-and-janitorial/hand-and-personal-care/hand-care/hand-sanitizer-and-dispensers/hand-sanitizer-and-wipes?attrs=Packaging+Type%7CPump+Bottle&filters=attrs',
                                    '31':
                                        'https://hdsupplysolutions.com/SearchDisplay?emsName=Widget_CatalogEntryList_801_35957&langId=-1&urlRequestType=Base&showResultsPage=true&categoryId=21253&resultType=products&sType=SimpleSearch&ajaxStoreImageDir=%2Fwcsstore%2FHDSB2BV2StorefrontAssetStore%2F&ddkey=ProductListingView_11_-3011_16957&resultCatEntryType=2&enableSKUListView=false&disableProductCompare=false&catalogId=10054&pageView=grid&searchTerm=hand+sanitizer+pump&storeId=10051&beginIndex=0&pageSize=96&fromPage=catalogEntryList',
                                },
                            },
                            {
                                value: 'Refill',
                                queries: {
                                    1: 'https://biz.officedepot.com/catalog/search.do;jsessionid=0000FCMMG6vUQfYmmlwbaYhBYxd:17ir6mhtn?N=1170286&seoText=&cbxRefine=1465265&recordsPerPageNumber=72&No=0',
                                    2: 'https://www.grainger.com/category/cleaning-and-janitorial/hand-and-personal-care/hand-care/hand-sanitizer-and-dispensers/hand-sanitizer-and-wipes?attrs=Packaging+Type%7CCartridge%7CDrum%7CJug&filters=attrs',
                                    31: 'https://hdsupplysolutions.com/SearchDisplay?emsName=Widget_CatalogEntryList_801_35957&langId=-1&urlRequestType=Base&showResultsPage=true&categoryId=25613&resultType=products&sType=SimpleSearch&ajaxStoreImageDir=%2Fwcsstore%2FHDSB2BV2StorefrontAssetStore%2F&facetName_2=Refill+Works+With+Es8+Touch-Free+Dispenser+%287724-01%2C+7720-01%29&facetName_1=Yes&ddkey=ProductListingView_11_-3011_16957&resultCatEntryType=2&enableSKUListView=false&disableProductCompare=false&catalogId=10054&pageView=grid&facet_2=ads_f20570_ntk_cs%253A%2522Refill%2BWorks%2BWith%2BEs8%2BTouch-Free%2BDispenser%2B%25287724-01%252C%2B7720-01%2529%2522&searchTerm=hand+sanitizer&facet_1=ads_f20570_ntk_cs%253A%2522Yes%2522&storeId=10051&beginIndex=0&pageSize=96&fromPage=catalogEntryList',
                                },
                            },
                            {
                                value: 'Wipes',
                                queries: {
                                    1: 'https://biz.officedepot.com/catalog/search.do;jsessionid=0000FCMMG6vUQfYmmlwbaYhBYxd:17ir6mhtn?N=1170286&seoText=&cbxRefine=1465263&recordsPerPageNumber=72&No=0',
                                    '2':
                                        'https://www.grainger.com/category/cleaning-and-janitorial/hand-and-personal-care/hand-care/hand-sanitizer-and-dispensers/hand-sanitizer-and-wipes?attrs=Hygiene+Product+Grouping%7CHand+Sanitizer+Wipes&filters=attrs',
                                    31: 'https://hdsupplysolutions.com/SearchDisplay?emsName=Widget_CatalogEntryList_801_35957&langId=-1&urlRequestType=Base&showResultsPage=true&categoryId=21171&resultType=products&sType=SimpleSearch&ajaxStoreImageDir=%2Fwcsstore%2FHDSB2BV2StorefrontAssetStore%2F&facetName_1=Package&ddkey=ProductListingView_11_-3011_16957&resultCatEntryType=2&enableSKUListView=false&disableProductCompare=false&catalogId=10054&pageView=grid&searchTerm=hand+sanitizer+wipes&facet_1=ads_f41502_ntk_cs%253A%2522Package%2522&storeId=10051&beginIndex=0&pageSize=96&fromPage=catalogEntryList',
                                },
                            },
                        ],
                    },
                ],
                name: 'Hand Sanitizer',
            },
            {
                imageUrl: 'https://storeqmtprd.blob.core.windows.net/procurement/stores/ppe/wipes-Web.jpg',
                queries: {
                    '1':
                        'https://biz.officedepot.com/catalog/search.do;jsessionid=0000qXiAYoxM1bFJL5bMd-2Kibh:17ir6mgqi?No=0&Nty=1&Ntx=mode+matchpartialmax&Ntk=all&Ntt=disinfecting+wipes&Nu=p_STYLE_ID&N=0&Ns=p_CONTRACT',
                    2: 'https://www.grainger.com/category/cleaning-and-janitorial/cleaning-chemicals/wet-cleaning-wipes-and-dispensers/wet-cleaning-wipes?attrs=Cleaning+Wipe+-+Item%7CDisinfecting+Cleaning+Wipes%7CGym+Equipment+Disinfecting+Wipes%7CSanitizing+Cleaning+Wipes&filters=attrs',
                    '31':
                        'https://hdsupplysolutions.com/SearchDisplay?langId=-1&urlRequestType=Base&showResultsPage=true&categoryId=25675&resultType=products&sType=SimpleSearch&facetName_8=Sani-Cloth&facetName_7=Klean-up&ajaxStoreImageDir=%2Fwcsstore%2FHDSB2BV2StorefrontAssetStore%2F&facetName_6=Lysol&facetName_5=Gym+Wipes&facetName_4=Frost+King&facetName_3=Fabuloso&facetName_2=Clorox&facetName_1=Cavicide&ddkey=ProductListingView_1_-3028_16977&resultCatEntryType=2&facet_8=mfName_ntk_cs%253A%2522Sani-Cloth%2522&facet_7=mfName_ntk_cs%253A%2522Klean-up%2522&facet_6=mfName_ntk_cs%253A%2522Lysol%2522&facet_5=mfName_ntk_cs%253A%2522Gym%2BWipes%2522&catalogId=10054&facet_4=mfName_ntk_cs%253A%2522Frost%2BKing%2522&facet_3=mfName_ntk_cs%253A%2522Fabuloso%2522&pageView=grid&facet_2=mfName_ntk_cs%253A%2522Clorox%2522&searchTerm=%22disinfecting+wipes%22&facet_1=mfName_ntk_cs%253A%2522Cavicide%2522&storeId=10051&beginIndex=0&pageSize=96&fromPage=catalogEntryList',
                },
                name: 'Wipes',
            },
            {
                imageUrl: 'https://storeqmtprd.blob.core.windows.net/procurement/stores/ppe/thermometer-Web.jpg',
                queries: {
                    1: 'https://biz.officedepot.com/catalog/search.do;jsessionid=0000qXiAYoxM1bFJL5bMd-2Kibh:17ir6mgqi?N=1181091&recordsPerPageNumber=72&No=0',
                    2: 'https://www.grainger.com/search/safety/emergency-and-exam-room-supplies/medical-thermometers-temperature-screening-devices?attrs=Item%7CAdjunctive+Temperature+Screening+Device%7CDisposable+Thermometer%7CFace+Recognition+Thermal+Imaging%7CNon-Contact+Forehead+Infrared+Thermometer%7CNon-Contact+Infrared+Thermometer&filters=attrs&searchQuery=thermometers&sst=1&gwwRemoveElement=true&ts_optout=true',
                    '31':
                        'https://hdsupplysolutions.com/c/thermometers-00-170-55-65?fromPage=catalogEntryList&beginIndex=0&pageSize=96&pageView=grid',
                },
                name: 'Thermometers',
            },
        ],
        url: 'covid-19-essentials',
        iconUrl: 'https://storeqmtprd.blob.core.windows.net/procurement/stores/ppe/PPE-Icon-Web.png',
    },
    {
        title: 'Air Filters',
        tagLine: 'All your air filter suppliers, all in one place',
        suppliers: [33, 46, 1, 2, 31],
        apiVersion: 'v2',
        url: 'air-filters',
        iconUrl: 'https://storeauroraqa.blob.core.windows.net/procurement/stores/airfilters/Air-Filters-Logo.png',
        categories: [
            {
                name: 'Pleated',
                imageUrl:
                    'https://storeauroraqa.blob.core.windows.net/procurement/stores/airfilters/Pleated-Filter.png',
                queries: null,
                rootQueries: {
                    1: {
                        url:
                            'https://biz.officedepot.com/catalog/search.do;jsessionid=0000zVLrIsmF1suJKDnwyW5QNb0:17ir6mgqi?Ntk=all&N=0&recordsPerPageNumber=72&No=0&Ntt=pleated+air+filter',
                        spacer: '+',
                        query: 'airfilter pleated',
                    },
                    2: {
                        url: 'https://www.grainger.com/search?searchBar=true&searchQuery=Pleated+Air+Filter',
                        spacer: '+',
                        query: 'airfilter pleated',
                    },
                    31: {
                        url:
                            'https://hdsupplysolutions.com/SearchDisplay?categoryId=&storeId=10051&catalogId=10054&langId=-1&sType=SimpleSearch&resultCatEntryType=2&showResultsPage=true&searchSource=Q&pageView=grid&beginIndex=0&pageSize=96&searchTerm=Pleated+Air+Filter',
                        spacer: '+',
                        query: 'airfilter pleated',
                    },
                    33: {
                        url: 'koch',
                        spacer: '+',
                        query: 'airfilter pleated',
                    },
                    46: {
                        url: 'tridim',
                        spacer: '+',
                        query: 'airfilter pleated',
                    },
                },
                subFilters: [
                    {
                        name: 'Dimensions',
                        required: true,
                        type: {
                            value: 'multiInput',
                            composition: [
                                {
                                    id: 'width',
                                    component: 'input',
                                    type: 'number',
                                    placeholder: 'W',
                                    connector: 'x',
                                },
                                {
                                    component: 'icon',
                                    value: 'far fa-times',
                                },
                                {
                                    id: 'height',
                                    component: 'input',
                                    type: 'number',
                                    placeholder: 'H',
                                    connector: 'x',
                                },
                                {
                                    component: 'icon',
                                    value: 'far fa-times',
                                },
                                {
                                    id: 'depth',
                                    component: 'input',
                                    type: 'number',
                                    placeholder: 'D',
                                    connector: 'x',
                                },
                            ],
                        },
                    },
                    {
                        name: 'MERV',
                        options: [
                            {
                                value: 'Any MERV',
                            },
                            {
                                value: 'MERV 6',
                                subUrl: 'merv+6',
                                subQuery: 'merv6',
                            },
                            {
                                value: 'MERV 7',
                                subUrl: 'merv+7',
                                subQuery: 'merv7',
                            },
                            {
                                value: 'MERV 8',
                                subUrl: 'merv+8',
                                subQuery: 'merv8',
                            },
                            {
                                value: 'MERV 9',
                                subUrl: 'merv+9',
                                subQuery: 'merv9',
                            },
                            {
                                value: 'MERV 10',
                                subUrl: 'merv+10',
                                subQuery: 'merv10',
                            },
                            {
                                value: 'MERV 11',
                                subUrl: 'merv+11',
                                subQuery: 'merv11',
                            },
                            {
                                value: 'MERV 12',
                                subUrl: 'merv+12',
                                subQuery: 'merv12',
                            },
                            {
                                value: 'MERV 13',
                                subUrl: 'merv+13',
                                subQuery: 'merv13',
                            },
                            {
                                value: 'MERV 14',
                                subUrl: 'merv+14',
                                subQuery: 'merv14',
                            },
                            {
                                value: 'MERV 15',
                                subUrl: 'merv+15',
                                subQuery: 'merv15',
                            },
                            {
                                value: 'MERV 16',
                                subUrl: 'merv+16',
                                subQuery: 'merv16',
                            },
                        ],
                    },
                ],
            },
        ],
    },
];
