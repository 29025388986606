import styled from 'styled-components';

const CheckoutWrapper = styled.div`
    background: #f4f5f6;

    .wrapper {
        background: #f4f5f6;
    }
`;

export default {
    CheckoutWrapper,
};
