import React, { useState, Fragment, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { checkImageUrl } from "../../../utils/checkImageUrl";
import Header from "../../components/Header";
import { fetchData } from "../../../utils/webFetch";
import { removeUserData } from '../../../utils/localStorage';
import ValueFinder from "./valueFinder";
import postAnalytics from '../../../utils/postAnalytics.js';

import "./styles.css";

const isBrowser = typeof window !== "undefined" && window.env;

export const Account = (props) => {
  const { logout } = useAuth0();

  const [state, setState] = useState({ userData: props.userData })

  useEffect(() => {
    const settings = (state.userData && state.userData.Network[state.userData.activeNetworkIndex] && state.userData.Network[state.userData.activeNetworkIndex].Setting && JSON.parse(state.userData.Network[state.userData.activeNetworkIndex].Setting)) || {}
    const priceLimit = settings && settings.PriceLimit
    const priceRadioOption = priceLimit ? { target: { className: getPriceOpt(priceLimit) } } : { target: { className: 'priceOpt3' } }
    const marketplaceLimit = settings
    const isExtensionInstalled = checkIfExtensionInstalled()

    setState((oldState) => {
      return {
        ...oldState,
        priceOpt: priceRadioOption.target.className,
        isExtensionInstalled,
        marketOpt: marketplaceLimit
      }
    })
  }, [])

  const checkIfExtensionInstalled = () => {
    const hasExtension = isBrowser ? document.getElementById('aurora-ext-enabled') : true
    setState((oldState) => {
      return {
        ...oldState,
        hasExtension: hasExtension ? true : false
      }
    })
  }

  const handleLogOut = async () => {
    postAnalytics('pm-accountManage-accountCard-click-logout', state.userData);
  
    let UserActivityEvent = new CustomEvent("UserActivityEvent", {
      detail: { activeNetwork: null },
    });
    if (document) document.dispatchEvent(UserActivityEvent);

    removeUserData();

    await setTimeout(() => {
      logout({ logoutParams: { returnTo: window.location.origin } });
    }, 1000);
  };

  const getPriceOpt = (priceLimit) => {
    const limit = priceLimit.toString(10);
    const priceMap = {
      "100": "priceOpt1",
      "1": "priceOpt2",
      "1.1": "priceOpt3",
    };
    return priceMap[limit];
  };

  const submitSettings = async (event) => {
    const userData = state.userData;

    const currentSettings = JSON.parse(
      userData.Network[userData.activeNetworkIndex].Setting
    );
    let updatedNetworkSettings = { ...currentSettings };
    let selectedPriceLimit = { PriceLimit: 1.1 }
    let selectedPriceToggleId = Array.from(document.querySelectorAll('#priceLimit-radio input')).find(el => el.checked).id

    switch (selectedPriceToggleId) {
      case "radio1":
        selectedPriceLimit = { PriceLimit: 100 }
        break;
      case "radio2":
        selectedPriceLimit = { PriceLimit: 1 }
        break;
      case "radio3":
        selectedPriceLimit = { PriceLimit: 1.1 }
        break;
      default:
        selectedPriceLimit = { PriceLimit: 1.1 }
        break;
    }

    updatedNetworkSettings = {
      ...currentSettings,
      ...selectedPriceLimit
    }

    let updatedNetwork = userData.Network;
    updatedNetwork[userData.activeNetworkIndex].Setting = JSON.stringify(
      updatedNetworkSettings
    );
    const updateduserData = {
      ...userData,
      Network: updatedNetwork,
      activeUserSettings: updatedNetworkSettings,
    };
    const settingsResult = await fetchData.setUserSettings(
      userData.Network[userData.activeNetworkIndex].UserToken,
      { Setting: JSON.stringify(updatedNetworkSettings) }
    );

    if (document && settingsResult) {
      const UserActivityEvent = new CustomEvent("UserActivityEvent", {
        detail: { activeNetwork: updateduserData.Network[updateduserData.activeNetworkIndex] },
      });
      const nextYear = new Date(
        new Date().setFullYear(new Date().getFullYear() + 1)
      );
      const data = JSON.stringify(updateduserData) + "; expires=" + nextYear;
      document.dispatchEvent(UserActivityEvent);
      cookieMonster.setCookie("userData", data, 0, 10, 1);
    }
    const newState = {
      userData: updateduserData,

      priceOpt: getPriceOpt(updatedNetworkSettings.PriceLimit),
    };
    window.localStorage.setItem("userData", JSON.stringify(updateduserData));
    setState(newState);
  };

  return (
    <Fragment>
      <div className="container">
        <Header {...props}></Header>

        <main role="main" className="card-page acc-main">
          <article className="account" style={{ minHeight: "auto" }}>

            <section>
              <div className="card">
                <div className="profile-info">
                  {checkImageUrl(
                    state.userData.ImageUrl,
                    "profile",
                    "profile-pic"
                  )}
                  <div className="profile-name-email">
                    <p className="name">
                      {state.userData.FirstName}{" "}
                      {state.userData.LastName}
                    </p>
                    <p className="email">{state.userData.Email}</p>
                    <button
                      className="log-out"
                      data-aurora-automation="account-logout"
                      onClick={async () => await handleLogOut()}
                    >
                      LOGOUT
                    </button>
                  </div>
                </div>
              </div>
            </section>
            {/* <section>
                  <div className="settings">
                    <h2>Settings</h2>
                    {state.LegacyMarketToggle && (
                      <div className="checkBoxHeader">
                        <label
                          className={
                            "checkBox" +
                            (state.LegacyMarket ? "" : " disabled")
                          }
                        >
                          <p>Use legacy Marketplace</p>
                          <input
                            data-aurora-automation="account-settings-legacy-market"
                            defaultChecked={state.LegacyMarket}
                            value={state.LegacyMarket}
                            type="checkbox"
                            id="marketcheckbox"
                            className="marketToggle"
                            onChange={(event) => {
                              submitSettings(event);
                            }}
                          />
                          <span
                            className={
                              "checkmark" +
                              (state.LegacyMarket ? "" : " disabled")
                            }
                          >
                            {" "}
                          </span>
                        </label>
                      </div>
                    )}

                    <div className="CO-radio-group" id="priceLimit-radio">
                      <p>Product Results</p>
                      <div className={`radioItem${state.priceOpt === "priceOpt1"? ' checked': ''}`}>
                        <input
                          id="radio1"
                          name="radio"
                          type="radio"
                          checked={state.priceOpt === "priceOpt1"}
                          className="priceOpt1"
                          onChange={(event) => {
                            submitSettings(event);
                          }}
                        />
                        <label
                          data-aurora-automation="account-settings-show-all"
                          htmlFor="radio1"
                        >
                          Show all
                        </label>
                      </div>
                      <div className={`radioItem${state.priceOpt === "priceOpt2"? ' checked': ''}`}>
                        <input
                          id="radio2"
                          name="radio"
                          type="radio"
                          checked={state.priceOpt === "priceOpt2"}
                          className="priceOpt2"
                          onChange={(event) => {
                            submitSettings(event);
                          }}
                        />
                        <label
                          data-aurora-automation="account-settings-lower-only"
                          htmlFor="radio2"
                        >
                          Lower prices only
                        </label>
                      </div>
                      <div className={`radioItem${state.priceOpt === "priceOpt3"? ' checked': ''}`}>
                        <input
                          id="radio3"
                          name="radio"
                          type="radio"
                          checked={state.priceOpt === "priceOpt3"}
                          className="priceOpt3"
                          onChange={(event) => {
                            submitSettings(event);
                          }}
                        />
                        <label
                          data-aurora-automation="account-settings-within-10percent"
                          htmlFor="radio3"
                        >
                          Show prices within 10%
                        </label>
                      </div>
                    </div>
                    <div className="radio-group">
                            <p>Marketplace Version</p>
                            <div className="radioItem">
                                <input id="radio4" name="radio" type="radio" checked={!state.marketOpt} className="marketOpt2" onChange={event=>{submitSettings(event)}}/>
                                <label htmlFor="radio4">New Marketplace (recommended)</label>
                            </div>
                            <div className="radioItem" >
                                <input id="radio3" name="radio" type="radio" checked={state.marketOpt} className="marketOpt1" onChange={event=>{submitSettings(event)}}/>
                                <label htmlFor="radio5">Legacy Marketplace</label>
                            </div>
                        </div>
                  </div>
                  
                </section> */}

            {/* <div className="bannerDiv">
                <section className="finderSection">
                  {!state.hasExtension && <ValueFinder />}
                </section>
              </div> */}
            {/* {state.isExtensionInstalled && (
                <section>
                  <div className="card extension-info">
                    <h2>Never miss out on the best value!</h2>
                    <a href="https://chrome.google.com/webstore/detail/qmerit-marketplace/iliobjcmbhjgdnlhinojfnbphecbekgo">
                      <img
                        src="/images/ChromeWebStore_Badge_v2_496x150.png"
                        alt="chrome web store"
                      />
                    </a>
                    <p>
                      Qmerit's Best Value Finder assists you while you shop
                      online.
                    </p>
                    <ul>
                      <li>Compare products from your preferred suppliers</li>
                      <li>Price check products in real-time</li>
                      <li>
                        Also works across non-contracted suppliers including:
                        Staples, The Home Depot, and more…
                      </li>
                    </ul>
                  </div>
                </section>
              )} */}

            {/* <a className="feedback" target="_blank" rel="noopener noreferrer" href="https://survey.qmerit.com/survey/yjj5tm">GIVE US YOUR FEEDBACK!</a> */}
          </article>
          {/* {!state.isExtensionInstalled && <ValueFinder />} */}
          {/* <ValueFinder /> */}
        </main>
      </div>
    </Fragment>
  );
}

export default Account;
