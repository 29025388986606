import styled from 'styled-components';

const AddressModalContainer = styled.div`
    .addressModal {
        padding: 1rem;
        min-height: 26.25rem;
        max-height: 40vh;
        margin: 0px;
        overflow-y: hidden;

        h3 {
            font-size: 1rem;
            line-height: 1.25rem;
        }
    }

    .modal-field-wrapper {
        padding: 0px 8px;
    }
    .zip-code-outer {
        margin-bottom: 48px !important;
    }
    .btn-outer {
        margin-top: 16px;
        margin-bottom: 0px !important;
    }
    .editaddressModal {
        padding: 1rem;
        // height: 40.25rem;
        // max-height: 40.25rem;
        // margin: 0px;
        // overflow-y: hidden;
        height: auto;
        max-height: 100%;
        &.deleteaddressmodal {
            height: 304px;
            max-width: 600px;
            width: 100%;
            box-sizing: border-box;
            .save-btn {
                background: linear-gradient(-180deg, rgb(255, 87, 34) 0%, rgb(244, 81, 30) 100%);
                border-color: #f6531f;
                &:hover {
                    /* background: linear-gradient( 
                    -180deg
                    ,rgb(246 83 31) 0%,rgb(172 50 12) 100%); */

                    background: linear-gradient(-180deg, rgb(255, 87, 34) 0%, rgb(230, 74, 25) 100%);
                    border: 1px solid rgb(230, 74, 25);
                }
            }
            h3 {
                font-size: 16px;
            }
            .delete-icon {
                margin: 24px auto;
                text-align: center;
                i {
                    width: 72px;
                    font-size: 64px;
                    color: rgb(150, 155, 165);
                    height: 72px;
                }
            }
            label {
                font-size: 16px;
                padding: 0 8px;
                margin-bottom: 12px;
            }
        }
    }
    .editaddressModalWithoutDelete {
        padding: 1rem;
        height: auto;
        max-height: 100%;
        margin: 0px;

        @media (max-width: 1152px) {
            margin-top: 43px;
        }
    }

    .modal-children {
        padding: 0px 0.5rem 0.5rem 0.5rem;
        padding: 0;
    }
    label {
        display: block;
        margin-bottom: 4px;
    }
    .modal-input {
        width: 100%;
        background-color: #fafafa;
        border: 1px solid #ececee;
        height: 44px;
        border-radius: 4px;
        font-size: 15px;
        padding: 0 12px;
        box-sizing: border-box;
        &.error {
            background-color: #fbe9e7;
            border: 1px solid #ff5722;
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            transition: background-color 5000s ease-in-out 0s;
            background-color: #fff;
        }
    }
    .selectField {
        min-height: 200px !important;
    }
    .error-text {
        color: #ff5722;
        display: flex;
        align-items: center;
        margin-top: 2px;
        margin-bottom: 8px;
        label {
            color: #ff5722;
        }
        i {
            margin-right: 3px;
        }
    }

    .form-groups {
        margin-bottom: 16px;
    }
    .addlin2-txt {
        font-size: 14px;
        font-style: italic;
    }
    .flexrow {
        display: flex;
    }
    .flexcol-6 {
        width: 50%;
    }
    .mr-8 {
        margin-right: 8px;
    }
    .ml-8 {
        margin-left: 8px;
    }
    .delete-add-wrapper {
        text-align: center;
        margin-top: 0px;
        margin-bottom: 10px;
    }
    .delete-add {
        width: 100%;
        height: 40px;
        max-width: 276px;
        border-radius: 8px;
        font-size: 12px;
        letter-spacing: 1px;
        font-weight: bold;
        color: rgb(255, 87, 34);
        text-align: center;
        background: linear-gradient(-180deg, rgb(244, 245, 246) 0%, rgb(237, 238, 239) 100%);
        border: 1px solid rgb(237, 238, 239);
        &:hover {
            /* background: linear-gradient( 
                        -180deg
                        ,rgb(244,245,246) 0%,rgb(184 184 184) 100%); */

            background: linear-gradient(-180deg, rgb(244, 245, 246) 0%, rgb(222, 223, 226) 100%);
            border: 1px solid rgb(222, 223, 226);
        }
    }
    .abb-select {
        .selectInput {
            .gKlNrd {
                min-height: 164px;
                @media (max-width: 1153px) {
                    min-height: 90px;
                }
            }
        }
        .label-container {
            display: none;
        }
    }
    .cancel-btn {
        background: linear-gradient(-180deg, rgb(244, 245, 246) 0%, rgb(237, 238, 239) 100%);
        border-radius: 8px;
        border: 1px solid rgb(237, 238, 239);
        height: 40px;
        // max-width: 276px;
        width: 100%;
        color: rgb(110, 114, 124);
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 1px;
        text-align: center;
        margin-right: 8px;
        &:hover {
            /* background: linear-gradient( 
            -180deg
            ,rgb(244,245,246) 0%,rgb(184 184 184) 100%); */
            background: linear-gradient(-180deg, rgb(244, 245, 246) 0%, rgb(222, 223, 226) 100%);
            border: 1px solid rgb(222, 223, 226);
        }
    }
    .save-btn {
        background: linear-gradient(-180deg, rgb(57, 99, 254) 0%, rgb(47, 85, 254) 100%);
        border-radius: 8px;
        border: 1px solid rgb(237, 238, 239);
        height: 40px;
        // max-width: 276px;
        width: 100%;
        color: rgb(255, 255, 255);
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 1px;
        text-align: center;
        // margin-left:8px;
        border-color: #2f55fe;
        &:hover {
            /* background: linear-gradient( 
                -180deg
                ,#3963fe 0%,#2b4dac 100%);
                    border-color: #2b4dac; */

            background: linear-gradient(-180deg, rgb(57, 99, 254) 0%, rgb(43, 77, 172) 100%);
            border: 1px solid rgb(43, 77, 172);
        }
        &.disable {
            background: #8aa3ff;
            color: #fff;
            border-color: #8aa3ff;
        }
    }
    .address2 {
        margin-bottom: 0px;
    }
    .location {
        margin-top: 16px;
    }
    .editaddressModaldelay {
        padding: 1rem;
        height: 18.25rem;
        max-height: 18.25rem;
        margin: 0px;
        overflow-y: hidden;
        min-height: 253px;
        max-height: unset;
        height: unset;
        .modaldelayicon {
            text-align: center;
            margin: 16px 0;
            .info-icon {
                font-size: 64px;
                color: rgb(150, 155, 165);
            }
        }
        h3 {
            font-size: 1rem;
        }
        form {
            .dont-show {
                display: flex;
                width: 200px;
                align-items: center;
                margin: 22px 0;
                .check {
                    width: 7%;
                    margin-left: 0;
                    margin-right: 8px;
                    color: rgb(185, 187, 193);
                }
                p {
                    // color: rgba(62, 65, 72, 0.35);
                    color: #b9bbc1;
                }
                input[type='checkbox']:checked + p {
                    color: #3e4148 !important;
                }
            }
            .bottomsec {
                .diff-address {
                    background: linear-gradient(-180deg, rgb(244, 245, 246) 0%, rgb(237, 238, 239) 100%);
                    border-radius: 8px;
                    border: 1px solid rgb(237, 238, 239);
                    height: 40px;
                    min-width: 276px;
                    width: 100%;
                    color: rgb(110, 114, 124);
                    font-family: Lato;
                    font-size: 12px;
                    font-weight: bold;
                    letter-spacing: 1px;
                    font-family: lato;
                    // margin-right:8px;
                    &:hover {
                        /* background: linear-gradient( 
                        -180deg
                        ,rgb(244,245,246) 0%,rgb(184 184 184) 100%); */

                        background: linear-gradient(-180deg, rgb(244, 245, 246) 0%, rgb(222, 223, 226) 100%);
                        border: 1px solid rgb(222, 223, 226);
                    }
                }
                .same-address {
                    background: linear-gradient(-180deg, rgb(57, 99, 254) 0%, rgb(47, 85, 254) 100%);
                    border-radius: 8px;
                    // border: 1px solid rgb(237, 238, 239);
                    border: 1px solid rgb(47, 85, 254);
                    height: 40px;
                    min-width: 276px;
                    width: 100%;
                    color: rgb(255, 255, 255);
                    font-family: Lato;
                    font-size: 12px;
                    font-weight: bold;
                    letter-spacing: 1px;
                    font-family: lato;
                    // margin-left:8px;
                    &:hover {
                        /* background: linear-gradient( 
                -180deg
                ,#3963fe 0%,#2b4dac 100%);
                    border-color: #2b4dac; */

                        background: linear-gradient(-180deg, rgb(57, 99, 254) 0%, rgb(43, 77, 172) 100%);
                        border: 1px solid rgb(43, 77, 172);
                    }
                }
            }
        }
    }
`;
const AddressModalStyles = styled.div`
    display flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 0px 8px 8px 8px;
    height: 100%;

    .modal-card {
        padding: 1rem;
    }

    h4 {
      color: rgb(62, 65, 72);
      font-family: Montserrat;
      font-size: 16px;
      font-weight: bold;
      height: 20px;
      letter-spacing: 0.44px;
      line-height: 20px;
    }

    .filterAddress {
        margin: 1rem 0px;
        margin-bottom: 1.5rem;
    }
    
    
    .optionContainer {
        margin: 0px;
        padding: 0px;
        height: 18rem;
        overflow-y: auto;
        /* width */
        &::-webkit-scrollbar {
          width: 8px;
          border-radius: 5px;   
        }
        
        /* Track */
        &::-webkit-scrollbar-track {
            background: #fff;
        }
        
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #d7d7d7;
            border-radius: 5px;
        }
        
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
        li {
            list-style-type: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
            box-shadow: inset 0px -1px 0px 0px rgb(237, 238, 239);
            margin: 8px 0;

            &:last-of-type {
                box-shadow: none;
            }
        }
    }
    .notAdmin{
        height: 20rem;
    }
    .addressInfo {
        margin-bottom: 0.5rem;
    }
    .selectButton {
        margin-right: 16px;
    }

    .addressVerification{
        display: flex;
    }

    .addressVerification i{
        width: 20px;
        height: 20px;
        color: rgb(255, 193, 7);
        font-size: 16px;
        font-weight: normal;
        line-height: 20px;
    }

    .addressVerification p{
        color: rgb(255, 160, 0);
        font-size: 14px;
        font-family: Lato, sans-serif;        
        font-weight: normal;
        line-height: 20px;
    }

    .selectButton Button{
        height: 32px;
        min-width: 120px;
        text-transform: capitalize;
        &:disabled{
          color:white;  
        }
    }
    .edit-btn{
        font-size:12px;
        color:rgb(185,187,193);
        font-weight:bold;
        font-family:Lato;
    }
`;

export default { AddressModalContainer, AddressModalStyles };
