import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import Loader from '../../../components/Loader'
import ComponentStyles from './styles'
import { Button, Input, MaskInput } from '../../../../../lib/@q-core/react-components'
import CheckoutSummary from './checkoutSummary'
import SupplierCart from './supplierCart'
import { getAddressCheckout, getCartData, getActivePC } from '../../../apis'
import { safeLocalStorage } from '../../../../utils/safeStorage'
import { formatAddress } from './helpers'
import AddressModal from './addressModal'
import _ from 'lodash'
import PCard from '../../../PCard'
import Attachments from '../../../components/Attachments'
import { checkAddressVerificationStatus } from '../../Cart/helpers'
import { useCartContext } from '../../../../hooks/useCartContext'
import { hasRaivenCheckout } from '../../../../hooks/useDefaultCheckout'

const { CheckoutMain, CheckoutContainer, ItemRow, BackToCart, AddressAddWrapper } = ComponentStyles

const getAddresses = async (userData, component, isResetSelectedAddress = true) => {
  const data = await getAddressCheckout(userData)

  const billingAddresses = data.addresses.filter(x => x.AddressType === 'Billing_Shipping' || x.AddressType === 'Billing')
  const shippingAddresses = data.addresses.filter(x => x.AddressType === 'Billing_Shipping' || x.AddressType === 'Shipping')

  //If only one address, use that as the default
  const defaultBilling  = billingAddresses.length == 1  ? billingAddresses[0]  : billingAddresses.find(x => x.IsDefaultBillingAddress)
  const defaultShipping = shippingAddresses.length == 1 ? shippingAddresses[0] : shippingAddresses.find(x => x.IsDefaultShippingAddress)

  if (_.has(data, 'addresses') && !_.isEmpty(data.addresses)) {
    let defaultSelectedAddresses = {}
    if (isResetSelectedAddress) {
      defaultSelectedAddresses = {
        billingAddress: defaultBilling ? defaultBilling : '',
        shippingAddress: defaultShipping ? defaultShipping : '',
      }
    }
    component.setState({
      shippingAddressOptions: shippingAddresses,
      billingAddressOptions: billingAddresses,
      ...defaultSelectedAddresses,
    })
  }

  return data
}

const getActiveCards = async (userData, component) => {
  const cards = await getActivePC({ userData }).then((cards) => {
    component.setState({
      cards,
    })
  })
  return cards
}

const getCartItems = async (userData, component) => {
  const cart = await getCartData({ userData }).then((cart) => {
    if (_.has(cart, 'CartRequisitions') && !_.isEmpty(cart.CartRequisitions)) {
      component.setState({
        cart,
      })

    } else {
      if (_.has(component, 'props.history')) {
        component.props.history.push('/')
      }
    }
  })
  return cart
}

const getCorporateOfficeId = async (userData) => {
  const isBrowser = typeof window !== 'undefined' && window.env
  const url = isBrowser ? window.env.apiRoot : process.env.RAZZLE_RUNTIME_API_ROOT
  const apiKey = isBrowser ? window.env.apiKey : process.env.RAZZLE_RUNTIME_API_KEY
  const token = localStorage.getItem('token')

  const myHeaders = new Headers()
  myHeaders.append('Ocp-Apim-Subscription-Key', apiKey)
  myHeaders.append('Authorization', `Bearer ${token}`)

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  }

  const response = await fetch(`${url}/v1/CorporateOffice?AdminPortalCorporateOfficeId=${userData.activeNetworkId}`, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      return result[0].Id
    })
    .catch((error) => console.error('error', error))

    return response
}

const getCompanyContractCodes = async (corporateOfficeId) => {
  const isBrowser = typeof window !== 'undefined' && window.env
  const url = isBrowser ? window.env.apiRoot : process.env.RAZZLE_RUNTIME_API_ROOT
  const apiKey = isBrowser ? window.env.apiKey : process.env.RAZZLE_RUNTIME_API_KEY
  const token = localStorage.getItem('token')

  const myHeaders = new Headers()
  myHeaders.append('Ocp-Apim-Subscription-Key', apiKey)
  myHeaders.append('Authorization', `Bearer ${token}`)

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  }

  const response = await  fetch(`${url}/v1/Company/${corporateOfficeId}/ContractCodes`, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      return result.length > 0
    })
    .catch((error) => console.error('error', error))

    return response
}

const hasNonNullContractCodeIds = () => {
  const cartArray = safeLocalStorage.getJSONItem('cart').CartRequisitions

  if (!Array.isArray(cartArray) || cartArray.length === 0) {
      return false;
  }

  const hasNullContractCodes = cartArray.every((item) => item.Requisitions.every((req) => req.ContractCodeId !== null));
  return hasNullContractCodes;
}

const checkoutValidations = async (props) => {
  if(!hasRaivenCheckout(props.userData)) {
    return;
  }

  const corporateOfficeId = await getCorporateOfficeId(props.userData)
  const accountHasContractCodesSetup = await getCompanyContractCodes(corporateOfficeId)
  const contractCodesAssigned= await hasNonNullContractCodeIds(corporateOfficeId)

  if(accountHasContractCodesSetup && !contractCodesAssigned) {
    getCorporateOfficeId(props.userData)
    props.history.push('/cart')
  }
}

class RCheckout extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      shippingAddress: {},
      billingAddress: {},
      attnShipping: '',
      attnBilling: '',
      Phone: '',
      POsuppliers: false,
      checkBool: false,
      modalOpen: false,
      modalType: '',
      selectedAddress: {},
      billingAddressOptions: [],
      shippingAddressOptions: [],
      cart: safeLocalStorage.getJSONItem('cart'),
      formValues: {
        jobId: '',
        purchaseOrderComment: '',
        internalComment: '',
        paymentCardId: null,
      },
      errors: [],
      errorMessage: '',
      commentBool: false,
      loader: false,
      blurredPhone: false,
      paymentMethod: 'PONumber',
      expiredCard: false,
      jobIdError: false,
      commentError: false,
      internalCommentError: false,
      featureFlags: props.featureFlags
    }
    this.setErrors = this.setErrors.bind(this)
    this.validatePhone = this.validatePhone.bind(this)
  }

  componentWillMount() {
    checkoutValidations(this.props)
  }

  componentDidMount() {
    if (this.props.userData) {
      const data = this.props.userData
      getAddresses(data, this)
      getCartItems(data, this)
      getActiveCards(data, this)
    }
  }

  updateCards = () => {
    if (this.props.userData) {
      const data = this.props.userData
      getActiveCards(data, this)
    }
  }

  setExpiredCard = (value) => {
    console.log('set Expired Card', value)
    this.setState({
      expiredCard: value,
    })
  }

  setAchtung(name, value) {
    this.setState({
      attnShipping: value,
    })
    if (name === 'Shipping') {
      if (!_.isEmpty(this.state.shippingAddress)) {
        let newShip = {
          ...this.state.shippingAddress,
          Attention: value,
        }
        this.setState({
          shippingAddress: newShip,
        })
      }
    }
  }

  setPaymentCardId = (value) => {
    this.setState({
      formValues: {
        ...this.state.formValues,
        paymentCardId: value,
      },
    })
  }

  setPaymentMethod(name) {
    let method = name
    if (method === 'PONumber') {
      this.setState({
        formValues: {
          ...this.state.formValues,
          paymentCardId: null,
        },
      })
    }
    this.setState({
      paymentMethod: method,
    })
  }

  setLoader(value) {
    this.setState({
      loader: value,
    })
  }

  setPhone(value) {
    let isValid = false,
      numberOnlyValue = value.replace(/[^\d]/g, ''),
      concat = '1' + numberOnlyValue

    if (!_.isEmpty(this.state.shippingAddress)) {
      if (concat.length < 12) {
        isValid = true
        let newShip = {
          ...this.state.shippingAddress,
          Phone: concat,
        }
        const name = 'Phone'
        this.setErrors(isValid, name)
        this.setState({
          shippingAddress: newShip,
          Phone: concat,
          formValues: {
            ...this.state.formValues,
            phone: concat,
          },
        })
      } else {
        if (concat.length < 12) {
          isValid = false
          const name = 'Phone'
          this.setErrors(isValid, name)
        }
      }
    }
  }

  setModal = (value, type, isResetSelectedAddress = true) => {
    if (!_.isNil(type)) {
      this.setState({
        modalOpen: true,
        modalType: type,
      })
    } else {
      this.setState(
        {
          modalOpen: value,
        },
        () => {
          if (this.props.userData) {
            const data = this.props.userData
            getAddresses(data, this, isResetSelectedAddress)
          }
        }
      )
    }
  }

  setFieldError = (value, msg) => {
    this.setState({
      error: value,
      errorMessage: msg,
    })
  }

  setSupplierDrawer(event) {
    let bool
    if (this.state.checkBool === false) {
      bool = true
    } else {
      bool = false
    }
    this.setState({
      checkBool: bool,
    })
  }

  setCommentDrawer(event) {
    let bool
    if (this.state.commentBool === false) {
      bool = true
    } else {
      bool = false
    }
    this.setState({
      commentBool: bool,
    })
  }
  selectAddress = (address, type, callback) => {
    let phone = this.state.Phone

    if (type === 'Shipping') {
      if (this.state.attnShipping && !_.isEmpty(this.state.attnShipping)) {
        let newShip = {
          ...address,
          Attention: this.state.attnShipping,
          Phone: phone,
        }
        this.setState(
          {
            shippingAddress: newShip,
            modalOpen: false,
          },
          () => {
            callback && callback()
          }
        )
      } else {
        const updatedState = {
          shippingAddress: address,
        }
        const { billingAddress } = this.state
        if (address.Id === billingAddress.Id) {
          updatedState.billingAddress = address
        }
        this.setState(
          {
            ...updatedState,
            modalOpen: false,
          },
          () => {
            callback && callback()
          }
        )
        //     else {

        //         let newShip = {
        //         ...address,
        //         Phone: phone,
        //     };
        //     this.setState({
        //         shippingAddress: newShip,
        //         modalOpen: false,
        //     }, ()  => {
        //         callback && callback();
        //     });
        // }
      }
    } else if (type === 'Billing') {
      const updatedState = {
        billingAddress: address,
      }
      const { shippingAddress } = this.state
      if (shippingAddress.Id === address.Id) {
        updatedState.shippingAddress = address
      }
      this.setState(
        {
          ...updatedState,
          modalOpen: false,
        },
        () => {
          callback && callback()
        }
      )
    }
  }

  updatedAddressCall = (address, type, callback) => {
    let phone = this.state.Phone

    if (type === 'Shipping') {
      if (this.state.attnShipping && !_.isEmpty(this.state.attnShipping)) {
        let newShip = {
          ...address,
          Attention: this.state.attnShipping,
          Phone: phone,
        }
        this.setState(
          {
            shippingAddress: newShip,
          },
          () => {
            callback && callback()
          }
        )
      } else {
        const updatedState = {
          shippingAddress: address,
        }
        const { billingAddress } = this.state
        if (address.Id === billingAddress.Id) {
          updatedState.billingAddress = address
        }
        this.setState(
          {
            ...updatedState,
          },
          () => {
            callback && callback()
          }
        )
        //     else {

        //         let newShip = {
        //         ...address,
        //         Phone: phone,
        //     };
        //     this.setState({
        //         shippingAddress: newShip,
        //         modalOpen: false,
        //     }, ()  => {
        //         callback && callback();
        //     });
        // }
      }
    } else if (type === 'Billing') {
      const updatedState = {
        billingAddress: address,
      }
      const { shippingAddress } = this.state
      if (shippingAddress.Id === address.Id) {
        updatedState.shippingAddress = address
      }
      this.setState(
        {
          ...updatedState,
        },
        () => {
          callback && callback()
        }
      )
    }
  }

  purchaseOrderTip = () => {
    return (
      <div className="purchaseOrderHelp">
        <div>A separate purchase order will automatically be created for each supplier in your cart.</div>
        <ul>
          <li>
            An extra digit will be automatically added to each supplier’s PO number (eg. 0005 ={'>'} 0005-1, 0005-2)
          </li>
          <li>If no PO Number is entered, one will be automatically generated</li>
          <li>PO Numbers are limited to numbers, letters, spaces, hyphens, forward slashes, and underscores</li>
        </ul>
      </div>
    )
  }

  addressDisplay(address, shipping) {
    let { company, street, cityState, DeliverTo2: deliverTo2 } = formatAddress(address)
    const suppliersInCart = (this.state.cart.CartRequisitions && this.state.cart.CartRequisitions.map((requisition) => requisition.SupplierId)) || []
    const addressVerificationStatus = checkAddressVerificationStatus(address.UnVerifiedForSuppliers, suppliersInCart)

    return (
      <div className="addressInfo">
        {company && <p className="company">{company}</p>}

        {street && <p>{street}</p>}

        {cityState && <p>{cityState}</p>}

        {deliverTo2 && (
          <p>
            <span className="addressInfoJobNumber">ID# {deliverTo2}</span>
          </p>
        )}
        {addressVerificationStatus !== 0 ?
         <div className='addressVerification'>
            <i className='far fa-exclamation-circle'></i>
            <p>Pending verification by supplier in checkout</p>
          </div> : null
        }
      </div>
    )
  }

  setMultiPO(req, value, name) {
    let updatedCartReq = ''
    this.validatePO(value, name)
    if (this.state.checkBool === false) {
      updatedCartReq = _.map(req, (Item, i) => {
        Item.PurchaseOrderNumber = value
        return Item
      })
    } else {
      updatedCartReq = _.map(this.state.cart.CartRequisitions, (Item, i) => {
        if (Item.Id === req.Id) {
          Item.PurchaseOrderNumber = value
        }
        return Item
      })
    }

    this.setState({
      cart: {
        ...this.state.cart,
        CartRequisitions: updatedCartReq,
      },
    })
  }

  setErrors(isValid, name) {
    if (!isValid) {
      this.setState({
        errors: [...this.state.errors, name],
      })
    } else {
      this.setState({
        errors: _.filter(this.state.errors, (item) => {
          return item !== name
        }),
      })
    }
  }

  validatePhone(value) {
    let isValid = false,
      errorMessage = ''
    if (value.length === 16) {
      isValid = true
    } else {
      isValid = false
      errorMessage = 'Invalid Phone Number'
    }
    if (value.length < 17) {
      this.setErrors(isValid, 'Phone')
    }

    this.setState({
      blurredPhone: true,
    })
    return {
      isValid,
      errorMessage,
    }
  }

  validatePO(value, name) {
    let isValid = false,
      errorMessage = ''
    let regex = '^[A-Za-z0-9-_/ ]*$'
    if (value.length < 26) {
      if (value.match(regex)) {
        isValid = true
      } else {
        isValid = false
        errorMessage = 'Invalid Character.  Enter number and letters only.'
      }
    } else {
      isValid = false
      errorMessage = 'Too many characters.'
    }
    this.setErrors(isValid, name)
    return {
      isValid,
      errorMessage,
    }
  }

  setNotes(Name, value) {
    if (Name === 'JobId') {
      if (value.length > 25) {
        this.setState({
          jobIdError: true,
        })
      } else {
        this.setState({
          jobIdError: false,
          formValues: {
            ...this.state.formValues,
            jobId: value,
          },
        })
      }
    }
    if (Name === 'Comment') {
      if (value.length > 250) {
        this.setState({
          commentError: true,
        })
      } else {
        this.setState({
          commentError: false,
          formValues: {
            ...this.state.formValues,
            purchaseOrderComment: value,
          },
        })
      }
    }
    if (Name === 'Internal') {
      if (value.length > 250) {
        this.setState({
          internalCommentError: true,
        })
      } else {
        this.setState({
          internalCommentError: false,
          formValues: {
            ...this.state.formValues,
            internalComment: value,
          },
        })
      }
    }
  }


  render() {
    const {
      shippingAddress,
      billingAddress,
      shippingAddressOptions,
      billingAddressOptions,
      commentError,
      internalCommentError,
      jobIdError,
    } = this.state

    let purchaseOrderHelp = this.purchaseOrderTip()

    return (
      <CheckoutMain>
        <div className="checkoutItems">
          <BackToCart>
            <Link className="back-to-cart" to="/cart">
              <i className="fas fa-arrow-left" />
              Back to cart
            </Link>
          </BackToCart>
          <CheckoutContainer>
            <div className="checkout-header">
              <h4>Shipping</h4>
            </div>
            <ItemRow>
              <div className="addressContainer">
                <div className="checkoutAddress">
                  {!_.isEmpty(shippingAddress) ? (
                    <div>
                      <div className="addressLabel">
                        <p className="locationLabel">Ship to</p>
                        {shippingAddressOptions.length > 0 && (
                          <p
                            className="changeLabel"
                            onClick={() => {
                              this.setModal(true, 'Shipping')
                            }}
                          >
                            Change
                          </p>
                        )}
                      </div>
                      {this.addressDisplay(shippingAddress)}

                      <Input
                        type="text"
                        placeholder="First and last name"
                        label="Attention"
                        optional
                        optionalTag
                        onChange={(e) => this.setAchtung('Shipping', e.target.value)}
                        value={this.state.attnShipping}
                      />
                      <MaskInput
                        requiredTag
                        Name="Phone"
                        className="masked"
                        mask="(###) ### - ####"
                        maskingType="phone"
                        placeholder="()-"
                        optionalTag={false}
                        optional={false}
                        label="Phone"
                        value={this.state.phone || ''}
                        onChange={(e) => this.setPhone(e.target.value)}
                        errors={this.state.errors}
                        setErrors={(name) => this.setErrors(name)}
                        onBlur={(e) => this.validatePhone(e.target.value)}
                        validate={this.validatePhone}
                        validateOnChange={this.state.blurredPhone}
                      />
                    </div>
                  ) : (
                    <div>
                      <AddressAddWrapper>
                        <div className="iconWrapper">
                          <i className="fal fa-map-marker-alt" />
                        </div>
                        <p className="addAddressxyz">Select a shipping address for this order</p>
                        <Button
                          type="indigoVibrant"
                          shape="square"
                          title="Select shipping address"
                          onClick={() => this.setModal(true, 'Shipping')}
                        />
                      </AddressAddWrapper>
                    </div>
                  )}
                </div>
              </div>
            </ItemRow>
          </CheckoutContainer>
          <CheckoutContainer>
            <div className="checkout-header">
              <h4>Payment</h4>
            </div>
            <ItemRow>
              <div className="POCard">
                <div className="POsection">
                  <p className="locationLabel">Choose a payment method</p>
                  {this.state.featureFlags.f12092CreditCardCheckout && (
                    <div className={`checkoutRadioButton${this.state.paymentMethod === 'CCard' ? ' checked' : ''}`}>
                        <input
                        className="checkoutRadio"
                        type="radio"
                        id="ccard"
                        name="drone"
                        onChange={(event) => {
                            this.setPaymentMethod(event.target.value)
                        }}
                        value="CCard"
                        checked={this.state.paymentMethod === 'CCard'}
                        />
                        <label htmlFor="ccard">Credit Card</label>
                    </div>
                  )}
                  <div className={`checkoutRadioButton${this.state.paymentMethod === 'PONumber' ? ' checked' : ''}`}>
                    <input
                      className="checkoutRadio"
                      type="radio"
                      id="porder"
                      name="drone"
                      value="PONumber"
                      onChange={(event) => {
                        this.setPaymentMethod(event.target.value)
                      }}
                      checked={this.state.paymentMethod === 'PONumber'}
                    />
                    <label htmlFor="porder">Purchase Order</label>
                  </div>
                </div>
                {this.state.paymentMethod === 'CCard' && (
                  <PCard
                    cards={this.state.cards}
                    userData={this.props.userData}
                    setPaymentCardId={(value) => {
                      this.setPaymentCardId(value)
                    }}
                    updateCards={this.updateCards}
                    setExpiredCard={this.setExpiredCard}
                  />
                )}
                {this.state.paymentMethod === 'PONumber' && (
                  <div>
                    {this.state.cart.CartRequisitions.length > 1 && (
                      <div className={`POcheckbox ${this.state.checkBool ? 'checked' : ''}`}>
                        <input
                          type="checkbox"
                          className="POToggle"
                          onChange={(event) => {
                            this.setSupplierDrawer(true)
                          }}
                        />{' '}
                        <span className="POText">Enter different PO for each supplier</span>
                      </div>
                    )}
                    {!this.state.checkBool && (
                      <div className="POinput">
                        <Input
                          Id="forAll"
                          type="text"
                          label="PO Number"
                          Name="POarray"
                          onChange={(e) => this.setMultiPO(this.state.cart.CartRequisitions, e.target.value, 'POarray')}
                          validate={this.validatePO}
                          validateOnChange
                          optional
                          toolTip={purchaseOrderHelp}
                          charLimit={26}
                          errors={this.state.errors}
                          setErrors={(name) => this.setErrors(name)}
                        />
                      </div>
                    )}
                    {this.state.checkBool &&
                      _.map(this.state.cart.CartRequisitions, (supplier, i) => {
                        return (
                          <div className="POinput" key={i}>
                            <Input
                              Id={i}
                              type="text"
                              label={supplier.Supplier.Name}
                              placeholder="PO Number"
                              Name="POarray"
                              validate={this.validatePO}
                              validateOnChange
                              onChange={(e) => this.setMultiPO(supplier, e.target.value, 'MultiPOarray')}
                              charLimit={26}
                              optional
                              toolTip={purchaseOrderHelp}
                              errors={this.state.errors}
                              setErrors={(name) => this.setErrors(name)}
                            />
                          </div>
                        )
                      })}
                  </div>
                )}

                <section>
                  {!_.isEmpty(billingAddress) ? (
                    <div>
                      <div className="local-outer">
                        <p className="locationLabel">Billing Address</p>
                        <div className="addressLabel">
                          {billingAddressOptions.length > 0 && (
                            <p
                              className="changeLabel"
                              onClick={() => {
                                this.setModal(true, 'Billing')
                              }}
                            >
                              Change
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="addressContainer">
                        <div className="checkoutAddress">{this.addressDisplay(billingAddress)}</div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <AddressAddWrapper>
                        <div className="iconWrapper">
                          <i className="fal fa-map-marker-alt" />
                        </div>
                        <p className="addAddressxyz">Select a billing address for this order</p>
                        <Button
                          type="indigoVibrant"
                          shape="square"
                          title="Select billing address"
                          onClick={() => this.setModal(true, 'Billing')}
                        />
                      </AddressAddWrapper>
                    </div>
                  )}
                </section>
              </div>
            </ItemRow>
          </CheckoutContainer>
          <CheckoutContainer>
            <div className="checkout-header">
              <h4>Notes</h4>
            </div>
            <ItemRow>
              <div className="checkoutNotes">
                <Input
                  type="text"
                  label="Job ID"
                  optional
                  optionalTag
                  onChange={(e) => this.setNotes('JobId', e.target.value)}
                  value={this.state.formValues.jobId}
                />
                {jobIdError && (
                  <div className="error-text">
                    <i className="far fa-exclamation-circle " />
                    <label>Too many characters.</label>
                  </div>
                )}
                <Input
                  type="textarea"
                  label="Comment"
                  optional
                  className="commentTextArea"
                  optionalTag
                  placeholder="Add a comment for your suppliers..."
                  onChange={(e) => this.setNotes('Comment', e.target.value)}
                  value={this.state.formValues.purchaseOrderComment}
                />
                {commentError && (
                  <div className="error-text">
                    <i className="far fa-exclamation-circle " />
                    <label>Too many characters.</label>
                  </div>
                )}
                <div className={`POcheckbox ${this.state.commentBool ? 'checked' : ''}`}>
                  <input
                    data-scorpius-automation={'checkout-Comment-switch'}
                    type="checkbox"
                    className="POToggle"
                    onChange={(event) => {
                      this.setCommentDrawer(true)
                    }}
                  />{' '}
                  <span className="POText">Add an internal comment</span>
                </div>
                {this.state.commentBool && (
                  <Input
                    data-scorpius-automation={'checkout-internalComment'}
                    type="textarea"
                    helperText="Internal comments are only visible to you and people you share with"
                    label="Internal Comment"
                    optional
                    className="commentTextArea"
                    optionalTag
                    placeholder="Add your internal comments for this order&hellip;"
                    onChange={(e) => this.setNotes('Internal', e.target.value)}
                    value={this.state.formValues.internalComment}
                  />
                )}
                {internalCommentError && (
                  <div className="error-text">
                    <i className="far fa-exclamation-circle " />
                    <label>Too many characters.</label>
                  </div>
                )}
              </div>
            </ItemRow>
          </CheckoutContainer>
          <SupplierCart
            history={this.props.history}
            loader={this.state.loader}
            cart={this.state.cart}
            addresses={{ Billing: billingAddress, Shipping: shippingAddress }}
          />
        </div>
        <div style={{display: 'flex', flexDirection: 'column', gap:24, position: 'sticky', top:0, width:'270px'}}>
          <CheckoutSummary
            billingAddress={billingAddress}
            shippingAddress={shippingAddress}
            cart={this.state.cart}
            addresses={{ Billing: billingAddress, Shipping: shippingAddress }}
            userData={this.props.userData}
            formValues={this.state.formValues}
            errors={this.state.errors}
            setLoader={(value) => this.setLoader(value)}
            history={this.props.history}
            paymentMethod={this.state.paymentMethod}
            expiredCard={this.state.expiredCard}
          />
          <Attachments
            userData={this.props.userData}
            attachments={this.state.cart?.Attachments}
            cartId={this.state.cart?.Id}
          />
        </div>
        {this.state.modalOpen && (
          <AddressModal
            getAddresses={getAddresses}
            billingAddressOptions={billingAddressOptions}
            shippingAddressOptions={shippingAddressOptions}
            modalType={this.state.modalType}
            setAddress={this.selectAddress}
            updatedAddressCall={this.updatedAddressCall}
            setModal={this.setModal}
            cart={this.state.cart}
            shippingAddress={this.state.shippingAddress}
            billingAddress={this.state.billingAddress}
          />
        )}
        {/* <iframe
                    height="600px"
                    width="600px" 
                    z-index="99" 
                    position="absolute" 
                    src="http://localhost:3020">
                </iframe> */}
        {this.state.loader && (
          <div className="overlay-loader">
            <Loader />
          </div>
        )}
      </CheckoutMain>
    )
  }
}

export default RCheckout
