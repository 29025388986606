import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import styles from './style.module.css';
import { safeLocalStorage } from '../../../utils/safeStorage';
import { downloadFile, getFileIcon } from '../../../utils/fileStorage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const RequestAttachments = ({isAdmin, isApprover, status, orderId, attachments = []}) => {
  const [files, setFiles] = useState(attachments);
  const [error, setError] = useState('');
  const [isFileDeletedEnabled, setIsFileDeletedEnabled] = useState(false);
  const fileInputRef = useRef(null);
  const attachmentsWrapperRef = useRef(null);
  const token = safeLocalStorage.getItem('token');
  const isBrowser = typeof window !== "undefined" && window.env;
  const apiRoot = isBrowser ? window.env.apiRoot : process.env.RAZZLE_RUNTIME_API_ROOT;
  const apiKey = isBrowser
  ? window.env.apiKey
  : process.env.RAZZLE_RUNTIME_API_KEY;
  
  const fileDownloadPath = `${apiRoot}/v1/PurchaseOrder/${orderId}/DownloadAttachment`
  const deleteFile = (fileName) => async () => {
    try {
      await axios.delete(`${apiRoot}/v1/PurchaseOrder/${orderId}/DeleteAttatchment`, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            "Ocp-Apim-Subscription-Key": `${apiKey}`,
        },
        data: { FileName: fileName } });
      console.log('File deleted successfully');
      setFiles(files.filter(file => file.FileName !== fileName));
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  }
  

  const uploadFiles = async (e) => {
    e.preventDefault();
    setError('');
  
    const selectedFiles = Array.from(e.target.files);
    console.log("selectedFiles: ", selectedFiles)
    const remainingSelectableFiles = 5 - files.length;
    console.log("remainingSelectableFiles: ", remainingSelectableFiles)

    const formData = new FormData();
  
    for (let i = 0; i < remainingSelectableFiles && i < selectedFiles.length; i++) {
      formData.append('file', selectedFiles[i], selectedFiles[i].name);
    }
  
    try {
      const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
        'Ocp-Apim-Subscription-Key': apiKey,
      };
  
      const response = await axios.post(
        `${apiRoot}/v1/PurchaseOrder/${orderId}/AttatchFile`,
        formData,
        { headers }
      ).then(res => res.data);
  
      setFiles([...response[0].Attatchments]);
  
      console.log('Files uploaded successfully', response);
    } catch (error) {
      console.error('Error uploading files:', error);
    }
  };

  const maxSelectableFiles = 5;
  const isMaxFilesReached = files.length >= maxSelectableFiles;

  const addFiles = () => {
    const maxSelectableFiles = 5;
  
    if (isMaxFilesReached) {
      return;
    }
  
    const remainingSelectableFiles = maxSelectableFiles - files.length;
  
    fileInputRef.current.click();
    fileInputRef.current.setAttribute('multiple', remainingSelectableFiles.toString());
  };

  const canUserAddFiles = () => {
    return (isAdmin || isApprover) || status === 'Pending';
  };

  const addFilesComponent = () => {
    if (canUserAddFiles()) {
      return ( 
        <>
          <input
            type="file"
            multiple
            accept=".pdf, .csv, .xls, .xlsx, .doc, .docx"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={uploadFiles}
          />
          <button className={styles.addFileButton} disabled={isMaxFilesReached} onClick={addFiles}>Add</button>
        </>
      );
    }
    return null;
  };

  const checkIfUserCanDeleteFiles = () => {
    if (isAdmin && (status === 'Approved' || status === 'Rejected')){
      setIsFileDeletedEnabled(true);
      return;
    }
    if ((isAdmin || isApprover) && status === 'Pending'){
      setIsFileDeletedEnabled(true);
      return;
    }
    setIsFileDeletedEnabled(false);
  }

  useEffect(() => {
    if(files.length !== 0 || attachments.length !== 0){
      setFiles(attachments);
    }
    checkIfUserCanDeleteFiles();
  }, [attachments]);

  return (
    <div className={styles.attachmentsWrapper} ref={attachmentsWrapperRef}>
        <div className={styles.attachmentsHeader}>
            <h5>Attachments</h5>
            {addFilesComponent()}
        </div>        
        <div className={styles.maxText}>{canUserAddFiles() ? 'Maximum: 5' : null}</div>
     {files.length > 0 ?
        <div className={styles.attachmentsContent}>
            {files.map((file, index) => (
                <div key={index} className={styles.fileItem}>
                    <FontAwesomeIcon icon={getFileIcon(file.FileName)}  onClick={() => downloadFile(file.FileName, fileDownloadPath, attachmentsWrapperRef.current)}/>
                    <div style={{ flex: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        <span className={styles.fileName} onClick={() => downloadFile(file.FileName, fileDownloadPath, attachmentsWrapperRef.current)}>{file.FileName}</span>
                    </div>
                    {isFileDeletedEnabled ? <i className="fal fa-trash trash-icon" style={{cursor: 'pointer'}} onClick={deleteFile(file.FileName)}></i> : null}
                </div>
            ))}
        </div>
        : null
    }
    </div>
  );
};

export default RequestAttachments;
