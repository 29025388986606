"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _globalStyledComponents = require("../../globalStyledComponents");

var SupplierHeader = _styledComponents["default"].div.withConfig({
  displayName: "styles__SupplierHeader",
  componentId: "sc-1nmp7h2-0"
})(["display:flex;margin-left:", ";img{border-radius:0.25rem;width:2.25rem;height:2.25rem;object-fit:contain;margin-right:1rem;", ";}.supplier-img-loading{background-color:#fafafa;border-radius:0.25rem;width:2.25rem;height:2.25rem;margin-right:1rem;", ";}.supplier-details{display:flex;flex-direction:column;a{width:fit-content;height:1.25rem;}.supplier-name-loading,.supplier-location-loading{height:1rem;border-radius:0.25rem;background-color:#fafafa;}.supplier-name-loading{margin-bottom:0.25rem;width:4.5rem;}.supplier-location-loading{width:9.25rem;}.supplier-name{text-align:left;font-weight:bold;}.loading-results{font-size:0.75rem;}.supplier-location{display:flex;p{overflow:hidden;white-space:nowrap;text-overflow:ellipsis;font-size:0.75rem;font-weight:normal;height:1rem;letter-spacing:0.25px;line-height:1rem;color:rgba(62,65,72,0.7);margin-right:8px;}span{cursor:pointer;font-size:0.75rem;height:1rem;letter-spacing:0.5px;line-height:1rem;font-weight:bold;color:#4060fd;&:hover{opacity:0.8;}}}}"], function (_ref) {
  var mobile = _ref.mobile;
  return !mobile && '3.25rem';
}, function (_ref2) {
  var mobile = _ref2.mobile;
  return mobile && 'margin-right: 0.5rem';
}, function (_ref3) {
  var mobile = _ref3.mobile;
  return mobile && 'margin-right: 0.5rem';
});

var PageCountContainer = _styledComponents["default"].div.withConfig({
  displayName: "styles__PageCountContainer",
  componentId: "sc-1nmp7h2-1"
})(["display:flex;margin:", ";p.count-text{margin:0.25rem 3rem 0 auto;}"], function (_ref4) {
  var mobile = _ref4.mobile;
  return mobile ? '0 0 0 -1rem' : '0 0 4rem';
});

var _default = {
  SupplierHeader: SupplierHeader,
  PageCountContainer: PageCountContainer,
  Row: _globalStyledComponents.Row,
  Column: _globalStyledComponents.Column,
  Card: _globalStyledComponents.Card
};
exports["default"] = _default;