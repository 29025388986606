import styled from 'styled-components';

const PCardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    .addCardxyz {
        color: #3e4148;
        font-size: 16px;
        font-family: Lato;
        font-weight: normal;
        text-align: center;
        line-height: 20px;
    }

    .iconWrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        i {
            width: 40px;
            height: 40px;
            color: #b9bbc1;
            font-size: 32px;
            font-weight: 300;
            text-align: center;
            line-height: 40px;
            margin-bottom: 1rem;
        }
    }

    button {
        margin-top: 1rem;
        text-transform: none;
        width: 15rem;
    }
`;

const DefaultWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 6px;
        .defaultName {
            color: #3e4148;
            font-size: 16px;
            font-family: Lato;
            font-weight: bold;
            line-height: 20px;
        }
        .cardObject {
            margin: 12px 0 24px 0;
            display: flex;
            flex-direction: column;
            .withChange {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
            .infoContainer {
                display: flex;
                flex-direction: row;
                img {
                    width: 38px;
                    height: 36px;
                }
                .textContainer {
                    display:flex;
                    flex-direction: column;
                    margin-left: .5rem;
                    .defaultInfo {
                        color: #3e4148;
                        font-size: 16px;
                        font-family: Lato;
                        font-weight: normal;
                        line-height: 20px;
                        i {
                            color: #ff5722;
                            margin-left: 0.25rem;
                        }
                    }
                }
            }
        }
    }
`;

export default {
    PCardWrapper,
    DefaultWrapper,
};
