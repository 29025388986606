import { useEffect, useState } from 'react'

export const useDebounce = (defaultValue = '', callback, delay = 500) => {
  const [debounceValue, setDebounceValue] = useState(defaultValue)

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      if (debounceValue) {
        callback(debounceValue)
      }
    }, delay)
    return () => clearTimeout(delayInputTimeoutId)
  }, [debounceValue])

  return { debounceValue, setDebounceValue }
}
