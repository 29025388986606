import fetchData from './fetchData';
import config from './apiConfig';

export default (token, data) =>
    new Promise((resolve, reject) => {
  const options = {
            ...config.updateProcurementUserVisibility,
            url: `${config.updateProcurementUserVisibility.url}/${token}/Contract/Visibility`,
            body: data,
        };

  return fetchData(options)
    .then((data) => {
                if (data && !data.ErrorCode) {
        resolve(data);
      } else {
                    reject({ fetchError: true, details: data });
      }
            })
    .catch((error) => {
                reject(error);
            });
});
