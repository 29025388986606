import fetchData from './fetchData';
import config from './apiConfig';

export default (
  userToken,
  selectedId,
  zip,
  city,
  addressLine2,
  state,
  street,
  company,
  addressType,
  externalId,
  deliveryTo2,
) =>
    new Promise((resolve, reject) => {
  const options = {
    ...config.UpdateAddress,
            url: `${config.UpdateAddress.url}/${userToken}/Id/${selectedId}`,
            body: {
      ExternalId: externalId,
      Name: company,
                Description: company,
      Street1: street,
                Street2: addressLine2,
                City: city,
      State: state,
                PostalCode: zip,
                Country: 'US',
                Attention: '',
                DeliverTo1: '',
                DeliverTo2: deliveryTo2,
      Email: null,
                Phone: null,
                Fax: null,
      Url: null,
      IsActive: true,
                AddressTypeId: addressType === 'Billing' ? 1 : addressType === 'Shipping' ? 2 : 3,
    },
  };
        return fetchData(options)
    .then((data) => {
      if (data && !data.ErrorCode) {
        resolve(data);
                } else {
        reject({ fetchError: true, details: data });
                }
            })
    .catch((error) => {
      reject(error);
            });
});
