import React from 'react';
import _ from 'lodash';
import { updateProcurementUserVisibility } from '../../apis';
import ComponentStyles from './styles';
import { safeLocalStorage } from '../../../utils/safeStorage';
import postAnalytics from '../../../utils/postAnalytics.js';


const { Switch } = ComponentStyles;
const isBrowser = typeof window !== 'undefined' && window.env;
const blobUrl = isBrowser ? window.env.blobUrl : process.env.RAZZLE_RUNTIME_BLOB_URL;

const Item = props => {
    const { supplier, userData, setStatusOfSupplier, onEdit } = props;
    const user = safeLocalStorage.getJSONItem('userData');
    const activeUserIsAdmin = !!user && !!user.activeUserSettings && user.activeUserSettings.Role === 'Admin';

    const updateStatus = async (checked, contractId) => {
        const userToken = userData.Network[userData.activeNetworkIndex].UserToken;

        let payload = [];

        supplier.Contracts.forEach(contract => {
            if (contract.CorporateOfficeContractId !== contractId && contract.Status === 'Active') {
                payload.push({
                    Id: contract.CorporateOfficeContractId,
                    IsVisible: false,
                });
            } else if (contract.CorporateOfficeContractId === contractId) {
                payload.push({
                    Id: contractId,
                    IsVisible: !contract.IsVisible,
                });
            }
        });

        await updateProcurementUserVisibility(userToken, payload)
            .then(() => {
                setStatusOfSupplier(true);
            })
            .catch(error => {
                setStatusOfSupplier(true);
                console.log('updateProcurementUserVisibility error: ', error);
            });
    };
    return (
        <div className='accountObject'>
            {_.map(supplier.Contracts, (contract, index) => (
                <li key={index}>
                    {contract.WideLogo ? (
                        <div className='supplierLogo'>
                            <img src={`${blobUrl}/${contract.WideLogo}`} alt={supplier.Name} />
                        </div>
                    ) : (
                        <div className='supplierLogo'>
                            <div style={{ width: '7.25rem' }} />
                        </div>
                    )}
                    <div className='accountContent'>
                        <div className='accountInfoContainer'>
                            {!!contract.AccountLabel ? (
                                <p className='accountLabel'>{contract.AccountLabel}</p>
                            ) : contract.AccountNumber ? (
                                <p className='accountNumber'>Account&nbsp;#{contract.AccountNumber}</p>
                            ) : null}
                            <p className='accountName'>{supplier.Name}</p>
                        </div>
                        {contract.Status !== 'Pending' ? (
                            <div className={`switchContainer ${contract.IsVisible ? 'Active' : 'Paused'}`}>
                                <p className='status'>{contract.IsVisible ? 'Active' : 'Paused'}</p>
                                <Switch>
                                    <input
                                        type='checkbox'
                                        checked={contract.IsVisible}
                                        value={contract.IsVisible}
                                        onChange={e => {
                                            console.log('updateStatus checked', e.target.checked);
                                            postAnalytics('pm-manageAccounts-accountsRow-toggle-accountActivePaused', {toggledTo: e.target.checked ? "Active" :"Paused"} );
                                            updateStatus(e.target.checked, contract.CorporateOfficeContractId);
                                        }}
                                    />

                                    <span className='slider round' />
                                </Switch>

                                {activeUserIsAdmin && (
                                    <i
                                        // style={{ color: '#969ba5', margin: '4px 0 0 16px' }}
                                        className='far fa-pencil'
                                        onClick={() => {
                                            postAnalytics('pm-manageAccounts-accountsRow-click-edit', supplier );
                                            onEdit(
                                                supplier.SupplierContractId,
                                                index,
                                                contract.CorporateOfficeContractId,
                                            );
                                        }}
                                    />
                                )}
                            </div>
                        ) : (
                            <div className='switchContainer'>
                                <p className='pendingAccount'>Verifying</p>
                                <div className='pendingIcon'>
                                    <i className='fal fa-question-circle' />
                                </div>
                            </div>
                        )}
                    </div>
                </li>
            ))}
        </div>
    );
};

export default Item;
