"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sortByPrice = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _lodash = _interopRequireDefault(require("lodash"));

var sortByPrice = function sortByPrice(arr, sortId) {
  var array;

  if (arr.PartList) {
    array = arr.PartList;
  } else {
    array = arr;
  }

  var sortedArr = [];
  var priceArray = array.map(function (item) {
    item.parsedPrice = parseFloat(item.Price.replace(/[,$]/g, ''));
    return item;
  });
  priceArray.forEach(function (item) {
    if (sortedArr.length === 0) return sortedArr.push(item);

    var index = _lodash["default"].sortedIndexBy(sortedArr, item, 'parsedPrice');

    sortedArr.splice(index, 0, item);
  });
  if (sortId === 3) sortedArr = _lodash["default"].reverse(sortedArr);

  if (arr.PartList) {
    arr.PartList = (0, _toConsumableArray2["default"])(sortedArr);
    return arr;
  } else {
    return sortedArr;
  }
};

exports.sortByPrice = sortByPrice;