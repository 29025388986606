import React from 'react';
import styles from "./styles.module.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartCircleExclamation } from '@fortawesome/pro-light-svg-icons'
import { faXmark } from '@fortawesome/pro-regular-svg-icons'

const LoginErrorModal = ({ show, onClose }) => {

    if (!show) return null;

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modal}>
                <button className={styles.closeButton} onClick={onClose}>
                <FontAwesomeIcon icon={faXmark} className={styles.closeIcon}/>
                </button>
               <div className={styles.messageWrapper}>
                    <FontAwesomeIcon icon={faCartCircleExclamation} className={styles.icon} />
                    <h3 className={styles.title}>Oops, there was a problem placing your order.</h3>
                    <p className={styles.description}>Don’t worry, your items were successfully added to your Raiven cart.<br/>Please try refreshing the page or logging back in to Raiven.</p>
                    <button className={styles.button} onClick={onClose}>
                        Ok
                    </button>
               </div>
            </div>
        </div>
    );
};

export default LoginErrorModal;
