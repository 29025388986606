import React from 'react';
import _ from 'lodash';
import ComponentStyles from './styles';
import { getSpendbridgeUrls } from '../../../../../utils/getSpendbridgeUrls';

const { EmptyCartContainer, SpendbridgeCart } = ComponentStyles;

const isBrowser = typeof window !== 'undefined' && window.env;
const blobUrl = isBrowser ? window.env.blobUrl : process.env.RAZZLE_RUNTIME_BLOB_URL;

const getSpendbridgeLinks = async (userData, link) => {
    const data = await getSpendbridgeUrls(userData);

    if (!_.isEmpty(data)) {
        if (_.has(data, link)) {
            document.location.href = data[link];
        }
    }
};

const EmptyCart = props => (
    <EmptyCartContainer className='easeIn'>
    <div className="column">
        <img alt="supplier-logo" src={`${blobUrl}/procurement/images/Empty-Cart-Illustration.svg`} />
        <h2>Your cart is empty</h2>
            <p>
        Add items to your cart by shopping from your suppliers and submitting your carts. Once a cart is
        submitted, you’ll see your items here and can check out with them.
            </p>

            {_.has(props, 'defaultCheckout') && props.defaultCheckout === 'spendbridge' && (
                <SpendbridgeCart>
              You can{' '}
              <a onClick={() => getSpendbridgeLinks(props.userData, 'cart')}>go to your active checkout</a>
              {' '}
              if you
              left items in progress.
</SpendbridgeCart>
      )}
        </div>
  </EmptyCartContainer>
);

export default EmptyCart;
