import styled from 'styled-components';

const SubmittedOrdersContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  flex: 1;
  flex-grow: 1;
  margin: 1.5rem 1.5rem 1.5rem 0;
  border-radius: 0.25rem;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.14),
    0px 2px 2px 0px rgba(0, 0, 0, 0.12), 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  list-style-type: none;
  .order-detail-header {
    display: flex;
    padding: 0.5rem;
    border-bottom: 1px solid #dedfe2;
   
}
.all-order:hover {
  opacity: 0.8;
}
	li {
		color: rgb(62, 65, 72);
		font-family: Lato;
		font-size: 12px;
		font-weight: bold;
		height: 16px;
		letter-spacing: 0.25px;
    line-height: 16px;
    padding-right: 1rem;

    &.Submit {
      width: 15.75rem;
    }
    &.Status {
      width: 7.5rem;
    }
    &.Order {
      width: 9rem;
      flex-grow: 1;
    }
    &.Supplier {
      width: 17rem;
    }
    &.Items {
      width: 4.5rem;
      text-align: center;
    }
    &.Subtotal {
      width: 6rem;
      text-align: right;
      margin-right: 3.75rem;
    }
	}

    img {
      height: 32px;
      width: 32px;
    }
    h3 {
      width: 604px;
      height: 20px;
      color: rgb(62, 65, 72);
      font-size: 16px;
      font-family: Lato;
      font-weight: bold;
      line-height: 20px;
      margin: 0.45rem 0.5rem;
      flex-grow: 1;
    }
    .viewButton {
      width: 108px;
      height: 16px;
      color: rgb(185, 187, 193);
      font-size: 12px;
      font-family: Lato;
      font-weight: bold;
      text-align: center;
      letter-spacing: 0.5px;
      line-height: 16px;
      margin: 0.7rem 0 0 0;
    }
  }


  .order-detail-expanded {
    display: flex;
    flex-direction: column;
    padding: 1rem 0.5rem;
    box-shadow: inset 0px -1px 0px 0px rgb(237, 238, 239);
    background-color: #ffffff;
    margin-left: 2rem;
    .shipPOSection {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .shipPaymentTitle {
        display: flex;
        flex-direction: column;
        width: 18.75rem;
        .shippingTitle {
          font-size: 14px;
          color: #6e727c;
          &.POvalue {
            margin-top: 1rem;
          }
        }
        .shippingDetail {
          p {
            font-size: 16px;
            color: #3e4148;
          }
        }
      }
    }

    .commentSection {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 1rem;
      .commentTitle {
        font-size: 14px;
        color: #6e727c;
        margin-top: 1rem;
      }
    }

    
    .order-summary {
      &.Date {
        width: 15.75rem;
      }
      &.Order {
        width: 7.5rem;
        flex-grow: 1;
      }
      &.Supplier {
        width: 17rem;
      }
      &.Items {
        width: 4.5rem;
        text-align: center;
      }
      &.Subtotal {
        width: 6rem;
        text-align: right;
      }
      i {
        margin: 4px 8px 0 24px;
        color: #969ba5;
        font-size: 16px;
        font-weight: 300;
        height: 20px;
        line-height: 20px;
        text-align: center;
        width: 20px;
      }
    }


    .order-bold {
      font-weight: bold;
      flex-shrink
    }
    .submitted-order-accountno{
      margin-top: 1rem;
    > p{
      font-size: 14px;
      color: #6e727c;
    }
    }

  }

  .order-total-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0.75rem 0;

    h3 {
      width: 376px;
      height: 20px;
      color: rgb(110, 114, 124);
      font-size: 14px;
      font-family: Lato;
      font-weight: normal;
      line-height: 20px;
    }
    .detailLabel {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: .25rem;
    }
  }

  .order-total {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0.75rem 1rem 0.75rem;

    h3 {
      width: 376px;
      height: 20px;
      color: rgb(110, 114, 124);
      font-size: 14px;
      font-family: Lato;
      font-weight: normal;
      line-height: 20px;
    }
    .detailLabel {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: .25rem;
      .totalAmount {
        width: 376px;
        height: 20px;
        color: rgb(62, 65, 72);
        font-size: 16px;
        font-family: Lato;
        font-weight: bold;
        text-align: right;
        line-height: 20px;
      }
    }
  }

  .currency {
    display: flex;
    justify-content: flex-end;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.25rem;
  }
`;

const AllOrders = styled.div`
  .all-order {
    color: rgb(185, 187, 193);
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
`;

export default { SubmittedOrdersContainer, AllOrders };
