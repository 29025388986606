import React, { PureComponent } from 'react';
import ComponentStyles from './styles';
import { Button, Search } from '../../../../../../lib/@q-core/react-components';
import Modal from '../../../../components/Modal';
import visaLogo from './img/finance-creditcard-visa.png';
import amexLogo from './img/finance-creditcard-amex.png';
import mastercardLogo from './img/finance-creditcard-mastercard.png';
import discoverLogo from './img/finance-creditcard-discover.png';
import moment from 'moment';
import { formatCard } from './helper';
import _ from 'lodash';

const { PCSelectModalContainer, PCSelectModalStyles } = ComponentStyles;

class PcardSelectModal extends PureComponent {
    constructor(props) {
        super(props);

        let formatted = [],
            cardOptions = props.cards;

        if (!_.isNil(cardOptions)) {
            formatted = _.map(cardOptions, function(item, index) {
                let card = formatCard(item);
                if (_.has(item, 'Id')) {
                    return {
                        ...card,
                        Id: item.Id,
                    };
                }
            });
        }
        this.state = {
            searchValue: '',
            formatted,
            filtered: formatted,
            cardOptions,
        };
    }

    setTypeImg = Type => {
        switch (Type) {
            case 'Mastercard':
                return mastercardLogo;
            case 'Visa':
                return visaLogo;
            case 'Amex':
                return amexLogo;
            case 'Discover':
                return discoverLogo;
            default:
                return;
        }
    };

    selectCard = card => {
        if (_.has(card, 'Id')) {
            let ogCard = _.find(this.state.cardOptions, { Id: card.Id });

            if (!_.isNil(ogCard)) {
                this.props.setCard(ogCard);
            }
        }
    };

    filteredItems = value => {
        this.setState({
            filtered: value,
        });
    };

    onClear = value => {
        if (_.isEmpty(value)) {
            this.setState({
                filtered: this.state.formatted,
            });
        }
    };

    expirationCheck = value => {
        let active_time = moment.utc(value, 'YYYY-MM-DD[T]HH:mm[Z]');
        let d = new Date(); //compare to right now date object
        let validDate = active_time.isAfter(d);
        if (validDate) {
            return true;
        } else {
            return false;
        }
    };

    render() {
        const { filtered, formatted } = this.state;
        return (
            <PCSelectModalContainer>
                <Modal
                    title='Select Card'
                    setEnabled={true}
                    darkBackground
                    maxHeight='420px'
                    setToggle={() => {
                        this.props.setSelectModal('false');
                    }}
                    maxWidth='600px'
                    cardClassName='pcSelectModal'
                >
                    <PCSelectModalStyles>
                        <Search
                            className='filterCard'
                            onError={function noRefCheck() {}}
                            onSubmit={value => this.onClear(value)}
                            placeholder='Search your cards&hellip;'
                            value={this.state.searchValue}
                            filterBy={['name', 'type']}
                            filtered={value => this.filteredItems(value)}
                            source={formatted}
                        />
                        <ul className='optionContainer'>
                            {_.map(filtered, (card, index) => {
                                let { name, number, type, expirationDate } = card;
                                return (
                                    <li key={index}>
                                        <div className='cardObject'>
                                            <div className='withChange'>
                                                <p className='defaultName'>{name}</p>
                                            </div>
                                            <div className='infoContainer'>
                                                <img className='CCType' src={this.setTypeImg(type)} alt='Card Type' />
                                                <div className='textContainer'>
                                                    <p className='defaultInfo'>
                                                        {type} ending in {number}
                                                    </p>
                                                    <p className='defaultInfo'>
                                                        {this.expirationCheck(expirationDate) ? 'Expires' : 'Expired'}
                                                        &nbsp;
                                                        {this.props.toLocalFromUTC({
                                                            utcDate: expirationDate,
                                                            format: 'MMM-YYYY',
                                                        })}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {this.expirationCheck(expirationDate) && (
                                            <Button
                                                type='indigoVibrant'
                                                shape='square'
                                                title='Select'
                                                onClick={() => {
                                                    this.selectCard(card);
                                                }}
                                            />
                                        )}
                                    </li>
                                );
                            })}
                        </ul>
                        <div
                            className='addCCContainer'
                            role='button'
                            onClick={() => this.props.setSelectModal('false', true)}
                        >
                            <i className='far fa-plus'></i>
                            <p className='addNewCardButton'>Add new card</p>
                        </div>
                    </PCSelectModalStyles>
                </Modal>
            </PCSelectModalContainer>
        );
    }
}

export default PcardSelectModal;
