const isBrowser = !!(typeof window !== 'undefined' && window.sessionStorage)

export const getActiveCorpSettings = (passedUserData) => {
  const userData = passedUserData || safeLocalStorage.getJSONItem('userData')
  const activeCorp =
    userData && userData.activeNetworkId && userData.Network.find((corp) => corp.Id === userData.activeNetworkId)
  const rawCorpSettings = activeCorp && activeCorp.CompanySetting
  const activeCorpSettings =
    rawCorpSettings &&
    rawCorpSettings.length > 0 &&
    typeof rawCorpSettings === 'string' &&
    rawCorpSettings.includes('{') &&
    JSON.parse(rawCorpSettings)
  return activeCorpSettings || {}
}

export const getValidStores = (passedSuppliers, passedStores) => {
  const suppliers = passedSuppliers || safeLocalStorage.getJSONItem('suppliers')
  const availableStores = passedStores || safeLocalStorage.getJSONItem('stores')
  const contractedSupplierIds = suppliers ? suppliers.map((supplier) => supplier.Id) : []
  return availableStores ? 
    availableStores.filter((store) => store.suppliers.filter((supplier) => contractedSupplierIds.includes(supplier)).length > 0)
    .sort((a, b) => a.title.localeCompare(b.title)) : []
}

export const safeSessionStorage = {
  getItem: function (storageKey) {
    if (isBrowser) return window.sessionStorage.getItem(storageKey)
  },
  setItem(storageKey, storageValue) {
    if (isBrowser) {
      return window.sessionStorage.setItem(storageKey, storageValue)
    }
  },
  getJSONItem(storageKey) {
    if (isBrowser) return tryParse(sessionStorage.getItem(storageKey))
  },
  setJSONItem(storageKey, storageValue) {
    if (isBrowser) return window.sessionStorage.setItem(storageKey, JSON.stringify(storageValue))
  },
}

export const safeLocalStorage = {
  getItem(storageKey) {
    if (isBrowser) return window.localStorage.getItem(storageKey)
  },
  setItem: function (storageKey, storageValue) {
    if (isBrowser) return window.localStorage.setItem(storageKey, storageValue)
  },
  removeItem(storageKey) {
    if (isBrowser) return window.localStorage.removeItem(storageKey)
  },
  getJSONItem: function (storageKey) {
    if (isBrowser) return tryParse(localStorage.getItem(storageKey))
  },
  setJSONItem(storageKey, storageValue) {
    if (isBrowser) return window.localStorage.setItem(storageKey, JSON.stringify(storageValue))
  },
}

const tryParse = (data) => {
  try {
    return JSON.parse(data)
  } catch (e) {
    return
  }
}
