import _ from 'lodash'
import { getSpendbridgeUrl } from '../../common/apis'

const isBrowser = typeof window !== 'undefined' && window.env
const sbApprovals = isBrowser ? window.env.sbApprovals : process.env.sbApprovals
const sbHistory = isBrowser ? window.env.sbHistory : process.env.sbHistory
const sbCart = isBrowser ? window.env.sbCart : process.env.sbCart

const model = {
  approvals: sbApprovals,
  history: sbHistory,
  cart: sbCart,
}

const getSpendbridgeLink = async (userData) => {
  const data = await getSpendbridgeUrl(userData)
  if (_.has(data, 'URL') && !_.isEmpty(data.URL)) {
    return data.URL
  }
}

export const getModel = () => model

export const getSpendbridgeUrls = (userData, options) => {
  let urls = _.mapValues(model, (value, id) => {
    const escaped = escape(value)
    return `&ReturnUrl=${escaped}`
  })
  let finalUrls

  if (_.has(options, 'sbUrl')) {
    return (finalUrls = assembleUrl(options.sbUrl, urls))
  }
  const spendbridgeUrl = getSpendbridgeLink(userData)

  const loader = document.getElementById('spendBridgeLoader');
  if(loader){
    document.getElementById('spendBridgeLoader').style.zIndex = 1000000
    document.getElementById('spendBridgeLoader').style.visibility = 'visible'
  }

  return new Promise((resolve, reject) => {
    spendbridgeUrl.then((sbUrl) => {
      finalUrls = assembleUrl(sbUrl, urls)
      resolve(finalUrls)
    })
  })
}

const assembleUrl = (sburl, urls) => {
  const final = _.mapValues(urls, (value, id) => `${sburl}${value}`)

  return final
}

export default {
  getModel,
  getSpendbridgeUrls,
}
