import React from 'react'
import styles from './style.module.css'

function Button({ children, onClick, disabled, style, className, type, text, variant, size, fullWidth, icon = null, ...props }) {
  const setVariantStyles = (variant = 'none') => {
    return {
      primary: styles.primary,
      approve: styles.approve,
      reject: styles.reject,
      default: styles.default,
      none: styles.button,
    }[variant]
  }

  const setSizeStyles = (size = 'md') => {
    return {
      xl: styles.xl,
      lg: styles.lg,
      md: styles.md,
      sm: styles.sm,
    }[size]
  }

  const variantStyles = setVariantStyles(variant)
  const sizeStyles = setSizeStyles(size)

  return (
    <button disabled={disabled} onClick={onClick} style={props.styles && props.styles} className={`${styles.button} ${variantStyles} ${sizeStyles} ${fullWidth ? styles.fullWidth : ''}`}>
      {icon}
      {text}
    </button>
  )
}

export default Button
