"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var renameKey = function renameKey(object, key, newKey) {
  var clonedObj = Object.assign({}, object);
  var targetKey = clonedObj["".concat(key)];
  delete clonedObj[key];
  clonedObj[newKey] = targetKey;
  return clonedObj;
};

var _default = function _default(items) {
  var modifiedItems = (0, _toConsumableArray2["default"])(items).map(function (item) {
    var newItem = renameKey(item, 'iconUrl', 'imageUrl');
    newItem = renameKey(newItem, 'title', 'name');
    return newItem;
  });
  return modifiedItems;
};

exports["default"] = _default;