import { useCartContext } from '../../../../../hooks/useCartContext'
import { safeLocalStorage } from '../../../../../utils/safeStorage'
import updateCartItemsContractCodes from '../../../../apis/updateCartItemsContractCodes'
import { DropdownBody } from '../../../../components/Dropdown/DropdownBody'
import ModalBase from '../../../../components/ModalBase'
import { ContractCodesModalContainer } from './styles'

const ContractCodesModal = () => {
  const { hideModal, state, setCart, clearSelectedItem } = useCartContext()
  const userData = safeLocalStorage.getJSONItem('userData')
  const { availableContractCodes, modalSelection, selectedItems } = state

  const onSelect = async (option) => {
    const updatedCart = await updateCartItemsContractCodes({
      userData,
      contractCodeId: option.id,
      items: selectedItems,
    })
    // setModalSelection(option)
    setCart(updatedCart)
    hideModal()
    clearSelectedItem()
  }

  const buildHeader = () => {
    return (
      <div className="modal-header">
        <h3>Select contract code</h3>
        <i className="fas fa-times close-icon" onClick={hideModal} />
      </div>
    )
  }
  return (
    <ModalBase onClose={hideModal}>
      <ContractCodesModalContainer>
        {buildHeader()}
        <div className="dropdown-container">
          <DropdownBody
            position="relative"
            options={availableContractCodes}
            onSelect={onSelect}
            selected={modalSelection}
            borderRadius={8}
          />
        </div>
      </ContractCodesModalContainer>
    </ModalBase>
  )
}

export default ContractCodesModal
