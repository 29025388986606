"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.filterItems = exports.handleClear = exports.handleKeyDown = exports.handleChange = exports.handleBlur = exports.handleFocus = exports.activateAnimation = exports.clearAnimation = void 0;

var _gsap = _interopRequireDefault(require("gsap"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var clearAnimation = function clearAnimation() {
  _gsap["default"].to('.clear-icon', {
    display: 'none',
    opacity: 0,
    duration: 0.2
  });

  _gsap["default"].to('.search-input', {
    paddingLeft: 40,
    borderColor: '#dedfe2'
  });

  _gsap["default"].to('.search-icon', {
    margin: '-41px 0 0 6px',
    duration: 0.3,
    ease: true
  });

  _gsap["default"].to('.search-icon', {
    background: '#fafafa',
    color: 'rgba(62, 65, 72, 0.35)',
    delay: 0.3,
    duration: 0.2
  });
};

exports.clearAnimation = clearAnimation;

var activateAnimation = function activateAnimation(value) {
  _gsap["default"].to('.search-icon', {
    background: value ? 'linear-gradient(-180deg, rgb(57, 99, 254) 0%, rgb(47, 85, 254) 100%)' : '#8aa3ff',
    color: '#ffffff'
  });

  _gsap["default"].to('.search-icon', {
    margin: '-41px 6px 0 auto',
    delay: 0.3,
    duration: 0.3,
    ease: true
  });

  _gsap["default"].to('.search-input', {
    paddingLeft: 20,
    borderColor: '#3963fe',
    delay: 0.3,
    duration: 0.2
  });
};

exports.activateAnimation = activateAnimation;

var handleFocus = function handleFocus(_ref) {
  var value = _ref.value;

  if (document.getElementsByClassName('search-input')[0] === document.activeElement) {
    activateAnimation(value);
  }
};

exports.handleFocus = handleFocus;
var prevValue = '';

var handleBlur = function handleBlur(_ref2) {
  var value = _ref2.value,
      onSubmit = _ref2.onSubmit;

  if (!value && document.getElementsByClassName('search-input')[0] !== document.activeElement) {
    if (prevValue) {
      onSubmit('');
      prevValue = value;
    }

    clearAnimation();
  }

  prevValue = value;
};

exports.handleBlur = handleBlur;

var handleChange = function handleChange(e, _ref3) {
  var value = _ref3.value,
      setValue = _ref3.setValue;
  prevValue = value;

  if (e.target.value) {
    _gsap["default"].to('.search-icon', {
      background: 'linear-gradient(-180deg, rgb(57, 99, 254) 0%, rgb(47, 85, 254) 100%)'
    });

    _gsap["default"].to('.clear-icon', {
      display: 'block',
      opacity: 1,
      duration: 0.2
    });
  } else {
    _gsap["default"].to('.search-icon', {
      background: '#8aa3ff'
    });

    _gsap["default"].to('.clear-icon', {
      display: 'none',
      opacity: 0,
      duration: 0.2
    });
  }

  setValue(e.target.value);
};

exports.handleChange = handleChange;

var handleKeyDown = function handleKeyDown(e, _ref4) {
  var value = _ref4.value,
      onSubmit = _ref4.onSubmit;
  if (e.keyCode == 13) onSubmit(value);
};

exports.handleKeyDown = handleKeyDown;

var handleClear = function handleClear(_ref5) {
  var setValue = _ref5.setValue,
      onSubmit = _ref5.onSubmit;
  onSubmit('');
  setValue('');
  clearAnimation();
};

exports.handleClear = handleClear;

var filterItems = function filterItems(_ref6) {
  var source = _ref6.source,
      keys = _ref6.keys,
      value = _ref6.value;
  var filtered = [],
      lowervalue = value.toLowerCase();

  _.map(source, function (item, key) {
    _.map(keys, function (filter, index) {
      if (_.has(item, filter)) {
        if (_.isString(item[filter])) {
          var filterString = item[filter].toLowerCase();

          if (filterString.indexOf(lowervalue) !== -1) {
            if (_.findIndex(filtered, item) === -1) {
              filtered.push(item);
            }
          }
        }
      }
    });
  });

  return filtered;
};

exports.filterItems = filterItems;