import React, { Fragment, useState, useEffect, useMemo, useContext, useCallback } from 'react'
import { Redirect } from 'react-router-dom'
import EmpyRequests from '../../components/EmptyRequests'
import RequestsTable from '../../components/RequestsTable'
import styles from './style.module.css'
import RequestRejectModal from '../../components/RequestRejectModal'
import RequestReassignModal from '../../components/RequestReassignModal'
import RequestDetailsModal from '../../components/RequestDetailsModal'
import Toggle from '../../components/Toggle'
import Toasts from '../../components/Toasts'
import postAnalytics from '../../../utils/postAnalytics'
import {
  getRequesters,
  processRequestAction,
  getApprovalsSummary,
  getRequestsSummary,
  getApprovers,
  getSuppliers,
} from './api'
import { useHistory, useLocation } from 'react-router-dom'
import formatRequestDate from '../../../utils/formatRequestDate'
import RequestsHeader from '../../components/RequestsHeader'
import RequestsFooter from '../../components/RequestsFooter'
import RequestsFilters from '../../components/RequestsFilters'
import ProcessedRequestModal from '../../components/ProcessedRequestModal'
// import { useFlags } from "launchdarkly-react-client-sdk";
import { MobileLayout } from '../../App'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import RequestsLoader from '../../components/RequestsLoader'
import DownloadCSV from '../../components/DownloadCSVButton'
import Button from '../../components/Button'
import { faReceipt } from '@fortawesome/pro-regular-svg-icons'
import { safeLocalStorage } from '../../../utils/safeStorage'

function Approvals(props) {
  // const featureFlags = useFlags();
  const [pendingRequests, setPendingRequests] = useState([])
  const [completedRequests, setCompletedRequests] = useState([])
  const [showRejectModal, setShowRejectModal] = useState(false)
  const [showReassignModal, setShowReassignModal] = useState(false)
  const [returnToDetails, setReturnToDetails] = useState(false)
  const [showRequestDetailsModal, setShowRequestDetailsModal] = useState(false)
  const [selectedRequest, setSelectedRequest] = useState(null)
  const [selectedStatus, setSelectedStatus] = useState({})
  const [selectedRequester, setSelectedRequester] = useState({ value: '', label: 'All' })
  const [requesters, setRequesters] = useState([])
  const [approvers, setApprovers] = useState([])
  const [selectedApprover, setSelectedApprover] = useState({ value: '', label: 'All' })
  const [vendors, setVendors] = useState([])
  const [selectedVendor, setSelectedVendor] = useState({ value: '', label: 'All' })
  const [requestSearchInput, setRequestSearchInput] = useState('')
  const [processedRequestMessage, setProcessedRequestMessage] = useState('')
  const [showProcessedRequestModal, setShowProceshowProcessedRequestModal] = useState(false)
  const [savedDirectLoadAnalytics, setSavedDirectLoadAnalytics] = useState(false)

  const [displayedStatuses, setDisplayedStatuses] = useState(['Pending'])
  const [isCompletedTabActive, setIsCompletedTabActive] = useState(false)

  const [isUserApprover, setIsUserApprover] = useState(
    props.activeUser && (props.activeUser.isApprover || props.activeUser.isAdmin)
  )
  const [activePage, setActivePage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [isActionTriggered, setIsActionTriggered] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isTableLoading, setIsTableLoading] = useState(true)
  const [loadMore, setLoadMore] = useState(false)
  const [isTabChanged, setIsTabChanged] = useState(false)
  //defaulting pending list to show oldest on top and completed to show newest
  const [isSubmittedFilterActive, setIsSubmittedFilterActive] = useState(false)
  const [requestsToExport, setRequestsToExport] = useState([])
  const location = useLocation()
  const history = useHistory()

  const [list, setList] = useState([])
  let toastProperties = null

  const isMobile = useContext(MobileLayout)
  function useQuery() {
    const { search } = useLocation()

    return useMemo(() => new URLSearchParams(search), [search])
  }

  const showToast = ({ type, title, action, undoAction }) => {
    switch (type) {
      case 'success':
        toastProperties = {
          id: list.length + 1,
          title,
          type,
          action,
          undoAction,
        }
        break
      case 'danger':
        toastProperties = {
          id: list.length + 1,
          title,
          type,
          action,
          undoAction,
        }
        break
      default:
        toastProperties = []
    }
    setList([...list, toastProperties])
  }

  const groupByKey = async (items, key) => {
    let sorted = {
      pending: [],
      completed: [],
    }

    if (key === 'approvalResult') {
      items.forEach((item) => {
        if (item.hasPendingApprovals && !item.status === 'Pending') {
          sorted['completed'].push(item)
        } else if (item.status === 'Pending') {
          if (props.activeUser.isAdmin) {
            sorted['pending'].push(item)
          } else {
            if (item.isCurrentApprover) {
              sorted['pending'].push(item)
            } else {
              sorted['completed'].push(item)
            }
          }
        } else {
          sorted['completed'].push(item)
        }
      })
    }

    if (key === 'status') {
      items.forEach((item) => {
        if (
          !item.isCurrentApprover &&
          (item[key] === 'Approved' || item[key] === 'Rejected' || item[key] === 'Reassigned')
        ) {
          sorted['completed'].push(item)
        } else {
          sorted['pending'].push(item)
        }
      })
    }

    return sorted
  }

  const processRequestStatus = (request) => {
    for (const step of request.approvalChainSteps) {
      if (step.approvalResult === 'Pending') {
        return true
      }
    }
    return false
  }

  const handleData = async (data) => {
    setTotalPages(data?.totalPages)

    const approvalsListFormatted = await data?.items.map((req) => ({
      id: req.id,
      externalId: req.externalId,
      approvalId: req.activeApprovalId,
      requester: req.placedByUser,
      requesterEmail: req.placedByEmail,
      comment: req.comment,
      approvers: req.currentApprovers.length > 0 ? req.currentApprovers : req.completedByUser,
      createdOn: formatRequestDate(req.createdOn),
      fullCreatedOn: req.createdOn,
      status: req.status,
      vendors: JSON.parse(req.metadata) ? JSON.parse(req.metadata).Icons : [],
      approvalResult: req.lastCurrentUserAction,
      isCurrentApprover: req.isCurrentApprover,
      approvalChainSteps: req.approvalChainSteps,
      total: new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(Number(req.total)),
      hasPendingApprovals: req.approvalChainSteps.some((step) => step.approvalResult === 'Pending'),
    }))

    const keyToFilterBy =
      isUserApprover && (props.activeTab === 'approver' || props.activeTab === 'all') ? 'approvalResult' : 'status'

    const filteredResults = await groupByKey(approvalsListFormatted, keyToFilterBy)

    if (JSON.stringify(filteredResults) === '{}') {
      setPendingRequests([])
      setCompletedRequests([])
    } else if (props.activeTab === 'all') {
      setPendingRequests(
        isMobile && loadMore ? pendingRequests.concat(filteredResults.pending) : filteredResults.pending
      )
      setCompletedRequests(
        isMobile && loadMore ? completedRequests.concat(filteredResults.completed) : filteredResults.completed
      )
    } else {
      setPendingRequests(
        isMobile && loadMore ? pendingRequests.concat(filteredResults.pending) : filteredResults.pending
      )
      setCompletedRequests(
        isMobile && loadMore ? completedRequests.concat(filteredResults.completed) : filteredResults.completed
      )
    }
    setLoadMore(false)

    if (filteredResults) {
      setIsLoading(false)
    }
  }

  const handleDataToExport = async (data) => {
    const approvalsListFormatted = await data.items.map((req) => {
      const currentStep = req.approvalChainSteps
        .slice()
        .reverse()
        .find((element) => element.approvalResult)
      const metadata = JSON.parse(req.metadata)
      const shipping =
        metadata && metadata.PurchaseOrders && metadata.PurchaseOrders[0] && metadata.PurchaseOrders[0].Shipping
      return {
        id: req.id,
        externalId: req.externalId,
        approvalId: req.activeApprovalId,
        requester: req.placedByUser,
        requesterEmail: req.placedByEmail,
        comment: req.comment,
        approvers: currentStep ? currentStep.approvers.map((approver) => `${approver.name} (${approver.email})`) : '',
        jobId: shipping ? shipping.DeliverTo2 : '',
        shippingId: shipping ? shipping.Id : '',
        createdOn: formatRequestDate(req.createdOn),
        fullCreatedOn: req.createdOn,
        status: req.status,
        vendors: metadata ? metadata.Icons : [],
        approvalResult: req.lastCurrentUserAction,
        isCurrentApprover: req.isCurrentApprover,
        approvalChainSteps: req.approvalChainSteps,
        total: new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(Number(req.total)),
      }
    })

    const filteredResults = await groupByKey(approvalsListFormatted, 'approvalResult')

    if (JSON.stringify(filteredResults) === '{}') {
      return []
    } else {
      return [...filteredResults.pending, ...filteredResults.completed]
    }
  }

  const collectApprovals = async (activePage, adminView, statusForCurrentUser = false, perPage = 50) => {
    const approvals = await getApprovalsSummary({
      token: localStorage.getItem('token'),
      status: selectedStatus.value,
      statuses: selectedStatus.value ? null : displayedStatuses,
      requesterId: selectedRequester.value,
      approverId: selectedApprover.value,
      supplierId: selectedVendor.value,
      searchString: requestSearchInput,
      sortDescending: isSubmittedFilterActive,
      adminView: adminView,
      page: activePage,
      perPage: perPage,
      statusForCurrentUser: statusForCurrentUser,
    })

    return approvals
  }

  const collectRequests = async (adminView) => {
    const requests = await getRequestsSummary({
      token: localStorage.getItem('token'),
      status: selectedStatus.value,
      statuses: selectedStatus.value ? null : displayedStatuses,
      requesterId: selectedRequester.value,
      approverId: selectedApprover.value,
      supplierId: selectedVendor.value,
      searchString: requestSearchInput,
      sortDescending: isSubmittedFilterActive,
      adminView: adminView,
      page: activePage,
      perPage: 50,
    })

    return requests
  }

  const collectSuppliers = async () => {
    const suppliers = await getSuppliers({ token: localStorage.getItem('token') })
    const formattedVendorsList = suppliers.Suppliers.map((item) => ({
      value: item.Id,
      label: item.Name,
    }))
    formattedVendorsList.unshift({ value: '', label: 'All' })
    setVendors(formattedVendorsList)
  }

  const collectApprovers = async () => {
    let approversList = await getApprovers({
      token: localStorage.getItem('token'),
    })

    const approversListFormatted = approversList.map((approver) => ({
      value: approver.id,
      label: approver.name,
      subLabel: approver.email,
    }))

    approversListFormatted.unshift({ value: '', label: 'All' })

    const sortedRequestersList = await approversListFormatted.sort((a, b) => a.label.localeCompare(b.label))

    setApprovers(sortedRequestersList)
  }

  const collectRequesters = async () => {
    let requestersList = await getRequesters({
      token: localStorage.getItem('token'),
    })

    const formattedRequestersList = await requestersList.map((req) => ({
      value: req.id,
      label: req.name,
      subLabel: req.email,
    }))

    formattedRequestersList.unshift({ value: '', label: 'All' })

    const sortedRequestersList = await formattedRequestersList.sort((a, b) => a.label.localeCompare(b.label))
    setRequesters(sortedRequestersList)
  }

  const onRequestsTableChange = (action, request) => {
    if (action === 'showRejectModal') {
      !isMobile && setShowRequestDetailsModal(false)
      setShowRejectModal(true)
      setSelectedRequest(request)
      postAnalytics('pm-aprovers-rejModal-open-click', request)
    }
    if (action === 'showReassignModal') {
      !isMobile && setShowRequestDetailsModal(false)
      setShowReassignModal(true)
      setSelectedRequest(request)
      postAnalytics('pm-aprovers-reasignModal-open-click', request)
    }
    if (action === 'showRequestDetailsModal') {
      setReturnToDetails(true)
      setShowRequestDetailsModal(true)
      setSelectedRequest(request)
      postAnalytics(
        props.activeTab === 'approver' ? 'pm-aprovers-reqModal-open-click' : 'pm-requests-reqModal-open-click',
        request
      )
    }
    if (action === 'approveRequest') {
      setShowRequestDetailsModal(false)
      showToast({
        type: 'success',
        title: `Approving request ${request.externalId}...`,
        undoAction: false,
        action: async () => {
          const body = {
            requestId: request.id,
            approvalId: request.approvalId,
            result: 'Approved',
            comment: '',
            reassignUserIds: [],
            reassignGroupIds: [],
          }

          const response = await processRequestAction({ token: localStorage.getItem('token') }, body)

          if (response && !response.isSuccess) {
            setProcessedRequestMessage(response.errorMessage)
            setShowProceshowProcessedRequestModal(true)
          } else if (response) {
            setIsActionTriggered(true)
            postAnalytics('pm-aprovers-reqModal-approved-click', request)
          }
        },
      })
    }
    if (action === 'rejectRequest') {
      setShowRejectModal(false)
      isMobile && setShowRequestDetailsModal(false)
      showToast({
        type: 'danger',
        title: `Rejecting request ${request.externalId}...`,
        undoAction: false,
        action: async () => {
          const body = {
            requestId: request.id,
            approvalId: request.approvalId,
            result: 'Rejected',
            comment: request.comment,
            reassignUserIds: [],
            reassignGroupIds: [],
          }

          const response = await processRequestAction({ token: localStorage.getItem('token') }, body)

          if (response && !response.isSuccess) {
            setProcessedRequestMessage(response.errorMessage)
            setShowProceshowProcessedRequestModal(true)
          } else if (response) {
            setIsActionTriggered(true)
            postAnalytics('pm-aprovers-reqModal-reject-click', request)
          }
        },
      })
    }
    if (action === 'reassignRequest') {
      setShowReassignModal(false)
      setShowRequestDetailsModal(false)
      showToast({
        type: 'success',
        title: `Request ${request.externalId} was reassigned to ${request.approver.name}`,
        undoAction: null,
        action: async () => {
          const body = {
            requestId: request.id,
            approvalId: request.approvalId,
            result: 'Reassigned',
            comment: request.comment,
            reassignUserIds: [request.approver.id],
            reassignGroupIds: [],
          }

          const response = await processRequestAction({ token: localStorage.getItem('token') }, body)

          if (response && !response.isSuccess) {
            setProcessedRequestMessage(response.errorMessage)
            setShowProceshowProcessedRequestModal(true)
          } else if (response) {
            setIsActionTriggered(true)
            postAnalytics('pm-aprovers-reqModal-reassign-click', request)
          }
        },
      })
    }
  }

  const onCloseModal = (action) => {
    if (action === 'hideRejectModal') {
      setShowRejectModal(false)
      returnToDetails && setShowRequestDetailsModal(true);
    }
    if (action === 'hideReassignModal') {
      setShowReassignModal(false)
      returnToDetails && setShowRequestDetailsModal(true);
    }
    if (action === 'hideRequestDetailsModal') {
      setShowRequestDetailsModal(false)
      setReturnToDetails(false)
    }
    if (action === 'hideProcessedRequestModal') {
      setShowProceshowProcessedRequestModal(false)
      returnToDetails && setShowRequestDetailsModal(true);
    }
  }

  const handleTabChange = (newTab) => {
    window.scrollTo(0, 0)
    setActivePage(1)
    if (newTab) {
      setIsTabChanged(true)
      history.replace(`/requests/${newTab}`)
    }
  }
  
  function resetFilters() {
    setSelectedStatus({ value: '', label: 'All' })
    setSelectedRequester({ value: '', label: 'All' })

    if (isMobile) return
    setRequestSearchInput('')
    setSelectedApprover({ value: '', label: 'All' })
    setSelectedVendor({ value: '', label: 'All' })
  }

  const collectAllData = useCallback(async (activePage) => {
    if (!props.activeUser.id) {
      setIsLoading(false);
      return;
    }
  
    await collectApprovers();
    await collectRequesters();
    await collectSuppliers();
  
    let collectedData = [];
  
    if (isUserApprover && props.activeTab === 'approver') {
      collectedData = await collectApprovals(activePage,false, true);
      handleData(collectedData);
      return;
    }
    
    if (props.activeTab === 'requests') {
      collectedData = await collectRequests();
      handleData(collectedData);
      return;
    }
    
    if (props.activeTab === 'all') {
      let collectAllDataToExport = [];
      collectedData = await collectApprovals(activePage, props.activeUser.isAdmin || props.activeUser.isReadOnly);
  
      collectAllDataToExport = await collectApprovals(1, props.activeUser.isAdmin || props.activeUser.isReadOnly, false, collectedData.totalPages * 50);
      const formattedRequestsToExport = await handleDataToExport(collectAllDataToExport);
      setRequestsToExport(formattedRequestsToExport);
      handleData(collectedData);
      return;
    }
  }, [props.activeUser, props.activeTab, isUserApprover, displayedStatuses, activePage, selectedStatus, selectedRequester, selectedApprover, selectedVendor, requestSearchInput]);
  
  useEffect(() => {
    async function init() {
      //if (activePage !== 1) {
      //  setActivePage(1);
      //  return;
      //}
      if (props.activeUser) {
        setIsTableLoading(true);
        await setIsUserApprover(props.activeUser && (props.activeUser.isApprover || props.activeUser.isAdmin));
        await collectAllData(activePage);
        setIsTableLoading(false);
      }
    }
  
    if (requestSearchInput) {
      const timer = setTimeout(() => {
        init();
      }, 1000);
  
      return () => clearTimeout(timer);
    } else {
        init();
    }
  }, [
    requestSearchInput,
    selectedStatus,
    selectedRequester,
    selectedApprover,
    selectedVendor,
    displayedStatuses,
    props.activeTab,
    props.activeUser,
    isActionTriggered,
    isSubmittedFilterActive,
    activePage
  ]);
  

  useEffect(() => {
    postAnalytics(
      props.activeTab === 'approver'
        ? 'pm-aprovers-viewToggle-approvals-click'
        : 'pm-aprovers-viewToggle-requests-click'
    )
    setSelectedStatus({ value: '', label: 'All' })
    setSelectedRequester({ value: '', label: 'All' })
    isMobile && setSelectedApprover({ value: '', label: 'All' })
  }, [props.activeTab])

  useEffect(() => {
    postAnalytics('pm-aprovers-filter-status-select', { selectedStatus })
  }, [selectedStatus])

  useEffect(() => {
    postAnalytics('pm-aprovers-filter-requester-select', { selectedRequester })
  }, [selectedRequester])

  useEffect(() => {
    postAnalytics('pm-aprovers-paginate-select', { activePage })
  }, [activePage])

  let query = useQuery()
  const requestIdByQueryParam = query.get('requestId')

  if (requestIdByQueryParam && !savedDirectLoadAnalytics) {
    postAnalytics('pm-requests-directLoadRequest', { requestIdByQueryParam: requestIdByQueryParam })
    setSavedDirectLoadAnalytics(true)
  }
  useEffect(() => {
    if (requestIdByQueryParam) {
      if (pendingRequests.length > 0 || completedRequests.length > 0) {
        const request = [...pendingRequests, ...completedRequests].find(
          (request) => Number(request.id) === Number(requestIdByQueryParam)
        )
        if (request) {
          setSelectedRequest(request)
          setShowRequestDetailsModal(true)
          history.replace({
            pathname: location.pathname,
            search: '',
          })
        }
      }
    }
  }, [pendingRequests, completedRequests])

  useEffect(() => {
    const loginRedirectUrl = JSON.parse(safeLocalStorage.getItem('loginRedirectURL'))

    if (loginRedirectUrl) {
      const url = new URL(loginRedirectUrl)
      window.location.href = `${url.pathname}${url.search}`
      safeLocalStorage.removeItem('loginRedirectURL')
    }
  }, [])

  return isLoading ? null : (
    <Fragment>
      <Toasts toastlist={list} position="center" setList={setList} />
      <div className={styles.approvalsWrapper}>
        <RequestsHeader activeUser={props.activeUser} />
        {showRejectModal ? (
          <RequestRejectModal
            isOpen={showRejectModal}
            selectedRequest={selectedRequest}
            onReject={onRequestsTableChange}
            onClose={onCloseModal}
          />
        ) : null}
        {showReassignModal ? (
          <RequestReassignModal
            isOpen={showReassignModal}
            selectedRequest={selectedRequest}
            onReassign={onRequestsTableChange}
            onClose={onCloseModal}
          />
        ) : null}
        {showRequestDetailsModal ? (
          <RequestDetailsModal
            isAdmin={props.activeUser.isAdmin}
            isApprover={isUserApprover && ['approver', 'all'].includes(props.activeTab)}
            activeTab={props.activeTab}
            isOpen={showRequestDetailsModal}
            onClose={onCloseModal}
            selectedRequest={selectedRequest}
            onChange={onRequestsTableChange}
          />
        ) : null}
        {showProcessedRequestModal ? (
          <ProcessedRequestModal onClose={onCloseModal} message={processedRequestMessage} />
        ) : null}
        <div className={styles.container}>
          {!isMobile && (
            <RequestsFilters
              activeUser={props.activeUser}
              activeTab={props.activeTab}
              isUserApprover={isUserApprover && ['approver', 'all'].includes(props.activeTab)}
              approvers={approvers}
              requesters={requesters}
              vendors={vendors}
              requestSearchInput={requestSearchInput}
              setRequestSearchInput={setRequestSearchInput}
              selectedStatus={selectedStatus}
              setSelectedStatus={setSelectedStatus}
              selectedRequester={selectedRequester}
              setSelectedRequester={setSelectedRequester}
              selectedApprover={selectedApprover}
              setSelectedApprover={setSelectedApprover}
              selectedVendor={selectedVendor}
              setSelectedVendor={setSelectedVendor}
              displayedStatuses={displayedStatuses}
              resetFilters={resetFilters}
            />
          )}
          <div className={styles.requestsWrapper}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: !isMobile && 16,
              }}
            >
              <h3 className={styles.title}>Requests</h3>
              {isMobile && (
                <RequestsFilters
                  activeUser={props.activeUser}
                  isUserApprover={isUserApprover && ['approver', 'all'].includes(props.activeTab)}
                  requesters={requesters}
                  approvers={approvers}
                  selectedStatus={selectedStatus}
                  setSelectedStatus={setSelectedStatus}
                  selectedRequester={selectedRequester}
                  setSelectedRequester={setSelectedRequester}
                  selectedApprover={selectedApprover}
                  setSelectedApprover={setSelectedApprover}
                  activeTab={props.activeTab}
                  displayedStatuses={displayedStatuses}
                  resetFilters={resetFilters}
                />
              )}
              {!isMobile && (
                <Toggle
                  activeTab={props.activeTab}
                  isTableLoading={isTableLoading}
                  action={handleTabChange}
                  activeUser={props.activeUser}
                />
              )}
            </div>
            {isMobile && (
              <Toggle
                activeTab={props.activeTab}
                isTableLoading={isTableLoading}
                action={handleTabChange}
                activeUser={props.activeUser}
              />
            )}

            {isMobile &&
              ((selectedStatus && selectedStatus.value && selectedStatus.value.length > 0) ||
                (selectedRequester && selectedRequester.label && selectedRequester.label !== 'All') ||
                (selectedApprover && selectedApprover.label && selectedApprover.label !== 'All')) && (
                <div className={styles.filtersSelectedContainer}>
                  {selectedStatus && selectedStatus.value && selectedStatus.value.length > 0 && (
                    <div>
                      <p>{selectedStatus.label}</p>
                      <FontAwesomeIcon
                        onClick={() => {
                          postAnalytics('pm-requests-mobileFilter-removeStatus-click', {
                            removedStatus: selectedStatus.label,
                          })
                          setSelectedStatus({ value: '', label: 'All' })
                        }}
                        icon={faTimes}
                        style={{
                          fontWeight: '400',
                          fontSize: '12px',
                          lineHeight: '11px',
                          color: 'rgba(0, 0, 0, 0.25)',
                        }}
                      />
                    </div>
                  )}
                  {selectedRequester && selectedRequester.label && selectedRequester.label !== 'All' && (
                    <div>
                      <p>{selectedRequester.label}</p>
                      <FontAwesomeIcon
                        onClick={() => {
                          postAnalytics('pm-requests-mobileFilter-removeRequester-click', {
                            removedStatus: selectedRequester.label,
                          })
                          setSelectedRequester({ value: '', label: 'All' })
                        }}
                        icon={faTimes}
                        style={{
                          fontWeight: '400',
                          fontSize: '12px',
                          lineHeight: '11px',
                          color: 'rgba(0, 0, 0, 0.25)',
                        }}
                      />
                    </div>
                  )}
                  {selectedApprover && selectedApprover.label && selectedApprover.label !== 'All' && (
                    <div>
                      <p>{selectedApprover.label}</p>
                      <FontAwesomeIcon
                        onClick={() => {
                          postAnalytics('pm-requests-mobileFilter-removeApprover-click', {
                            removedStatus: selectedApprover.label,
                          })
                          setSelectedApprover({ value: '', label: 'All' })
                        }}
                        icon={faTimes}
                        style={{
                          fontWeight: '400',
                          fontSize: '12px',
                          lineHeight: '11px',
                          color: 'rgba(0, 0, 0, 0.25)',
                        }}
                      />
                    </div>
                  )}
                </div>
              )}
            {isTableLoading ? (
              <RequestsLoader />
            ) : (
              <Fragment>
                {!isMobile && props.activeTab === 'all' && requestsToExport.length > 0 && (
                  <DownloadCSV
                    data={requestsToExport}
                    status={!isTableLoading && displayedStatuses.includes('Pending') ? 'Pending' : 'Completed'}
                  />
                )}
                {!isTableLoading && pendingRequests && !isCompletedTabActive ? (
                  <div style={{ marginBottom: !isMobile && 16 }}>
                    <h4 className={styles.sectionTitle}>
                      <span className={styles.titleText}>Pending</span>
                      <Button
                        text="View Completed"
                        icon={<FontAwesomeIcon icon={faReceipt} />}
                        size="sm"
                        styles={{ color: '#2244FD' }}
                        onClick={() => {                          
                          setDisplayedStatuses(
                            props.activeTab === 'approver'
                              ? ['Pending', 'Approved', 'Rejected']
                              : ['Approved', 'Rejected']
                          )
                          setActivePage(1)
                          resetFilters()
                          setIsCompletedTabActive(true)
                          setIsSubmittedFilterActive(true)
                        }}
                      />
                    </h4>
                    {!isTableLoading &&
                    pendingRequests &&
                    pendingRequests.length === 0 &&
                    completedRequests.length === 0 ? (
                      <EmpyRequests />
                    ) : (
                      <RequestsTable
                        isPendingTable={true}
                        isApprover={isUserApprover && ['approver', 'all'].includes(props.activeTab)}
                        requests={pendingRequests}
                        onChange={onRequestsTableChange}
                        activeTab={props.activeTab}
                        isSubmittedFilterActive={isSubmittedFilterActive}
                        setIsSubmittedFilterActive={setIsSubmittedFilterActive}
                      />
                    )}
                  </div>
                ) : null}
                {!isTableLoading && isCompletedTabActive ? (
                  <div style={{ marginBottom: !isMobile && 16 }}>
                    <h4 className={styles.sectionTitle}>
                      <span className={styles.titleText}>Completed</span>
                      <Button
                        text="View Pending"
                        icon={<FontAwesomeIcon icon={faReceipt} />}
                        size="sm"
                        styles={{ color: '#2244FD' }}
                        onClick={() => {
                          setActivePage(1)
                          setDisplayedStatuses(['Pending'])
                          setIsCompletedTabActive(false)
                          resetFilters()
                          setIsSubmittedFilterActive(false)
                        }}
                      />
                    </h4>
                    {!isTableLoading && completedRequests.length === 0 ? (
                      <EmpyRequests />
                    ) : (
                      <RequestsTable
                        isApprover={isUserApprover && ['approver', 'all'].includes(props.activeTab)}
                        isCompletedTable={true}
                        requests={completedRequests}
                        onChange={onRequestsTableChange}
                        activeTab={props.activeTab}
                        isSubmittedFilterActive={isSubmittedFilterActive}
                        setIsSubmittedFilterActive={setIsSubmittedFilterActive}
                      />
                    )}
                  </div>
                ) : null}
              </Fragment>
            )}
          </div>
        </div>
        {totalPages > 0 ? (
          <RequestsFooter
            activePage={activePage}
            totalPages={totalPages}
            setActivePage={setActivePage}
            setLoadMore={setLoadMore}
          />
        ) : null}
      </div>
    </Fragment>
  )
}

export default Approvals
