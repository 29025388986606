import React, {Fragment, PureComponent} from 'react'
import './styles.css'

export class ToastMessage extends PureComponent{
    constructor(props){
        super(props)
        this.state = {
            icon: this.getIcon(props.type)
        }
    }
  
    getIcon = type => {
        switch (type) {
            case "info":
                return (
                    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
                        <g id="d---Aurora" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g id="Alert/Basic/Danger" transform="translate(-14.000000, -10.000000)" fill="#00BCD4" fillRule="nonzero">
                                <g id="Icon" transform="translate(14.000000, 10.000000)">
                                    <path d="M7.99998093,0.250032783 C12.2798779,0.250032783 15.7499624,3.7213685 15.7499624,8.00001431 C15.7499624,12.2811625 12.2798779,15.7499958 7.99998093,15.7499958 C3.7200839,15.7499958 0.249999404,12.2811625 0.249999404,8.00001431 C0.249999404,3.7213685 3.7200839,0.250032783 7.99998093,0.250032783 Z M7.99998093,14.2499994 C11.4552644,14.2499994 14.249966,11.4540771 14.249966,8.00001431 C14.249966,4.54598201 11.452823,1.75002921 7.99998093,1.75002921 C4.54597915,1.75002921 1.74999583,4.54705013 1.74999583,8.00001431 C1.74999583,11.4552978 4.54585708,14.2499994 7.99998093,14.2499994 Z M7.99998093,3.68752459 C8.72486323,3.68752459 9.3124778,4.27513915 9.3124778,5.00002146 C9.3124778,5.72490376 8.72486323,6.31251833 7.99998093,6.31251833 C7.27509862,6.31251833 6.68748406,5.72490376 6.68748406,5.00002146 C6.68748406,4.27513915 7.27509862,3.68752459 7.99998093,3.68752459 Z M9.7499851,11.6250057 C9.7499851,11.8320975 9.58206944,12.0000048 9.37497765,12.0000048 L6.6249842,12.0000048 C6.41789241,12.0000048 6.2499851,11.8320975 6.2499851,11.6250057 L6.2499851,10.8750075 C6.2499851,10.6679157 6.41789241,10.5000167 6.6249842,10.5000167 L6.99998331,10.5000167 L6.99998331,8.50001669 L6.6249842,8.50001669 C6.41789241,8.50001669 6.2499851,8.3321058 6.2499851,8.12501401 L6.2499851,7.3750158 C6.2499851,7.167924 6.41789241,7.00001669 6.6249842,7.00001669 L8.62497944,7.00001669 C8.83207123,7.00001669 8.99997854,7.167924 8.99997854,7.3750158 L8.99997854,10.5000167 L9.37497765,10.5000167 C9.58206944,10.5000167 9.7499851,10.6679157 9.7499851,10.8750075 L9.7499851,11.6250057 Z" id="info-icon"></path>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case "success":
                return (
                    <svg width="16px" height="12px" viewBox="0 0 16 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
                        <g id="d---Aurora" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g id="Alert/Basic/Danger" transform="translate(-14.000000, -12.000000)" fill="#8BC34A" fillRule="nonzero">
                                <g id="Icon" transform="translate(14.000000, 12.000000)">
                                    <path d="M13.6202068,0.608334045 C13.7666603,0.461911054 14.0040865,0.461911054 14.15054,0.608334045 L15.034388,1.49221255 C15.1808415,1.63866606 15.1808415,1.87612277 15.034388,2.02254576 L5.6652697,11.3916946 C5.51878567,11.5381176 5.28135948,11.5381176 5.13493649,11.3916946 L0.965543352,7.22230143 C0.819089844,7.0758174 0.819089844,6.83839121 0.965543352,6.6919377 L1.84939134,5.80808971 C1.99587537,5.66163621 2.23330156,5.66163621 2.37975507,5.80808971 L5.40010309,8.82843774 L13.6202068,0.608334045 Z" id="check-icon"></path>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case "warning":
                return (
                    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
                        <g id="d---Aurora" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g id="d-P1---Aurora---Cart-(Delivery)" transform="translate(-812.000000, -738.000000)" fill="#FFC107" fillRule="nonzero">
                                <g id="Alert/Button/Info" transform="translate(798.000000, 728.000000)">
                                    <g id="Icon" transform="translate(14.000000, 10.000000)">
                                        <path d="M7.99998093,0.250032783 C12.2798779,0.250032783 15.7499624,3.7213685 15.7499624,8.00001431 C15.7499624,12.2811625 12.2798779,15.7499958 7.99998093,15.7499958 C3.7200839,15.7499958 0.249999404,12.2811625 0.249999404,8.00001431 C0.249999404,3.7213685 3.7200839,0.250032783 7.99998093,0.250032783 Z M7.99998093,14.2499994 C11.4552644,14.2499994 14.249966,11.4540771 14.249966,8.00001431 C14.249966,4.54598201 11.452823,1.75002921 7.99998093,1.75002921 C4.54597915,1.75002921 1.74999583,4.54705013 1.74999583,8.00001431 C1.74999583,11.4552978 4.54585708,14.2499994 7.99998093,14.2499994 Z M9.3124778,11.0000072 C9.3124778,11.7237298 8.72370357,12.312504 7.99998093,12.312504 C7.27625829,12.312504 6.68748406,11.7237298 6.68748406,11.0000072 C6.68748406,10.2762845 7.27625829,9.68751028 7.99998093,9.68751028 C8.72370357,9.68751028 9.3124778,10.2762845 9.3124778,11.0000072 Z M6.7696677,4.39373018 C6.75895605,4.17955833 6.92973201,4.00002384 7.14420904,4.00002384 L8.85575281,4.00002384 C9.07022984,4.00002384 9.2410058,4.17955833 9.23029416,4.39373018 L9.01780077,8.64372005 C9.00782154,8.84333505 8.84305753,9.00001192 8.64325942,9.00001192 L7.35670243,9.00001192 C7.15690432,9.00001192 6.99214031,8.84333505 6.98216109,8.64372005 L6.7696677,4.39373018 Z" id="e"></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
    
                )
            case "danger":
                return(
                    <svg width="6px" height="16px" viewBox="0 0 6 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
                        <g id="d---Aurora" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g id="Alert/Basic/Danger" transform="translate(-19.000000, -10.000000)" fill="#FF5722" fillRule="nonzero">
                                <g id="Icon" transform="translate(19.000000, 10.000000)">
                                    <path d="M5.49998689,13.5000072 C5.49998689,14.8785074 4.37849908,15.9999952 2.99999881,15.9999952 C1.62148661,15.9999952 0.499998808,14.8785074 0.499998808,13.5000072 C0.499998808,12.121495 1.62148661,11.0000072 2.99999881,11.0000072 C4.37849908,11.0000072 5.49998689,12.121495 5.49998689,13.5000072 Z M0.789365794,0.787507087 C0.767973023,0.359102347 1.10949443,3.33786011e-05 1.53844848,3.33786011e-05 L4.46153721,3.33786011e-05 C4.89049127,3.33786011e-05 5.23201267,0.359102347 5.2106199,0.787507087 L4.7856026,9.28748682 C4.76564416,9.68662527 4.43620768,10.0000095 4.03655043,10.0000095 L1.96343526,10.0000095 C1.56377801,10.0000095 1.23434154,9.68662527 1.21438309,9.28748682 L0.789365794,0.787507087 Z" id="exclamation-icon"></path>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case "default":
            default:
                return (
                    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
                        <g id="d---Aurora" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g id="Alert/Basic/Danger" transform="translate(-14.000000, -10.000000)" fill="#3862C5" fillRule="nonzero">
                                <g id="Icon" transform="translate(14.000000, 10.000000)">
                                    <path d="M13.9999666,3.33786011e-05 C15.1030829,3.33786011e-05 15.9999619,0.896912344 15.9999619,2.00003338 L15.9999619,11.0000072 C15.9999619,12.1031234 15.1030829,13.0000024 13.9999666,13.0000024 L9.49997735,13.0000024 L5.59684945,15.9281265 C5.34999335,16.10937 4.99998808,15.9312393 4.99998808,15.6249961 L4.99998808,13.0000024 L2,13.0000024 C0.896878965,13.0000024 0,12.1031234 0,11.0000072 L0,2.00003338 C0,0.896912344 0.896878965,3.33786011e-05 2,3.33786011e-05 L13.9999666,3.33786011e-05 Z M7.99998331,10.5000131 C8.55311071,10.5000131 8.99998331,10.0531405 8.99998331,9.50001311 C8.99998331,8.94688094 8.55311071,8.50001311 7.99998331,8.50001311 C7.44685114,8.50001311 6.99998331,8.94688094 6.99998331,9.50001311 C6.99998331,10.0531405 7.44685114,10.5000131 7.99998331,10.5000131 Z M8.79374124,7.05000436 L9.19373418,3.0500139 C9.22498411,2.75628291 8.99372245,2.50002742 8.69684816,2.50002742 L7.30311369,2.50002742 C7.00935218,2.50002742 6.77812105,2.75628291 6.80622767,3.0500139 L7.20622061,7.05000436 C7.23124497,7.30625986 7.44685114,7.5000155 7.70310663,7.5000155 L8.29685522,7.5000155 C8.55311071,7.5000155 8.76871689,7.30625986 8.79374124,7.05000436 Z" id="message-icon"></path>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
        }
        
    }

    render(){
        return (
            <Fragment>
                <div className={"toast " + this.props.type + (this.props.buttonText ? " withButton" : "")}>
                    {this.state.icon}
                    <p>{this.props.message}</p>
                    {this.props.buttonText &&
                        <button onClick={this.props.buttonClickHandler}>{this.props.buttonText}</button>
                    }
                </div>
            </Fragment>
        )
    }
}

export default ToastMessage