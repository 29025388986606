import fetchData from './fetchData';
import config from './apiConfig';

export default ({ newParams }) => {
    const { Name, Type, Number, ExpirationDate, Token } = newParams;
    const userToken = newParams.userData.Network[newParams.userData.activeNetworkIndex].UserToken;

    return new Promise((resolve, reject) => {
        const options = {
            ...config.postUpdatePro,
            url: `${config.postUpdatePro.url}/${userToken}/Card`,
            body: {
                Name,
                Token,
                Type,
                Number,
                ExpirationDate,
            },
        };
        return fetchData(options)
            .then(data => {
        if (data && !data.ErrorCode) {
          resolve(data);
                } else {
          reject({ fetchError: true, details: data });
        }
      })
      .catch(error => {
        reject(error);
            });
  });
};
