"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _lodash = _interopRequireDefault(require("lodash"));

var _apiConfig = _interopRequireDefault(require("./apiConfig"));

var _fetchData = require("./fetchData");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var setSearchUrl = function setSearchUrl(_ref) {
  var query = _ref.query,
      config = _ref.config,
      searchUrl = _ref.searchUrl,
      id = _ref.id;

  if (query.subFilters && query.subFilters.length > 1 && query.subFilters[0]) {
    var curCat = _lodash["default"].find(config.categories, ['name', query.term]);

    if (curCat) {
      var url = curCat.rootQueries[id].url;
      query.subFilters.forEach(function (sub) {
        if (sub) url = url + (sub.subUrl ? curCat.rootQueries[id].spacer + sub.subUrl : '');
      });
      return url;
    }
  }

  if (!query.subFilters || _lodash["default"].isEmpty(query.subFilters) || !query.subFilters[0] || query.subFilters[0] && !query.subFilters[0].queries) return searchUrl;
  if (query.subFilters[0].queries && query.subFilters[0].queries[id]) return query.subFilters[0].queries[id];
  return null;
};

var setSearchQuery = function setSearchQuery(_ref2) {
  var query = _ref2.query,
      config = _ref2.config,
      id = _ref2.id;
  var term = query.term,
      subFilters = query.subFilters;

  var curCat = _lodash["default"].find(config.categories, ['name', term]);

  var searchQuery = curCat && curCat.rootQueries && curCat.rootQueries[id] && curCat.rootQueries[id].query ? curCat.rootQueries[id].query : term.replace('%20', '').toLowerCase();

  if (subFilters && subFilters.length > 1) {
    if (curCat) {
      subFilters.forEach(function (sub) {
        if (sub) searchQuery = searchQuery + (sub.subQuery ? ' ' + sub.subQuery : '');
      });
      return searchQuery;
    }
  }

  return searchQuery;
};

var _default = function _default(_ref3) {
  var query = _ref3.query,
      config = _ref3.config,
      id = _ref3.id,
      accountNumber = _ref3.accountNumber,
      storeCode = _ref3.storeCode,
      searchUrl = _ref3.searchUrl,
      env = _ref3.env,
      abortSignal = _ref3.abortSignal,
      cancelToken = _ref3.cancelToken;
  var apiKey = env.apiKey,
      apiUrl = env.apiUrl,
      bearerToken = env.bearerToken,
      userToken = env.userToken,
      apiVersion = env.apiVersion;
  return new Promise(function (resolve, reject) {
    var url = "".concat((0, _apiConfig["default"])(apiUrl, apiVersion).getSearchItems.url);
    var SearchUrl = setSearchUrl({
      query: query,
      config: config,
      searchUrl: searchUrl,
      id: id
    });
    var Query = setSearchQuery({
      query: query,
      config: config,
      id: id
    });

    var options = _objectSpread(_objectSpread({}, (0, _apiConfig["default"])(apiUrl).getSearchItems), {}, {
      url: url,
      body: {
        UserToken: userToken,
        Supplier: id,
        Query: Query,
        ZipCode: query.zip,
        SearchUrl: SearchUrl,
        StoreCode: storeCode,
        Take: 25,
        AccountNumber: accountNumber
      },
      bearerToken: bearerToken,
      apiKey: apiKey,
      abortSignal: abortSignal,
      cancelToken: cancelToken
    });

    return (0, _fetchData.cancelableFetchData)(options).then(function (data) {
      if (data && !data.ErrorCode) {
        data.fetchedData.PartList = !SearchUrl ? [] : data.fetchedData.PartList;

        var stateObject = _objectSpread(_objectSpread({}, data), {}, {
          fetchError: data.fetchedData && data.fetchedData.ErrorCode ? true : false
        });

        resolve(stateObject);
      } else {
        reject({
          fetchError: true,
          details: data
        });
      }
    })["catch"](function (error) {
      reject(error);
    });
  });
};

exports["default"] = _default;