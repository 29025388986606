import React, { useState, useContext } from 'react'
import './style.css'
import { MobileLayout } from '../../App'
import useComponentVisible from '../../../utils/useComponentVisible'

const Tooltip = (props) => {
  let timeout
  const isMobile = useContext(MobileLayout)

  const hideTip = () => {
    clearInterval(timeout)
    setActive(false)
  }

  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(true, hideTip)
  const [active, setActive] = useState(false)

  const showTip = () => {
    isMobile && setIsComponentVisible(true)
    timeout = setTimeout(() => {
      setActive(true)
    }, props.delay || 400)
  }

  return (
    <div
      className="Tooltip-Wrapper"
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
      onClick={showTip}
      ref={isMobile ? ref : undefined}
    >
      {props.children}
      {(isMobile ? isComponentVisible : true) && active && (
        <div className={`Tooltip-Tip ${props.direction || 'top'}`}>{props.content}</div>
      )}
    </div>
  )
}

export default Tooltip
