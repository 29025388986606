"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.cancelableFetchData = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _axios = _interopRequireDefault(require("axios"));

var _lodash = _interopRequireDefault(require("lodash"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var urlController = {
  url: '',
  validUrl: false,
  buildUrl: function buildUrl(options) {
    this.url = options.root ? options.root + '/' : '';
    if (options.version && options.version.length > 0) this.url += options.version + '/';
    if (options.url) this.url += options.url;
    if (options.query && options.query.length > 0) this.url += '?' + options.query;
    if (options.locParams && options.locParams.length > 0) this.url += '&' + options.locParams;
    return this;
  },
  validateUrl: function validateUrl() {
    // from: https://gist.github.com/dperini/729294
    this.validUrl = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(this.url);
    return this;
  }
};

var getFetchOptions = function getFetchOptions(options) {
  var newOptions = {};

  if (_lodash["default"].has(options, 'body')) {
    newOptions.data = options.body;
  }

  if (_lodash["default"].has(options, 'method')) {
    newOptions.method = options.method;
  }

  newOptions.headers = {
    // 'Content-Type': 'application/json',
    // 'Content-Type': 'application/json',
    // Accept: 'application/json',
    Authorization: 'bearer ' + options.bearerToken,
    'Ocp-Apim-Subscription-Key': options.apiKey
  };
  return newOptions;
};

var fetchData = function fetchData(options) {
  return new Promise(function (resolve, reject) {
    var url = urlController.buildUrl(options).validateUrl().url;
    var formattedOptions = getFetchOptions(options);
    (0, _axios["default"])(_objectSpread({
      url: url
    }, formattedOptions)).then(function (response) {
      if (response.data) resolve({
        fetchedData: response.data,
        isLoading: false
      });
    })["catch"](function (fetchError) {
      console.log('fetchError: ', fetchError);
      reject({
        isLoading: false,
        fetchedData: _objectSpread({
          ErrorCode: 500
        }, fetchError)
      });
    });
  });
};

var cancelableFetchData = function cancelableFetchData(options) {
  return new Promise(function (resolve, reject) {
    var url = urlController.buildUrl(options).validateUrl().url;
    var formattedOptions = getFetchOptions(options);
    return (0, _axios["default"])(_objectSpread({
      url: url,
      cancelToken: options.cancelToken
    }, formattedOptions)).then(function (response) {
      if (response.data) resolve({
        fetchedData: response.data,
        isLoading: false
      });
    })["catch"](function (err) {
      if (_axios["default"].isCancel(err)) {
        console.log('First request canceled', err.message);
      } else {
        console.log(err);
      }
    });
  });
};

exports.cancelableFetchData = cancelableFetchData;
var _default = fetchData;
exports["default"] = _default;