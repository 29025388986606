"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledComponents = _interopRequireWildcard(require("styled-components"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var InputContainer = _styledComponents["default"].div.withConfig({
  displayName: "styles__InputContainer",
  componentId: "gvv60-0"
})(["display:flex;flex-direction:column;width:", ";.label-container{display:flex;font-family:'Lato';font-size:14px;letter-spacing:0.25px;line-height:20px;", " span{font-style:italic;color:#b9bbc1;margin-left:4px;}", "}p{margin:0 0 4px;font-family:'Lato';font-size:14px;font-style:italic;color:#6e727c;letter-spacing:0.25px;line-height:20px;}.details-container{display:flex;justify-content:space-between;margin-top:4px;", " .char-limit{display:flex;justify-self:flex-end;margin-left:auto;.limit-reached{color:#ff5722;font-weight:bold;}}}.icon-left,.icon-right{color:rgba(62,65,72,0.35);margin:-32px 0 12px 0;font-size:16px;font-weight:normal;height:20px;line-height:20px;width:20px;}.icon-left{margin-left:12px;}.icon-right{margin:-32px 12px 0 auto;}", ""], function (_ref) {
  var width = _ref.width;
  return width ? width : '100%';
}, function (_ref2) {
  var helperText = _ref2.helperText;
  return !helperText && (0, _styledComponents.css)(["margin-bottom:4px;"]);
}, function (_ref3) {
  var toolTip = _ref3.toolTip;
  return toolTip && (0, _styledComponents.css)(["justify-content:space-between;"]);
}, function (_ref4) {
  var type = _ref4.type;
  return type === 'password' && (0, _styledComponents.css)(["margin-top:16px;"]);
}, function (_ref5) {
  var error = _ref5.error;
  return error && ErrorStyle;
});

var InputStyle = _styledComponents["default"].input.withConfig({
  displayName: "styles__InputStyle",
  componentId: "gvv60-1"
})(["", " ", " ", " &:focus{", "}"], function () {
  return BasicStyles;
}, function (_ref6) {
  var rounded = _ref6.rounded;
  if (rounded) return (0, _styledComponents.css)(["border-radius:22px;"]);else return (0, _styledComponents.css)(["border-radius:4px;"]);
}, function (_ref7) {
  var iconLeft = _ref7.iconLeft,
      iconRight = _ref7.iconRight;
  if (iconLeft) return (0, _styledComponents.css)(["padding:12px 12px 12px 36px;"]);else if (iconRight) return (0, _styledComponents.css)(["padding:12px 36px 12px 12px;"]);else return (0, _styledComponents.css)(["padding:12px;xw"]);
}, function (_ref8) {
  var rounded = _ref8.rounded;
  if (rounded) return (0, _styledComponents.css)(["border-radius:22px;"]);else return (0, _styledComponents.css)(["border-radius:4px;"]);
});

var TextAreaStyle = _styledComponents["default"].textarea.withConfig({
  displayName: "styles__TextAreaStyle",
  componentId: "gvv60-2"
})(["", " min-height:64px;padding:12px;"], function () {
  return BasicStyles;
});

var ErrorStyle = (0, _styledComponents.css)(["textarea,input{background-color:#fbe9e7;border:1px solid #ff5722;&::placeholder{color:rgba(255,87,34,0.5);}&:focus{border:1px solid rgba(255,87,34,0.5);}}.error-field{display:flex;align-items:center;color:#ff5722;i{font-size:13.4px;margin-right:4px;}}"]);
var BasicStyles = (0, _styledComponents.css)(["display:flex;flex-direction:column;background-color:#fafafa;border:1px solid #dedfe2;width:100vw - 24;height:", ";cursor:pointer;font-size:16px;color:#3e4148;font-family:'Lato';line-height:20px;&::placeholder{color:rgba(62,65,72,0.35);font-family:'Lato';font-size:16px;line-height:20px;}&:focus{outline:none;border:1px solid #3963fe;box-shadow:0px 0px 4px 0px #3963fe;}&:disabled{background:#dedfe2;border:1px solid #dedfe2;}"], function (_ref9) {
  var height = _ref9.height;
  return height ? height : '20px';
});
var _default = {
  InputStyle: InputStyle,
  InputContainer: InputContainer,
  TextAreaStyle: TextAreaStyle
};
exports["default"] = _default;