import React, { useState, useEffect, useRef, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons'
import styles from './style.module.css'
import { MobileLayout } from '../../App'

function Select({ options, onChange, placeholder = 'Select an option', value, name, disabled }) {
  const isMobile = useContext(MobileLayout)
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(isMobile ? value : options[0])
  const ref = useRef(null)

  const handleSelection = (option) => {
    setSelectedOption(option)
    onChange(option)
    setIsOpen(false)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false)
      }
    }
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  useEffect(() => {
    if (value) {
      setSelectedOption(value)
    }
  }, [value])

  return (
    <div ref={ref} style={{ position: 'relative', height: 38 }}>
      <div className={isOpen ? styles.selectOpenWrapper : styles.selectWrapper} onClick={() => setIsOpen(!isOpen)}>
        {selectedOption && selectedOption.label ? (
          <span className={styles.selectedOption}>{selectedOption.label}</span>
        ) : (
          <span className={styles.placeholder}>{placeholder}</span>
        )}
        {isOpen ? (
          <FontAwesomeIcon icon={faChevronUp} color="#2244FD" style={{ marginRight: 4 }} />
        ) : (
          <FontAwesomeIcon icon={faChevronDown} color="rgba(0, 0, 0, 0.25)" style={{ marginRight: 4 }} />
        )}
      </div>

      {isOpen ? (
        <div className={styles.options}>
          <ul className={styles.listContainer}>
            {options.map((option) => (
              <li key={option.value} onClick={() => handleSelection(option)}>
                <span className={styles.label}>{option.label}</span>
                {option.subLabel && <span className={styles.subLabel}>{option.subLabel}</span>}
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </div>
  )
}

export default Select
