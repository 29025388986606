import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faHexagonXmark, faMemoCircleCheck } from '@fortawesome/pro-regular-svg-icons'
import { useParams } from 'react-router-dom'
import { triggerActionFromEmail } from '../api'
import Loader from '../../../../common/components/Loader'
import styles from './styles.module.css'
import { RaivenLogo } from '../../../../common/components/svg/raivenIcon'
import postAnalytics from '../../../../utils/postAnalytics'

const RequestActionByEmail = () => {
  const { token = '' } = useParams()
  const urlParams = new URLSearchParams(window.location.search)
  const action = urlParams.get('action')
  const [icon, setIcon] = useState(null)
  const [request, setRequest] = useState({
    id: null,
    approver: null,
    errorMessage: '',
  })
  const [isActionDone, setIsActionDone] = useState(false)
  const [error, setError] = useState(false)

  const setActionIcon = () => {
    if (action === 'approved') {
      return setIcon({ value: faCircleCheck, color: '#00C853' })
    }
    if (action === 'rejected') {
      return setIcon({ value: faHexagonXmark, color: '#FF3D00' })
    }
    return null
  }

  const triggerAction = async () => {
    const body = {
      approvalToken: token,
      result: action === 'approved' ? 'Approved' : 'Rejected',
      comment: '',
      reassignUserIds: [],
      reassignGroupIds: [],
    }
    const response = await triggerActionFromEmail(localStorage.getItem('token'), body)
    if (response && response.isSuccess) {
      setRequest({
        ...request,
        id: response.requestId,
      })
      setActionIcon()
      setIsActionDone(true)
    } else {
      setRequest({
        id: response.requestId,
        approver: response.approver,
        errorMessage: response.errorMessage,
      })
      setIsActionDone(true)
      setError(true)
    }
  }

  const showActionTaken = (errorMessage) => {
    if (errorMessage.includes('Approved')) return 'approved'
    if (errorMessage.includes('Rejected')) return 'rejected'
    return 'invalid'
  }

  useEffect(() => {
    postAnalytics('pm-email-completeFromEmail-click', {action: action, token: token})

    if (action === 'approved' || action === 'rejected') {
      triggerAction()
    }
  }, [])

  const closeThisWindowMessage = <p className={styles.message} style={{ textAlign: 'center', marginBottom: 48 }}>
      You can close this window
    </p>

  if (!isActionDone)
    return (
      <div className={styles.centeredContainer}>
        <Loader />
      </div>
    )

  if (error)
    return (
      <div className={styles.wrapper}>
        {request.id ? (
          <section className={styles.content}>
            <FontAwesomeIcon icon={faMemoCircleCheck} color="rgba(0, 0, 0, 0.5)" className={styles.icon} />
            <h3 className={styles.title}>
              Request #{request.id} has already been {showActionTaken(request.errorMessage)} by {request.approver}
            </h3>
            {closeThisWindowMessage}
            <RaivenLogo />
          </section>
        ) : (
          <div className={styles.centeredContainer}>
            <p>Action taken is invalid</p>
            <RaivenLogo />
          </div>
        )}
      </div>
    )

  return (
    <div className={styles.wrapper}>
      <section className={styles.content}>
        {icon && <FontAwesomeIcon icon={icon.value} color={icon.color} className={styles.icon} />}
        <h3 className={styles.title}>
          You {action} request #{request.id}
        </h3>
        {closeThisWindowMessage}
        <RaivenLogo />
      </section>
    </div>
  )
}

export default RequestActionByEmail
