"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var SwitchContainer = _styledComponents["default"].div.withConfig({
  displayName: "styles__SwitchContainer",
  componentId: "sc-12qile-0"
})(["display:flex;align-items:center;.label{position:relative;z-index:2;color:", ";width:7.375rem;text-align:", ";margin-left:0.5rem;", "}"], function (_ref) {
  var size = _ref.size;
  return size === "large" ? "rgba(62, 65, 72, 0.35)" : "#3e4148";
}, function (_ref2) {
  var size = _ref2.size;
  return size === "large" ? "center" : "left";
}, function (_ref3) {
  var size = _ref3.size,
      width = _ref3.width,
      checked = _ref3.checked;
  if (size === "large") return (0, _styledComponents.css)(["margin-left:-", ";color:", ";"], width - (checked ? 0.75 : 2.5) + "rem", checked && "#ffffff");
});

var Switch = _styledComponents["default"].div.withConfig({
  displayName: "styles__Switch",
  componentId: "sc-12qile-1"
})(["position:relative;display:inline-block;width:", ";height:", ";cursor:pointer;input{position:relative;z-index:3;opacity:0;width:", ";height:", ";margin:0;cursor:pointer;}.slider{z-index:1;position:absolute;cursor:pointer;width:", ";height:", ";background:linear-gradient( -180deg,rgb(244,245,246) 0%,rgb(237,238,239) 100% );border:1px solid #dedfe2;-webkit-transition:0.4s;transition:0.4s;margin-left:-", ";border-radius:", ";}.slider:before{position:absolute;content:\"\";height:", ";width:", ";left:4px;bottom:4px;background-color:#ffffff;border-radius:22px;box-shadow:0px 2px 4px 0px rgba(0,0,0,0.14),0px 3px 4px 0px rgba(0,0,0,0.12),0px 1px 5px 0px rgba(0,0,0,0.2);-webkit-transition:0.4s;transition:0.4s;}input:checked + .slider{background:linear-gradient(-180deg,#8bc34a 0%,#689f38 100%);}input:focus + .slider{box-shadow:none;}input:checked + .slider:before{-webkit-transform:", ";-ms-transform:", ";transform:", ";}.slider:before{border-radius:50%;}"], function (_ref4) {
  var width = _ref4.width;
  return width + "rem";
}, function (_ref5) {
  var height = _ref5.height;
  return height + "rem";
}, function (_ref6) {
  var width = _ref6.width;
  return width + "rem";
}, function (_ref7) {
  var height = _ref7.height;
  return height + "rem";
}, function (_ref8) {
  var width = _ref8.width;
  return width + "rem";
}, function (_ref9) {
  var height = _ref9.height;
  return height + "rem";
}, function (_ref10) {
  var width = _ref10.width;
  return width + "rem";
}, function (_ref11) {
  var height = _ref11.height;
  return height * 2 + "rem";
}, function (_ref12) {
  var spanSize = _ref12.spanSize;
  return spanSize + "rem";
}, function (_ref13) {
  var spanSize = _ref13.spanSize;
  return spanSize + "rem";
}, function (_ref14) {
  var width = _ref14.width,
      spanSize = _ref14.spanSize;
  return "translateX(".concat(width - spanSize - 0.5, "rem)");
}, function (_ref15) {
  var width = _ref15.width,
      spanSize = _ref15.spanSize;
  return "translateX(".concat(width - spanSize - 0.5, "rem)");
}, function (_ref16) {
  var width = _ref16.width,
      spanSize = _ref16.spanSize;
  return "translateX(".concat(width - spanSize - 0.5, "rem)");
});

var _default = {
  SwitchContainer: SwitchContainer,
  Switch: Switch
};
exports["default"] = _default;