"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledComponents = _interopRequireWildcard(require("styled-components"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var SelectContainer = _styledComponents["default"].div.withConfig({
  displayName: "styles__SelectContainer",
  componentId: "jnwo38-0"
})(["display:flex;flex-direction:column;width:", ";.label-container{display:flex;font-family:'Lato';font-size:14px;letter-spacing:0.25px;line-height:20px;", " span{font-style:italic;color:#b9bbc1;margin-left:4px;}}.helper-text{margin:0 0 4px;font-family:'Lato';font-size:14px;font-style:italic;color:#6e727c;letter-spacing:0.25px;line-height:20px;}"], function (_ref) {
  var width = _ref.width;
  return width ? width : '100%';
}, function (_ref2) {
  var helperText = _ref2.helperText;
  return !helperText && (0, _styledComponents.css)(["margin-bottom:4px;"]);
});

var BasicStyles = (0, _styledComponents.css)(["display:flex;flex-direction:column;background-color:#fafafa;border:1px solid #dedfe2;width:100vw - 24;height:", ";cursor:pointer;font-size:16px;color:#3e4148;font-family:'Lato';line-height:20px;&::placeholder{color:rgba(62,65,72,0.35);font-family:'Lato';font-size:16px;line-height:20px;}&:focus{outline:none;border:1px solid #3963fe;box-shadow:0px 0px 4px 0px #3963fe;}&:disabled{background:#dedfe2;border:1px solid #dedfe2;}"], function (_ref3) {
  var height = _ref3.height;
  return height ? height : '20px';
});

var LIStyle = _styledComponents["default"].li.withConfig({
  displayName: "styles__LIStyle",
  componentId: "jnwo38-1"
})(["", " border:0;&:hover{background-color:#dedfe2;}"], function () {
  return BasicStyles;
});

var PopupContainer = _styledComponents["default"].div.withConfig({
  displayName: "styles__PopupContainer",
  componentId: "jnwo38-2"
})(["position:relative;"]);

var PopupContent = _styledComponents["default"].ul.withConfig({
  displayName: "styles__PopupContent",
  componentId: "jnwo38-3"
})(["z-index:1;border:0;padding:0px;margin:0px;border-radius:", ";display:flex;flex-direction:column;position:absolute;width:", ";background-color:", ";box-shadow:", ";"], function (_ref4) {
  var radius = _ref4.radius;
  return radius ? "".concat(radius, "px") : '4px';
}, function (_ref5) {
  var width = _ref5.width;
  return width ? width : '100%';
}, function (_ref6) {
  var background = _ref6.background;
  return background ? background : '#FFF';
}, function (_ref7) {
  var shadow = _ref7.shadow;
  return shadow ? shadow : '0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12), 0px 3px 5px 0px rgba(0, 0, 0, 0.2)';
});

var PopupLi = (0, _styledComponents["default"])(LIStyle).withConfig({
  displayName: "styles__PopupLi",
  componentId: "jnwo38-4"
})(["", " margin-bottom:0;display:inline-block;&:hover ", "{display:block;}border-radius:0;border:0;font-size:", ";padding:", ";"], function () {
  return PopupBasicStyles;
}, PopupContent, function (_ref8) {
  var fontSize = _ref8.fontSize;
  return fontSize ? fontSize : '14px';
}, function (_ref9) {
  var itemPadding = _ref9.itemPadding;
  return itemPadding ? itemPadding : '8px 12px';
});
var PopupBasicStyles = (0, _styledComponents.css)(["cursor:pointer;font-size:16px;color:#3e4148;font-family:'Lato';line-height:20px;"]);

var SelectULStyle = _styledComponents["default"].ul.withConfig({
  displayName: "styles__SelectULStyle",
  componentId: "jnwo38-5"
})(["", " list-style-type:none;height:18px;width:176px;margin:0;padding:12px;flex-direction:row;justify-content:space-between;border:1px solid rgb(222,223,226);background-color:#ffffff;border-radius:4px;"], function () {
  return DropDownBasicStyles;
});

var SelectLIStyle = _styledComponents["default"].li.withConfig({
  displayName: "styles__SelectLIStyle",
  componentId: "jnwo38-6"
})(["", " border:0;&:hover{color:#3963fe;}margin-bottom:12px;"], function () {
  return DropDownBasicStyles;
});

var LabelDiv = _styledComponents["default"].div.withConfig({
  displayName: "styles__LabelDiv",
  componentId: "jnwo38-7"
})(["", " display:flex;flex-direction:row;justify-content:space-between;border:0;width:100%;i{color:rgba(62,65,72,0.35);font-size:16px;font-weight:normal;height:20px;line-height:20px;width:20px;}"], function () {
  return DropDownBasicStyles;
});

var DropDownContent = _styledComponents["default"].div.withConfig({
  displayName: "styles__DropDownContent",
  componentId: "jnwo38-8"
})(["", " min-width:176px;z-index:1;border:0;border-radius:4px;"], function () {
  return DropDownBasicStyles;
});

var DropDownLi = (0, _styledComponents["default"])(SelectLIStyle).withConfig({
  displayName: "styles__DropDownLi",
  componentId: "jnwo38-9"
})(["", " padding:12px 16px;display:inline-block;&:hover{color:#3963fe;}&:last-child{border-radius:0 0 8px 8px;}&:first-child{border-radius:8px 8px 0 0;}border-radius:0;border:0;margin:0;"], function () {
  return DropDownBasicStyles;
});
var DropDownLiSelected = (0, _styledComponents["default"])(DropDownLi).withConfig({
  displayName: "styles__DropDownLiSelected",
  componentId: "jnwo38-10"
})(["color:#3963fe;"]);

var DropDownItemsContainer = _styledComponents["default"].div.withConfig({
  displayName: "styles__DropDownItemsContainer",
  componentId: "jnwo38-11"
})(["display:flex;flex-direction:column;width:calc(100% + 24px);background-color:#ffffff;margin:4px 0 0 -12px;border-radius:8px;box-shadow:0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12),0px 3px 5px 0px rgba(0,0,0,0.2);"]);

var DropDownBasicStyles = (0, _styledComponents.css)(["display:flex;flex-direction:column;height:20px;cursor:pointer;font-size:14px;color:#3e4148;font-family:'Lato';line-height:20px;&::placeholder{color:rgba(62,65,72,0.35);font-family:'Lato';font-size:16px;line-height:20px;}&:focus{outline:none;border:1px solid rgb(0,188,212);box-shadow:0px 0px 4px 0px rgb(77,208,225);}&:disabled{background:#dedfe2;border:1px solid #dedfe2;}"]);

var MultiSectionHeader = _styledComponents["default"].h4.withConfig({
  displayName: "styles__MultiSectionHeader",
  componentId: "jnwo38-12"
})(["color:#b9bbc1;font-size:16px;font-weight:bold;height:20px;line-height:20px;padding:8px 0 4px;box-shadow:inset 0px 1px 0px 0px rgb(222,223,226);cursor:default;margin:0 12px;"]);

var _default = {
  LIStyle: LIStyle,
  PopupContainer: PopupContainer,
  PopupContent: PopupContent,
  PopupLi: PopupLi,
  SelectULStyle: SelectULStyle,
  SelectLIStyle: SelectLIStyle,
  LabelDiv: LabelDiv,
  DropDownContent: DropDownContent,
  DropDownLi: DropDownLi,
  DropDownLiSelected: DropDownLiSelected,
  DropDownItemsContainer: DropDownItemsContainer,
  MultiSectionHeader: MultiSectionHeader,
  SelectContainer: SelectContainer
};
exports["default"] = _default;