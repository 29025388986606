"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _default = function _default() {
  if (window.innerWidth < 660) {
    return 1;
  } else if (window.innerWidth >= 660 && window.innerWidth < 950) {
    return 2;
  } else if (window.innerWidth >= 950 && window.innerWidth < 1240) {
    return 3;
  } else if (window.innerWidth >= 1240 && window.innerWidth < 1530) {
    return 4;
  } else if (window.innerWidth >= 1530) {
    return 5;
  }
};

exports["default"] = _default;