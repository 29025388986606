import React, { PureComponent } from 'react'
import _ from 'lodash'
import ComponentStyles from './styles'
import DateFormat from '../../../../../../utils/dateTimeFormat'
import { calcTotal, calcTotalItems } from '../../../../Cart/helpers'
import { handleBrokenProductImage } from '../../../../Cart/components/SupplierCart/helpers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons'
import postAnalytics from '../../../../../../utils/postAnalytics.js'
import { downloadFile } from '../../../../../../utils/fileStorage';
import { safeLocalStorage } from '../../../../../../utils/safeStorage';

const { ItemRow } = ComponentStyles

const isBrowser = typeof window !== "undefined" && window.env;
const apiRoot = isBrowser ? window.env.apiRoot : process.env.RAZZLE_RUNTIME_API_ROOT;
const userData = safeLocalStorage.getJSONItem('userData');
const userToken = userData?.Network[userData.activeNetworkIndex].UserToken;
const fileDownloadPath = `${apiRoot}/v1/UserToken/${userToken}/PurchaseOrder/CsvDownload/`

export class ItemAccordian extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      openDrawer: false,
    }
  }

  manageDrawer() {
    const bool = this.state.openDrawer
    this.setState({
      openDrawer: !bool,
    })
  }

  formatPhone(raw) {
    const removeInt = raw.substring(1)
    let formattedPhone = removeInt.replace(/(\d{3})(\d{3})(\d{4})/, '($1)$2-$3')
    return formattedPhone
  }

  render() {
    const { order, index } = this.props
    return (
      <ItemRow key={index}>
        <div>
          <div
            className={`order-detail-container ${this.state.openDrawer ? '' : 'closed'}`}
            onClick={(e) => {
              postAnalytics(
                `pm-orders-submittedOrders-click${this.state.openDrawer ? 'ToCollapse' : 'ToExpand'}-row`,
                order
              )
              this.manageDrawer(e)
            }}
          >
            <li className="order-summary Date">
              {DateFormat.toLocalFromUTC({
                utcDate: order.CreatedOn,
                format: 'YYYY-MM-DD H:mm a',
              })}{' '}
              &nbsp;
              {DateFormat.localTimeZone()}
            </li>
            <li className="order-summary Status">{order.Status}</li>
            <li className="order-summary Order">{order.PurchaseOrderNumber}</li>
            <li className="order-summary Supplier">{order.Supplier.Name}</li>
            <li className="order-summary Items">{calcTotalItems(order.Items)}</li>
            <li className="order-summary Subtotal">${order.TotalAmount}</li>
            <FontAwesomeIcon
              icon={this.state.openDrawer ? faChevronUp : faChevronDown}
              onClick={(e) => {
                postAnalytics(
                  `pm-orders-submittedOrders-click${this.state.openDrawer ? 'ToCollapse' : 'ToExpand'}-chevronIcon`,
                  order
                )
                this.manageDrawer(e)
              }}
              style={{ color: '#969ba5', paddingRight: '16px' }}
            />
          </div>
          {this.state.openDrawer && (
            <div>
              <div className="order-detail-expanded">
                <div className="shipPOSection">
                  <div className="shipPaymentTitle">
                    {order.Shipping.City && order.Shipping.State && order.Shipping.PostalCode && (
                      <li className="shippingTitle">Shipping Address</li>
                    )}
                    <div className="shippingDetail">
                      {order.Shipping.Name && (
                        <p data-scorpius-automation="order-detail-shipping-name">{order.Shipping.Name}</p>
                      )}
                      {order.Shipping.Street1 && (
                        <p data-scorpius-automation="order-detail-shipping-Street1">{order.Shipping.Street1}</p>
                      )}
                      {order.Shipping.Street2 && (
                        <p data-scorpius-automation="order-detail-shipping-Street2">{order.Shipping.Street2}</p>
                      )}
                      {order.Shipping.City && order.Shipping.State && order.Shipping.PostalCode && (
                        <p data-scorpius-automation="order-detail-shipping-City">
                          {order.Shipping.City}, {order.Shipping.State} {order.Shipping.PostalCode}
                        </p>
                      )}
                      {(order.Shipping.Attention || order.Shipping.Phone) && (
                        <p data-scorpius-automation="order-detail-shipping-attention">
                          {order.Shipping.Attention ? 'Attn:' : ''}{' '}
                          {order.Shipping.Attention ? order.Shipping.Attention : ''}
                          {order.Shipping.Attention && order.Shipping.Phone ? (
                            <span className="dotDivider">&#183;</span>
                          ) : (
                            ''
                          )}
                          {order.Shipping.Phone ? this.formatPhone(order.Shipping.Phone) : ''}
                        </p>
                      )}
                      {order.Shipping.DeliverTo2 && (
                        <span className="addressInfoJobNumber">ID# {order.Shipping.DeliverTo2}</span>
                      )}
                      <div className="submitted-order-accountno">
                        <li className="shippingTitle">Account</li>
                        <p>
                          {order.AccountNumber} <span>{order.AccountLabel && `"${order.AccountLabel}"`}</span>
                        </p>
                      </div>
                    </div>
                    {order.JobId && (
                      <div>
                        <li className="shippingTitle POvalue">Job ID</li>
                        <div className="shippingDetail">
                          <p data-scorpius-automation="order-detail-jobId">{order.JobId}</p>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="shipPaymentTitle end">
                    <li className="shippingTitle">Purchase Order</li>
                    <div className="shippingDetail">
                      <p data-scorpius-automation="order-detail-purchase-order">{order.PurchaseOrderNumber}</p>
                    </div>
                    <li className="shippingTitle POvalue">Billing Address</li>
                    <div className="shippingDetail">
                      {order.Billing.Name && (
                        <p data-scorpius-automation="order-detail-billing-name">{order.Billing.Name}</p>
                      )}
                      {order.Billing.Street1 && (
                        <p data-scorpius-automation="order-detail-billing-Street1">{order.Billing.Street1}</p>
                      )}
                      {order.Billing.Street2 && (
                        <p data-scorpius-automation="order-detail-billing-Street2">{order.Billing.Street2}</p>
                      )}
                      {order.Billing.City && order.Billing.State && order.Billing.PostalCode && (
                        <p data-scorpius-automation="order-detail-billing-City">
                          {order.Billing.City}, {order.Billing.State} {order.Billing.PostalCode}
                        </p>
                      )}
                      {order.Billing.Attention && (
                        <p data-scorpius-automation="order-detail-billing-Attention">Attn: {order.Billing.Attention}</p>
                      )}
                      {order.CSVUrl && (
                        <div
                          className="csvSection"
                          onClick={() => {
                            postAnalytics('pm-orders-submittedOrders-click-downloadCsv', order);
                            downloadFile(null, fileDownloadPath + order.Id, null, 'order.csv');
                          }}
                        >
                          <i className="fal fa-download" />
                          <li className="csvTitle">Download CSV</li>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {(order.PurchaseOrderMessage || order.InternalPurchaseOrderMessage) && (
                  <div className="commentSection">
                    {order.PurchaseOrderMessage && (
                      <div className="comment">
                        <li className="commentTitle">Order Comments</li>
                        <div className="commentValue">
                          <p data-scorpius-automation="order-detail-comment">{order.PurchaseOrderMessage}</p>
                        </div>
                      </div>
                    )}
                    {order.InternalPurchaseOrderMessage && (
                      <div className="internalComment">
                        <li className="commentTitle">Internal Comments</li>
                        <div className="commentValue">
                          <p data-scorpius-automation="order-detail-internalComment">
                            {order.InternalPurchaseOrderMessage}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                <ItemRow>
                  <section>
                    {_.map(order.Items, (item, i) => (
                      <div className="productSection" key={i}>
                        <img alt="product" src={item.ImageUrl} onError={(e) => handleBrokenProductImage(e)} />
                        <div style={{ flex: 3, width: '100%' }}>
                          <p className="item-name">{item.PartName}</p>
                          {item.ContractCodeName ? (
                            <p className="contractCodeLabel">
                              Contract code:{' '}
                              <span>
                                {item.ContractCodeName}
                                {item.ContractCodeDescription ? ` - ${item.ContractCodeDescription}` : null}
                              </span>
                            </p>
                          ) : null}
                        </div>
                        <div className="item-details">
                          <div className="item-quantity-unit">
                            <div className="quantity-container">
                              <p data-scorpius-automation="product-quantity" className="small lite">
                                {item.Quantity}
                              </p>
                            </div>
                            <p data-scorpius-automation="product-price" className="unit-price small lite">
                              ${item.UnitPrice}/{item.UnitOfMeasure.toLowerCase()}
                            </p>
                          </div>
                          <div data-scorpius-automation="product-item-quantity-price" className="item-price currency">
                            ${calcTotal(item.UnitPrice, item.Quantity)}
                          </div>
                        </div>
                      </div>
                    ))}
                  </section>
                </ItemRow>
                <div className="subTotalSection">
                  <p className="subTotal">Subtotal</p>
                  <div data-scorpius-automation="product-subtotal" className="subTotalValue">
                    ${order.TotalAmount}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </ItemRow>
    )
  }
}

export default ItemAccordian
