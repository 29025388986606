import axios from 'axios';
import _ from 'lodash';

const urlController = {
    url: '',
    validUrl: false,
    buildUrl: function (options) {
        this.url = options.root ? options.root + '/' : '';
        if (options.version && options.version.length > 0) this.url += options.version + '/';
        if (options.url) this.url += options.url;
        if (options.query && options.query.length > 0) this.url += '?' + options.query;
        if (options.locParams && options.locParams.length > 0) this.url += '&' + options.locParams;

        return this;
    },
    validateUrl: function () {
        // from: https://gist.github.com/dperini/729294
        this.validUrl = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
            this.url,
        );
        return this;
    },
};

const getFetchOptions = options => {
    let newOptions = {};
    if (_.has(options, 'body')) {
        newOptions.data = options.body;
    }

    if (_.has(options, 'method')) {
        newOptions.method = options.method;
    }

    newOptions.headers = {
        // 'Content-Type': 'application/json',
        // 'Content-Type': 'application/json',
        // Accept: 'application/json',
        Authorization: 'bearer ' + options.bearerToken,
        'Ocp-Apim-Subscription-Key': options.apiKey,
    };

    return newOptions;
};

export const fetchData = options => {
    return new Promise((resolve, reject) => {
        const url = urlController.buildUrl(options).validateUrl().url;
        const formattedOptions = getFetchOptions(options);
        axios({ url, ...formattedOptions })
            .then(response => {
                if (response.data) resolve({ fetchedData: response.data, isLoading: false });
            })
            .catch(fetchError => {
                console.log('fetchError: ', fetchError);
                reject({
                    isLoading: false,
                    fetchedData: { ErrorCode: 500, ...fetchError },
                });
            });
    });
};

export const cancelableFetchData = options => {
    return new Promise((resolve, reject) => {
        const url = urlController.buildUrl(options).validateUrl().url;
        const formattedOptions = getFetchOptions(options);

        return axios({
            url,
            cancelToken: options.cancelToken,
            ...formattedOptions,
        })
            .then(response => {
                if (response.data) resolve({ fetchedData: response.data, isLoading: false });
            })
            .catch(err => {
                if (axios.isCancel(err)) {
                    console.log('First request canceled', err.message);
                } else {
                    console.log(err);
                }
            });
    });
};

export default fetchData;
