export default (apiUrl, apiVersion) => ({
    getSearchItems: {
        root: apiUrl,
        method: 'post',
        url: `${apiVersion || 'v1'}/Supplier/Store/Search`,
        query: undefined,
    },
    postAnalytics: {
        root: apiUrl,
        method: 'post',
        url: 'v1/Procurement/Magneto',
        query: undefined,
    },
    getSuppliers: {
        root: apiUrl,
        method: 'get',
        url: 'v2/Procurement/User',
        query: undefined,
    },
    addToCart: {
        root: apiUrl,
        method: 'post',
        url: 'v1/Procurement/Cart/Token',
        query: undefined,
    },
    getItemDetails: {
        root: apiUrl,
        method: 'post',
        url: 'v1/Supplier/Store/Item/Details',
        query: undefined,
    },
});
