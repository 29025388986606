import styled from 'styled-components';

const CartSummaryWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    background: #fff;
    max-width: 16.875rem;
`;
const CartSummaryContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    background: #fff;
    max-width: 16.875rem;
    border-radius: 4px;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.14), 0px 2px 2px 0px rgba(0, 0, 0, 0.12),
        0px 1px 3px 0px rgba(0, 0, 0, 0.2);
    width: 270px;

    .cart-summary-header {
        display: flex;
        padding: 1rem;
        background-color: #f4f5f6;
        border-bottom: 1px solid #dedfe2;
        align-items: center;
        justify-content: center;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
        h3 {
            font-size: 1.125rem;
            line-height: 2rem;
        }
    }
    .place-order {
        color: white;
        margin: 0;
        &:disabled {
            background: #8aa3ff;
            color: #fff;
            border: none;
        }
        &:disabled: hover {
            background: #8aa3ff;
            color: #fff;
        }
    }
    .cart-suppliers-container {
        display: flex;
        flex-direction: column;
        padding: 1rem 0.75rem;

        .supplier-summary {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 0.5rem;
            max-height: 1000px;

            &.removed {
                display: none;
            }

            &:last-child {
                margin: 0;
            }

            &.unable-to-checkout {
                .supplier-items-summary {
                    img {
                        opacity: 0.3;
                    }
                    p {
                        color: #e4dfe8;
                    }
                }

                .supplier-total {
                    span {
                        color: #e4dfe8;
                    }
                }
            }

            i {
                color: #ffc107;
                margin-right: 0.5rem;
            }

            .supplier-items-summary {
                display: flex;
                align-items: center;

                img {
                    height: 1.25rem;
                    width: 1.25rem;
                    border-radius: 0.25rem;
                    object-fit: contain;
                    margin-right: 0.5rem;
                }
            }
        }
    }

    .cart-summary-details {
        border-top: 1px solid #dedfe2;

        ul {
            padding: 0px;
            margin: 0px;
            padding: 1rem 0.75rem;
        }

        li {
            display: flex;
            list-style-type: none;
            justify-content: space-between;
        }
    }

    .cart-summary-total {
        display: flex;
        flex-direction: column;
        padding: 1rem 0.75rem;
        border-top: 1px solid #dedfe2;

        .cart-total {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 1rem;

            h5 {
                color: #3e4148;
                font-size: 1rem;
                font-weight: bold;
                line-height: 1.25rem;
                text-transform: uppercase;
                margin-right: 0.75rem;
            }

            .total {
                font-size: 1.5rem;
                line-height: 2rem;
                font-weight: bold;
            }
        }

        button {
            text-transform: none;
        }
    }

    .currency {
        display: flex;
        justify-content: flex-end;
        font-size: 1rem;
        font-weight: bold;
        line-height: 1.25rem;
    }
`;

export default { CartSummaryWrapper, CartSummaryContainer };
