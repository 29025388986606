import React, { useEffect, useState } from 'react'
import { Loader } from '../../components'
import { useParams, useHistory } from 'react-router-dom'
import { getUserInfo } from './api'
import { setCookie, getParsedCookie, getRawCookie } from '../../../utils/cookies'
import { fetchCompanyId } from '../../routes/secure'
import { useFlags } from "launchdarkly-react-client-sdk";
import Approvals from './index'
import Error from '../Error'
import EmptyApprovals from './EmptyApprovals'

const routeParamsStates = ['pending', 'approver', 'requests', 'all']
// const activeTabStates = ["pending", "approver", "requests", "all", "error"];

export const TabsManager = ({ userData, ...props }) => {
  const featureFlags = useFlags();
  const history = useHistory()
  const routeParams = useParams()
  const [activeTab, setActiveTab] = useState('pending')
  const [activeUser, setActiveUser] = useState()

  const forwardToTab = (tab) => {
    history.push(`/requests/${tab}`)
    setActiveTab(tab)
  }

  const handleSetActiveTab = (user) => {
    if (routeParamsStates.includes(routeParams.page)) {
      if (routeParams.page === 'approver') {
        if (user.isApprover) {
          setActiveTab(routeParams.page)
        } else if (user.isAdmin || user.isReadOnly) {
          forwardToTab('all')
        } else {
          forwardToTab('requests')
        }
      }

      if (routeParams.page === 'requests') {
        setActiveTab(routeParams.page)
      }

      if (routeParams.page === 'all') {
        if (user.isAdmin || user.isReadOnly) {
          setActiveTab(routeParams.page)
        } else {
          setActiveTab('error')
        }
      }
    } else if (!routeParams.page) {
      if (user.isAdmin) {
        forwardToTab('all')
      } else if (user.isApprover) {
        forwardToTab('approver')
      } else {
        forwardToTab('requests')
      }
    } else {
      setActiveTab('error')
    }
  }

  useEffect(() => {
    const getCompanyId = async () => {
      const companyId = getRawCookie('companyId')
      if (!companyId) {
        const corporateId = userData.Network[userData.activeNetworkIndex].Id
        const compId = await fetchCompanyId(corporateId)
        setCompanyId(compId)
      }
    }
    getCompanyId()
  }, [])

  useEffect(() => {
    if (routeParamsStates.includes(routeParams.page) && activeUser) {
      handleSetActiveTab(activeUser)
    }
  }, [routeParams.page])

  useEffect(() => {
    const getCompanyId = async () => {
      const companyId = getRawCookie('companyId')
      if (!companyId) {
        const corporateId = userData.Network[userData.activeNetworkIndex].Id
        await fetchCompanyId(corporateId)
      }
      getCurrentUser()
    }

    const getCurrentUser = async () => {
      const user = await getUserInfo()

      handleSetActiveTab(user)
      setActiveUser(user)

      if (!user || !user.id) {
        setActiveTab('error')
      }
    }

    if (activeTab === 'pending') {
      getCompanyId()
    }
  }, [activeTab])

  if(!activeUser){
    return <Loader/>
  }
  switch (activeTab) {
    case 'approver':
      return <Approvals {...props} activeUser={activeUser} activeTab={activeTab} featureFlags={featureFlags} />

    case 'requests':
      return <Approvals {...props} activeUser={activeUser} activeTab={activeTab} featureFlags={featureFlags} />

    case 'all':
      return <Approvals {...props} activeUser={activeUser} activeTab={activeTab} featureFlags={featureFlags} />

    case 'error':
      return <Approvals {...props} activeUser={activeUser} activeTab={activeTab} featureFlags={featureFlags} />

    default:
      return <Loader />
  }
}

export default TabsManager
