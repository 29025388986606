import React, { useState } from 'react'
import Modal from '../../../components/Modal'
import SelectField from '../../../components/SelectField'
import cityList from '../../../../common/features/Checkout/raiven/addressModal/cityList'
import { postAddress } from '../../../../common/apis'
import { safeLocalStorage } from '../../../../utils/safeStorage'
import ComponentStyles from './styles'
// import { ADDRESS_VERIFICATION_STATUS, isVerifiedAddressForSuppliers } from '../../Cart/helpers'

const regEx = /^([a-zA-Z0-9 _-]+)$/
const zipCodeRegEx = /^[0-9]+$/

const { AddressModalContainer } = ComponentStyles

function AddAddressModal({ isOpen, onClose, userData, setSelectedAddress, supplierIds, saveCallback, ...props }) {
  // const [addresses, setAddresses] = useState([])
  // const [searchInput, setSearchInput] = useState('')
  // const [filteredAddresses, setFilteredAddresses] = useState([])
  const [companyError, setCompanyError] = useState(false)
  const [streetError, setStreetError] = useState(false)
  const cityError = false
  const [zipError, setZipError] = useState(false)

  const [address, setAddress] = useState({
    company: '',
    street: '',
    addressLine2: '',
    DeliverTo2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    phone: '',
    email: '',
    isDefaultShippingAddress: false,
    isDefaultBillingAddress: false,
  })

  const saveNewAddress = async () => {
    const user = safeLocalStorage.getJSONItem('userData')
    let userToken = user.Network[user.activeNetworkIndex].UserToken
    let CorporateOfficeUserId = user.Network[user.activeNetworkIndex].CorporateOfficeUserId

    const { zip, city, addressLine2, state, street, company } = address

    await postAddress({
      userToken,
      zip,
      city,
      addressLine2,
      state,
      street,
      company,
      addressType: 'Shipping',
      CorporateOfficeUserId,
    })
      .then((res) => {
        onClose(!isOpen, undefined, false)

        if (res) {
          saveCallback()
        }
      })
      .catch((e) => {
        console.log(e, 'error')
      })
  }

  function handleFieldChange(field, value, validation) {
    setAddress({
      ...address,
      [field]: value,
    })
  }

  return (
    <AddressModalContainer>
      <Modal
        title="Add Shipping Address"
        setEnabled={isOpen}
        darkBackground
        maxHeight="800px"
        setToggle={() => {
          onClose(false, undefined, false)
        }}
        maxWidth="600px"
        cardClassName="addressModal"
      >
        <div className="modal-field-wrapper">
          <div className="form-groups">
            <label htmlFor="location" className="location">
              Location Name
            </label>
            <input
              type="text"
              id="location"
              className={companyError ? 'modal-input error' : 'modal-input'}
              value={address.company}
              onChange={(e) => {
                setCompanyError(e.target.value.length > 64)
                handleFieldChange('company', e.target.value)
              }}
              onBlur={(e) => {
                setCompanyError(e.target.value.length > 64)
                handleFieldChange('company', e.target.value)
              }}
            />
            {companyError && (
              <div className="error-text">
                <i className="far fa-exclamation-circle " />
                <label>This field is not valid</label>
              </div>
            )}
          </div>

          <div className="form-groups">
            <label htmlFor="address">Address</label>
            <input
              type="text"
              id="address"
              className={streetError ? 'modal-input error' : 'modal-input'}
              value={address.street}
              onChange={(e) => {
                setStreetError(!e.target.value || !regEx.test(address.street) || e.target.value.length > 64)
                handleFieldChange('street', e.target.value)
              }}
              onBlur={(e) => {
                setStreetError(!e.target.value || !regEx.test(address.street) || e.target.value.length > 64)
                handleFieldChange('street', e.target.value)
              }}
            />
            {streetError && (
              <div className="error-text">
                <i className="far fa-exclamation-circle " />
                <label>Enter a valid street address</label>
              </div>
            )}
          </div>

          <div className="form-groups">
            <label htmlFor="address2" className="address2">
              Address Line2
            </label>
            <span className="addlin2-txt">Apt, Ste, PO box, etc...</span>
            <input
              type="text"
              id="address2"
              className="modal-input"
              value={address.addressLine2}
              onChange={(e) => handleFieldChange('addressLine2', e.target.value)}
              onBlur={(e) => handleFieldChange('addressLine2', e.target.value)}
            />
          </div>

          <div className="form-groups">
            <label htmlFor="deliveryTo2">ID (DeliverTo2)</label>
            <input
              type="text"
              id="deliveryTo2"
              className="modal-input"
              value={address.DeliverTo2}
              onChange={(e) => handleFieldChange('DeliverTo2', e.target.value)}
              onBlur={(e) => handleFieldChange('DeliverTo2', e.target.value)}
            />
          </div>

          <div className="form-groups">
            <label htmlFor="city">City</label>
            <input
              type="text"
              id="city"
              className={cityError ? 'modal-input error' : 'modal-input'}
              value={address.city}
              onChange={(e) => handleFieldChange('city', e.target.value)}
              onBlur={(e) => handleFieldChange('city', e.target.value)}
            />
            {cityError && (
              <div className="error-text">
                <i className="far fa-exclamation-circle " />
                <label>Enter a valid city</label>
              </div>
            )}
          </div>

          <div className="form-groups zip-code-outer">
            <div className="flexrow abb-select">
              <div className="flexcol-6 mr-8">
                <label htmlFor="state">State</label>
                <div>
                  <SelectField
                    // label="State"
                    id="abbreviation"
                    name="abbreviation"
                    placeholder=""
                    onChange={function noRefCheck(e) {
                      handleFieldChange('state', e.abbreviation)
                    }}
                    selectOptions={cityList}
                    value={address.state}
                    itemPadding="-2px"
                  />
                </div>
              </div>
              <div className="flexcol-6 ml-8">
                <label htmlFor="zip">ZIP Code</label>
                <input
                  type="text"
                  id="zip"
                  className={zipError ? 'modal-input error' : 'modal-input'}
                  value={address.zip}
                  onChange={(e) => {
                    setZipError(!e.target.value || !zipCodeRegEx.test(address.zip) || e.target.value.length > 9)
                    handleFieldChange('zip', e.target.value)
                  }}
                  onBlur={(e) => {
                    setZipError(!e.target.value || !zipCodeRegEx.test(address.zip) || e.target.value.length > 9)
                    handleFieldChange('zip', e.target.value)
                  }}
                />
                {zipError && (
                  <div className="error-text">
                    <i className="far fa-exclamation-circle " />
                    <label>Enter a valid ZIP Code</label>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="form-groups btn-outer">
            <div className="flexrow ">
              <div className="flexcol-6 mr-8">
                <button type="button" className="cancel-btn" onClick={onClose}>
                  Cancel
                </button>
              </div>
              <div className="flexcol-6 ml-8">
                <button
                  type="button"
                  className={
                    address.zip && address.city && address.street && address.state ? 'save-btn' : 'save-btn disable'
                  }
                  disabled={!(address.zip && address.city && address.street && address.state)}
                  onClick={() => saveNewAddress()}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </AddressModalContainer>
  )
}

export default AddAddressModal
