import _ from 'lodash';
import React, { PureComponent } from 'react';

import './supplierGrid.css';
import { MarketplaceCart, MarketplaceGo, MarketplaceInfo } from '../../../../components/svg';
import { safeLocalStorage } from '../../../../../utils/safeStorage';
import postAnalytics from '../../../../../utils/postAnalytics'

const isBrowser = typeof window !== 'undefined' && window.env;
const blobUrl = isBrowser ? window.env.blobUrl : process.env.RAZZLE_RUNTIME_BLOB_URL;

export class SupplierGrid extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showInfoCard: false,
            user: safeLocalStorage.getJSONItem('userData'),
        };
    }

    suppPunchout = (supplierId, supplierContracts) => {
        const validSupplierContract = _.filter(supplierContracts, supplier => {
            if (supplier.AccountNumber !== null && supplier.IsVisible && supplier.Status === 'Active') return supplier;
        });

        if (validSupplierContract && validSupplierContract.length === 1) {
            this.props.supplierPunchout(supplierId, validSupplierContract[0].AccountNumber);
        }
    };

    render() {
        const { suppliers } = this.props;
        const { user } = this.state;
        const activeUserIsAdmin =
            user && user.activeUserSettings && user.activeUserSettings.Role === 'Admin' ? true : false;
        const activeContractSuppliers = suppliers.filter(item =>
            item.Contracts.find(field => field.IsVisible && field.Status === 'Active'),
        );

        return (
            <React.Fragment>
                <section className='supplier-grid'>
                    {!_.isEmpty(suppliers) &&
                        (this.props.type === 'catalog' ? (
                            <div className='titleLink'>
                                <h2>Exclusive Online Catalogs</h2>
                            </div>
                        ) : (
                            <h2>Special Discounts and Partnerships</h2>
                        ))}

                    <ul>
                        {_.map(activeContractSuppliers, (supplier, index) => {
                            // if (supplier.Status !== 'Active') return null
                            return (
                                <li key={index}>
                                    <div className='supplier'>
                                        <img src={blobUrl + '/' + supplier.WideLogo} alt={supplier.Name} />
                                    </div>
                                    <div className='card-button-overlay'>
                                        {this.props.type === 'catalog' && (
                                            <div
                                                className='market-overlay-icon'
                                                onClick={
                                                    event =>{
                                                        postAnalytics('pm-home-catalogsHover-click-shop',supplier);
                                                        this.suppPunchout(supplier.Id,supplier.Contracts)
                                                    // this.props.supplierPunchout(supplier.Id, supplier.AccountNumber);
                                                }}
                                            >
                                                {/* <div className='shopping-cart'>

                                                <i className="fal fa-shopping-cart shopping-outline"></i>
                                            <i className={'fas fa-shopping-cart shopping-fill'} />
                                                </div> */}
                                                <MarketplaceCart />
                                                Shop
                                            </div>
                                        )}
                                        {this.props.type === 'info' && (
                                            <div
                                                className='market-overlay-icon'
                                                onClick={() => {
                                                    postAnalytics('pm-home-catalogsHover-click-go',supplier);
                                                    window.open(supplier.Website, '_blank')}
                                                }
                                                target={'_blank'}
                                            >
                                                <div className='marketplace-go'>
                                                    <MarketplaceGo />
                                                </div>
                                                Go
                                            </div>
                                        )}
                                        {supplier.Metadata && supplier.Metadata.length > 0 && (
                                            <div
                                                className='market-overlay-icon'
                                                onClick={event => {
                                                    postAnalytics('pm-home-catalogsHover-click-info',supplier);
                                                    this.props.showInfoCard(supplier.Id, supplier.SupplierContractId);
                                                }}
                                            >
                                                <MarketplaceInfo />
                                                Info
                                            </div>
                                        )}
                                    </div>
                                </li>
                            );
                        })}
                        {
                            activeUserIsAdmin &&
                            (this.props.type === 'catalog' ? (
                                <li className='addAccountList'>
                                    <div
                                        className='addAccount'
                                        role='button'
                                        onClick={() => this.props.setAddAccountModal(true)}
                                    >
                                        <i className='far fa-plus' />
                                        <p>Add Account</p>
                                    </div>
                                </li>
                            ) : (
                                ''
                            ))}
                    </ul>
                </section>
            </React.Fragment>
        );
    }
}

export default SupplierGrid;
