
import { faFileLines,  faFileSpreadsheet, faFilePdf, faFile} from '@fortawesome/pro-regular-svg-icons'
import axios from 'axios';
import { safeLocalStorage } from '../utils/safeStorage';

export const  downloadFileFromUrl = (fileName, url, targetElement = null) => {
  let referenceNode = document.body;
  if(targetElement){
    //targetElement allows us to create the link in a specific spot if needed
    //this avoids the click being outside a modal and closing it
    referenceNode = targetElement.parentNode;
  }
  fetch(url, {}).then(response => response.blob()).then(blob => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      referenceNode.appendChild(link);
      link.click();
      referenceNode.removeChild(link);
    })
    .catch(error => {
      console.error('Error downloading file:', error);
    });
};

export const downloadFile = async (fileName, endpointPath, targetElement, outPutFileName) => {
  let referenceNode = document.body;
  const isBrowser = typeof window !== "undefined" && window.env;
  const apiKey = isBrowser
  ? window.env.apiKey
  : process.env.RAZZLE_RUNTIME_API_KEY;

  if(targetElement){
    //targetElement allows us to create the link in a specific spot if needed
    //this avoids the click being outside a modal and closing it
    referenceNode = targetElement.parentNode;
  }
  
  try {
    const myHeaders = new Headers()
    myHeaders.append('Ocp-Apim-Subscription-Key', apiKey)
    myHeaders.append('Authorization', `Bearer ${safeLocalStorage.getItem('token')}`)
  
    var requestOptions = {
      responseType: 'blob', 
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }
    const url = new URL(endpointPath);
    url.searchParams.append('FileName', fileName); 

    const response = await fetch(url, requestOptions);    
    const blob = await response.blob();

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = outPutFileName || fileName;
    referenceNode.appendChild(link);
    link.click();
    referenceNode.removeChild(link);
  } catch (error) {
    console.error('Error downloading file:', error);
  }
};

export const getFileIcon = (fileName) => {
  if (fileName.endsWith('.pdf')) {
    return faFilePdf;
  } else if (fileName.endsWith('.doc') || fileName.endsWith('.docx')) {
    return faFileLines;
  } else if (fileName.endsWith('.csv') || fileName.endsWith('.xls') || fileName.endsWith('.xlsx')) {
    return faFileSpreadsheet;
  } else {
    return faFile;
  }
};

export default { downloadFileFromUrl, getFileIcon, downloadFile };