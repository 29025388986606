import React, { useEffect, useContext } from 'react'
import styles from './style.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/pro-regular-svg-icons'
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons'
import { MobileLayout } from '../../App'
import useComponentVisible from '../../../utils/useComponentVisible'

function RequestModal({
  children,
  header,
  footer,
  onClose,
  width,
  height = false,
  showMobileStyleModal = false,
  reactToOutsideClick = false,
  awaitingActions = false,
  headerRightInfo
}) {
  const isMobile = useContext(MobileLayout)

  const closingFunction = () => {
    if (isMobile) {
      const modalOverlay = document.querySelector(
        'div.src-common-components-RequestModal-__style-module___mobileModalOverlay'
      )
      const modalDiv = document.querySelector(
        'div.src-common-components-RequestModal-__style-module___modal.src-common-components-RequestModal-__style-module___fadeInBottom.src-common-components-RequestModal-__style-module___mobileStyleModal'
      )
      modalDiv && modalDiv.classList.remove('src-common-components-RequestModal-__style-module___fadeInBottom')
      modalDiv && modalDiv.classList.add('src-common-components-RequestModal-__style-module___fadeOutBottom')
      modalOverlay && (modalOverlay.style.transition = '250ms')
      modalOverlay && (modalOverlay.style.backgroundColor = 'unset')
      setTimeout(() => {
        modalDiv && modalDiv.classList.add('src-common-components-RequestModal-__style-module___fadeInBottom')
        // reactToOutsideClick && reactToOutsideClick(false)
        onClose()
      }, 800)
    } else {
      onClose()
    }
  }

  const { ref } = useComponentVisible(true, closingFunction)

  useEffect(() => {
    document.querySelector('html').style.overflowY = 'hidden'
    document.body.style.overflowY = 'hidden'
    const rootDiv = document.getElementById('root')

    if (isMobile) {
      rootDiv.style.overflowY = 'hidden'
      rootDiv.style.position = 'fixed'
      rootDiv.style.left = '0'
      rootDiv.style.right = '0'
    }
    return () => {
      const spendBridgeLoader = document.querySelector('#spendBridgeLoader')
      if(spendBridgeLoader){
        spendBridgeLoader.style.display = 'none'
      }
      !isMobile
        ? (document.querySelector('html').style.overflowY = 'unset')
        : (document.querySelector('html').style.overflowY = 'scroll')
      !isMobile ? (document.body.style.overflowY = 'unset') : (document.body.style.overflowY = 'scroll')
      !isMobile ? (rootDiv.style.overflowY = 'unset') : (rootDiv.style.overflowY = 'scroll')
      isMobile && (rootDiv.style.position = 'unset')
      isMobile && (rootDiv.style.left = 'unset')
      isMobile && (rootDiv.style.right = 'unset')
      const scrollY = rootDiv.style.top
      rootDiv.style.top = ''
      isMobile && window.scrollTo(0, parseInt(scrollY || '0', 10) * -1)
    }
  }, [])

  return (
    <div className={`${showMobileStyleModal ? styles.mobileModalOverlay : styles.modalOverlay}`}>
      <div
        ref={showMobileStyleModal || !isMobile ? ref : undefined}
        className={`${styles.modal} ${showMobileStyleModal ? styles.fadeInBottom : ''} ${
          showMobileStyleModal ? styles.mobileStyleModal : ''
        }`}
        style={{ width: !isMobile && width, height: height }}
      >
        {showMobileStyleModal && (
          <div onClick={() => closingFunction()} className={styles.mobileHandleContainer}>
            <div className={styles.mobileHandle}></div>
          </div>
        )}
        <div
          className={`${styles.header} ${showMobileStyleModal ? styles.mobileHeader : ''}`}
          onClick={isMobile ? onClose : undefined}
        >
          {header}
          {headerRightInfo}
          {!showMobileStyleModal && (
            <div className={styles.closeButton} onClick={!isMobile ? onClose : undefined}>
              {isMobile ? (
                <FontAwesomeIcon icon={faChevronLeft} className={styles.leftArrowIcon} />
              ) : (
                <FontAwesomeIcon icon={faXmark} className={styles.closeIcon} />
              )}
            </div>
          )}
        </div>
        <div
          className={styles.childrenContainer}
          style={{
            height:
              isMobile && awaitingActions ? 'calc((100vh - 84px) - 114px)' : isMobile && 'calc((100vh - 84px) - 68px)',
            overflowY: isMobile ? 'scroll' : 'hidden',
            overflowX: 'hidden',
            flex: 1,
            flexDirection: 'column',
          }}
        >
          {children}
          {footer}
        </div>
      </div>
    </div>
  )
}

export default RequestModal
