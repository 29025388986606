Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var formatString = {
  capitalize: function capitalize(value) {
    var newValue = value;

    if (value) {
      newValue = value.replace(/\w+/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    }

    return newValue;
  }
};
var _default = formatString;
exports["default"] = _default;