import React, { useEffect, Fragment } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { removeUserData } from '../../../utils/localStorage';

export const Logout = () => {
    const { logout } = useAuth0();

    useEffect(() => {
        const UserActivityEvent = new CustomEvent('UserActivityEvent', { detail: { activeNetwork: null } });
        document.dispatchEvent(UserActivityEvent);

        postAnalytics('pm-accountManage-accountCard-click-logout', state.userData);
        removeUserData();
        
        logout({ logoutParams: { returnTo: window.location.origin } });            
    }, [])

    return <Fragment />;
}

export default Logout;
