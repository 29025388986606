import { Fragment, useEffect, useState } from 'react'
import CartItem from './CartItem'
import DotLoader from './dotLoader'
import { handleUndo, onCurPendingItemUpdate } from './helpers'
import ComponentStyles from './styles'

const { SupplierCartContainer } = ComponentStyles

const isBrowser = typeof window !== 'undefined' && window.env
const blobUrl = isBrowser ? window.env.blobUrl : process.env.RAZZLE_RUNTIME_BLOB_URL

const SupplierCart = (props) => {
  const {
    supplier,
    items,
    userData,
    undo,
    onDeletingItem,
    TotalAmount,
    loadingSupplierItems,
    setCart,
    onSetSupplierLoading,
    onUnSetSupplierLoading,
  } = props
  const [curPendingItem, setCurPendingItem] = useState(false)
  const [pendingItemsCount, setPendingItemsCount] = useState(0)
  const [isDeletingItem, setIsDeletingItem] = useState(false)
  const [timeout, setTimeoutFunc] = useState(null)
  const [deletedItem, setDeletedItem] = useState(null)
  const [supplierCart, setSupplierCart] = useState(items)

  useEffect(() => {
    if (items) {
      setSupplierCart(items)
    }
  }, [items])

  useEffect(() => {
    if (isDeletingItem) {
      setPendingItemsCount(pendingItemsCount - 1)
      if (pendingItemsCount <= 1) {
        onDeletingItem(supplier.Id)
        setCurPendingItem(null)
      }
    }
    setIsDeletingItem(false)
  }, [isDeletingItem])

  useEffect(() => {
    if (deletedItem) {
      supplierCart.map((item, i) => item.Id === deletedItem.Id && supplierCart[i].deleted)
      setDeletedItem(null)
    }
  }, [deletedItem])

  useEffect(() => {
    if (pendingItemsCount >= 1 && curPendingItem) {
      onCurPendingItemUpdate({
        curPendingItem,
        userData,
        supplier,
        setTimeoutFunc,
        setDeletedItem,
        setIsDeletingItem,
        setCart,
        onSetSupplierLoading,
        onUnSetSupplierLoading,
      })
    }
  }, [curPendingItem])

  useEffect(() => {
    if (undo) {
      handleUndo({
        timeout,
        supplierCart,
        setSupplierCart,
        setCurPendingItem,
        curPendingItem,
        pendingItemsCount,
        setPendingItemsCount,
      })
    }
  }, [undo])
  return (
    <SupplierCartContainer>
      <div className="supplier-cart-header ">
        <div className="supplier-logo-outer ">
          <img alt="supplier-logo" src={`${blobUrl}/${supplier.Logo}`} />
          <h4>{supplier.Name}</h4>
        </div>
        <div>
          <p style={{ fontSize: '14px' }}>Account </p>
          <p style={{ color: '#3e4148' }}>
            {supplier.AccountNumber}{' '}
            <span style={{ color: 'grey' }}>{supplier.AccountLabel && `"${supplier.AccountLabel}"`}</span>
          </p>
        </div>
      </div>
      {supplierCart.map((item) => (
        <CartItem item={item} userData={userData} supplier={supplier} key={`cart-item-${item.Id}`} />
      ))}
      <div className="supplier-cart-total">
        <h5>Subtotal</h5>
        <div className="supplier-total currency">
          {loadingSupplierItems.includes(supplier.Id) ? (
            <DotLoader />
          ) : (
            <Fragment>
              {curPendingItem && <i className="far fa-exclamation-circle" />}${TotalAmount}
            </Fragment>
          )}
        </div>
      </div>
    </SupplierCartContainer>
  )
}

export default SupplierCart
