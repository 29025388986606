import axios from 'axios';
import _ from 'lodash';

const isBrowser = typeof window !== 'undefined' && window.env;
const apiKey = isBrowser ? window.env.apiKey : process.env.RAZZLE_RUNTIME_API_KEY;

const urlController = {
    url: '',
    validUrl: false,
    buildUrl: function (options) {
        this.url = options.root ? `${options.root}/` : '';
        if (options.version && options.version.length > 0) this.url += `${options.version}/`;
        if (options.url) this.url += options.url;
        if (options.query && options.query.length > 0) this.url += `?${options.query}`;
        return this;
    },
    validateUrl() {
    // from: https://gist.github.com/dperini/729294
    this.validUrl = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
      this.url,
    );
    return this;
  },
};

export const getFetchOptions = options => {
    const newOptions = {};
    if (_.has(options, 'body')) {
        newOptions.data = options.body;
    }

    if (_.has(options, 'method')) {
        newOptions.method = options.method;
    }

    newOptions.headers = {
        'Ocp-Apim-Subscription-Key': `${apiKey}`,
    };

    if (_.has(options, 'bearerToken')) {
        newOptions.headers.Authorization = `bearer ${options.bearerToken}`;
    }

    return newOptions;
};

const fetchData = options => new Promise((resolve, reject) => {
    const { url } = urlController.buildUrl(options).validateUrl();
    const formattedOptions = getFetchOptions(options);
    axios({ url, ...formattedOptions })
      .then((response) => {
                if (response.data) {
                    if (_.isArray(response.data)) {
            resolve(response.data);
          } else {
            resolve({ ...response.data });
                    }
        }
      })
      .catch((fetchError) => {
        console.log('fetchError: ', fetchError);
        reject({
                    ErrorCode: 500,
          ...fetchError,
        });
            });
  });

export const cancelableFetchData = options => new Promise((resolve, reject) => {
    const { url } = urlController.buildUrl(options).validateUrl();
    const formattedOptions = getFetchOptions(options);

    return axios({
      url,
      cancelToken: options.cancelToken,
            ...formattedOptions,
    })
      .then((response) => {
        if (response.data) resolve({ ...response.data });
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log('Original request canceled');
        } else {
          console.log(err);
        }
        reject({ error: err });
            });
  });

export default fetchData;
