import { FormatCart } from '../../../lib/@q-core/raiven-utils'
import { safeLocalStorage } from '../../utils/safeStorage'
import config from './apiConfig'
import { cancelableFetchData } from './fetchData'
/**
 * Items shape: [
 * {
    SupplierId: 0,
    AccountNumber: "string",
    SupplierPartNumber: "string",
    Quantity: 0,
    ContractCodeId: 0
  }
 * ]
 **/

const updateCartItemsContractCodes = async ({ userData, items, contractCodeId }) => {
  const userToken = userData.Network[userData.activeNetworkIndex].UserToken
  return new Promise((resolve, reject) => {
    const options = {
      ...config.postItemQuantity,
      url: `${config.postItemQuantity.url}/${userToken}/UpdateQuantities`,
      body: items.map((item) => ({
        ...item,
        ContractCodeId: contractCodeId,
      })),
    }
    return cancelableFetchData(options)
      .then((data) => {
        if (data && !data.ErrorCode) {
          let stateObject = {
            ...data,
            fetchError: !!(data && data.ErrorCode),
          }
          stateObject = FormatCart.cart(stateObject)
          safeLocalStorage.setJSONItem('cart', stateObject)
          resolve(stateObject)
        } else {
          reject({ fetchError: true, details: data })
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export default updateCartItemsContractCodes
