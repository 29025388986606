import { useRef } from 'react'
import { useOnClickOutside } from '../../../hooks/useOnClickOutside'
import { ModalBaseBackground, ModalBaseContainer } from './styles'

const ModalBase = ({ children, onClose }) => {
  const ref = useRef()
  useOnClickOutside(ref, () => {
    onClose()
  })
  return (
    <>
      <ModalBaseContainer>
        <div ref={ref} className="modal">
          {children}
        </div>
      </ModalBaseContainer>
      <ModalBaseBackground />
    </>
  )
}

export default ModalBase
