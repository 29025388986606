import styled from 'styled-components';

const Title = styled.h2`
    font-family: Montserrat;
    font-size: 1.125rem;
    font-weight: bold;
    height: 1.5rem;
    line-height: 1.5rem;
    margin: 0;
    width: 100%;
`;

const TitleContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    i {
        color: #969ba5;
        margin-right: 0.25rem;
        margin-left: auto;
        cursor: pointer;
    }

    .matts-style {
        justify-content: center;
        align-items: center;
        display: flex;
        background: rgba(0, 0, 0, 0.5);
        height: 1.5rem;
        width: 1.5rem;
        border-radius: 0.75rem;
        z-index: 99;
        margin: -1.75rem -2.25rem 1.25rem auto;
        color: #ffffff;
        font-weight: 300;
    }
`;

const ModalContainer = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  backdrop-filter: blur(8px);
  z-index: 99;
  /* background: ${({ darkBackground }) => darkBackground && 'rgba(0, 0, 0, 0.5)'}; */
`;

const Card = styled.div`
    display: flex;
    flex-direction: column;
    height: ${({ height }) => height || '50%'};
    max-height: 70vh;
    width: ${({ width }) => width || '50%'};
    max-width: ${({ maxWidth }) => maxWidth && maxWidth};
    max-height: ${({ maxHeight }) => maxHeight && maxHeight};
    background: rgb(255, 255, 255);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14), 0px 4px 5px 0px rgba(0, 0, 0, 0.12),
        0px 1px 10px 0px rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
    padding: 1rem 1.5rem;
    margin: 1.5rem;

    .modal-children {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
`;

export default {
  Title,
  TitleContainer,
  ModalContainer,
  Card,
};
