import moment from "moment";
import momentTimezone from "moment-timezone";
import _ from "lodash";

// const UTCFormat = 'YYYY-MM-DDTHH:mmZZ';

// HINT: You probably want to use toLocalFromUTC({utcDate: '', format: ''})

// Converts UTC date time to local and local to UTC date time
const UTCFormat = "YYYY-MM-DDTHH:mmZZ";
const localTimeFormat = "YYYY-MM-DDTHH:mm";
const DateFormat = {
  toLocalDate: (date, format) => {
    let newDate = moment(new Date(date));

    if (format) {
      newDate = newDate.format(format);
    } else {
      newDate = newDate.format("YYYY-MM-DD");
    }

    return newDate;
  },

  toLocalTime(date) {
    const today = moment().set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    const dateObj = moment.utc(date);
    const dateObj00 = moment(dateObj)
      .local()
      .set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });

    return moment(today + dateObj - dateObj00).toDate();
  },

  toLocalDateTime(date, format) {
    let dateFormat = format;

    if (!format) {
      dateFormat = "YYYY-MM-DDTHH:mm";
    }
    return moment(new Date(date)).format(dateFormat);
  },

  toLocalDateTimeWithTimezone(date, format, defaultValue) {
    if(date === null || date === undefined){
      return defaultValue || '';
    }
    if (!format) {
      format = "YYYY-MMM-DD, HH:mm";
    }
    const newDate = DateFormat.toLocalFromUTC({
      utcDate: date,
      format,
    });


    const timezoneAbbr = momentTimezone.tz(momentTimezone.tz.guess()).zoneAbbr();

    return `${newDate} ${timezoneAbbr}`;
  },

  toUTC(date, format) {
    let dateFormat = format;

    if (!format) {
      dateFormat = UTCFormat;
    }

    return moment(date)
      .utc()
      .format(dateFormat);
  },

  toUTCAdjusted(params) {
    let format = UTCFormat;

    if (_.has(params, "format")) {
      format = params.format;
    }

    return this.modifyDate({
      ...params,
      format,
      date: moment(params.date).utc(),
    });
  },

  toAdjusted(params) {
    let format = "YYYY-MM-DD";

    if (_.has(params, "format")) {
      format = params.format;
    }

    return this.modifyDate({
      ...params,
      format,
      date: moment(params.date),
    });
  },

  modifyDate(params) {
    const { date, format, amount, type, method } = params;
    let adjustedDate;

    try {
      adjustedDate = date[method](amount, type).format(format);
    } catch (e) {
      let errorMessage = "";
      if (!_.has(params, ["amount", "type", "method", "date"])) {
        errorMessage =
          "Missing params. Should have amount, type, method, and date";
      }
      this.handleError(e, errorMessage);
    }

    return adjustedDate;
  },

  toLocalFromUTC(params) {
    const { utcDate, utcFormat } = params;
    let { format } = params;
    let formattedUTCDate = "";

    if (!format) {
      format = "YYYY-MM-DD";
    }

    if (utcFormat) {
      formattedUTCDate = moment(utcDate, utcFormat).format("YYYY-MM-DDTHH:mm");
    }

    if (formattedUTCDate === "Invalid date" || formattedUTCDate === "") {
      formattedUTCDate = moment(utcDate.replace(/\+00:00$/, "")).format(
        "YYYY-MM-DDTHH:mm"
      );
    }

    return moment
      .utc(formattedUTCDate)
      .local()
      .format(format);
  },

  toLocalDateFromNow(date) {
    const datediff = moment(new Date(), "YYYY-MM-DD").diff(
      moment(date, "YYYY-MM-DD"),
      "days"
    );
    let dateString = "";

    if (datediff < 1) {
      dateString = DateFormat.toLocalDateTime(date, "h:mm a");
    } else if (datediff === 1) {
      dateString = `Yesterday ${DateFormat.toLocalDateTime(date, "h:mm a")}`;
    } else {
      dateString = DateFormat.toLocalDateTime(date, "MMM DD, h:mm a");
    }

    return dateString;
  },

  toLocalDayFromNow(params) {
    let { utcDate, format } = params;
    let localDateFromUtcDate = this.toLocalFromUTC({
      utcDate,
      format: "YYYY-MM-DD",
    });
    const datediff = moment(new Date(), "YYYY-MM-DD").diff(
      moment(localDateFromUtcDate, "YYYY-MM-DD"),
      "days"
    );
    let dateString = "";
    if (datediff < 1) {
      dateString = "Today";
    } else if (datediff === 1) {
      dateString = "Yesterday";
    } else {
      if (moment(utcDate).year() !== moment().year()) {
        format += ", YYYY";
      }
      dateString = this.toLocalFromUTC({ utcDate, format });
    }
    return dateString;
  },

  appendTimeToDate(time, date) {
    let updatedDateTime;

    if (time && date) {
      //   hours = moment(time).format('HH'),
      const hours = moment(time, "YYYY-MMM-DD HH:mm").format("HH");
      const minutes = moment(time).format("mm");
      //  minutes = moment(time, 'hh:mm').format('mm'),
      const formattedDate = moment(date, "YYYY-MM-DD").format("YYYY-MM-DD");

      //  formattedDate = moment(date).format(localDateFormat);

      updatedDateTime = moment(formattedDate)
        .hour(hours)
        .minute(minutes)
        .format(localTimeFormat);
    }
    return updatedDateTime;
  },

  validateNoPastDate(value) {
    let invalid = false;
    let setDate = new Date(`${value} 12:00:00`);
    const current = new Date();

    if (setDate.getFullYear() < current.getFullYear()) {
      invalid = true;
    } else if (setDate.getFullYear() === current.getFullYear()) {
      if (setDate.getMonth() < current.getMonth()) {
        invalid = true;
      } else if (setDate.getMonth() === current.getMonth()) {
        if (setDate.getDate() < current.getDate()) {
          invalid = true;
        } else {
          invalid = false;
        }
      }
    }

    return invalid;
  },

  localTimeZone() {
    const zoneGuess = moment.tz(moment.tz.guess()).format("z");
    return zoneGuess;
  },

  handleError(e, message) {
    console.log(message);
    console.log(e);
  },
};

export default DateFormat;
