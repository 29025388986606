import React, { useState, useEffect } from 'react'
import './styles.css'
import OrderEditSubitem from './OrderEditSubItem'

function OrderEditItem({ index, userData, item, contractCodes, handleEditOrder, handleInputBlur, handleRemoveItem, setShowWarningMessage, hasOneItemLeft, onSelect, ...props }) {

    const isBrowser = typeof window !== 'undefined' && window.env
    const blobUrl = isBrowser ? window.env.blobUrl : process.env.RAZZLE_RUNTIME_BLOB_URL

    function getListSubtotal(item) {
        if (isNaN(item.Items.reduce((sum, item) => sum + item.ListPrice * item.Quantity, 0))) {
            return '-'
        }
        return new Intl.NumberFormat('en-US', {
            maximumFractionDigits: 2,
            currency: 'USD',
            style: 'currency',
        }).format(item.Items.reduce((sum, item) => sum + item.ListPrice * item.Quantity, 0))
    }

    return (
        <div key={props.key} className="order-item">
            <div className="order-item-header">
                <img
                    src={`${blobUrl}/${item.Supplier.SquareLogo}`}
                    alt="supplier square logo"
                    style={{ width: 32, height: 32, borderRadius: 8 }}
                />
                <p
                    className="bold-label"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        color: '#000000',
                    }}
                >
                    {item.Supplier.Name}
                </p>
            </div>
            <div style={{ width: '100%' }}>
                {item.Items.map((subitem, i2) => (
                    <OrderEditSubitem 
                        key={subitem.Id}
                        index={index}
                        secondIndex={i2}
                        subitem={subitem}
                        contractCodes={contractCodes}
                        handleEditOrder={handleEditOrder}
                        handleInputBlur={handleInputBlur}
                        handleRemoveItem={handleRemoveItem}
                        setShowWarningMessage={setShowWarningMessage}
                        hasOneItemLeft={hasOneItemLeft}
                        onSelect={onSelect}
                     />
                ))}
            </div>
            <div style={{ width: '100%' }}>
                <div
                    style={{
                        display: 'flex',
                        gap: 16,
                        justifyContent: 'flex-end',
                        borderTop: '1px solid #DADDE1',
                        padding: 16,
                    }}
                >
                    <p
                        style={{
                            fontFamily: 'Rubik',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: 16,
                            lineHeight: '20px',
                            display: 'flex',
                            alignItems: 'center',
                            textAlign: 'right',
                            color: 'rgba(0, 0, 0, 0.5)',
                        }}
                    >
                        Subtotal
                    </p>
                    <p
                        className="bold-label"
                        style={{
                            textAlign: 'right',
                            color: '#000000',
                            width: 120,
                        }}
                    >
                        {getListSubtotal(item)}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default OrderEditItem