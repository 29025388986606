import styled from 'styled-components';

const CheckoutMain = styled.main`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 80rem;
    margin: 0 auto;
    flex: 1;

    .checkoutItems {
        margin-right: 1.875rem;
        max-width: 54.375rem;
        flex: 2;

        .POcheckbox {
            margin-bottom: 1.375rem;
        }
    }
    .back-to-cart:hover {
        opacity: 0.8;
    }
    .addressInfo {
        color: #3e4148;
        font-size: 1rem;
        line-height: 1.25rem;

        .company {
            font-weight: bold;
            margin-bottom: 0.25rem;
        }

        .addressVerification{
            display: flex;
        }
        
        .addressVerification i{
            width: 20px;
            height: 20px;
            color: rgb(255, 193, 7);
            font-size: 16px;
            font-weight: normal;
            line-height: 20px;
        }
        
        .addressVerification p{
            color: rgb(255, 160, 0);
            font-size: 14px;
            font-family: Lato, sans-serif;        
            font-weight: normal;
            line-height: 20px;
        }
    }
    .addressInfoJobNumber {
        color: rgba(0, 0, 0, 0.5);
    }
    .edit-btn {
        background: transparent;
        padding: 0;
        display: block;
        width: 100%;
        color: #b9bbc1 !important;
        font-family: Lato;
        font-size: 12px;
        font-weight: bold;
        height: 16px;
        letter-spacing: 0.5px;
        line-height: 16px;
        text-align: center;
        width: 120px;
        padding: 0;
        &:hover {
            opacity: 0.8;
        }
    }
    .add-address-btn {
        color: rgb(57, 99, 254);
        font-family: Lato;
        font-size: 16px;
        font-weight: normal;
        height: 20px;
        line-height: 20px;
        background-color: transparent;
        padding: 0;
        justify-content: end;
        margin-top: 13px;
        margin-left: 0;
        &:hover {
            opacity: 0.8;
        }
    }
    .add-address-btn i {
        margin-right: 10px;
    }
    .purchaseOrderHelp {
        width: 600px;
        font-family: Lato;
        color: #fff;

        div {
            font-family: Lato;
            color: #fff;
            margin-bottom: 1rem;
        }
        ul {
            margin: 0;
            padding: 0;
            padding-left: 1rem;
        }

        li {
            margin-bottom: 1rem;

            &:last-of-type {
                margin-bottom: 0px;
            }
        }
    }
`;

const CheckoutWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
`;
const CheckoutContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
    border-radius: 0.25rem;
    margin-bottom: 1.5rem;
    flex-grow: 1;

    .checkout-header {
        display: flex;
        padding: 1rem;
        background-color: #f4f5f6;
        border-bottom: 1px solid #dedfe2;
        align-items: center;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
    }

    .locationLabel {
        color: #6e727c;
        font-size: 0.875rem;
        font-weight: normal;
        letter-spacing: 0.25px;
        line-height: 1.25rem;
        margin-bottom: 0.25rem;
    }

    .changeLabel {
        color: rgb(57, 99, 254);
        font-size: 12px;
        font-family: Lato;
        font-weight: bold;
        letter-spacing: 0.5px;
        line-height: 16px;
        cursor: pointer;
        &:hover {
            opacity: 0.8;
        }
    }

    .addressContainer {
        display: flex;
        flex-direction: column;
        flex: 1;
        .masked {
            margin: 1rem 0;
            margin-bottom: 0;
            .maskInput-field {
                ::placeholder {
                    letter-spacing: 2rem;
                }
            }
            .areaWrapper {
                height: 2rem;
            }
        }
    }

    .checkoutAddress {
        margin-bottom: 0;
        width: 100%;

        .addressLabel {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 0.25rem;

            .locationLabel {
                margin-bottom: 0px;
            }
        }
    }

    .supplier-cart-total {
        display: flex;
        justify-content: flex-end;
        padding: 1rem;
        border-top: 1px solid #dedfe2;

        .supplier-total {
            display: flex;
            align-items: center;
            min-width: 8.5rem;

            i {
                color: #ffc107;
                margin-right: 0.5rem;
            }
        }
    }

    .currency {
        display: flex;
        justify-content: flex-end;
        font-size: 1rem;
        font-weight: bold;
        line-height: 1.25rem;
    }

    .checkbox {
        margin-right: 1rem;
    }

    .tooltip-container {
        i {
            color: #b9bbc1;
        }
    }
`;

const ItemRow = styled.div`
    display: flex;
    align-items: center;
    padding: 1rem 1rem 1.5rem 1rem;

    .input-container {
        width: 26.63rem;
        margin-top: 16px;
    }
    .local-outer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 16px;
    }

    .checkoutNotes {
        width: 100%;
        textarea {
            resize: vertical;
        }
        .commentTextArea {
            width: 100%;
        }
        input {
            margin-bottom: 0rem;
        }
        .POcheckbox {
            color: rgba(62, 65, 72, 0.35);
            margin-bottom: 0.875rem;
            margin-top: 0.875rem;
        }
        .error-text {
            color: #ff5722;
            display: flex;
            align-items: center;
            // margin-top: 2px;
            margin-bottom: 8px;
            label {
                color: #ff5722;
                margin: 0 0 4px;
                font-family: 'Lato';
                font-size: 14px;
                font-style: italic;
            }
            i {
                margin-right: 3px;
            }
        }
    }

    input {
        .details-container {
            width: 26.63rem;
        }
    }

    .POinput {
        margin-bottom: 2rem;

        .input-container {
            .details-container {
                .char-limit {
                    display: none;
                }
            }
        }
    }

    .detail-container {
        width: 26.63rem;
    }

    .checkoutRadio {
        margin: 0px 0.5rem 0px 0.75rem;
        font-size: 1rem;
    }
    .checkoutRadioButton {
        input {
            color: #b9bbc1;
        }
        label {
            color: #3e4148;
            opacity: 0.35;
        }
    }
    .checkoutRadioButton.checked {
        label {
            opacity: 1;
        }
    }

    .POCard {
        display: flex;
        flex: 1;
        flex-direction: column;

        .checkoutAddress {
            display: flex;
            flex: 1;
            flex-direction: row;
            justify-content: space-between;
        }
    }

    .POsection {
        margin-bottom: 1rem;
    }

    h4 {
        margin-bottom: 1rem;
        color: rgb(62, 65, 72);
        font-family: Montserrat;
        font-size: 16px;
        font-weight: bold;
        height: 20px;
        letter-spacing: 0.44px;
        line-height: 20px;
        width: 134px;
    }

    .POToggle {
        margin: 0px;
        margin-right: 5px;
    }

    .POText {
        color: rgba(62, 65, 72, 0.35);
        font-size: 16px;
        font-weight: normal;
        line-height: 20px;
    }

    .POcheckbox {
        color: rgba(62, 65, 72, 0.35);
        margin: 0px 0px 0px 0.75rem;
        letter-spacing: normal;
        &.checked {
            .POText {
                color: #3e4148;
            }
        }
    }
    checkbox.disabled {
        border-color: red;
        color: rgba(62, 65, 72, 0.35);
    }
    img {
        width: 3.75rem;
        height: 3.75rem;
        object-fit: contain;
        border-radius: 0.25rem;
    }
    p.item-name {
        display: -webkit-box;
        margin: 0 3rem 0 1rem;
        height: 2.5rem;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: left;
        opacity: 1;
    }

    .item-details {
        display: flex;
        width: fit-content;
        margin-left: auto;
        justify-content: flex-end;
        align-items: center;

        .item-price {
            min-width: 8.5rem;
            opacity: 1;
        }

        .item-quantity-unit {
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: flex-end;

            p.unit-price {
                font-size: 0.75rem;
                margin: 0 1.25rem 0 auto;
            }

            .quantity-container {
                display: flex;
                align-items: center;

                p {
                    font-size: 0.75rem;
                }

                input {
                    text-align: center;
                    background: #fafafa;
                    border-radius: 0.25rem;
                    border: 1px solid #dedfe2;
                    height: 1rem;
                    width: 1.75rem;
                    margin: 0 0.5rem;
                    padding: 0.25rem;
                    font-size: 0.75rem;

                    &::-webkit-outer-spin-button,
                    &::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                    }

                    &[type='number'] {
                        -moz-appearance: textfield;
                    }

                    &:focus {
                        outline: none;
                        border: 1px solid #3963fe;
                        box-shadow: 0px 0px 4px 0px #3963fe;
                    }
                }

                i {
                    cursor: pointer;
                    color: #969ba5;
                }
            }
        }
    }
`;

const BackToCart = styled.div`
    margin-bottom: 1.5rem;

    i {
        margin-right: 0.25rem;
    }
    a {
        color: #3963fe;
        font-size: 0.75rem;
        font-weight: normal;
        letter-spacing: 0.25px;
        line-height: 1rem;
    }
`;

const AddressAddWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    .addAddressxyz {
        color: #3e4148;
        font-size: 16px;
        font-family: Lato;
        font-weight: normal;
        text-align: center;
        line-height: 20px;
    }

    .iconWrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        i {
            width: 40px;
            height: 40px;
            color: #b9bbc1;
            font-size: 32px;
            font-weight: 300;
            text-align: center;
            line-height: 40px;
            margin-bottom: 1rem;
        }
    }

    button {
        margin-top: 1rem;
        text-transform: none;
        width: 15rem;
    }
`;

export default {
  CheckoutMain,
  CheckoutWrapper,
  CheckoutContainer,
  ItemRow,
  BackToCart,
  AddressAddWrapper,
};
