import fetchData from './fetchData'
import config from './apiConfig'

export default (userToken, activeNetworkId, contractName, accountNumber, label) =>
  new Promise((resolve, reject) => {
    const options = {
      ...config.postCorporateOfficeContract,
      url: `${
        config.postCorporateOfficeContract.url
      }/${userToken}/CorporateOfficeContract?AdminPortalCorporateOfficeId=${encodeURIComponent(
        activeNetworkId
      )}&ContractName=${encodeURIComponent(contractName)}&AccountNumber=${encodeURIComponent(
        accountNumber
      )}&AccountLabel=${encodeURIComponent(label)} `,
    }
    return fetchData(options)
      .then((data) => {
        if (data && !data.ErrorCode) {
          resolve(data)
        } else {
          reject({ fetchError: true, details: data })
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
