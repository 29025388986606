import React from 'react';
import _ from 'lodash';
import { Avatar } from '../../../../lib/@q-core/react-components';
import StyledComponents from './styles';

const { CorporateLogoStyle } = StyledComponents;

const CorporateLogo = props => {
    const { account } = props;

    return (
        <CorporateLogoStyle>
            {_.has(account, 'ImageUrl') && !_.isEmpty(account.ImageUrl) ? (
                <Avatar type='company' className='withImage' imageUrl={account.ImageUrl} alt='Corporate Logo' />
            ) : (
                <Avatar type='company' className='noImage' title={account.DBAName} />
            )}
        </CorporateLogoStyle>
    );
};

export default CorporateLogo;
