const isBrowser = typeof window !== 'undefined' && window.env
const apiUrl = isBrowser ? window.env.apiRoot : process.env.RAZZLE_RUNTIME_API_ROOT

export default {
  getCompanyContractCodes: {
    root: apiUrl,
    method: 'get',
    url: 'v1/Company/',
  },
  postAnalytics: {
    root: apiUrl,
    method: 'post',
    url: 'v1/Procurement/Magneto',
  },
  getCartData: {
    root: apiUrl,
    method: 'get',
    url: 'v1/Procurement/Carts/UserToken',
  },
  getCheckoutData: {
    root: apiUrl,
    method: 'get',
    url: 'v1/Cart/EqualLevel/User',
  },
  postItemQuantity: {
    root: apiUrl,
    method: 'put',
    url: 'v1/Procurement/Cart/Token',
  },
  deleteCartItem: {
    root: apiUrl,
    method: 'delete',
    url: 'v1/Procurement/Cart/Token',
  },
  getSupplierContractData: {
    root: apiUrl,
    method: 'get',
    url: 'v2/Procurement/User',
  },
  getSubmittedOrders: {
    root: apiUrl,
    method: 'get',
    url: 'v1/UserToken',
  },
  getAddressCheckOut: {
    root: apiUrl,
    method: 'get',
    url: 'v1/Procurement/Location',
  },
  getSpendbridgeUrl: {
    root: apiUrl,
    method: 'get',
    url: 'v1/Procurement/Spendbridge/Token',
  },
  postSpendbridgeCheckout: {
    root: apiUrl,
    method: 'post',
    url: 'v1/Procurement/Spendbridge/Token',
  },
  postCheckout: {
    root: apiUrl,
    method: 'post',
    url: 'v1/Procurement/Cart/Token',
  },
  userLogin: {
    root: apiUrl,
    method: 'get',
    url: 'v1/Procurement/Login',
  },
  getActivePC: {
    root: apiUrl,
    method: 'get',
    url: 'v1/User',
  },
  getSupplierContractTiles: {
    root: apiUrl,
    method: 'get',
    url: 'v1/SupplierContract',
  },
  postCorporateOfficeContract: {
    root: apiUrl,
    method: 'post',
    url: 'v1/User',
  },
  updateProcurementUserStatus: {
    root: apiUrl,
    method: 'put',
    url: 'v2/Procurement/User',
  },
  updateProcurementUserVisibility: {
    root: apiUrl,
    method: 'put',
    url: 'v2/Procurement/User',
  },
  deleteCorporateOfficeContract: {
    root: apiUrl,
    method: 'delete',
    url: 'v1/Procurement/User',
  },
  editCorporateOfficeContract: {
    root: apiUrl,
    method: 'put',
    url: 'v1/Procurement/User',
  },
  deleteAddress: {
    root: apiUrl,
    method: 'delete',
    url: 'v1/Address/UserToken',
  },
  UpdateAddress: {
    root: apiUrl,
    method: 'put',
    url: 'v1/Address/UserToken',
  },
  postAddress: {
    root: apiUrl,
    method: 'post',
    url: 'v1/Address/UserToken',
  },
  // getPrice: {
  //     root: apiUrl,
  //     method: 'get',
  //     url: 'v1/Procurement/Cart/Token'
  // }
}
