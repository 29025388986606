import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMemoCircleCheck } from '@fortawesome/pro-regular-svg-icons'
import RequestModal from '../RequestModal'
import Button from '../Button'
import styles from './style.module.css'
import { MobileLayout } from '../../App';

const ProcessedRequestModal = ({ message, onClose }) => {
  const isMobile = useContext(MobileLayout)

  return (
    <RequestModal
      width={400}
      onClose={() => onClose('hideProcessedRequestModal')}
      header={<h3 className={styles.title} style={{ height: isMobile && '44px' }}>Request already processed</h3>}
      footer={
        <div style={{ display: 'flex', flexDirection: 'row-reverse', gap: 16, padding: isMobile ? '16px 24px 60px 24px' : '16px 24px' }}>
          <Button onClick={() => onClose('hideProcessedRequestModal')} styles={{ fontSize: isMobile && '16px', lineHeight: isMobile && '20px', letterSpacing: isMobile && '0.02em' }} text="OK" variant="primary" size="md" />
        </div>
      }
      showMobileStyleModal={isMobile}
    >
      <div style={{ paddingLeft: 16, paddingRight: 16 }}>
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 16 }}>
          <FontAwesomeIcon
            icon={faMemoCircleCheck}
            style={{ color: 'rgba(0, 0, 0, 0.5)', fontStyle: 'normal', fontWeight: 400, fontSize: 32, lineHeight: '32px' }}
          />
        </div>
        <p className={styles.message}>{message}</p>
      </div>
    </RequestModal>
  )
}

export default ProcessedRequestModal
