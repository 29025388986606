import fetchData from './fetchData';
import config from './apiConfig';
import { safeLocalStorage } from '../../utils/safeStorage';
import { FormatCart } from '../../../lib/@q-core/raiven-utils';

export default ({ AccountNumber, partId, supplierId, userData }) => {
    const userToken = userData.Network[userData.activeNetworkIndex].UserToken;

    return new Promise((resolve, reject) => {
        const options = {
            ...config.deleteCartItem,
            url: `${config.deleteCartItem.url}/${userToken}/Item`,
            body: {
                AccountNumber,
                SupplierPartNumber: partId,
                SupplierId: parseInt(supplierId, 10),
            },
        };
        return fetchData(options)
            .then(data => {
                if (data && !data.ErrorCode) {
                    let stateObject = {
                        ...data,
                        fetchError: !!(data && data.ErrorCode),
                    };
                    stateObject = FormatCart.cart(stateObject);
                    safeLocalStorage.setJSONItem('cart', stateObject);
                    resolve(stateObject);
                } else {
                    reject({ fetchError: true, details: data });
                }
            })
            .catch(error => {
                reject(error);
            });
    });
};
