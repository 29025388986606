"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.safeLocalStorage = exports.safeSessionStorage = void 0;
var isBrowser = typeof window !== "undefined" && window.sessionStorage ? true : false;
var safeSessionStorage = {
  getItem: function getItem(storageKey) {
    if (isBrowser) return window.sessionStorage.getItem(storageKey);
    return;
  },
  setItem: function setItem(storageKey, storageValue) {
    if (isBrowser) return window.sessionStorage.setItem(storageKey, storageValue);
    return;
  },
  getJSONItem: function getJSONItem(storageKey) {
    if (isBrowser) return tryParse(sessionStorage.getItem(storageKey));
    return;
  },
  setJSONItem: function setJSONItem(storageKey, storageValue) {
    if (isBrowser) return window.sessionStorage.setItem(storageKey, JSON.stringify(storageValue));
    return;
  }
};
exports.safeSessionStorage = safeSessionStorage;
var safeLocalStorage = {
  getItem: function getItem(storageKey) {
    if (isBrowser) return window.localStorage.getItem(storageKey);
    return;
  },
  setItem: function setItem(storageKey, storageValue) {
    if (isBrowser) return window.localStorage.setItem(storageKey, storageValue);
    return;
  },
  getJSONItem: function getJSONItem(storageKey) {
    if (isBrowser) return tryParse(localStorage.getItem(storageKey));
    return;
  },
  setJSONItem: function setJSONItem(storageKey, storageValue) {
    if (isBrowser) return window.localStorage.setItem(storageKey, JSON.stringify(storageValue));
    return;
  }
};
exports.safeLocalStorage = safeLocalStorage;

var tryParse = function tryParse(data) {
  try {
    return JSON.parse(data);
  } catch (e) {
    return;
  }
};