import React from 'react'
import { CSVLink } from 'react-csv'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/pro-regular-svg-icons'
import styles from './styles.module.css'
import useGetLocalItem from '../../../hooks/useGetLocalItem'

const DownloadCSV = ({ data, status }) => {
  const userData = useGetLocalItem('userData')

  const companyNameRaw = userData.Network[+userData.activeNetworkIndex].DBAName.replace(/ /g, '-') || ''
  const companyName = companyNameRaw.replace(/[^a-zA-Z0-9\s]]/g, '')
  const headers = [
    { label: 'Request number', key: 'requestNumber' },
    { label: 'Requester email', key: 'requesterEmail' },
    { label: 'Created on', key: 'createdOn' },
    { label: 'Current approvers', key: 'currentApprovers' },
    { label: 'Status', key: 'status' },
    { label: 'Suppliers', key: 'suppliers' },
    { label: 'PO Numbers', key: 'poNumbers' },
    { label: 'Last action taken', key: 'lastActionTaken' },
    { label: 'Job ID', key: 'jobId' },
    { label: 'Shipping ID', key: 'shippingId' },
    { label: 'Approvers', key: 'approvers' },
    { label: 'Total', key: 'total' },
  ]

  const formattedData = data.map((item) => {
    return {
      requestNumber: item.externalId,
      requester: item.requester,
      requesterEmail: item.requesterEmail,
      createdOn: item.fullCreatedOn,
      currentApprovers: item.status === 'Pending' ? item.approvers.join(', ') : '',
      status: item.status,
      suppliers: item.vendors.map((vendor) => vendor.SupplierName).join(', '),
      jobId: item.jobId,
      shippingId: item.shippingId,
      poNumbers: item.vendors
        .filter((vendor) => Boolean(vendor.PurchaseOrderNumber))
        .map((vendor) => {
          return vendor.PurchaseOrderNumber
        })
        .join(', '),
      lastActionTaken: item.approvalResult || item.status,
      approvers: item.approvalChainSteps.length === 0 
        ? 'No approval needed'
        : item.approvalChainSteps
        .map((chainItem) => chainItem.approvers.map((approver) => `${approver.name} (${approver.email})`).join(', '))
        .join(', '),
      total: item.total,
    }
  })

  return (
    <CSVLink
      data={formattedData}
      headers={headers}
      className={styles.downloadButton}
      filename={`${!!companyName ? `${companyName}-` : ''}${status}-Requests-${new Date().toISOString()}.csv`}
    >
      <FontAwesomeIcon icon={faDownload} className={styles.downloadButtonIcon} />
      <span className={styles.downloadButtonText}>Download CSV</span>
    </CSVLink>
  )
}

export default DownloadCSV
