import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ComponentStyles from './styles';
import { handleBlur, handleChange } from './helpers';
import Tooltip from '../Tooltip';

const { MaskInputContainer, MaskInputStyle } = ComponentStyles;

const MaskInput = props => {
    const {
        label,
        height,
        width,
        placeholder,
        optionalTag,
        disabled,
        helperText,
        onChange,
        iconLeft,
        onIconLeftClick,
        iconRight,
        onIconRightClick,
        imageRight,
        rounded,
        className,
        toolTip,
        mask,
        maskingType,
        required,
        setErrors,
    } = props;
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [formattedValue, setFormattedValue] = useState('');
    const state = {
    setError,
    setErrorMessage,
    setFormattedValue,
    formattedValue,
    onChange,
  };

    useEffect(() => {
        if (!required) {
            setErrors(error, props.Name);
        }
    }, [error]);

    // choose Default (you provide the mask), Phone (###)###-####, Card ####-####-####-####

    return (
        <MaskInputContainer
            className={`maskInput-container ${className}`}
        data-automation={`maskIinput-container-${label}`}
        error={error}
            width={width}
        height={height}
        helperText={helperText}
        type={props.type}
            toolTip={toolTip}
      >
        <div className={`label-container ${toolTip ? 'tooltip' : ''}`}>
              {label || ''}
                {optionalTag ? <span>- Optional</span> : ''}
                {toolTip ? <Tooltip content={toolTip} /> : ''}
            </div>
        {helperText && <p className="helper-text">{helperText}</p>}
            {maskingType === 'phone' ? (
            <div className="phoneSection">
                    {maskingType === 'phone' && (
              <div className="areaWrapper">
                      <div className="plusCode">+</div>
                      <div className="areaCode">1</div>
                        </div>
              )}
                <MaskInputStyle
                      className="maskInput-field"
                        data-automation={`maskInput-field-${label}`}
                        height={height}
                      mask={mask}
                        maskType={maskingType}
                        width={width}
                        placeholder={placeholder}
                      disabled={disabled}
                        onChange={e => handleChange(e, { ...props, ...state })}
                      onBlur={e => handleBlur(e, { ...props, ...state })}
                      iconLeft={iconLeft}
                        iconRight={iconRight}
                        imageRight={imageRight}
                        rounded={rounded}
                      value={formattedValue}
                    />
                </div>
          ) : (
              <MaskInputStyle
                  className="maskInput-field"
                    data-automation={`maskInput-field-${label}`}
                    height={height}
                  mask={mask}
                  maskType={maskingType}
                  width={width}
                    placeholder={placeholder}
                  disabled={disabled}
                  onChange={e => handleChange(e, { ...props, ...state })}
                    onBlur={e => handleBlur(e, { ...props, ...state })}
                  iconLeft={iconLeft}
                  iconRight={iconRight}
                    imageRight={imageRight}
                  rounded={rounded}
                  value={formattedValue}
                />
          )}

            {iconLeft && <i className={`icon-left ${iconLeft}`} onClick={onIconLeftClick} />}
        {iconRight && <i className={`icon-right ${iconRight}`} onClick={onIconRightClick} />}
            {imageRight && <img className={`image-right ${imageRight}`} src={imageRight} alt="right icon" />}
        <div className="details-container">
            {error && required && (
                <p className="error-field" data-automation={`input-error-${label} `}>
              <i className='far fa-exclamation-circle' />
              {errorMessage}
              <br />
                    </p>
                )}
            </div>
      </MaskInputContainer>
    );
};

MaskInput.propTypes = {
    type: PropTypes.string,
    label: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
    placeholder: PropTypes.string,
    optional: PropTypes.bool,
    optionalTag: PropTypes.bool,
    disabled: PropTypes.bool,
    helperText: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    iconLeft: PropTypes.string,
    onIconLeftClick: PropTypes.func,
    iconRight: PropTypes.string,
    onIconRightClick: PropTypes.func,
    imageRight: PropTypes.string,
    rounded: PropTypes.bool,
    required: PropTypes.bool,
    onError: PropTypes.func,
    className: PropTypes.string,
    value: PropTypes.string.isRequired,
    mask: PropTypes.string,
    Name: PropTypes.string,
    maskingType: PropTypes.string,
    validate: PropTypes.func,
    validateOnChange: PropTypes.bool,
};

export default MaskInput;
