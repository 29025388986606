import _ from 'lodash'
import { FormatCart } from '../../../lib/@q-core/raiven-utils'
import config from './apiConfig'
import fetchData from './fetchData'

export default ({ userData }) => {
  const userToken = userData.Network[userData.activeNetworkIndex].UserToken

  return new Promise((resolve, reject) => {
    const options = {
      ...config.getCartData,
      url: `${config.getCartData.url}/${userToken}`,
    }
    return fetchData(options)
      .then((data) => {
        if (data && !data.ErrorCode) {
          let stateObject = {
            ...data,
            fetchError: !!(data && data.ErrorCode),
          }
          if (_.has(stateObject, 'CartRequisitions') && !_.isEmpty(stateObject.CartRequisitions)) {
            stateObject = FormatCart.cart(stateObject)
            const updatedCartRequisitions = stateObject.CartRequisitions.map((cartItem) => ({
              ...cartItem,
              // TotalAmount: Number(cartItem.TotalAmount.toString().replace(/\,/g,'')),
              Requisitions: cartItem.Requisitions.map((reqItem) => ({
                ...reqItem,
                UnitPrice: Number(reqItem.UnitPrice.toString().replace(/,/g, '')),
              })),
            }))
            stateObject = { ...stateObject, CartRequisitions: updatedCartRequisitions }
          }
          resolve(stateObject)
        } else {
          reject({ fetchError: true, details: data })
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}
