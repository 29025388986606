import fetchData from './fetchData';
import config from './apiConfig';

export default (token, id) =>
    new Promise((resolve, reject) => {
  const options = {
            ...config.deleteAddress,
            url: `${config.deleteAddress.url}/${token}/Id/${id}`,
        };
        return fetchData(options)
    .then((data) => {
      if (data && !data.ErrorCode) {
                    resolve(data);
      } else {
                    reject({ fetchError: true, details: data });
                }
            })
    .catch((error) => {
      reject(error);
            });
});
