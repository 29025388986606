export default {
    auroraThemeCtaColor: '#2f55fe',
    auroraThemeBackgroundColor: 'linear-gradient( -225deg, rgb(57, 99, 254) 0%, rgb(43, 77, 172) 100%)',
    auroraThemeTextColor: '255, 255, 255',
    auroraTextOpacity: 1,
    auroraDeempasizedTextOpacity: 0.7,
    auroraLoadingTextOpacity: 0.5,
    auroraActiveIconOpacity: 0.6,
    auroraInactiveIconOpacity: 0.3,
    auroraHeaderButtonActiveBackgroundColor: '57, 99, 254',
    auroraHeaderButtonActiveBackgroundOpacity: 0.5,
};
