import React, { useContext } from 'react'
import Button from '../Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faArrowDown, faArrowUp, faCheck, faXmark } from '@fortawesome/pro-regular-svg-icons'
import styles from './style.module.css'
import postAnalytics from '../../../utils/postAnalytics'
import { MobileLayout } from '../../App'
import Tooltip from '../Tooltip'

// const isBrowser = typeof window !== "undefined" && window.env;
// const blobUrl = isBrowser
//   ? window.env.blobUrl
//   : process.env.RAZZLE_RUNTIME_BLOB_URL;

function RequestsTable({
  requests = [],
  isApprover = false,
  onChange,
  isPendingTable,
  isCompletedTable,
  activeTab,
  isSubmittedFilterActive,
  setIsSubmittedFilterActive,
}) {
  const isMobile = useContext(MobileLayout)
  const isBrowser = typeof window !== 'undefined' && window.env
  const blobUrl = isBrowser ? window.env.blobUrl : process.env.RAZZLE_RUNTIME_BLOB_URL

  const setStatusColor = (status) => {
    switch (status) {
      case 'Pending':
        return 'rgba(0, 0, 0, 0.5)'
      case 'Approved':
        return '#00C853'
      case 'Rejected':
        return '#FF3D00'
      default:
        return 'rgba(0, 0, 0, 0.5)'
    }
  }

  function onRowClick(event, request) {
    event.preventDefault()
    //matching on 'IMG' so that supplier image clicks also open the modal
    if (event.target.tagName === 'TD' || event.target.tagName === 'IMG' || isMobile) {
      onChange('showRequestDetailsModal', request)
    }
  }

  const handleVendorsIcons = (vendors) => {
    if (vendors !== null && vendors.length > 0) {
      if (vendors.length > 3) {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
              gap: 4,
              height: '100%',
            }}
          >
            <img style={{ width: 16, height: 16 }} src={`${blobUrl}/${vendors[0].Icon}`} alt="vendor icon" />
            <img style={{ width: 16, height: 16 }} src={`${blobUrl}/${vendors[1].Icon}`} alt="vendor icon" />
            <img style={{ width: 16, height: 16 }} src={`${blobUrl}/${vendors[2].Icon}`} alt="vendor icon" />
            <FontAwesomeIcon icon={faPlus} style={{ color: 'rgba(0, 0, 0, 0.5)' }} />
          </div>
        )
      } else {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
              gap: 4,
              height: '100%',
            }}
          >
            {vendors.map((vendor) => (
              <img
                key={vendor.SupplierId}
                style={{ width: 16, height: 16 }}
                src={`${blobUrl}/${vendor.Icon}`}
                alt="vendor icon"
              />
            ))}
          </div>
        )
      }
    } else return null
  }

  const setActionTooltipMessage = (status) => {
    switch (status) {
      case 'Reassigned':
        return 'You reassigned for approval'
      case 'Approved':
        return 'You approved'
      case 'Rejected':
        return 'You rejected'
      default:
        return 'No action taken'
    }
  }

  const setStatusTooltipMessage = (status) => {
    switch (status) {
      case 'Pending':
        return 'Order pending approvals'
      case 'Approved':
        return 'Order submitted'
      case 'Rejected':
        return 'Order rejected'
      default:
        return 'Order pending approvals'
    }
  }

  const handleApprovers = (approvers) => {
    if (Array.isArray(approvers) && approvers.length > 0) {
      if (approvers.length > 1) {
        return `${approvers.length} approvers`
      } else {
        return approvers[0]
      }
    } else {
      return approvers
    }
  }

  const getRequestOverallStatus = (request) => {
    const hasPendingApprovalStep = request.approvalChainSteps.some((step) => step.approvalResult === 'Pending')

    return hasPendingApprovalStep ? 'Pending' : request.status
  }

  const getIcon = (request) => {
    const overallStatus = getRequestOverallStatus(request)

    switch (overallStatus) {
      case 'Pending':
        return <img src="/images/icon-checkmark-circle.svg" alt="icon" />
      case 'Reassigned':
        return <img src="/images/icon-edit-user.svg" alt="icon" />
      case 'Rejected':
        return <img src="/images/icon-filled-times-hexagonal-border.svg" alt="icon" />
      case 'Approved':
        return <img src="/images/icon-check-solid-circle.svg" alt="icon" />
      default:
        return null // Handle other cases if necessary
    }
  }

  return isMobile ? (
    <div className={styles.requestsContainer}>
      {requests.length > 0 &&
        requests.map((request) => {
          return (
            <div className={styles.mobileRowContainer} key={request.externalId} onClick={(e) => onRowClick(e, request)}>
              {isPendingTable ? <div style={{ width: '20px' }}></div> : isCompletedTable && getIcon(request)}
              <div className={styles.rowDetails}>
                <div className={styles.rowStacked}>
                  <p className={styles.rowDarkText}>
                    {activeTab === 'all' || activeTab === 'approver'
                      ? request.requester
                      : handleApprovers(request.approvers)}
                  </p>
                  <p className={styles.rowLightText}>{request.externalId}</p>
                </div>
                <div className={styles.rowStacked} style={{ alignItems: 'flex-end' }}>
                  <p className={styles.rowDarkText}>{request.total}</p>
                  <p className={styles.rowLightText}>{request.createdOn}</p>
                </div>
              </div>
            </div>
          )
        })}
    </div>
  ) : (
    <table>
      <thead>
        <tr>
          <th>Request</th>
          <th
            onClick={() => {
              postAnalytics('pm-approvals-sort-by-submitted', {
                'sort-by-submitted': isSubmittedFilterActive ? 'descending' : 'ascending',
              })
              setIsSubmittedFilterActive(!isSubmittedFilterActive)
            }}
            style={{ display: 'flex', gap: 8, alignItems: 'center' }}
          >
            <span>Submitted</span>
            <FontAwesomeIcon
              icon={isSubmittedFilterActive ? faArrowUp : faArrowDown}
              style={{ color: 'rgba(0, 0, 0, 0.75)' }}
            />
          </th>
          <th
            style={{
              minWidth: 140,
            }}
          >
            {isApprover ? 'Requester' : 'Approver'}
          </th>
          <th>Vendor</th>
          <th>Total</th>
          {(isApprover && isPendingTable) || (isApprover && isCompletedTable) ? (
            <th
              style={{
                minWidth: 140,
              }}
            >
              Action
            </th>
          ) : null}
          {(isApprover && !isPendingTable) || (!isApprover && isPendingTable) || (!isApprover && isCompletedTable) ? (
            <th style={{ width: 76 }}>Status</th>
          ) : null}
        </tr>
      </thead>
      <tbody>
        {requests.length > 0 &&
          requests.map((request) => {
            return (
              <tr key={request.externalId} onClick={(e) => onRowClick(e, request)}>
                <td style={{ color: '#2244FD' }}>{request.externalId}</td>
                <td>{request.createdOn}</td>
                <td
                  style={{
                    minWidth: 140,
                  }}
                >
                  {activeTab === 'all' || activeTab === 'approver'
                    ? request.requester
                    : handleApprovers(request.approvers)}
                </td>
                <td>{request.vendors && handleVendorsIcons(request.vendors)}</td>
                <td>{request.total}</td>
                {(isApprover && isPendingTable) ||
                (!isApprover && isPendingTable) ||
                (!isApprover && isCompletedTable) ? null : (
                  <td
                    style={{
                      width: 140,
                      color: setStatusColor(request.approvalResult),
                      textAlign: 'center',
                    }}
                  >
                    <Tooltip content={setActionTooltipMessage(request.approvalResult)} direction="top">
                      {request.approvalResult}
                    </Tooltip>
                  </td>
                )}
                {isApprover && isPendingTable ? (
                  <td style={{ width: 235 }}>
                    <div style={{ display: 'flex', gap: 8 }}>
                      <Button
                        text="Approve"
                        variant="approve"
                        icon={<FontAwesomeIcon icon={faCheck} style={{ color: '#009C1B' }} />}
                        size="sm"
                        onClick={() => onChange('approveRequest', request)}
                      />
                      <Button
                        text="Reject"
                        variant="reject"
                        icon={<FontAwesomeIcon icon={faXmark} style={{ color: '#DE0400' }} />}
                        size="sm"
                        onClick={() => onChange('showRejectModal', request)}
                      />
                      <Button
                        text="Reassign"
                        variant="default"
                        size="sm"
                        onClick={() => onChange('showReassignModal', request)}
                      />
                    </div>
                  </td>
                ) : (
                  <td style={{ width: 76, color: setStatusColor(getRequestOverallStatus(request)) }}>
                    <Tooltip content={setStatusTooltipMessage(request.status)} direction="top">
                      {getRequestOverallStatus(request)}
                    </Tooltip>
                  </td>
                )}
              </tr>
            )
          })}
      </tbody>
    </table>
  )
}

export default RequestsTable
