import styled from 'styled-components';

const AccountWrapper = styled.div`
    max-width: 73.5rem;
    width: 100%;

    .backButton {
        color: rgba(62, 65, 72, 0.7);
        font-size: 0.875rem;
        line-height: 1.25rem;
        &:hover {
                opacity: 0.8;
            }

        i {
            color: #969ba5;
            font-size: 1rem;
            margin-right: 0.5rem;
            line-height: 1.25rem;
        }
    }
    .accessDenied{
        display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: 100%;
    h2{
        color: #3e4148;
        margin-bottom: 16px;
    }
    h3{
        color: #969ba5;
        font-size: 17px;
        max-width: 500px;
    margin: 0 auto;
    font-weight: 600;
    }
}

    }
    
`;

const AccountContents = styled.div`
    margin: 1.5rem 0 0 10rem;
    height: 64px;
    h2 {
        margin-bottom: 1rem;
    }
    .manage-account {
        display: flex;
        justify-content: space-between;
        .manageLink {
            height: 16px;
            color: #3963fe;
            font-size: 12px;
            font-family: Lato;
            font-weight: bold;
            text-align: right;
            letter-spacing: 0.5px;
            line-height: 16px;
            margin: 8px 0px;
            &:hover {
                opacity: 0.8;
            }
        }
    }
    .accountSupplier {
        padding-bottom: 8px !important;
    }

    .accountObject {
        /* margin-bottom: 0px; */
        margin-bottom: 24px;
        li {
            display: flex;
            flex-direction: row;
            list-style: none;
            max-height: 64px;
            margin-bottom: 8px;
            @media (max-width: 991px) {
                max-height: unset;
            }
            .supplierLogo {
                width: 94px;
                height: 94px;
                margin-right: 24px;
                img {
                    width: 6.25rem;
                    object-fit: contain;
                    margin-right: 1rem;
                    margin-top: 0.75rem;
                }
            }
            .far.fa-pencil {
                color: rgb(185, 187, 193);
                margin: 4px 0 0 16px;
                fontsize: 16px;
                lineheight: ' 20px';
                textalign: 'center';
                width: '20px';
                cursor: pointer
                            &:hover {
                    color: #969ba5;
                }
            }
            .accountContent {
                width: 100%;
                min-height: 64px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                background-color: #ffffff;
                box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12),
                    0 0 2px 0 rgba(0, 0, 0, 0.14);
                border-radius: 0.5rem;
                margin-bottom: 8px;
                flex-grow: 1;
                padding: 12px 12px;
                box-sizing: border-box;
                .accountInfoContainer {
                    flex-direction: column;
                    width: 300px;
                    .accountLabel {
                        height: 20px;
                        color: #3e4148;
                        font-size: 14px;
                        font-family: Lato;
                        font-weight: normal;
                        line-height: 20px;
                    }
                    .accountNumber {
                        height: 20px;
                        color: #3e4148;
                        font-size: 14px;
                        font-family: Lato;
                        font-weight: normal;
                        line-height: 20px;
                    }
                    .accountName {
                        height: 20px;
                        color: #3e4148;
                        font-size: 14px;
                        font-family: Lato;
                        font-weight: 70%;
                        line-height: 20px;
                        opacity: 0.7;
                    }
                }
                .switchContainer {
                    display: flex;
                    margin: 9px 0 0 0;
                    .status {
                        height: 20px;
                        color: #3e4148;
                        font-size: 14px;
                        font-family: Lato;
                        font-weight: normal;
                        text-align: right;
                        line-height: 20px;
                        margin: 1px 8px 0 0;
                        &.fade {
                            color: #dedfe2;
                        }
                    }
                    .pendingAccount {
                        height: 20px;
                        color: #b9bbc1;
                        font-size: 14px;
                        font-family: Lato;
                        font-weight: normal;
                        text-align: right;
                        line-height: 20px;
                        margin: 1px 0.5rem 0 0;
                    }

                    .pendingIcon {
                        margin: 1px 2.3rem 0 0;
                        color: #b9bbc1;
                    }
                }
                .switchContainer.Paused p {
                    opacity: 0.35;
                }
            }
        }
    }
`;

const Switch = styled.label`
    position: relative;
    display: inline-block;
    width: 36px;
    height: 20px;
    margin-top: 2px;

    .switch label:before {
        position: absolute;
        content: '';
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }
    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(62, 65, 72, 0.35);
        -webkit-transition: 0.4s;
        transition: 0.4s;
        opacity: 0.75;
    }

    .slider:before {
        position: absolute;
        content: '';
        height: 12px;
        width: 12px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: 0.4s;
    }
    .slider:checked + slider:before {
        -webkit-transform: translateX(16px);
        -ms-transform: translateX(16px);
        transform: translateX(16px);
    }

    input:checked + .slider {
        background-color: #689f38;
    }
    input:focus + .slider {
        box-shadow: 0 0 1px #689f38;
    }
    input:checked + .slider:before {
        -webkit-transform: translateX(16px);
        -ms-transform: translateX(16px);
        transform: translateX(16px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
`;

export default {
    Switch,
    AccountWrapper,
    AccountContents,
};
