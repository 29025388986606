"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _formatNumber = _interopRequireDefault(require("../formatNumber"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var formatItems = function formatItems(items) {
  var internal = _lodash["default"].map(items, function (item, index) {
    var listPrice = item.ListPrice,
        unitPrice = item.UnitPrice;

    if (_lodash["default"].has(item, 'ListPrice')) {
      item.ListPrice = (0, _formatNumber["default"])(listPrice);
      item.ListPriceSource = listPrice;
    }

    if (_lodash["default"].has(item, 'UnitPrice')) {
      item.UnitPrice = (0, _formatNumber["default"])(unitPrice);
      item.UnitPriceSource = unitPrice;
    }

    item.TotalAmount = listPrice * item.Quantity;
    item.TotalAmount = (0, _formatNumber["default"])(item.TotalAmount);
    return item;
  });

  return internal;
};

var formatCart = function formatCart(cart) {
  var formatted = cart;

  if (_lodash["default"].has(cart, 'CartRequisitions') && _lodash["default"].isArray(cart.CartRequisitions) && !_lodash["default"].isEmpty(cart.CartRequisitions)) {
    formatted = _lodash["default"].map(cart.CartRequisitions, function (reqArray, index) {
      if (_lodash["default"].has(reqArray, 'Requisitions') && _lodash["default"].isArray(reqArray.Requisitions) && !_lodash["default"].isEmpty(reqArray.Requisitions)) {
        if (_lodash["default"].has(reqArray, 'TotalAmount') && !_lodash["default"].isNil(reqArray.TotalAmount)) {
          reqArray.TotalAmount = (0, _formatNumber["default"])(reqArray.TotalAmount);
        }

        reqArray.Requisitions = formatItems(reqArray.Requisitions);
      }

      return reqArray;
    });
  }

  if (_lodash["default"].has(cart, 'CartTotal')) {
    cart.CartTotal = (0, _formatNumber["default"])(cart.CartTotal);
  }

  return _objectSpread(_objectSpread({}, cart), {}, {
    CartRequisitions: formatted
  });
};

var _default = {
  cart: formatCart,
  items: formatItems
};
exports["default"] = _default;