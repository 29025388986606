import styled from 'styled-components';

const PCSelectModalContainer = styled.div`
    .pcSelectModal {
        padding: 1rem;
        height: 26.25rem;
        max-height: 26.25rem;
        margin: 0px;
        overflow-y: hidden;

        h3 {
            font-size: 1rem;
            line-height: 1.25rem;
        }
    }

    .modal-children {
        padding: 0px 0.5rem 0.5rem 0.5rem;
    }
`;
const PCSelectModalStyles = styled.div`
    display flex;
    flex-direction: column;
    justify-content: space-between;

    .modal-card {
        padding: 1rem;
    }

    h4 {
      color: rgb(62, 65, 72);
      font-family: Montserrat;
      font-size: 16px;
      font-weight: bold;
      height: 20px;
      letter-spacing: 0.44px;
      line-height: 20px;
    }

    .filterCard {
        margin: 1rem 0px;
        margin-bottom: 1.5rem;
    }

    .optionContainer {
        margin: 0px;
        padding: 0px;
        height: 18rem;
        overflow-y: auto;

        li {
            list-style-type: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
            box-shadow: inset 0px -1px 0px 0px rgb(237, 238, 239);

            &:last-of-type {
                box-shadow: none;
            }
        }
    }
    .addCCContainer {
        display: flex;
        flex-direction: row;
        margin-top: 0.75rem;
        i {
            color: #3963fe;
            font-size: 16px;
            font-weight: bold;
            height: 20px;
            line-height: 20px;
            text-align: center;
            width: 20px;
        }
        .addNewCardButton {
            width: 276px;
            color: #3963fe;
            font-size: 16px;
            font-family: Lato;
            font-weight: normal;
        }
}

    button {
        text-transform: none;
        min-width: 7.5rem;
        margin: 0;
    }
`;

export default { PCSelectModalContainer, PCSelectModalStyles };
