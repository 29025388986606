import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { SelectField } from '../../components/SelectField'
import Modal from '../../components/Modal'
import { Button, Input } from '../../../../lib/@q-core/react-components'
import { getSupplierContractTiles, deleteCorporateOfficeContract } from '../../apis'
import postAnalytics from '../../../utils/postAnalytics.js'

const isBrowser = typeof window !== 'undefined' && window.env
const blobUrl = isBrowser ? window.env.blobUrl : process.env.RAZZLE_RUNTIME_BLOB_URL
const getSupplierContractDetails = async ({ setSupplierContractInfo }) => {
  const supplierContractInfo = await getSupplierContractTiles()
  setSupplierContractInfo(_.orderBy(supplierContractInfo, 'Name'))
}

const AddAccountModal = (props) => {
  const { isEdit, setEdit, supplierToEdit, contractToEdit } = props
  const [label, setLabel] = useState(contractToEdit ? contractToEdit.AccountLabel : '')
  const [labelError, setLabelError] = useState(false)
  const [accountNumber, setAccountNumber] = useState(contractToEdit ? contractToEdit.AccountNumber : '')
  const [accountNumberError, setAccountNumberError] = useState(false)
  const [contractName, setContractName] = useState(
    supplierToEdit
      ? {
          name: supplierToEdit.Name,
          url: `${blobUrl}/${supplierToEdit.IconLogo}`,
          id: supplierToEdit.SupplierContractId,
        }
      : null
  )
  const [supplierContract, setSupplierContractInfo] = useState([])
  const [isDelete, setDelete] = useState(false)
  const [isAccountAlreadyExists, setAccountAlreadyExists] = useState(false)

  const deleteAccount = () => {
    const userData = JSON.parse(window.localStorage.getItem('userData'))
    const userToken = userData.Network[userData.activeNetworkIndex].UserToken
    const CorporateOfficeContractId = contractToEdit && contractToEdit.CorporateOfficeContractId
    deleteCorporateOfficeContract(userToken, CorporateOfficeContractId)
      .then((res) => {
        setDelete(false)
        setEdit(false)
      })
      .catch((e) => console.log(e, 'error'))
  }

  const setLabelText = (value) => {
    if (value.length < 26) {
      setLabelError(false)
    } else {
      setLabelError(true)
    }
    setLabel(value)
  }
  const setAccountNumberText = (value) => {
    if (value.length < 26) {
      setAccountNumberError(false)
    } else {
      setAccountNumberError(true)
    }
    setAccountNumber(value)
  }
  const closeModal = () => {
    if (isEdit) {
      setEdit(false)
    } else {
      props.setAddAccountModal(false)
    }
  }

  const onSave = async () => {
    postAnalytics('pm-editAccountModal-footer-click-saveButton', { isEdit: isEdit === true })

    if (isEdit) {
      const supplierIdInFilteredSuppliers = props.filteredSuppliers.map((supplier) => supplier.Id).flat()
      const accountNumbersInFilteredSuppliers = props.filteredSuppliers
        .map((supplier) => supplier.Contracts.map((contract) => contract.accountNumber))
        .flat()

      if (
        accountNumbersInFilteredSuppliers.includes(accountNumber) &&
        supplierIdInFilteredSuppliers.includes(props.supplierToEdit.Id)
      ) {
        setAccountAlreadyExists(true)
      } else {
        props.editExistingSupplier(supplierToEdit, contractToEdit, accountNumber, label, contractName.id)
      }
    } else {
      const response = await props.addNewSupplier(contractName.name, accountNumber, label)
      if (response && response.ErrorCode && response.ErrorCode === 409) {
        setAccountAlreadyExists(true)
      }
    }
  }

  useEffect(() => {
    getSupplierContractDetails({ setSupplierContractInfo })
  }, [])

  return (
    <div>
      <Modal
        title={isEdit ? 'Edit Account' : 'Add Account'}
        setEnabled
        darkBackground
        height="fit-content"
        setToggle={() => {
          postAnalytics('pm-editAccountModal-header-click-closeModalX', { isEdit: isEdit === true })
          closeModal()
        }}
        maxWidth="600px"
        cardClassName="addAccountModal"
      >
        <div className="editAccountInner">
          <div>
            <SelectField
              label="Contract"
              name="name"
              url="url"
              onChange={function noRefCheck(e) {
                console.log('e', e)
                setContractName({ id: e.id, name: e.name, url: e.url })
              }}
              selectOptions={supplierContract.map((item) => ({
                id: item.Id,
                name: item.Name,
                url: `${blobUrl}/${item.IconLogo}`,
              }))}
              value={contractName || 'Select a supplier contract...'}
            />
          </div>

          <div className="addFormField">
            <Input
              Name="Name"
              type="text"
              label="Label"
              optionalTag
              optional
              value={label}
              onChange={(e) => setLabelText(e.target.value)}
              // errors={this.state.errors}
              // setErrors={name => this.setErrors(name)}
            />
            {labelError && (
              <div className="error-text">
                <i className="far fa-exclamation-circle " />
                <label>Too many characters.</label>
              </div>
            )}
          </div>

          <div className="addFormField">
            <Input
              Name="Name"
              type="text"
              label="Account Number"
              optionalTag
              optional
              value={accountNumber}
              onChange={(e) => setAccountNumberText(e.target.value)}
            />
            {accountNumberError && (
              <div className="error-text">
                <i className="far fa-exclamation-circle " />
                <label>Too many characters.</label>
              </div>
            )}
          </div>

          {isAccountAlreadyExists && (
            <div className="error-message">
              <i className="far fa-exclamation-circle " />
              <span>This info already exists</span>
            </div>
          )}

          <div className="edit-text" style={isEdit ? { paddingTop: '0px' } : { paddingTop: '24px' }}>
            {isEdit && (
              <button
                onClick={() => {
                  postAnalytics('pm-manageAccounts-editAccountModal-click-deleteAccount', supplierToEdit)
                  setDelete(true)
                }}
              >
                Delete account
              </button>
            )}
          </div>

          <div className="buttonContainer add-account-btn">
            <button
              type="default"
              className="cancel-btn"
              onClick={() => {
                postAnalytics('pm-manageAccounts-header-click-cancelDeleteButton')
                closeModal()
              }}
            >
              Cancel
            </button>
            <Button
              onClick={() => onSave()}
              type="indigoVibrant"
              shape="square"
              title="Save"
              disabled={!(contractName && !accountNumberError && !labelError)}
              className={contractName && !accountNumberError && !labelError ? 'activeSave' : 'inactiveSave'}
            />
          </div>
        </div>
      </Modal>

      {isDelete && (
        <Modal
          title="Delete"
          setEnabled={isDelete}
          darkBackground
          height="fit-content"
          cardClassName="deleteModal"
          setToggle={() => {
            postAnalytics('pm-manageAccounts-header-click-closeModalWithX')
            setDelete(false)
          }}
        >
          <p> Please confirm that you would like to delete this item </p>
          <div className="delete-buttons">
            <button
              type="default"
              className="cancel-btn"
              onClick={() => {
                postAnalytics('pm-manageAccounts-header-click-cancelDeleteButton')
                setDelete(false)
              }}
            >
              {' '}
              Cancel
            </button>
            <button
              className="delete-btn"
              onClick={() => {
                deleteAccount()
              }}
            >
              {' '}
              Delete
            </button>
          </div>
        </Modal>
      )}
    </div>
  )
}

export default AddAccountModal
