import React, { PureComponent, Fragment } from 'react';
import { SsoIframe } from '../../components/EQL/SsoIframe';
import { EqlPages } from "../EqlPages";
import Header from '../../components/Header';

const isBrowser = typeof window !== 'undefined' && window.env;
const eqlGoPath = isBrowser ? window.env.eqlGoPath : process.env.RAZZLE_RUNTIME_EQLGO_PATH;

const iframeCheck = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export class PoRedirects extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      supplierId: this.getURIQueryValue('supplier') || '',
      productId: this.getURIQueryValue('productId') || '',
      EqlGo: this.getURIQueryValue('EqlGo') || '',
      urlRoot: this.getURIQueryValue('urlRoot') || '',
      inIframe: iframeCheck(),
    };
  }

  getURIQueryValue(key) {
    const qString = window.location.search;
    if (qString.includes(key)) {
      return qString.split(`${key}=`)[1].split('&')[0];
    }
    return undefined;
  }

  render() {
    return (
          <Fragment>
              <Header {...this.props} punchOut />
              {this.props.hasExtension !== undefined && (
                    <section id='product-view'>
                        {this.state.EqlGo ? (
                      <EqlPages
                                skipWarning
                                eqlUrl={`https://${this.state.urlRoot}${eqlGoPath}/${this.state.productId}`}
                            />
                        ) : (
                      <SsoIframe poForward fullScreen={this.props.hasExtension} />
                        )}
                                                          </section>)}
            </Fragment>
    );
  }
}

export default PoRedirects;
