import styled from 'styled-components'

const SupplierCartContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.12),
    0 0 2px 0 rgba(0, 0, 0, 0.14);
  border-radius: 0.25rem;
  margin-bottom: 1.5rem;
  max-height: 1000vh;
  width: 100%;
  &.removed {
    display: none;
  }
  .supplier-logo-outer {
    display: flex;
    align-items: center;
    p {
      margin-right: 27px;
    }
  }
  .supplier-cart-header {
    display: flex;
    padding: 0.75rem 1rem 0.75rem 0.75rem;
    background-color: #f4f5f6;
    border-bottom: 1px solid #dedfe2;
    align-items: center;
    justify-content: space-between;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    p {
      color: rgba(62, 65, 72, 0.7);
    }

    img {
      border-radius: 0.25rem;
      width: 2rem;
      height: 2rem;
      object-fit: contain;
      margin-right: 0.5rem;
    }
    .edit-cart-header {
      color: rgb(57, 99, 254);
      font-size: 12px;
      font-family: Lato;
      font-weight: bold;
      letter-spacing: 0.5px;
      line-height: 16px;
      margin-left: auto;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
  }

  .supplier-cart-total {
    display: flex;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid #dedfe2;

    .supplier-total {
      display: flex;
      align-items: center;
      min-width: 8.5rem;

      i {
        color: #ffc107;
        margin-right: 0.5rem;
      }
    }
  }

  .currency {
    display: flex;
    justify-content: flex-end;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.25rem;
  }

  .checkbox {
    margin-right: 1rem;
  }
`

const ItemRow = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 0.5rem 1rem 0;
  padding: 0.5rem 0;
  height: auto;
  max-height: 6.75rem;

  img {
    width: 3.75rem;
    height: 3.75rem;
    object-fit: contain;
    border-radius: 0.25rem;
  }
  .item-description {
    width: 100%;
    padding: 0 3rem 0 1rem;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .contracts-dropdown-container {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .contract-code-warning {
        display: flex;
        align-items: center;
        gap: 4px;

        color: #ff3d00;
        font-family: Rubik;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }
    }
  }
  p.item-name {
    display: -webkit-box;
    max-height: 2.5rem;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;
    opacity: 1;
  }

  .item-details {
    display: flex;
    width: fit-content;
    margin-left: auto;
    justify-content: flex-end;
    align-items: center;

    .item-price {
      min-width: 8.5rem;
      opacity: 1;
    }

    .item-quantity-unit {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: flex-end;

      p.unit-price {
        font-size: 0.75rem;
        margin: 0 1.25rem 0 auto;
      }

      .quantity-container {
        display: flex;
        align-items: center;

        p {
          font-size: 0.75rem;
        }
        .fa-trash-alt:hover {
          opacity: 0.8;
        }

        input {
          text-align: center;
          background: #fafafa;
          border-radius: 0.25rem;
          border: 1px solid #dedfe2;
          height: 1rem;
          width: 1.75rem;
          margin: 0 0.5rem;
          padding: 0.25rem;
          font-size: 0.75rem;

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
          }

          &[type='number'] {
            -moz-appearance: textfield;
          }

          &:focus {
            outline: none;
            border: 1px solid #3963fe;
            box-shadow: 0px 0px 4px 0px #3963fe;
          }
        }

        i {
          cursor: pointer;
          color: #969ba5;
        }
      }
    }
  }
`

export default { SupplierCartContainer, ItemRow }
