import React, { useState, useEffect } from 'react'
import { Auth0Provider } from '@auth0/auth0-react'
import { ThemeProvider } from 'styled-components'
import { Router } from './routes'
import theme from './shared/theme'
import { safeLocalStorage } from '../utils/safeStorage'
import { useFlags, withLDProvider, useLDClient } from 'launchdarkly-react-client-sdk'
import './Fonts.css'
import './App.css'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { setCookie, getRawCookie } from '../utils/cookies'
import { removeUserData } from '../utils/localStorage';

export const MobileLayout = React.createContext()
export const EnvContext = React.createContext()

const isBrowser = typeof window !== 'undefined'
const ldUserKey = isBrowser ? window.env.ldUserKey : process.env.RAZZLE_RUNTIME_LD_USER_KEY
const insightsKey = isBrowser ? window.env.insightsKey : process.env.RAZZLE_RUNTIME_INSIGHTS_KEY
const REDIRECT_DOMAIN = isBrowser ? window.env.redirectDomain : process.env.RAZZLE_RUNTIME_REDIRECT_DOMAIN

const App = (cookies) => {
  const flags = useFlags()
  const userData = safeLocalStorage.getJSONItem('userData')
  const ldClient = useLDClient()
  const isMobile = () => (isBrowser ? (window.innerWidth > 480 ? false : true) : null)
  const auth0Redirect = isBrowser ? window.location.origin : ''
  const queryString = isBrowser ? window.location.search : ''
  const urlParams = isBrowser ? new URLSearchParams(queryString) : { get: () => 'false'}

  const [isMobileLayout, setIsMobileLayout] = useState(isMobile)
  const [env, setEnv] = useState(isBrowser ? window.env : {})

  if (userData?.Email && ldClient && ldClient.getContext().email !== userData.Email) {
    const identityData = { email: userData.Email, key: userData.Id }
    console.log('App :: ldConfig :: email set, adding identity :: ', identityData)
    ldClient.identify(identityData)
  }

  if (isBrowser) {
    console.log('feature flags: ', JSON.parse(JSON.stringify(flags)))
    console.log('REDIRECTED_CHECK :: setting cookie :: window.location.origin ::', window.location.origin)
    if ((new URL(REDIRECT_DOMAIN).hostname === new URL(window.location.origin).hostname) && queryString.length > 0 && urlParams.get('redirected') === 'true' && !getRawCookie('redirected')) setCookie('redirected', true, 0, 0, 5)
  }

  const appInsights = new ApplicationInsights({
    config: {
      connectionString: insightsKey,
      /* ...Other Configuration Options... */
    },
  })
  if (insightsKey && JSON.parse(JSON.stringify(flags)).f6953UserMonitoring) {
    appInsights.loadAppInsights()
    appInsights.trackPageView()
  }

  const handleRedicrectCallback = (appState) => {
    //remove any potential old userData after returning from a new log-in
    removeUserData();
    const currentPath = safeLocalStorage.getItem('currentPath')
    console.log('handleRedicrectCallback :: valid path :: ', appState.returnTo || currentPath || '/')
    
    if(appState.returnTo && appState.returnTo.length > 1){
      //history.push didn't work here because all the necessary data wasn't being loaded
      window.location.href = appState.returnTo;
    }
  }

  useEffect(() => {
    const handleResize = () => setIsMobileLayout(isMobile)
    window.addEventListener('resize', handleResize)
    setEnv(window.env)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    window.raivenff = JSON.parse(JSON.stringify(flags))
  }, [flags])

  console.log('App :: ldClient :: user :: ', ldClient?.getContext())

  return (
    <Auth0Provider
      domain={env.auth0Domain}
      clientId={env.auth0ClientId}
      onRedirectCallback={handleRedicrectCallback}
      authorizationParams={{
        redirect_uri: auth0Redirect,
        audience: env.auth0Audience,
        responseType: 'token refresh_token user_meta_data',
        scope: 'openid profile email read:users read:user_idp_token',
      }}
    >
      <ThemeProvider theme={theme}>
        <MobileLayout.Provider value={isMobileLayout}>
          <EnvContext.Provider value={env}>
            <Router featureFlags={flags} {...cookies} />
          </EnvContext.Provider>
        </MobileLayout.Provider>
      </ThemeProvider>
    </Auth0Provider>
  )
}

const clientLDConfig = {
  clientSideID: ldUserKey,
  user: {
    key: 'example_key'
  }
}

export default withLDProvider(clientLDConfig)(App)

// export default App;
