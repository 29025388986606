import React, { PureComponent, Fragment } from 'react';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import { getBrowser } from '../../../utils/deviceDataFunctions';
import SsoIframe from '../../components/EQL/SsoIframe';

const isBrowser = typeof window !== 'undefined' && window.env;
const blobUrl = isBrowser ? window.env.blobUrl : process.env.RAZZLE_RUNTIME_BLOB_URL;

class EQLSearch extends PureComponent {
  constructor(props) {
    super(props);
    console.log(`EQLSearch :: constructor :: cart [${ typeof props.cookies.cart }] :: `, props.cookies.cart);
    this.state = {
      eqlSearchUrl: `https://demo.equallevel.com/proqure/search/results?utf8=%E2%9C%93&src=hd&category_id=all_categories&subcategory_ids=&search=${props.match.params.searchTerm}`,
    };
  }

  componentDidMount() {
    const browser = getBrowser();
    if ((browser.name === 'Chrome' && window.navigator.userAgent.indexOf('Edg') === -1) && browser.name === 'Safari') {
      this.setState({ isCompatibleBrowser: false });
    }
  }

  render() {
    return (
          <Fragment>
              <div className='container'>
                  <Header {...this.props} />
                  {(!this.state.isCompatibleBrowser && !this.state.fullscreen) ? (
                          <Modal
                              closeIcon={false}
                              setEnabled
                              darkBackground
                              maxHeight="316px"
                              maxWidth="400px"
                            >
                              <div className="incompatible-browser-modal">
                                  <img
                                      alt="qmerit-wordmark"
                                      src={`${blobUrl  }/qmerit/Qmerit-Wordmark-Indiqo.png`}
                                    />
                                  <h4>
                                      We need to open your Marketplace experience in a different window
                                    </h4>
                                  <p>
                                      Due to recent browser changes, we need to open your Qmerit
                                      Marketplace in a new window. Feel free to leave this tab open if
                                      you need to return to your Qmerit app.
                                    </p>
                                <button onClick={() => this.setState({ fullscreen: true })}>Continue</button>
                            </div>
                        </Modal>
                    ) : (
                          <main role="main">
                              <div className="flex-row cart-section EQLSearch">
                                  <SsoIframe fullScreen={this.state.fullscreen} eql forwardUrl={this.state.eqlSearchUrl} />
                                />
                            </div>
                        </main>
                    )}
                </div>
            </Fragment>
    );
  }
}

export default EQLSearch;
