import _ from 'lodash';
import fetchData from './fetchData';
import config from './apiConfig';
import { FormatNumber, FormatCart } from '../../../lib/@q-core/raiven-utils';
import { safeLocalStorage } from '../../utils/safeStorage';

export default ({ userData, getAll }) => {
    const userToken = userData.Network[userData.activeNetworkIndex].UserToken;
    const fetchAll = getAll;

    return new Promise((resolve, reject) => {
        const options = {
            ...config.getSubmittedOrders,
            bearerToken:  safeLocalStorage.getItem('token'),
            //We are going to need a paged table eventually.  We decided not to do that yet, so I'm asking
            //for 10,000 for fetchAll.  We'll want paging before we get close to that.
            url: `${config.getSubmittedOrders.url}/${userToken}/PurchaseOrder${fetchAll ? '?top=10000' : '?top=10'}`,
        };
        return fetchData(options)
            .then(data => {
                if (data && !data.ErrorCode) {
                    const formattedData = _.map(data, (order, index) => {
                        order = {
                            ...order,
                            Items: FormatCart.items(order.Items),
                        };

                        if (_.has(order, 'TotalAmount') && !_.isNil(order.TotalAmount)) {
                            order.TotalAmount = FormatNumber(order.TotalAmount);
                        }
                        return order;
                    });

                    resolve(formattedData);
                } else {
                    reject({ fetchError: true, details: data });
                }
            })
            .catch(error => {
                reject(error);
            });
    });
};
