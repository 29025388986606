const isBrowser = typeof window !== 'undefined' && window.env;
const pcsUrl = isBrowser ? window.env.pcsRootUrl : process.env.RAZZLE_RUNTIME_PCS_ROOT_URL;
const apiUrl = isBrowser ? window.env.apiRoot : process.env.RAZZLE_RUNTIME_API_ROOT;

export default {
    postAddPCard: {
        root: pcsUrl,
        method: 'post',
        url: 'api/CreditCard',
    },

    postUpdatePro: {
        root: apiUrl,
        method: 'post',
        url: 'v1/User',
    },
};
