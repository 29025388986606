import React from 'react';
// import bodymovin from 'lottie-web';
import StyledComponents from './styles';

const isBrowser = typeof window !== 'undefined' && window.env;
const { LoaderContainer } = StyledComponents;

const blobUrl = isBrowser ? window.env.blobUrl : process.env.RAZZLE_RUNTIME_BLOB_URL;

const Loader = props => {
    const { className, fullScreen, message, height, width } = props;

    // useEffect(() => {
    //     bodymovin.loadAnimation({
    //         container: document.getElementById('q-loader'),
    //         renderer: 'svg',
    //         loop: true,
    //         autoplay: true,
    //         animationData: require('./q-loader-animation.json'),
    //     });
    // }, []);

    return (
        <LoaderContainer fullScreen={fullScreen} className={className} height={height} width={width}>
            <img src={`${blobUrl}/procurement/branding/R_Loader.gif`} alt="loader" />
        {message && <h4>{message}</h4>}
      </LoaderContainer>
    );
};

export default Loader;
