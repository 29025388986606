import { useCartContext } from '../../../../../hooks/useCartContext'
import { Checkbox } from '../../../../components'
import { getAllItemsIds } from '../../helpers'
import styles from './styles'

const { CartHeaderContainer } = styles

const CartHeader = () => {
  const { state, clearSelectedItem, selectAllItem } = useCartContext()
  const { cartUniqueItemTotal, selectedItems, cart } = state

  const isChecked = selectedItems.length > 0
  const isPartial = selectedItems.length < cartUniqueItemTotal && isChecked

  const selectAll = isChecked ? [] : getAllItemsIds(cart)

  const toggleCheckbox = () => {
    ;(isPartial ? clearSelectedItem : selectAllItem)(isPartial ? [] : selectAll)
  }
  return (
    <CartHeaderContainer style={{ marginBottom: 24 }}>
      <div className="checkbox-container" onClick={toggleCheckbox}>
        <Checkbox checked={isChecked} partial={isPartial} onClick={toggleCheckbox} />
        <span className="mutted-text">Select all</span>
      </div>
      <span className="mutted-text">Select items to view options</span>
    </CartHeaderContainer>
  )
}

export default CartHeader
