import { getSupplierContractData, getSubmittedOrders } from '../../apis';

export const showInfoCard = (supplierId, state, CompanyContractId) => {
    const { setShowInfo, setSupplierInfo, suppliers } = state;
    setShowInfo(true);
    setSupplierInfo(suppliers.find(supplier => supplier.SupplierContractId === CompanyContractId));
};

export const handleSearchSubmit = (searchTerm, props) => {
    const searchUrl = `https://shop.equallevel.com/qmerit/search/results?utf8=%E2%9C%93&src=hd&category_id=all_categories&subcategory_ids=&search=${encodeURIComponent(
        searchTerm,
    )}`;
    console.log('About to navigate to EQL search: ', searchUrl);
    props.history.push(`/search/${encodeURIComponent(searchTerm)}`);
};

export const renderQplaceSuppliers = async ({ user, setSuppliers }) => {
    const result = await getSupplierContractData({ userData: user });
    setSuppliers(result.Suppliers);
};

export const setOrders = async ({ user, setRecentOrders, showAll }) => {
    console.log('setOrders', user, setRecentOrders, showAll);
    if (!showAll) {
        const result = await getSubmittedOrders({ userData: user });
        setRecentOrders(result);
    } else {
        const result = await getSubmittedOrders({ userData: user, getAll: showAll });
        setRecentOrders(result);
    }
};
