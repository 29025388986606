import { FormatCart } from '../../../lib/@q-core/raiven-utils'
import { safeLocalStorage } from '../../utils/safeStorage'
import config from './apiConfig'
import fetchData from './fetchData'

export default ({ items = [], userData }) => {
  const userToken = userData.Network[userData.activeNetworkIndex].UserToken

  return new Promise((resolve, reject) => {
    const options = {
      ...config.deleteCartItem,
      url: `${config.deleteCartItem.url}/${userToken}/Items`,
      body: items,
    }
    return fetchData(options)
      .then((data) => {
        if (data && !data.ErrorCode) {
          let stateObject = {
            ...data,
            fetchError: !!(data && data.ErrorCode),
          }
          stateObject = FormatCart.cart(stateObject)
          safeLocalStorage.setJSONItem('cart', stateObject)
          resolve(stateObject)
        } else {
          reject({ fetchError: true, details: data })
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}
