import React, { PureComponent } from 'react';
import _ from 'lodash';
import { Wrapper } from '../../components';
import RCheckout from './raiven';
import ComponentStyles from './styles';
import getDefaultCheckout from '../../../utils/getDefaultCheckout';

const { CheckoutWrapper } = ComponentStyles;

class Checkout extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      defaultCheckout: null,
    };
  }

  componentDidMount() {
    let checkout = 'eql';

    if (_.has(this, 'props.userData') && !_.isEmpty(this.props.userData)) {
      checkout = getDefaultCheckout(this.props.userData);
    }

    this.setState({
      defaultCheckout: checkout,
    });
  }

  render() {
    return (
      <CheckoutWrapper>
        <Wrapper {...this.props}>
          {/* {this.state.defaultCheckout === 'eql' && <EqlCheckout {...this.props} />} */}
          <RCheckout {...this.props} />
        </Wrapper>
      </CheckoutWrapper>
    );
  }
}

export default Checkout;
