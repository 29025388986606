import styled from 'styled-components'

const ItemRow = styled.section`
  display: flex;
  flex-direction: column;

  



  .order-detail-container {
    display: flex;
    flex-direction: row;
    padding: 1rem 0.5rem;
    box-shadow: inset 0px -1px 0px 0px rgb(237, 238, 239);
    background-color: #fafafa;
    &.closed {
      background-color: #ffffff;
    }
    i:hover{
      opacity:0.8;
      cursor: pointer;
    }
    .order-summary {
      width: 236px;
      height: 16px;
      color: rgb(62, 65, 72);
      font-size: 12px;
      font-family: Lato;
      font-weight: normal;
      letter-spacing: 0.25px;
      line-height: 16px;
      padding-right: 1rem;
      &.Date {
        width: 15.75rem;
      }
      &.Status {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 7.5rem;
      }
      &.Order {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 9rem;
        flex-grow: 1;
      }
      &.Supplier {
        width: 17rem;
      }
      &.Items {
        width: 4.5rem;
        text-align: center;
      }
      &.Subtotal {
        width: 6rem;
        text-align: right;
        margin-right: 2rem;
        font-weight: bold;
      }
      i {
        margin: 4px 8px 0 24px;
        color: #969ba5;
        font-size: 16px;
        font-weight: 300;
        height: 20px;
        line-height: 20px;
        text-align: center;
        width: 20px;
      }
    }


    .order-bold {
      font-weight: bold;
      flex-shrink
    }

  }

  .order-detail-expanded {
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem 1rem 1rem;
    margin: 0;
    background-color: #ffffff;
    .shipPOSection {
      display: flex;
      flex-direction: row;
      padding-bottom: 1rem;
      box-shadow: inset 0px -1px 0px 0px rgb(237, 238, 239);
      .shipPaymentTitle {
        display: flex;
        flex-direction: column;
        flex: 1;
        .shippingTitle {
          font-size: 14px;
          color: #6E727C;
          font-weight: 400;
          padding-bottom: 4px;
          &.POvalue {
            margin-top: 1rem;
          }
        }
        .shippingDetail {
          p {
            font-size: 16px;
            // color: rgb(110, 114, 124);
            font-family: Lato;
            font-size: 14px;
            font-weight: normal;
            height: 20px;
            letter-spacing: 0.25px;
            line-height: 20px;
            color: #3E4148;

          }
          .dotDivider {
            margin: 0 0.5rem;
          }
          .addressInfoJobNumber {
            color: rgba(0, 0, 0, 0.5);
            font-family: 'Rubik';
            font-weight: 400;
            font-size: 14px;
          }
        }
        .csvSection {
          cursor: pointer
          display: flex;
          flex-direction: row;
          margin-top: 20px;
          i {
            width: 20px;
            height: 20px;
            color: rgb(110, 114, 124);
            font-size: 16px;
            font-weight: 300;
            text-align: center;
            line-height: 20px;
            margin-right: 0.25rem;
            padding-top: 1px;
            color: #3963FE;
          }
          li {
            width: 276px;
            height: 20px;
            color: #3963fe;
            font-size: 16px;
            font-family: Lato;
            font-weight: normal;
            line-height: 20px;
            &.csvTitle:hover {
              color: #3359BA !important;
          }
          }
        }
      }
    }

    .commentSection {
      display: flex;
      flex-direction: column;
      width: 100%;
      box-shadow: inset 0px -1px 0px 0px rgb(237, 238, 239);
      padding-bottom: 1rem;
      .commentTitle {
        font-size: 14px;
        color: #6e727c;
      }

      .commentValue p {
        overflow-wrap: anywhere;
        color: #6E727C;
        padding-bottom: 4px;
      }
    }

    
    .order-summary {
      &.Date {
        width: 15.75rem;
      }
      &.Order {
        width: 7.5rem;
        flex-grow: 1;
      }
      &.Supplier {
        width: 17rem;
      }
      &.Items {
        width: 4.5rem;
        text-align: center;
      }
      &.Subtotal {
        width: 6rem;
        text-align: right;
      }
      i {
        margin: 4px 8px 0 24px;
        color: #969ba5;
        font-size: 16px;
        font-weight: 300;
        height: 20px;
        line-height: 20px;
        text-align: center;
        width: 20px;
      }
    }


    .order-bold {
      font-weight: bold;
      flex-shrink
    }

  }
  
  section {
    box-shadow: inset 0px -1px 0px 0px rgb(237, 238, 239);
  }
  .productSection {
    display: flex;
    flex-direction: row;
    padding-top: 1.5rem;
    padding-bottom: 1rem;

    img {
      width: 3.75rem;
      height: 3.75rem;
      object-fit: contain;
      border-radius: 0.25rem;
    }
    p.item-name {
      display: -webkit-box;
      margin: 0 1rem;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: left;
      flex: 2;
      max-width: 770px; 
    }
    .item-details {
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;
    }

    .contractCodeLabel {
      font-size: 12px;
      line-height: 16px;
      font-family: 'Rubik';
      color: rgba(0, 0, 0, 0.5);
      margin: 4px 1rem 0 1rem;
    }
  
    .contractCodeLabel span {
        color: rgba(0, 0, 0, 1);
    }
  }

  .subTotalSection {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 1rem;
    
    .subTotal {
      width: 64px;
      height: 20px;
      color: rgb(110, 114, 124);
      font-size: 16px;
      font-family: Lato;
      font-weight: normal;
      text-align: right;
      letter-spacing: 0.5px;
      line-height: 20px;
      margin-right: 2.75rem;
    }
    .subTotalValue {
      color: rgb(62, 65, 72);
      font-size: 1rem;
      font-weight: bold;
      text-align: right;
      line-height: 20px;
    }
  }


  .item-details {
    display: flex;
    justify-content: space-between;
    flex: 1;
    align-items: center;



    .item-quantity-unit {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      p.unit-price {
        font-size: 0.75rem;
      }

      .quantity-container {
        display: flex;
        align-items: center;

        p {
          font-size: 0.75rem;
        }

        input {
          text-align: center;
          background: #fafafa;
          border-radius: 0.25rem;
          border: 1px solid #dedfe2;
          height: 1rem;
          width: 1.75rem;
          margin: 0 0.5rem;
          padding: 0.25rem;
          font-size: 0.75rem;

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
          }

          &[type="number"] {
            -moz-appearance: textfield;
          }

          &:focus {
            outline: none;
            border: 1px solid #3963fe;
            box-shadow: 0px 0px 4px 0px #3963fe;
          }
        }

        i {
          cursor: pointer;
          color: #969ba5;
        }
      }
    }
  }
`

export default { ItemRow }
