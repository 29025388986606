import _ from 'lodash'

function checkForRaivenAndSpendbridge(userData) {
  const activeNetwork = userData.Network.find((corp) => corp.Id === userData.activeNetworkId) || { CompanySetting: '' }
  let companySetting = activeNetwork.CompanySetting
  let userSetting = activeNetwork.Setting

  if (
    companySetting &&
    typeof companySetting === 'string' &&
    companySetting.length > 0 &&
    (JSON.parse(companySetting).DefaultCheckout === 'raiven' ||
      JSON.parse(companySetting).DefaultCheckout === 'spendbridge') &&
    userSetting &&
    typeof userSetting === 'string' &&
    userSetting.length > 0 &&
    (JSON.parse(userSetting).DefaultCheckout === 'raiven' ||
      JSON.parse(userSetting).DefaultCheckout === 'spendbridge') &&
    JSON.parse(userSetting).DefaultCheckout !== JSON.parse(companySetting).DefaultCheckout
  ) {
    return true
  }
  return false
}

//  If(1){return 'raivenAndSpendbridge (4 tabs)';}
//  If(2){return 'spendbridge (2 tabs)';}
//  If(3){return 'raiven (2 tabs)';}

export const useDefaultCheckout = (userData) => {
  const activeNetwork = userData.Network.find((corp) => corp.Id === userData.activeNetworkId) || { CompanySetting: '' }

  if (checkForRaivenAndSpendbridge(userData)) {
    return 'raivenAndSpendbridge'
  }

  if (_.has(userData, 'activeUserSettings.DefaultCheckout')) {
    if (!_.isEmpty(userData.activeUserSettings.DefaultCheckout)) {
      //the user level setting takes priority over the company level
      return userData.activeUserSettings.DefaultCheckout
    }
  }

  if (_.has(userData, 'activeCorpSettings.DefaultCheckout')) {
    if (!_.isEmpty(userData.activeCorpSettings.DefaultCheckout)) {
      return userData.activeCorpSettings.DefaultCheckout
    }
  }

  if (
    activeNetwork.CompanySetting &&
    typeof activeNetwork.CompanySetting === 'string' &&
    activeNetwork.CompanySetting.length > 0 &&
    JSON.parse(activeNetwork.CompanySetting).DefaultCheckout === 'raiven'
  ) {
    return 'raiven'
  }

  return 'eql'
}


export const hasRaivenCheckout = (userData) => {
  const defaultCheckout = useDefaultCheckout(userData) 
  return defaultCheckout === 'raivenAndSpendbridge' || defaultCheckout === 'raiven'
}


