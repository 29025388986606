import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { Wrapper } from '../../components'
import { safeLocalStorage } from '../../../utils/safeStorage'
import { getSupplierContractData } from '../../apis'
import { Link } from 'react-router-dom'
import ComponentStyles from './styles'
import FeatureStyles from '../Marketplace/styles'
import AddAccountModal from '../Marketplace/addAccountModal'
import { postCorporateOfficeContract, editCorporateOfficeContract } from '../../apis'
import Item from './item'
import postAnalytics from '../../../utils/postAnalytics.js'

// import { renderQplaceSuppliers } from '../Marketplace/helpers';
const { AccountWrapper, AccountContents } = ComponentStyles
const { AddAccountModalContainer } = FeatureStyles

// const blobUrl = isBrowser ? window.env.blobUrl : process.env.RAZZLE_RUNTIME_BLOB_URL;
const getCorporateOfficeContractDetails = async ({ user, setSuppliers, setStatusOfSupplier }) => {
  await getSupplierContractData({ userData: user })
    .then((res) => {
      const suppliers = res.Suppliers
      setSuppliers(_.orderBy(suppliers, 'Name'))
      setStatusOfSupplier(false)
    })
    .catch((e) => {
      console.log(e, 'error')
    })
}
const Manage = (props) => {
  const user = safeLocalStorage.getJSONItem('userData')
  console.log('user is', user)
  const activeUserIsAdmin = user && user.activeUserSettings && user.activeUserSettings.Role === 'Admin' ? true : false
  const [suppliers, setSuppliers] = useState()
  const [statusOfSupplier, setStatusOfSupplier] = useState(false)
  const [addAccountModal, setAddAccountModal] = useState(false)
  const [supplierToEdit, setSupplier] = useState()
  const [contractToEdit, setcontractToEdit] = useState()
  const [isEdit, setEdit] = useState(false)

  // const [recentOrders, setRecentOrders] = useState(null);
  useEffect(() => {
    setStatusOfSupplier(true)
  }, [])

  useEffect(() => {
    if (statusOfSupplier || !addAccountModal || !isEdit) {
      getCorporateOfficeContractDetails({ user, setSuppliers, setStatusOfSupplier })
    }
  }, [statusOfSupplier, addAccountModal, isEdit])

  const addNewSupplier = async (contractName, accountNumber, label) => {
    const user = safeLocalStorage.getJSONItem('userData')
    const activeNetworkId = user && user.activeNetworkId
    let userToken = user.Network[user.activeNetworkIndex].UserToken
    if (contractName) {
      const response = await postCorporateOfficeContract(userToken, activeNetworkId, contractName, accountNumber, label)
        .then((res) => {
          setAddAccountModal(false)
        })
        .catch((error) => {
          console.log('error: ', error)
          return error.response.data
        })

      return response
    }
  }
  const editExistingSupplier = async (supplierToEdit, contractToEdit, accountNumber, label, SupplierContractId) => {
    const user = safeLocalStorage.getJSONItem('userData')
    let userToken = user.Network[user.activeNetworkIndex].UserToken
    const ContractId = contractToEdit.CorporateOfficeContractId
    const selectedSupplier = _.find(suppliers, ['SupplierContractId', SupplierContractId])
    const supplierId = selectedSupplier && selectedSupplier.SupplierContractId
    if (supplierId) {
      await editCorporateOfficeContract(userToken, ContractId, supplierId, accountNumber, label)
        .then((res) => {
          setEdit(false)
        })
        .catch((e) => {
          console.log(e, 'error')
        })
    }
  }

  const handleEdit = (supplierId, contractIndex, CorporateOfficeContractId) => {
    setEdit(true)
    const supplierToEdit = suppliers.find((supplier) => supplier.SupplierContractId === supplierId)

    const contractToEdit = supplierToEdit.Contracts.find(
      (contract) => contract.CorporateOfficeContractId === CorporateOfficeContractId
    )

    setSupplier(supplierToEdit)
    setcontractToEdit(contractToEdit)
  }

  const filteredSuppliers = suppliers
    ? suppliers.map((supplier) => ({
        Id: supplier.Id,
        Name: supplier.Name,
        Contracts: supplier.Contracts.map((contract) => ({
          accountNumber: contract.AccountNumber,
        })),
      }))
    : null

  return (
    <Wrapper {...props}>
      <AccountWrapper>
        <Link
          to="/marketplace"
          onClick={() => {
            postAnalytics('pm-manageAccounts-header-click-backToMarketplace')
          }}
        >
          <p className="backButton">
            <i className="fal fa-arrow-left"></i>
            Back to marketplace
          </p>
        </Link>

        <AccountContents>
          <section style={{ flexGrow: '1', marginRight: '1.5rem', width: '100%' }}>
            <div className="manage-account">
              <h2>Manage Accounts </h2>
              {activeUserIsAdmin && (
                <a
                  onClick={() => {
                    postAnalytics('pm-manageAccounts-header-click-addAccount')
                    setAddAccountModal(true)
                  }}
                >
                  <p className="manageLink">Add account</p>
                </a>
              )}
            </div>
            {addAccountModal && (
              <AddAccountModalContainer>
                <AddAccountModal
                  setAddAccountModal={setAddAccountModal}
                  addNewSupplier={addNewSupplier}
                  isEdit={isEdit}
                  setEdit={setEdit}
                />
              </AddAccountModalContainer>
            )}
            {suppliers && suppliers.length > 0 && (
              <ul>
                {_.map(
                  suppliers.sort((x, y) =>
                    x.Name.replace(/\s/g, '').toLowerCase() > y.Name.replace(/\s/g, '').toLowerCase() ? 1 : -1
                  ),
                  (supplier, index) => {
                    const Contract = { ...supplier.Contracts[0], WideLogo: supplier.WideLogo }
                    let { Contracts } = supplier
                    Contracts[0] = Contract
                    return (
                      <Item
                        key={supplier.Id + '-' + supplier.SupplierContractId}
                        supplier={{ ...supplier, Contracts }}
                        userData={props.userData}
                        setStatusOfSupplier={setStatusOfSupplier}
                        onEdit={handleEdit}
                        index={index}
                      />
                    )
                  }
                )}
              </ul>
            )}
            {isEdit && (
              <AddAccountModalContainer>
                <AddAccountModal
                  isEdit={isEdit}
                  setEdit={setEdit}
                  supplierToEdit={supplierToEdit}
                  contractToEdit={contractToEdit}
                  editExistingSupplier={editExistingSupplier}
                  filteredSuppliers={filteredSuppliers}
                />
              </AddAccountModalContainer>
            )}
          </section>
        </AccountContents>
      </AccountWrapper>
      {/* <AccountWrapper> */}
      {/* <div className='accessDenied'>

               <h2>Access denied</h2>
           <h3>Try navigating back to Qmerit's MRO Marketplace to find what you are looking for.</h3>
           <a href="/marketplace">Go to Marketplace</a>
           </div>
           </AccountWrapper> */}
    </Wrapper>
  )
}

export default Manage
