import React, { PureComponent } from 'react';
import ComponentStyles from './styles';
import { Button, Input } from '../../../../../../lib/@q-core/react-components';
import MaskInput from '../../MaskInput';

import Modal from '../../Modal';
import _ from 'lodash';
import moment from 'moment';
import { postAddPCard, postUpdatePro } from '../../../apis';
import visaLogo from './img/finance-creditcard-visa.png';
import mastercardLogo from './img/finance-creditcard-mastercard.png';
import amexLogo from './img/finance-creditcard-amex.png';
import discoverLogo from './img/finance-creditcard-discover.png';

const { AddPCardModalContainer } = ComponentStyles;

const postPCard = async (params, component) => {
    let newParams;
    await postAddPCard({ params }).then(data => {
        newParams = {
            userData: params.userData,
            Number: data.Number,
            Name: params.Name,
            Type: params.Type,
            Token: data.Token,
            ExpirationDate: data.ExpirationDate,
        };
    });
    setTimeout(() => {
        postUpdate(newParams, component);
    }, 500);
}

const postUpdate = async (newParams, component) => {
    await postUpdatePro({ newParams }).then(data => {

        let createdCardID = _.find(data, ["Token", newParams.Token]);

        let createdCard = {
            Id: createdCardID.Id,
            ExpirationDate: newParams.ExpirationDate,
            Name: newParams.Name,
            IsDefault: false,
            Number: newParams.Number,
            Token: newParams.Token,
            Type: newParams.Type,
        };

        component.props.declareSelected(createdCard, data);
})};

class AddPCardModal extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            Name: '',
            Number: '',
            Month: '',
            Year: '',
            cImg: '',
            Type: '',
            errors: [],
            errorMessage: '',
            loader: false,
            userData: props.userData,
            newData: '',
            maskFormat:  '#### #### #### #### ###',
            typeFlagged: false,
            dateError: false,
            newDefault:'',
            validExpire: 'true',
            validYear: 'true',
            validMonth: 'true',
            submittedAdd: false,
            validCard: false,
        };
        this.setErrors = this.setErrors.bind(this);
        this.validateType = this.validateType.bind(this);
    }

    toUTC(date, format) {
        let dateFormat = format;

        return moment(date).utc().format(dateFormat);
    }

    setName(value) {
        this.setState({
            Name: value,
        });
    }

    validateType(value, name) {
        let isValid = false,
            errorMessage = '',
            Name = 'Number';

        let x = value.substring(0,1);

        if(['3','4','5','6'].includes(x)) {
            isValid = true;
            this.setState({
                validCard: true,
            });
        } else {
            isValid = false;
            errorMessage = 'Invalid Card';
            this.setState({
                validCard: false,
            });
        }

        return {
            Name,
            isValid,
            errorMessage,
        };
    }


    setNumber(value, name) {
        if (value.length < 24) {
            if (value.length > 0) {
                let x = value.substring(0,1);
                switch(x) {
                    case '4':
                        this.setState({
                            cImg : visaLogo,
                            Type: 'Visa',
                            maskFormat:  '#### #### #### #### ###',
                        });
                        break;
                    case '3':
                        this.setState({
                            cImg : amexLogo,
                            Type: 'Amex',
                            maskFormat:  '#### ###### #####',
                        });
                        break;
                    case '5':
                        this.setState({
                            cImg : mastercardLogo,
                            Type: 'Mastercard',
                            maskFormat:  '#### #### #### #### ###',
                        });
                        break;
                    case '6':
                        this.setState({
                            cImg : discoverLogo,
                            Type: 'Discover',
                            maskFormat:  '#### #### #### #### ###',
                        });
                        break;
                    default:
                        this.setState({
                            cImg : '',
                            Type : '',
                        });
                        break;
                }
            } else {
                this.setState({
                    cImg : '',
                    Type : '',
                });
                this.validateType(value);
            }
            this.setState({
                Number: value,
            });
        }
    }

    setMonth = (value) => {
        if (value.length < 3) {
            this.validateMonth(value);
        //     if ((parseInt(value, 10) > 12) || (parseInt(value, 10) === 0)) {
        //         // this.validateMonth(value);
        //     } else {
        //         bool = true;
        //     }
            this.setState({
                Month: value,
                // validExpire: bool,
                // validMonth: true,
            });
            // if (this.state.Year && this.state.validYear) {
                // if you have the Year in state then calculate using this month value
                this.calcMonthYear("Month", value);
            // };
        };
    }

    validateMonth = (value, name) => {
        let isValid = true,
            Name = 'Month',
            errorMessage = '';

        if ((parseInt(value, 10) > 12) || (parseInt(value, 10) === 0)) {
            isValid = false;
            errorMessage = 'Invalid Month';
            this.setState({
                validCard: false,
                validMonth: false,
            });
        } else {
            isValid = true;
            errorMessage = '';
            this.setState({
                validCard: true,
                validMonth: true,
            });
        }
        return {
            Name,
            isValid,
            errorMessage,
        };
    }

    validateYear = (value) => {
        let isValid = true,
            Name = 'Year',
            errorMessage = '';
        if ((parseInt(value, 10) < 20) || !value) {
            isValid = false;
            errorMessage = 'Invalid Year';
            this.setState({
                validCard: false,
                validYear: false,
            });
        } else {
            isValid = true;
            errorMessage = '';
            this.setState({
                validCard: true,
                validYear: true,
            });
        }
        return {
            Name,
            isValid,
            errorMessage,
        };

    }

    setYear(value) {
        if (value.length < 3) {
            this.validateYear(value);
            this.setState({
                Year: value,
            })
                // if (this.state.Month && this.state.validMonth) {
                    // if you have the Year in state then calculate using this month value
                    this.calcMonthYear("Year", value);
                // };
                
            // }
        };
    }

    calcMonthYear(name, value) {
        let month = (name === 'Year' ? (parseInt(this.state.Month, 10) - 1) : (parseInt(value, 10) - 1)),
            year = (name === 'Year' ? (parseInt(value, 10) + 2000) : (parseInt(this.state.Year, 10)) + 2000),
            validDate = true; //true until proven it is not date is not past today

        //create new Date object based on inputed year and month
        let expireDate = new Date(`${year}`, `${month}`, 29),
                active_time = moment.utc(expireDate, 'YYYY-MM-DD[T]HH:mm[Z]'),
                d = new Date(); //compare to right now date object
            validDate = active_time.isAfter(d); //BOOLEAN is active_time after today?
        // console.log("month", month);
        // console.log("year", year);
        // console.log("validDate", validDate);
        // console.log("expiredDate", expireDate);
        // console.log("d", d);
        // console.log("validDate", validDate);

        //assign vaidDate calculated boolean
       
        this.setState({
            validExpire: validDate,
        })

    }



    setErrors = (isValid, label) => {
        if(label==='Month'){
            this.setState({
                // validExpire:!isValid,
                validMonth:!isValid,
            });

        }
        else if(label==='Year'){
            this.setState({
                // validExpire:!isValid,
                validYear:!isValid
            });

        }
     
            // this.setState({                
            //     validExpire:!this.state.validMonth || !this.state.validYear,
            // });
      
        if (!isValid) {
            this.setState({
                errors: [...this.state.errors, label],  
            });
        } else {
            this.setState({
                errors: _.filter(this.state.errors, item => {
                    return item !== label;
                }),
            });
        }
    }

    submitForm() {
        const {Month, Year, Name, Number, Type, validExpire, validCard, validMonth, validYear } = this.state;

        if (Month && Year && Name && Number && Type && validExpire && validCard && validYear && validMonth) {
            this.setState({
                submittedAdd: true,
            });
            let adjustedMonth = parseInt(Month, 10) - 1,
                expireDate = new Date(`20${Year}`, `${adjustedMonth}`, 15),
                ExpirationDate =  this.toUTC(expireDate, 'YYYY-MM-DD'),
                stripSymbols = Number.replace(/\D+/g, '');

            
            let params = {
                Name,
                ExpirationDate,
                Number: stripSymbols,
                Type,
                userData: this.state.userData,
            };
            this.props.setAddModal('false');
            postPCard(params, this);
        }
    }
    

   

    render() {
        const {Month, Year, Name, Number, Type, validExpire, validCard, validMonth, validYear } = this.state;
        return (
            <AddPCardModalContainer>
                <Modal
                    title='Add Card'
                    setEnabled={true}
                    darkBackground
                    height='fit-content'
                    setToggle={() => {
                        this.props.setAddModal('false');
                    }}
                    maxWidth='600px'
                    cardClassName='addPCardModal'
                >
                    <div className="addFormField">
                        <Input
                            Name='Name'
                            type='text'
                            label='Name on the card'
                            onChange={(e) => this.setName(e.target.value)}
                            errors={this.state.errors}
                            setErrors={(isValid,label) => this.setErrors(isValid,label)}
                        />
                    </div>
                    <div className="addFormField">
                        <MaskInput
                            Name='Number'
                            maskingType='card'
                            className='masked'
                            mask={this.state.maskFormat}
                            required
                            label='Card Number'
                            errors={this.state.errors}
                            setErrors={(isValid,Name) => this.setErrors(isValid,Name)}
                            onChange={(e) => this.setNumber(e.target.value)}
                            imageRight={this.state.cImg}
                            validate={this.validateType}
                            validateOnChange={true}
                        />
                        
                    </div>
                    <p className='expireSection'>Expiration Date</p>
                    <div className={"expireField"}>
                        <div className="fieldContain">
                            <MaskInput
                                Name="Month"
                                className="pCardMonth"
                                placeholder="MM"
                                mask='##'
                                type='text'
                                width='3.5rem'
                                required={false}
                                value={this.state.Month}
                                errors={this.state.errors}
                                setErrors={(isValid,Name) => this.setErrors(isValid,Name)}
                                onChange={(e) => this.setMonth(e.target.value)}
                                validate={this.validateMonth}
                                validateOnChange={true}

                            />

                        </div>

                        <span className='addDash'>&ndash;</span>
                        <div className="fieldContain">
                            <MaskInput
                                Name="Year"
                                placeholder="YY"
                                className="pCardYear"
                                mask='##'
                                type='text'
                                width='3rem'
                                required={false}
                                errors={this.state.errors}
                                setErrors={(isValid,Name) => this.setErrors(isValid,Name)}
                                onChange={(e) => this.setYear(e.target.value)}
                                validate={this.validateYear}
                                validateOnChange={true}
                            />
                        </div>
                    </div>

                    { (!this.state.validExpire || !this.state.validMonth || !this.state.validYear ) &&
                        <div className="dateError">
                            <i className='far fa-exclamation-circle' />
                            <p>Enter a valid expiration date</p>
                        </div>
                    }
                    { !this.state.validMonth &&
                        <div className="dateError">
                            <i className='far fa-exclamation-circle' />
                            <p>Enter a valid month.</p>
                        </div>
                    }
                    { !this.state.validYear &&
                        <div className="dateError">
                            <i className='far fa-exclamation-circle' />
                            <p>Enter a valid year.</p>
                        </div>
                    }

                    <div className="buttonContainer">
                        <Button
                            shape='square'
                            title='Cancel'
                            type="default"
                            onClick={() => this.props.setAddModal('false')}
                            className="cancel-btn"
                        />
                        <Button
                            type='indigoVibrant'
                            disabled={!Month || !Year || !Name || !Number || !Type || !validExpire || !validCard || !validYear || !validMonth}
                            shape='square'
                            title='Save'
                            onClick={() => {
                                this.submitForm()
                                this.setState({
                                    submittedAdd: true,
                                });
                            }}
                        />
                    </div>
                    
                </Modal>
            </AddPCardModalContainer>
        );
    }
}

export default AddPCardModal;