"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.playAnimation = void 0;

var _gsap = _interopRequireDefault(require("gsap"));

var openAnimation = function openAnimation(blurBackground) {
  var blurElement = {
    a: 0
  };

  var cardAnimation = _gsap["default"].timeline({
    paused: true,
    onComplete: function onComplete() {
      _gsap["default"].killTweensOf('.modal-container');
    },
    onStart: function onStart() {
      _gsap["default"].set('.modal-container', {
        display: 'flex'
      });

      _gsap["default"].set('.modal-card', {
        opacity: 0
      });
    }
  });

  if (blurBackground) {
    cardAnimation.to(blurElement, 0.3, {
      a: 9,
      onUpdate: function onUpdate() {
        _gsap["default"].set('.modal-container', {
          'backdrop-filter': "blur(".concat(blurElement.a, "px)"),
          backgroundColor: 'rgba(237, 238, 239, 0.5)'
        });
      }
    });
  }

  cardAnimation.to('.modal-card', {
    opacity: 1,
    duration: 0.3
  });
  return cardAnimation;
};

var closeAnimation = function closeAnimation(blurBackground, setToggle) {
  var blurElement = {
    a: 8
  };

  var cardAnimation = _gsap["default"].timeline({
    paused: true,
    onComplete: function onComplete() {
      _gsap["default"].set('.modal-container', {
        display: 'none'
      });

      _gsap["default"].killTweensOf('.modal-container');

      if (setToggle) setToggle(false);
    },
    onStart: function onStart() {
      _gsap["default"].set('.modal-card', {
        opacity: 1
      });

      _gsap["default"].set('.modal-container', {
        display: 'flex'
      });
    }
  });

  cardAnimation.to('.modal-card', {
    opacity: 0,
    duration: 0.3
  });

  if (blurBackground) {
    cardAnimation.to(blurElement, 0.3, {
      a: 0,
      onUpdate: function onUpdate() {
        _gsap["default"].set('.modal-container', {
          'backdrop-filter': "blur(".concat(blurElement.a, "px)"),
          backgroundColor: 'rgba(237, 238, 239, 0.5)'
        });
      }
    });
  }

  return cardAnimation;
};

var playAnimation = function playAnimation(_ref) {
  var toggle = _ref.toggle,
      blurBackground = _ref.blurBackground,
      setToggle = _ref.setToggle;
  if (toggle) openAnimation(blurBackground).play();else closeAnimation(blurBackground, setToggle).play();
};

exports.playAnimation = playAnimation;