import React, { Fragment } from 'react';
import Message from '../message';
import Header from '../Header';

const NotFound = props => (
  <Fragment>
      <Header {...props} />

      <main role="main" className="flex-row">
      <article>
              <section id="cart-sent-tab" className="tab-content">
          <Message>
                        <h1>Oops, this page isn't available.</h1>
                        <p>Try navigating back to the Raiven Marketplace to find what you are looking for.</p>
                      <a href="/marketplace">Go to the Raiven Marketplace</a>
                    </Message>
                </section>
            </article>
    </main>
    </Fragment>
);

export default NotFound;
