import fetchData from './fetchData'
import config from './apiConfig'

export default (data) =>
  new Promise((resolve, reject) => {
    const options = {
      ...config.postAddress,
      url: `${config.postAddress.url}/${data.userToken}`,
      body: {
        // Id: '',
        ExternalId: '',
        Name: data.company,
        Description: data.company,
        Street1: data.street,
        Street2: data.addressLine2,
        City: data.city,
        State: data.state,
        PostalCode: data.zip,
        Country: 'US',
        Attention: '',
        DeliverTo1: '',
        DeliverTo2: '',
        Email: null,
        Phone: null,
        Fax: null,
        Url: null,
        AddressTypeId: data.addressType === 'Billing' ? 1 : data.addressType === 'Shipping' ? 2 : 3,
        // CorporateOfficeId: 0,
        AdminPortalCorporateOfficeId: data.CorporateOfficeUserId,
        IsActive: true,
        // UserId: 0
      },
    }
    return fetchData(options)
      .then((data) => {
        if (data && !data.ErrorCode) {
          resolve(data)
        } else {
          reject({ fetchError: true, details: data })
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
