import React, { Fragment } from 'react';
import { useAuth0 } from '@auth0/auth0-react'
import { ProcurementRoutes } from './secure';
import Error from '../features/Error';

export const Router = (props) => {
    const { featureFlags, cookies } = props;
    const { isLoading, error } = useAuth0();

    if (isLoading) return <div></div>;

    if (error) return <Error featureFlags={featureFlags} />;

    return (
        <Fragment>
            <ProcurementRoutes featureFlags={featureFlags} {...cookies} />
        </ Fragment>
    )
}

// ==================================================
// Auth0 interactions example components:
// ==================================================

// function LoginButton() {
//     const {
//         isAuthenticated,
//         loginWithRedirect,
//     } = useAuth0();

//     return !isAuthenticated && (
//         <button onClick={loginWithRedirect}>Log in</button>
//     );
// }

// function LogoutButton() {
//     const {
//         isAuthenticated,
//         logout,
//     } = useAuth0();

//     return isAuthenticated && (
//         <button onClick={() => {
//             logout({
//                 logoutParams: {
//                     returnTo: window.location.origin
//                 }
//             });
//         }}>Log out</button>
//     );
// }

// function Profile() {
//     const { user } = useAuth0();

//     return <div>Hello {user.name}</div>;
// }

export default Router;
