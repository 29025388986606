const SquareMinusIcon = () => {
  return (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 1C13.0938 1 14 1.90625 14 3V13C14 14.125 13.0938 15 12 15H2C0.875 15 0 14.125 0 13V3C0 1.90625 0.875 1 2 1H12ZM4.25 7.25C3.8125 7.25 3.5 7.59375 3.5 8C3.5 8.4375 3.8125 8.75 4.25 8.75H9.75C10.1562 8.75 10.5 8.4375 10.5 8C10.5 7.59375 10.1562 7.25 9.75 7.25H4.25Z"
        fill="#2F55FE"
      />
    </svg>
  )
}

export default SquareMinusIcon
