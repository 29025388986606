import { eraseCookie } from '../utils/cookies';

export const getItem = (itemName) => {
    try {
        const localItem = JSON.parse(localStorage.getItem(itemName))
        return { localItem }
    } catch (error) {
        return { error: error }
    }
}

export const removeUserData = () => {
    localStorage.removeItem("userData");
    localStorage.removeItem("suppliers");
    eraseCookie("cart");
    eraseCookie("companyId");
    eraseCookie('loggedIn');
}