import React, { useState, useEffect } from "react";
import { playAnimation } from "./helpers";
import ComponentStyles from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const { ModalContainer, Card, TitleContainer } = ComponentStyles;

export default (props) => {
  const {
    setEnabled = false,
    toggle,
    children,
    blurBackground = true,
    closeIcon = true,
    containerClassName,
    cardClassName,
    title,
    height,
    width,
    maxHeight,
    maxWidth,
    mattsStyle = false,
    setToggle,
    darkBackground,
  } = props;
  const [loaded, setLoaded] = useState(false);
  const containerName = `modal-container ${containerClassName}`;
  const cardName = `modal-card ${cardClassName}`;

  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (loaded) {
      if (setEnabled) {
        setTimeout(() => {
          playAnimation({
            toggle: true,
            blurBackground,
            setToggle,
            darkBackground,
          });
        }, 500);
      } else {
        playAnimation({
          toggle,
          blurBackground,
          setToggle,
          darkBackground,
        });
      }
    }
  }, [toggle, loaded]);

  return (
    <ModalContainer className={containerName} darkBackground={darkBackground}>
      <Card
        className={cardName}
        height={height}
        width={width}
        maxHeight={maxHeight}
        maxWidth={maxWidth}
      >
        <TitleContainer>
          {title && <h3>{title}</h3>}
          {closeIcon && (
            <span
              className={`${mattsStyle ? "matts-style" : ""}`}
              data-scorpius-automation="popupcard-closeicon"
              onClick={() => setToggle(false)}
              style={{ cursor: "pointer", opacity: 0.5 }}
            >
              <FontAwesomeIcon icon={faTimes} color="#000000" />
            </span>
          )}
        </TitleContainer>
        <div className="modal-children" style={{ display: "flex" }}>
          {children}
        </div>
      </Card>
    </ModalContainer>
  );
};
