export const getModel = () => {
  if (window.navigator.userAgent.indexOf("iPhone") !== -1) return "iPhone";
  if (window.navigator.userAgent.indexOf("iPad") !== -1) return "iPad";
  if (window.navigator.userAgent.indexOf("iPod") !== -1) return "iPod";
  if (window.navigator.userAgent.indexOf("Android") !== -1) return "Android";
  return "Desktop";
};

export const getBrowserVersion = (browser) => {
  if (browser === "IE" && window.navigator.userAgent.split("MSIE ")[1])
    return window.navigator.userAgent.split("MSIE ")[1].split(";")[0];
  if (window.navigator.userAgent.split(`${browser}/`)[1])
    return window.navigator.userAgent.split(`${browser}/`)[1].split(" ")[0];
  return "Unknown";
};

export const getBrowser = () => {
  const isOpera =
    (!!window.opr && !!window.opr.addons) ||
    !!window.opera ||
    window.navigator.userAgent.indexOf(" OPR/") >= 0;
  const isFirefox = typeof InstallTrigger !== "undefined";
  var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const isIE = /* @cc_on!@ */ false || !!document.documentMode;
  const isEdge = !isIE && !!window.StyleMedia;
  const isChrome =
    !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
  const isEdgeChromium = isChrome && navigator.userAgent.indexOf("Edg") !== -1;
  const isBlink = (isChrome || isOpera) && !!window.CSS;

  if (isOpera) return { name: "Opera", version: null };
  if (isFirefox)
    return { name: "Firefox", version: getBrowserVersion("Firefox") };
  if (isSafari) return { name: "Safari", version: getBrowserVersion("Safari") };
  if (isIE) return { name: "IE", version: getBrowserVersion("IE") };
  if (isEdge) return { name: "Edge", version: getBrowserVersion("Edge") };
  if (isChrome) return { name: "Chrome", version: getBrowserVersion("Chrome") };
  if (isEdgeChromium)
    return { name: "EdgeChromium", version: getBrowserVersion("Edge") };
  if (isBlink) return { name: "isBlick", version: null };
  return { name: "Unknown", version: null };
};

export const getOS = () => {
  const isBrowser = typeof window !== "undefined" && window.navigator;

  if (isBrowser) {
    if (/iPad|iPhone|iPod/.test(window.navigator.userAgent)) {
      return "iOS";
    }
    if (/Android/.test(window.navigator.userAgent)) {
      return "Android";
    }
    if (window.navigator.platform.indexOf("Mac") !== -1) {
      return "Mac OS";
    }
    if (window.navigator.platform.indexOf("Win") !== -1) {
      return "Windows";
    }
    if (window.navigator.platform.indexOf("Xll") !== -1) {
      return "UNIX";
    }
    if (window.navigator.platform.indexOf("Linux") !== -1) {
      return "Linux";
    }
    return "Unknown";
  }
  return "Unknown";
};
