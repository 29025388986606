"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "FormatNumber", {
  enumerable: true,
  get: function get() {
    return _formatNumber["default"];
  }
});
Object.defineProperty(exports, "FormatString", {
  enumerable: true,
  get: function get() {
    return _formatString["default"];
  }
});
Object.defineProperty(exports, "ParseUrl", {
  enumerable: true,
  get: function get() {
    return _parseUrl["default"];
  }
});
Object.defineProperty(exports, "FormatCart", {
  enumerable: true,
  get: function get() {
    return _formatCart["default"];
  }
});

var _reactDom = _interopRequireDefault(require("react-dom"));

var _formatNumber = _interopRequireDefault(require("./formatNumber"));

var _formatString = _interopRequireDefault(require("./formatString"));

var _parseUrl = _interopRequireDefault(require("./parseUrl"));

var _formatCart = _interopRequireDefault(require("./formatCart"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

if (typeof window !== 'undefined') {
  var WebFont = require('webfontloader');

  WebFont.load({
    google: {
      families: ['Lato:300,400,700', 'Montserrat:500,700', 'La+Belle+Aurore:300,400']
    }
  });
}