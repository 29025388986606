import React, { PureComponent, Fragment } from "react";
import _ from "lodash";
import "./supplierInfoCard.css";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const isBrowser = typeof window !== "undefined" && window.env;
const blobRoot = isBrowser
  ? window.env.blobUrl
  : process.env.RAZZLE_RUNTIME_BLOB_URL;
class SupplierInfoCard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeType: this.props.supplier
        ? this.props.supplier.Metadata[0].Type
        : "",
      metaDataContent: {},
    };
    this.handleInfoShow = this.handleInfoShow.bind(this);
  }

  handleInfoShow(newActiveType, event) {
    this.setState({ activeType: newActiveType });
  }

  componentDidMount() {
    const { supplier } = this.props;
    supplier.Metadata.forEach((contractSection) => {
      const unstyledData = `${blobRoot}/${contractSection.Url}`;
      axios(unstyledData)
        .then((res) => {
          if (res && res.data) {
            const updatedMetaData = Object.assign(
              {},
              this.state.metaDataContent
            );
            updatedMetaData[contractSection.Type] = res.data;
            this.setState({ metaDataContent: updatedMetaData });
          }
        })
        .catch((err) => {
          console.log(`Error${err}`);
        });
    });
  }

  render() {
    const { supplier } = this.props;
    const { metaDataContent } = this.state;
    const hasContactInfo =
      supplier.ContactName ||
      supplier.ContactPhoneNumber ||
      supplier.ContactEmail ||
      supplier.Contracts;

    if (!this.props.showCard) return <Fragment></Fragment>;

    return (
      <div className="supplier-info-card-wrapper">
        <aside className="supplier-info-card">
          <header>
            <img src={`${blobRoot}/${supplier.IconLogo}`} alt="supplier logo" />
            <h1>{supplier.Name}</h1>
            <span
              className="fal fa-times"
              onClick={this.props.hideCard}
              style={{ cursor: "pointer", opacity: 0.5 }}
            >
              <FontAwesomeIcon icon={faTimes} color="#000000" />
            </span>
          </header>
          {/* {supplier.AccountNumber || hasContactInfo ? (
                      <section className='account-info-container'>
                          {supplier.AccountNumber && (
                              <div className='account-number'>
                                  <label>Account Number</label>
                                  <p>{supplier.AccountNumber}</p>
                              </div>
                          )} */}
          {hasContactInfo && (
            <section className="account-info-container">
              {!_.isEmpty(supplier.Contracts) && (
                <div>
                  <div className="account-number">
                    <label>Account</label>
                    {_.map(supplier.Contracts, (contract, index) => {
                      let { IsVisible } = contract;
                      if (IsVisible) {
                        return (
                          <p key={contract.AccountNumber}>
                            {contract.AccountNumber}{" "}
                            <span>
                              {contract.AccountLabel &&
                                `"${contract.AccountLabel}"`}
                            </span>
                          </p>
                        );
                      }
                    })}
                  </div>
                </div>
              )}
              {(supplier.ContactName || supplier.ContactPhoneNumber) && (
                <div className="Supplier Contact">
                  <label>Supplier Contact</label>
                  {supplier.ContactName && <p>{supplier.ContactName}</p>}
                  {supplier.ContactPhoneNumber && (
                    <a
                      className="supplier-phone"
                      href={`tel:${supplier.ContactPhoneNumber}`}
                    >
                      {/* <i className='far fa-phone-alt' /> */}
                      {supplier.ContactPhoneNumber}
                    </a>
                  )}
                  {supplier.ContactEmail && (
                    <a
                      className="supplier-email"
                      href={`mailto:${supplier.ContactEmail}`}
                    >
                      {/* <i className='far fa-envelope' /> */}
                      {supplier.ContactEmail}
                    </a>
                  )}
                </div>
              )}
            </section>
          )}
          {/* </section> */}
          {/* ) : (
                      <span className='line' />
                  )} */}

          <main>
            <nav>
              {supplier.Metadata.map((contractSection) => (
                <p
                  className={
                    this.state.activeType === contractSection.Type
                      ? "active"
                      : "inActive"
                  }
                  onClick={(e) => this.handleInfoShow(contractSection.Type, e)}
                  key={contractSection.Type}
                  href={`#${contractSection.Type}`}
                >
                  {contractSection.Type}
                </p>
              ))}
            </nav>

            <section>
              {supplier.Metadata.map((contractSection) => {
                const metaContent = metaDataContent[contractSection.Type] || "";
                return (
                  <div
                    key={contractSection.Type}
                    className={
                      this.state.activeType === contractSection.Type
                        ? "content-wrapper"
                        : "content-wrapper hidden"
                    }
                  >
                    {/* <h3>{contractSection.Type}</h3> */}
                    <div className="iframe-container">
                      <div className="markdown-container">
                        <ReactMarkdown
                          width="100%"
                          height="340px"
                          frameBorder="0"
                          allowFullScreen
                          overflow="scroll"
                          linkTarget="_blank"
                          children={metaContent}
                          remarkPlugins={[gfm]}
                          rehypePlugins={[rehypeRaw]}
                        />
                      </div>
                    </div>
                    {/* <div
                                          className='innerHtmlCSS'
                                          key={contractSection.Type}
                                          id={contractSection.Type}
                                          dangerouslySetInnerHTML={{ __html: unstyledData}}
                                      /> */}
                  </div>
                );
              })}
            </section>
          </main>
        </aside>
      </div>
    );
  }
}

export default SupplierInfoCard;
