import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import styles from './style.module.css';
import { MobileLayout } from '../../App';

const RequestsHeader = ({ activeUser }) => {
  const isMobile = useContext(MobileLayout)
  const companyName = activeUser.company && activeUser.company.name ? activeUser.company.name : undefined

  const isBrowser = typeof window !== 'undefined' && window.env
  const blobUrl = isBrowser ? window.env.blobUrl : process.env.RAZZLE_RUNTIME_BLOB_URL

  return (
    <div className={styles.headerWrapper}>
      <div className={styles.header}>
        <Link to='/' onClick={(e) => isMobile && e.preventDefault()}>
          <div className={styles.backButton}>
            {!isMobile && <FontAwesomeIcon icon={faChevronLeft} />}
            {isMobile ?
              <img src={`${blobUrl}/procurement/branding/Raiven-Wordmark-Gradient-Black.png`} alt='logo'></img>
            :
              <img src='/images/Logo.png' alt='logo' />
            }
            {!isMobile && <span>Back to Marketplace</span>}
          </div>
        </Link>
        {!!companyName && <span className={styles.companyName}>{companyName}</span>}
      </div>
    </div>
  );
};

export default RequestsHeader;
