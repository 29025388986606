import apiConfig from "./apiConfig";
import _ from 'lodash';
import fetchData from "./fetchData";
import { getBrowser, getModel, getOS } from "./deviceDataFunctions";
import { safeLocalStorage } from "./safeStorage";

const profile = safeLocalStorage.getJSONItem('userData');
const getUserToken = profile => {
    let userToken;
    if (!_.isNil(profile) && _.has(profile, 'Network') && _.isArray(profile.Network)) {
        profile.Network = _.map(profile.Network, (company, index) => {
            if (company.Id === profile.activeNetworkId) {
                if (_.has(company, 'UserToken') && !_.isNil(company.UserToken)) {
                    userToken = company.UserToken;
                }
            }
            return company;
        });
        if (_.isNil(userToken)) {
            if (!_.isNil(profile.Network[0], 'UserToken')) {
                userToken = profile.Network[0].UserToken;
            }
        }
    }
    return userToken;
};

const setData = (action, analyticsData) => {
  const browser = getBrowser();
  const user = JSON.parse(safeLocalStorage.getItem("userData")) || {};
  const userToken = getUserToken(profile);
  return {
    browser: browser.name,
    browserVersion: browser.version,
    operatingSystem: getOS(),
    platform: "Qmerit-Marketplace",
    url: window.location.href,
    timestamp: new Date().toISOString(),
    action: action,
    device: getModel(),
    data: analyticsData,
    userId: user.Id,
    userToken: userToken,
    activeNetworkId: user.activeNetworkId,
  };
};

export default (action, data) => {
  const bearerToken = safeLocalStorage.getItem("bearerToken");
  const isBrowser = typeof window !== "undefined" && window.env;
  const apiUrl = isBrowser
    ? window.env.apiRoot
    : process.env.RAZZLE_RUNTIME_API_ROOT;
  const apiKey = isBrowser
    ? window.env.apiKey
    : process.env.RAZZLE_RUNTIME_API_KEY;

  const config = apiConfig(apiUrl);
  return new Promise((resolve, reject) => {
    const bodyData = setData(action, data);
    const url = `${config.postAnalytics.url}`;
    const options = {
      ...config.postAnalytics,
      body: bodyData,
      url,
      bearerToken,
      apiKey,
    };
    return fetchData(options)
      .then((data) => {
        if (data && !data.ErrorCode) {
          const stateObject = {
            ...data,
            fetchError:
              data.fetchedData && data.fetchedData.ErrorCode ? true : false,
          };
          resolve(stateObject);
        } else {
          reject({ fetchError: true, details: data });
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
