import styled from 'styled-components'

export const ContractCodesModalContainer = styled.div`
  display: flex;
  flex-direction: column;

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 16px;
    min-width: 350px;
  }

  .dropdown-container {
    padding-left: 8px;
    padding-right: 8px;
  }

  .close-icon {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.25);
  }
`
