import { useState } from 'react'
import { DropdownBodyContainer } from './styles'

export const DropdownBody = ({ position = 'relative', options = [], selected, onSelect, borderRadius }) => {
  const [hoveredOption, setHoveredOption] = useState(null)
  const [localOptions, setLocalOptions] = useState(options)
  const [search, setSearch] = useState('')
  const onMouseEnter = (id) => {
    setHoveredOption(id)
  }
  const onMouseLeave = () => {
    setHoveredOption(null)
  }

  const onClick = (selectedOption) => {
    onSelect(selectedOption)
  }

  const handleSearch = ({ target }) => {
    const value = target.value
    setSearch(value)
    if (value === '') {
      setLocalOptions(options)
    } else {
      setLocalOptions(
        options.filter((option) => `${option.id} - ${option.label}`.toLowerCase().includes(value.toLowerCase()))
      )
    }
  }

  return (
    <DropdownBodyContainer position={position} borderRadius={borderRadius}>
      <input type="text" placeholder="Search..." className="search-input" value={search} onChange={handleSearch} />
      <div className="options-container">
        {localOptions.map((option) => (
          <div
            className={`option ${
              (selected && selected.id === option.id) || option.id === hoveredOption ? 'selected' : ''
            }`}
            key={`dropdown-option-${option.id}`}
            onMouseEnter={() => onMouseEnter(option.id)}
            onMouseLeave={onMouseLeave}
            onClick={() => onClick(option)}>
            <span>{option.label}</span>
            {selected && selected.id === option.id && <i className="fas fa-check check-mark" />}
          </div>
        ))}
      </div>
    </DropdownBodyContainer>
  )
}
