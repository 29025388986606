import styled from 'styled-components';

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.header`
  display: flex;
  width: 100%;
  height: 3rem;
  align-items: center;
  background: ${({ theme }) => theme.auroraThemeBackgroundColor};
  color: rgb(${({ theme }) => theme.auroraThemeTextColor});
  z-index: 10000;
  position: relative;

  .remove-icon {
    &:hover {
      opacity: 0.8 !important;
    }
  }

  .button.cart-icon {
    margin-right: 1em;

    &:focus {
      outline: none;
    }
  }

  a.marketplace-icon {
    display: flex;
    margin-left: 1rem;
    margin-right: auto;

    img {
      height: 1rem;
      object-fit: contain;
      margin-right: 0.325rem;
    }
    p {
      color: #172d87;
      font-family: Montserrat;
      font-size: 0.75rem;
      font-weight: 600;
      height: 1rem;
      letter-spacing: 0.5px;
      line-height: 1rem;
    }
  }

  .button {
    display: flex;
    align-items: center;
    border: none;
    margin-right: 1rem;
    background-color: transparent;
    position: relative;

    &.user {
      color: rgb(${({ theme }) => theme.auroraThemeTextColor}, 0.7);

      &:hover {
        color: rgb(${({ theme }) => theme.auroraThemeTextColor});
      }

      // i {
      //     color: #3963fe;
      // }
    }

    // &.cart-icon i {
    //     color: #3963fe;
    // }

    &.active {
      cursor: unset;
      p,
      i {
        position: relative;
        color: white;
        z-index: 1;
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: rgba(57, 99, 254, 0.5);
        mix-blend-mode: multiply;
        z-index: 1;
        border-radius: 8px;
      }
    }

    .cart-count {
      display: none;

      &.active {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 4px;
        margin-right: 4px;
        text-align: center;
        height: 16px;
        min-width: 16px;
        opacity: 1;
        background-color: #ffffff;
        color: #3963fe;
        border-radius: 8px;
        font-size: 11px;
        line-height: 16px;
        font-weight: bold;
        box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.14),
          0px 2px 2px 0px rgba(0, 0, 0, 0.12),
          0px 1px 3px 0px rgba(0, 0, 0, 0.2);
      }
    }
  }

  .mini-cart-component {
    position: fixed;
    z-index: 99;
    top: 2.5rem;
    right: 1rem;
    outline: none;
  }

  .dropdown {
    margin: auto 1rem auto 0px;
  }
`;

const SpendbridgeEmptyCart = styled.div`
  margin-top: 1rem;
  text-align: center;
  font-size: 0.875rem;
  color: rgba(62, 65, 72, 0.7) !important;

  a {
    color: #3963fe !important;
    font-weight: normal !important;
    font-size: 0.875rem !important;
    height: auto !important;
    line-height: 1.25rem !important;
  }
`;

export default { HeaderContainer, HeaderWrapper, SpendbridgeEmptyCart };

//  header .button p {
//     display: flex;
//     font-family: Montserrat;
//     font-size: 12px;
//     font-weight: normal;
//     line-height: 16px;
//     letter-spacing: 0.5px;
//     color: rgb(var(--aurora-theme-text-color), 0.7);
//   }

//   header .button i {
//     color: rgb(var(--aurora-theme-text-color), 0.7);
//   }

//   header .button i.active {
//     color: #ffffff;
//   }

//   header .button .cart-count {
//     display: none;
//   }

//   header .button i.active {
//   }

//   header .button .cart-count.active {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     padding-left: 1px;
//     margin-right: 4px;
//     text-align: center;
//     height: 16px;
//     min-width: 16px;
//     opacity: 1;
//     background-color: #ffffff;
//     color: #3963fe;
//     border-radius: 8px;
//     font-size: 11px;
//     line-height: 16px;
//     font-weight: bold;
//     box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.14),
//       0px 2px 2px 0px rgba(0, 0, 0, 0.12), 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
//   }

//   header .button.cart-icon {
//     margin-right: 32px;
//   }

//   header .mini-cart-component {
//     position: absolute;
//     z-index: 99;
//     top: 2.5rem;
//     right: 1rem;
//   }

//   header .stores .stores-selector {
//     display: flex;
//     align-items: center;
//   }

//   header .stores .label-container {
//     display: none;
//   }

//   header .stores p {
//     margin-right: 4px;
//   }

//   header .stores ul {
//     width: fit-content;
//     border-radius: 8px;
//     width: 15rem;
//     margin-top: 12px;
//     margin-left: -16px;
//   }

//   header .stores li {
//     font-size: 14px;
//     font-weight: normal;
//   }

//   header .stores li:first-child {
//     border-radius: 8px 8px 0 0;
//   }

//   header .stores li:last-child {
//     border-radius: 0 0 8px 8px;
//   }

//   header:after {
//     position: relative;
//     content: "";
//     height: 3rem;
//   }
