import styled from 'styled-components'

export const BulkPanelContainer = styled.div`
  position: sticky;
  bottom: 24px;
  z-index: 99;
  display: flex;
  justify-content: center;

  .panel {
    width: fit-content;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.9) 100%);
    padding: 16px;
  }

  .panel-leading {
    display: flex;
    align-items: center;
    gap: 443px;
  }

  .options-text {
    color: rgba(255, 255, 255, 0.5);
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.32px;
  }

  .selected-items {
    color: #fff;
    text-align: center;
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .actions-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    width: max-content;
  }

  .items-count-container {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .close-icon {
    color: rgba(255, 255, 255, 0.25);
    cursor: pointer;
  }
`
