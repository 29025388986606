import styled from 'styled-components'

const MarketplaceContainer = styled.main`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;

  .eql-search {
    width: 80%;
    margin: 0 1.5rem 3rem;
    max-width: 37.5rem;

    input {
      cursor: text;
    }
  }

  .q-place-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    width: fit-content;
    position: relative;
  }

  .nav-sidebar {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 25rem;

    ul.nav-icons {
      display: flex;
      height: fit-content;
      justify-content: flex-end;
      margin: 0 0 1.5rem;
      min-height: 1.25rem;

      li {
        display: flex;
        align-items: center;
        margin-left: 3rem;
        cursor: pointer;

        i {
          color: #969ba5;
          margin-right: 0.5rem;
        }
        p {
          font-weight: bold;
        }
      }
    }

    img.ad-image {
      height: 12.5rem;
      width: 100%;
      object-fit: contain;
      border-radius: 0.75rem;
      cursor: pointer;
    }

    .stores-nav {
      margin-top: 1.5rem;
      ul {
        padding-left: 1.5rem;
        li {
          display: flex;
          align-items: center;
          margin-bottom: 1.5rem;

          img {
            background: linear-gradient(-180deg, rgb(57, 99, 254) 0%, rgb(47, 85, 254) 100%);
            border-radius: 4px;
            min-width: 4.5rem;
            box-shadow:
              0px 0px 2px 0px rgba(0, 0, 0, 0.14),
              0px 2px 2px 0px rgba(0, 0, 0, 0.12),
              0px 1px 3px 0px rgba(0, 0, 0, 0.2);
            height: 2.25rem;
            padding: 0.75rem;
            margin-right: 1.5rem;
            object-fit: contain;
            cursor: pointer;
          }

          img:hover {
            background: linear-gradient(-180deg, rgb(57, 99, 254) 0%, rgb(43, 77, 172) 100%);
          }

          p {
            font-weight: bold;
            cursor: pointer;
          }
        }
      }
    }
  }
`

const AddAccountModalContainer = styled.div`
  .edit-text {
    padding-bottom: 16px;
    text-align: center;
    p {
      color: #6e727c;
      font-style: italic;
      font-size: 14px;
      margin-bottom: 48px;
    }
    button {
      margin-top: 48px;
      background: linear-gradient(-180deg, rgb(244, 245, 246) 0%, rgb(237, 238, 239) 100%);
      border-radius: 8px;
      border: 1px solid rgb(237, 238, 239);
      height: 40px;
      width: 276px;
      font-size: 12px;
      font-weight: bold;
      color: #ff5722;
      outline: none;
    }
  }
  .addAccountModal {
    display: flex;
    padding: 16px;
    margin: 0px;
    min-height: 24rem;
    max-height: 100vh;
    .modalInner {
      padding: 0px 8px;
    }
    @media (max-width: 1153px) {
      margin-top: 20px;
    }
    .fal.fa-times:hover {
      opacity: 0.8;
    }

    h3 {
      font-size: 1rem;
      line-height: 1.25rem;
      margin-bottom: 16px;
    }

    .addFormField {
      margin-top: 1rem;
      width: 100%;

      .input-field {
        // max-width: 34.5rem;
        max-width: 100%;
      }

      .masked {
        max-width: 100%;
      }
      .error-text {
        color: #ff5722;
        display: flex;
        align-items: center;
        margin-top: 2px;
        margin-bottom: 8px;
        label {
          color: #ff5722;
        }
        i {
          margin-right: 3px;
        }
      }
    }

    .editAccountInner {
      padding: 0px 0.5rem 0.5rem 0.5rem;
    }

    .select-dropdown {
      margin-top: 1rem;
      width: 100%;
      .input-field {
        max-width: 34.5rem;
      }

      .masked {
        max-width: 100%;
      }
    }

    .dropdown-content {
      img {
        width: 22px;
        height: 22px;
        margin-right: 0px !important;
        margin-bottom: 4px;
      }
      div {
        padding-left: 12px;
      }
    }

    .input-container {
      width: 100%;
      details-container {
        margin-top: 1rem;
      }
    }

    .expireSection {
      margin-top: 1rem;
      color: #3e4148;
      font-family: Lato;
      font-size: 14px;
      font-weight: normal;
    }

    .expireField {
      display: flex;
      flex-direction: row;
      height: 56px;
      .fieldContain {
        .maskInput-container {
          .maskInput-field {
            max-width: 1.8rem;
          }
          .details-container {
            visibility: hidden;
            p.error-field {
              visibility: hidden;
            }
          }
        }
      }

      .addDash {
        margin: 1rem 0.25rem;
        color: #3e4148;
        font-family: Lato;
        font-size: 16px;
        font-weight: normal;
      }
    }

    .dateError {
      display: flex;
      align-items: center;
      color: #ff5722;

      i {
        font-size: 13.4px;
        margin-right: 4px;
      }

      p {
        font-size: 14px;
        font-family: Lato;
        color: #ff5722;
        font-style: italic;
      }
    }

    .buttonContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      button {
        margin: 0;
        width: 281px;
        text-transform: capitalize;
        &:not(:last-child) {
          margin-right: 16px;
        }
        &.cancel-btn {
          color: #6e727c;
        }
      }
    }
  }
  .error-message {
    display: flex;
    gap: 6px;
    align-items: center;
    margin-top: 8px;

    i {
      color: #ff3d00;
      font-size: 16px;
    }

    span {
      color: #ff3d00;
      font-size: 12px;
    }
  }
  .deleteModal {
    padding: 16px;
    max-width: 600px;
    h3 {
      margin-bottom: 16px;
    }
    p {
      margin-bottom: 48px;
      color: #3e4148;
    }
    .delete-buttons {
      display: flex;
      button {
        width: 50%;
        outline: none;
        &:not(:last-child) {
          margin-right: 16px;
        }
        &.cancel-btn {
          color: #6e727c;
          background: linear-gradient(-180deg, rgb(244, 245, 246) 0%, rgb(237, 238, 239) 100%);
          border-radius: 8px;
          border: 1px solid rgb(237, 238, 239);
        }
        &.delete-btn {
          background: #ff5722;
        }
      }
    }
  }

  .add-account-btn {
    button {
      background: linear-gradient(-180deg, rgb(244, 245, 246) 0%, rgb(237, 238, 239) 100%);
      border-radius: 8px;
      border: 1px solid rgb(237, 238, 239);
    }
    button:disabled {
      border: none;
    }
    .activeSave {
      background: linear-gradient(-180deg, rgb(57, 99, 254) 0%, rgb(47, 85, 254) 100%);
      border-radius: 8px;
      border: 1px solid rgb(47, 85, 254);
    }
    // .activeSave:hover{
    //     color:#fff;
    // }
    .inactiveSave {
      background: #8aa3ff;
      color: #fff;
      border: none;
    }
    .inactiveSave:hover {
      color: #fff;
    }
  }

  .warning-text {
    background: rgba(255, 204, 188, 0.95);
    border-radius: 0px;
    padding: 8px;
    margin-top: 18px;
    margin-bottom: 18px;
    margin-right: 5px p {
      color: #bf360c;
      font-size: 14px;
      line-height: 20px;
    }
    span {
      margin-right: 15px;
      font-weight: bold;
      color: #ff5722;
    }
  }

  // .modal-children {
  //     padding: 0px 0.5rem 0.5rem 0.5rem;
  // }
`

export default {
  MarketplaceContainer,
  AddAccountModalContainer,
}
