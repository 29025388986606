"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var SubFiltersContainer = _styledComponents["default"].div.withConfig({
  displayName: "styles__SubFiltersContainer",
  componentId: "sc-1o60jka-0"
})(["display:flex;align-items:center;.label-container,.details-container{display:none;}.selectInput{height:1.25rem;margin-right:1rem;background-color:#ffffff;h4{font-size:14px;color:#b9bbc1;padding:8px 0 4px;margin:0 12px;}div > div{}}.subfilters-modal-children{display:flex;flex-direction:column;align-items:center;height:100%;padding-top:1.5rem;.selectInput{margin:0;}.subfilters-container{display:flex;align-items:center;justify-content:space-between;}.subfilters-modal-button{width:18.75rem;height:3.25rem;border:1px solid #2f55fe;border-radius:0.75rem;margin-top:3rem;&:disabled{background-color:#8aa3ff;}}}.error-field{display:flex;align-items:center;color:#ff5722;margin:0.5rem auto 0 0;i{font-size:13.4px;margin-right:4px;}}"]);

var _default = {
  SubFiltersContainer: SubFiltersContainer
};
exports["default"] = _default;