import moment from 'moment';
import _ from 'lodash';
import fetchData from './fetchData';
import config from './apiConfig';
import { getBrowser, getModel, getOS } from '../../utils/deviceDataFunctions';
import { safeLocalStorage } from '../../utils/safeStorage';

const profile = safeLocalStorage.getJSONItem('userData');
const getUserToken = profile => {
    let userToken;
    if (!_.isNil(profile) && _.has(profile, 'Network') && _.isArray(profile.Network)) {
        profile.Network = _.map(profile.Network, (company, index) => {
            if (company.Id === profile.activeNetworkId) {
                if (_.has(company, 'UserToken') && !_.isNil(company.UserToken)) {
                    userToken = company.UserToken;
                }
            }
            return company;
        });
        if (_.isNil(userToken)) {
            if (!_.isNil(profile.Network[0], 'UserToken')) {
                userToken = profile.Network[0].UserToken;
            }
        }
    }
    return userToken;
};

const userToken = getUserToken(profile);
const bearerToken = safeLocalStorage.getItem('token');

const setData = (action, analyticsData) => {
    const browser = getBrowser();
    return {
        data: {
            Browser: browser.name,
            BrowserVersion: browser.version,
            OperatingSystem: getOS(),
            UserToken: userToken,
            Platform: 'Qmerit-Marketplace',
            Url: window.location.href,
            Timestamp: moment().toISOString(),
            Action: action,
            Device: getModel(),
            Data: analyticsData,
        },
    };
};

export default (action, data) =>
    new Promise((resolve, reject) => {
        const body = setData(action, data);
        const url = `${config.postAnalytics.url}`;
  const options = {
            ...config.postAnalytics,
    body,
            url,
            bearerToken,
        };
        return fetchData(options)
    .then((data) => {
                if (data && !data.ErrorCode) {
                    const stateObject = {
                        ...data,
                        fetchError: !!(data && data.ErrorCode),
                    };
                    resolve(stateObject);
      } else {
                    reject({ fetchError: true, details: data });
      }
    })
    .catch((error) => {
                reject(error);
            });
});
