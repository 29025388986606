import _ from 'lodash'
import { useState, useEffect } from 'react'
import { useCartContext } from '../../../../../hooks/useCartContext'
import { postSpendbridgeCheckout } from '../../../../apis'
import { calcTotalItems } from '../../helpers'
import DotLoader from '../SupplierCart/dotLoader'
import ComponentStyles from './styles'
import LoginErrorModal from '../../../Checkout/LoginErrorModal'
import axios from 'axios'

const { CartSummaryWrapper, CartSummaryContainer, ContractsWarning } = ComponentStyles
const isBrowser = typeof window !== 'undefined' && window.env
const apiRoot = isBrowser ? window.env.apiRoot : process.env.RAZZLE_RUNTIME_API_ROOT;
const apiKey = isBrowser
? window.env.apiKey
: process.env.RAZZLE_RUNTIME_API_KEY;
const blobUrl = isBrowser ? window.env.blobUrl : process.env.RAZZLE_RUNTIME_BLOB_URL

const CartSummary = (props) => {
  const { onCheckoutClick, defaultCheckout } = props
  const { state, toggleShowContractCodesWarning } = useCartContext()
  const { cart, loadingItems, undo, availableContractCodes, showContractRequiredWarning } = state
  const [showSpendbridgeError, setShowSpendbridgeError] = useState(false)
  const undoSuppliers = undo.supplierIds

  const showContractCodes = !!availableContractCodes.length

  const isSpendbridgeAccountValid = async () => {
    const bearerToken = window.localStorage.getItem('token')
    const userToken = props.userData.Network[props.userData.activeNetworkIndex].UserToken

    await axios.post(`${apiRoot}/v1/Procurement/Spendbridge/Token/${userToken}/Cart`, [0], {
      headers: {
        'Ocp-Apim-Subscription-Key': `${apiKey}`},
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }
    )
    .catch((error) => {
      if (error.response && error.response.status === 409) {
        setShowSpendbridgeError(true)
      } 
    })
  }

  const onCheckout = () => {
    if (defaultCheckout === 'spendbridge') {
      postSpendbridgeCheckout(props.userData, cart, isSpendbridgeAccountValid)
    } else {
      onCheckoutClick()
    }
  }

  useEffect(() => {
    if (showContractRequiredWarning) {
      const everyItemHasContractCode = cart.CartRequisitions.every((cartRequisition) =>
        cartRequisition.Requisitions.every((requisition) => !!requisition.ContractCodeId)
      )
      if (everyItemHasContractCode) {
        toggleShowContractCodesWarning(false)
      }
    }
  }, [showContractRequiredWarning, cart])

  return (
    <>
      <LoginErrorModal
        show={showSpendbridgeError}
        onClose={() => setShowSpendbridgeError(false)}
       />
      <CartSummaryWrapper>
          <CartSummaryContainer>
            <div className="cart-summary-header">
              <h3>Your Cart</h3>
            </div>
            <div className="cart-suppliers-container">
              {cart.CartRequisitions.map((req, i) => {
                const totalItems = calcTotalItems(req.Requisitions)

                return (
                  <div className={`supplier-summary${req.Requisitions.length === 0 ? ' removed' : ''}`} key={i}>
                    <div className="supplier-items-summary ">
                      <img alt="supplier-logo" src={`${blobUrl}/${req.Supplier.IconLogo}`} />
                      <p className="small lite">
                        {totalItems} item{totalItems > 1 && 's'}
                      </p>
                    </div>
                    {loadingItems.includes(req.Supplier.Id) ? (
                      <div className="supplier-total">
                        <DotLoader />
                      </div>
                    ) : (
                      <p className="supplier-total">
                        {_.includes(undoSuppliers, req.Supplier.Id) && <i className="far fa-exclamation-circle" />}$
                        {req.TotalAmount}
                      </p>
                    )}
                  </div>
                )
              })}
            </div>
            <div className="cart-summary-total">
              <div className="cart-total">
                <h5>Subtotal</h5>
                {loadingItems.length ? <DotLoader /> : <p className="total currency">${cart.CartTotal}</p>}
              </div>
              <button
                onClick={() => {
                  if (showContractCodes) {
                    const everyItemHasContractCode = cart.CartRequisitions.every((cartRequisition) =>
                      cartRequisition.Requisitions.every((requisition) => !!requisition.ContractCodeId)
                    )
                    if (everyItemHasContractCode) {
                      toggleShowContractCodesWarning(false)
                      onCheckout()
                    } else {
                      toggleShowContractCodesWarning(true)
                    }
                  } else {
                    onCheckout()
                  }
                }}>
                Proceed to checkout
              </button>
            </div>
          </CartSummaryContainer>
      </CartSummaryWrapper>
      {showContractRequiredWarning && showContractCodes && (
        <ContractsWarning>
        <div >
          <i className="far fa-exclamation-circle" />
          <span> Add a contract code for each item</span>
        </div>
        </ContractsWarning>
      )}
      {(!showContractRequiredWarning || !showContractCodes) && (
        <div style={{ height: '1.5rem' }}></div>
      )}
    </>
  )
}

export default CartSummary
