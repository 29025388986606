import styled from 'styled-components'

const WrapperStyles = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: auto;
  background-color: #ffffff;

  .wrapper-children {
    display: flex;
    height: calc(100% - 3rem);
    padding: 1.5rem 3.25rem;
  }
`

export default { WrapperStyles }
