import React, { useState, useEffect, Fragment } from 'react'
import MiniCart from '../../../../lib/@q-core/mini-cart'
import { handleUpdateActiveNetwork, handleNavigation, getLocalCart } from './helpers'
import { safeLocalStorage, getActiveCorpSettings } from '../../../utils/safeStorage'
import NetworkDropdown from '../NetworkDropdown'
import ComponentStyles from './styles'
import './styles.css'
import postAnalytics from '../../../utils/postAnalytics.js'

const { HeaderContainer } = ComponentStyles

const isBrowser = typeof window !== 'undefined' && window.env
const apiRoot = isBrowser ? window.env.apiRoot : process.env.RAZZLE_RUNTIME_API_ROOT
const apiKey = isBrowser ? window.env.apiKey : process.env.RAZZLE_RUNTIME_API_KEY
const blobUrl = isBrowser ? window.env.blobUrl : process.env.RAZZLE_RUNTIME_BLOB_URL

const Header = props => {
  const { currentView, history, onCartUpdate } = props
  const bearerToken = safeLocalStorage.getItem('token')
  const localCart = safeLocalStorage.getJSONItem('cart')

  const [rendered, setRendered] = useState(false)
  const [userData, setUserData] = useState(null)
  const [cart, setCart] = useState(localCart)
  const [toggle, setToggle] = useState(false)
  const [cartItemTotal, setCartItemTotal] = useState(null)

  useEffect(() => {
    const data = isBrowser ? JSON.parse(window.localStorage.getItem('userData')) : props.userData
    setUserData(data)
    getLocalCart({ userData: data, setCart })
    setRendered(true)
    // this.state.activeTheme ? this.setUserTheme(this.state.activeTheme) : this.setDefaulttheme()
  }, [])

  useEffect(() => {
    if (props.cartItemTotal) setCartItemTotal(props.cartItemTotal)
    safeLocalStorage.getJSONItem('cart')
    if (localCart && rendered) {
      localCart.CartItemTotal = props.cartItemTotal
      setCart(localCart)
    }
  }, [props.cartItemTotal])

  useEffect(() => {
    if (cart && cart.CartItemTotal >= 0) {
      setCartItemTotal(cart.CartItemTotal)
      if (onCartUpdate) onCartUpdate(cart)
    }
    if (rendered) {
      safeLocalStorage.setJSONItem('cart', { ...cart })
    }
  }, [cart])

  return (
    <Fragment>
      {userData && userData.Network && (
        <HeaderContainer className="actions-header">
          <NetworkDropdown
            className="network-dropdown"
            data-aurora-automation="header-corporate-dropdown"
            activeUser={userData.Network[userData.activeNetworkIndex]}
            items={userData.Network}
            selected={userData.activeNetworkIndex}
            onItemClick={event => handleUpdateActiveNetwork(event, setUserData)}
            onUserClick={() => {
              history.push('/account')
            }}
          />
          <div className="header-nav" data-aurora-automation="header-primary-navigation">
            <a
              className={currentView === '/marketplace' ? 'button active' : 'button'}
              onClick={() => {
                handleNavigation('/marketplace', props)
              }}
            >
              <p>Marketplace</p>
            </a>
            {/* <a className={currentView === 'requests' ? 'button active' : 'button'} onClick={() => { history.push('/requests') }}><p>Requests</p></a> */}

            {getActiveCorpSettings().StoresEnabled && (
              <div
                className={`stores ${currentView === '/stores' ? 'button active' : 'button'}`}
                onClick={() => handleNavigation('/stores/covid-19-essentials', props)}
              >
                {/* <SelectField
                  mode="popup"
                  selectOptions={[
                    { id: 1, name: "Air Filters", url: "air-filters" },
                    {
                      id: 2,
                      name: "COVID-19 Essentials",
                      url: "covid-19-essentials",
                    },
                  ]}
                  onChange={(value) =>
                    handleNavigation(`/stores/${value.url}`, props)
                  }
                  onError={(err) => console.log(err)}
                > */}
                <div className="stores-selector">
                  <p>Stores</p>
                  {/* <i className="fas fa-caret-down" /> */}
                </div>
                {/* </SelectField> */}
              </div>
            )}
            <a
              className={currentView === '/quotes' ? 'button active' : 'button'}
              onClick={() => {
                handleNavigation('/quotes', props)
              }}
            >
              <p>Quotes</p>
            </a>
            <a
              className={currentView === '/requisitions' ? 'button active' : 'button'}
              onClick={() => {
                handleNavigation('/requisitions', props)
              }}
            >
              <p>Requisitions</p>
            </a>

            <a
              className={currentView === '/orders' ? 'button active' : 'button'}
              onClick={() => {
                handleNavigation('/orders', props)
              }}
            >
              <p>Orders</p>
            </a>

            {/* <a className={this.state.isStandingActive ? 'button active' : 'button'} onClick={() => { this.history.push('/standing') }}>Standing</a> */}
          </div>
          <a
            className={`cart-icon ${currentView === '/cart' ? 'button active' : 'button'}`}
            onClick={() => {
              setToggle(!toggle)
            }}
          >
            {cartItemTotal > 0 ? <p className="cart-count active easeIn">{cartItemTotal}</p> : <p className="cart-count" />}
            <i className={`fas fa-shopping-cart${toggle ? ' active' : ''}`} />
          </a>
          <p
            className="user"
            data-aurora-automation="header-account-link"
            onClick={() => {
              postAnalytics('pm-header-navigation-click-accountManageLegacy', userData)
              history.push('/account')
            }}
          >
            {userData.FirstName}
            <img src={userData.ImageUrl || '/images/profile-generic.png'} alt="account profile" />
          </p>
          {userData && cart && currentView !== '/cart' && (
            <MiniCart
              className="marketplace-mini-cart"
              popup
              cart={cart}
              isLoadingCart={false}
              toggle={toggle}
              setToggle={setToggle}
              onCartUpdate={cart => setCart({ ...cart })}
              env={{
                apiRoot,
                apiKey,
                bearerToken,
                userToken: userData.Network[userData.activeNetworkIndex].UserToken,
                marketplaceRoot: '',
                blobUrl,
                userData,
              }}
            />
          )}
        </HeaderContainer>
      )}
    </Fragment>
  )
}

export default Header
