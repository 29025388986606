import styled from 'styled-components';

const TooltipContainer = styled.div`
    display: inline-block;
    position: relative;
    text-align: left;
    cursor: pointer;

    .tooltipContent {
        display: none;
        position: absolute;
    }

    &:hover {
        .tooltipContent {
            display: block !important;
            transform: translate(-50%, -100%);
        }
    }
`;

const TooltipContent = styled.div`
    top: 0%;
    left: 50%;
    transform: translateY(-50%);
    z-index: 99999999;
    display: none;
    position: absolute;
    background: rgba(62, 65, 72, 0.75);
    color: #fff;
    font-size: 12px;
    font-family: Lato;
    font-weight: normal;
    letter-spacing: 0.25px;
    line-height: 16px;
    padding: 8px;
`;

export default {
    TooltipContainer,
    TooltipContent,
};
