import styled from 'styled-components'

export const DropdownContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  .dropdown-button {
    width: fill-available;
    border: 1px solid #dadde1;
    background: #f5f6f7;
    padding: 2px 4px;
    border-radius: 4px;
    cursor: pointer;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .label-text {
    color: rgba(0, 0, 0, 0.5);
    font-family: Rubik;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
  .selected-label-text {
    color: #000000;
    font-family: Rubik;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  .mutted-text {
    color: rgba(0, 0, 0, 0.25);
  }
`

export const DropdownBodyContainer = styled.div`
  width: fill-available;
  position: ${({ position }) => position};
  ${({ position }) =>
    position !== 'relative'
      ? `
  top: 22px;
  z-index: 99;
  margin-top: 4px;
  `
      : ''}
  border-radius: ${({ borderRadius }) => `${borderRadius ? borderRadius : '4'}px`};
  border: ${({ position }) => `${position !== 'relative' ? '1px solid #e2e5e9' : '0px none'}`};
  background: #fff;
  box-shadow: ${({ position }) => `${position !== 'relative' ? '0px 2px 4px 0px rgba(0, 0, 0, 0.12)' : 'unset'}`};

  .search-input {
    width: fill-available;
    padding: 2px 16px;
    height: 36px;
    background-color: rgba(245, 246, 247, 1);
    border: none;
    border-radius: 4px;
  }

  .options-container {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
    max-height: 300px;

    padding: ${({ position }) => `${position !== 'relative' ? '8px 8px 8px 8px' : '8px 0px 8px 0px'}`};

    .option {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px;

      color: #000;
      font-family: Rubik;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;

      .check-mark {
        color: #2244fd;
        text-align: center;
        font-family: Font Awesome 6 Pro;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .selected {
      border-radius: 4px;
      background: #f2f3f5;
    }
  }
`
