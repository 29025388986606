import React, { useState, useEffect, Fragment } from 'react';
import _ from 'lodash';
import Loader from '../../components/Loader';
import { getSpendbridgeUrl } from '../../apis';

const getSpendbridgeLink = async (userData) => {
    const data = await getSpendbridgeUrl(userData);
    if (_.has(data, 'URL') && !_.isEmpty(data.URL)) {
        return data.URL;
    }
};

const SpendbridgeIframe = props => {
    const [pageUrl] = useState(props.pageUrl || 'home');
    const [isFrame] = useState(props.isFrame || false);

    useEffect(() => {
        if (!_.isEmpty(props.userData)) {
            const spendbridgeUrl = getSpendbridgeLink(props.userData);
            spendbridgeUrl.then(url => {
        let pageLocation;
        const pageUrl = document.location.pathname;

        if (pageUrl === '/spendbridge/approvals') {
          // setPageUrl(`${url}&ReturnUrl=~/home`);
          pageLocation = escape('~/home');
        } else if (pageUrl === '/spendbridge/history') {
          pageLocation = escape('~/history');
          // setPageUrl(`${url}&ReturnUrl=~history`);
        } else if (pageUrl === '/spendbridge/checkout') {
          pageLocation = escape('~/shopping/cart');
          // setPageUrl(`${url}&ReturnUrl=~/shopping/cart `);
        }
        if (!_.isNil(pageLocation)) {
          document.location.href = `${url}&ReturnUrl=${pageLocation}`;
          // setPageUrl(`${url}&ReturnUrl=${pageLocation}`)
        }
            });
        }
    }, [document.location.pathname]);

    if (isFrame) {
        return (
      <Fragment>
              {pageUrl && (
                    <iframe
                  name="spendbridgeFrame"
                  id="spendBridgeFrame"
                  title="spendBridgeFrameIframe"
                  className="flex-column"
                  key={new Date().getMilliseconds()}
                  src={pageUrl}
                  overflow="hidden"
                  width="100%"
                        height={window ? `${window.innerHeight - 52}px` : '400px'}
                  frameBorder="0"
                  allowFullScreen
                  style={{ marginBottom: '0' }}
                />
        )}
            </Fragment>
        );
    } else {
        return <Loader />;
    }
};

export default SpendbridgeIframe;
