import React from 'react';
import Modal from '../../../../components/Modal';

const DeleteAddressModal = ({ setDeleteModal, deleteAddress }) => (
    <Modal
        title={'Delete Address'}
        setEnabled
        setToggle={() => setDeleteModal(false)}
        cardClassName='editaddressModal deleteaddressmodal'
    >
        <div className='delete-icon'>
            <i className='fal fa-trash-alt' />
        </div>

        <div>
            <label>
                Confirm that you want this address deleted from checkout for all suppliers. Removing this address will
                not affect any orders already being processed with this address.
            </label>
        </div>

        <div className='form-groups btn-outer'>
            <div className='flexrow '>
                <div className='flexcol-6 mr-8'>
                    <button type='button' className='cancel-btn' onClick={() => setDeleteModal(false)}>
                        Cancel
                    </button>
                </div>

                <div className='flexcol-6 ml-8'>
                    <button type='button' className='save-btn' onClick={deleteAddress}>
                        Confirm delete
                    </button>
                </div>
            </div>
        </div>
    </Modal>
);
export default DeleteAddressModal;
