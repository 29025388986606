import React, { PureComponent, Fragment } from 'react'
import NotFound from '../../components/NotFound'
import Header from '../../components/Header'
import { Wrapper } from '../../components'

export class AdminPortal extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { loading: true, renderMFE: false }
  }

  componentDidMount() {
    const isBrowser = typeof window !== 'undefined' && window.env
    const MFE_DOMAIN = isBrowser ? window.env.mfeDomain : process.env.mfeDomain
    const MFE_ADMIN_PORTAL_PATH = isBrowser ? window.env.mfeAdminPortalPath : process.env.mfeAdminPortalPath

    if (MFE_DOMAIN && MFE_ADMIN_PORTAL_PATH) {
      const script = document.createElement('script')
      script.setAttribute('crossorigin', 'anonymous')
      script.src = `${MFE_DOMAIN}/${MFE_ADMIN_PORTAL_PATH}`
      script.async = true
      document.body.appendChild(script)
      this.setState({ loading: false, renderMFE: true })
    } else {
      this.setState({ loading: false, renderMFE: false })
    }
  }

  render() {
    if (this.state.loading) return <div></div>
    if (this.state.renderMFE)
      return (
        <Wrapper {...this.props}>
          <div id="mfe-admin-portal" style={{ width: '100%' }}></div>
        </Wrapper>
      )
    return <NotFound {...this.props}></NotFound>
  }
}

export default AdminPortal
