import styled from 'styled-components';

const SubnavContainer = styled.div`
  background: ${({ theme }) => theme.auroraThemeBackgroundColor};
  color: rgb(${({ theme }) => theme.auroraThemeTextColor});
  z-index: 99;

  ul {
    display: flex;
    margin: 0;
    padding-bottom: 1rem;
    padding-left: 2rem;

    li {
      list-style-type: none;
      margin-right: 3rem;
      opacity: 0.7;
      cursor: pointer;

      svg {
        opacity: 0.7;
      }

      a {
        color: rgb(${({ theme }) => theme.auroraThemeTextColor});
      }

      &:hover {
        opacity: 1;
        svg {
          opacity: 1;
        }
      }

      &.active {
        font-weight: bold;
        opacity: 1;

        svg {
          opacity: 1;
        }
      }

      i {
        margin-right: 0.5rem;
      }
    }
  }
`;

export default { SubnavContainer };
