"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RootCSS = exports.Card = exports.Column = exports.Row = void 0;

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var Row = _styledComponents["default"].div.withConfig({
  displayName: "globalStyledComponents__Row",
  componentId: "sc-1gliyl9-0"
})(["display:flex;width:", ";height:", ";justify-content:", ";flex-wrap:", ";min-height:", ";margin:", ";flex:", ";"], function (_ref) {
  var width = _ref.width;
  return width ? width : '100%';
}, function (_ref2) {
  var height = _ref2.height;
  return height ? height : '';
}, function (_ref3) {
  var justifyContent = _ref3.justifyContent;
  return justifyContent || '';
}, function (_ref4) {
  var wrap = _ref4.wrap;
  return wrap && 'wrap';
}, function (_ref5) {
  var minHeight = _ref5.minHeight;
  return minHeight ? minHeight : '';
}, function (_ref6) {
  var margin = _ref6.margin;
  return margin ? margin : '';
}, function (_ref7) {
  var flex = _ref7.flex;
  return flex ? flex : '';
});

exports.Row = Row;

var Column = _styledComponents["default"].div.withConfig({
  displayName: "globalStyledComponents__Column",
  componentId: "sc-1gliyl9-1"
})(["display:flex;flex-direction:column;width:", ";height:", ";margin:", ";flex:", ";"], function (_ref8) {
  var width = _ref8.width;
  return width ? width : '';
}, function (_ref9) {
  var height = _ref9.height;
  return height ? height : '100%';
}, function (_ref10) {
  var margin = _ref10.margin;
  return margin ? margin : '';
}, function (_ref11) {
  var flex = _ref11.flex;
  return flex ? flex : '';
});

exports.Column = Column;

var Card = _styledComponents["default"].div.withConfig({
  displayName: "globalStyledComponents__Card",
  componentId: "sc-1gliyl9-2"
})(["background-color:#ffffff;border-radius:0.5rem;box-shadow:0px 2px 4px 0px rgba(0,0,0,0.14),0px 3px 4px 0px rgba(0,0,0,0.12),0px 1px 5px 0px rgba(0,0,0,0.2);height:", ";width:", ";", " ", ""], function (_ref12) {
  var height = _ref12.height;
  return height ? height : '12.5rem';
}, function (_ref13) {
  var width = _ref13.width;
  return width ? width : '16.875rem';
}, function (_ref14) {
  var inheritHeight = _ref14.inheritHeight;
  return inheritHeight && (0, _styledComponents.css)(["height:inherit;"]);
}, function (_ref15) {
  var loading = _ref15.loading;
  return loading && (0, _styledComponents.css)(["box-shadow:none;border:1px solid #dedfe2;"]);
});

exports.Card = Card;
var RootCSS = (0, _styledComponents.css)(["display:flex;flex-direction:column;height:100vh;overflow-y:auto;overflow-x:hidden;.powered-by-qmerit{align-self:center;position:relative;bottom:0;height:1.5rem;width:10.5rem;object-fit:contain;padding-bottom:", ";}"], function (_ref16) {
  var mobile = _ref16.mobile;
  return mobile ? '9rem' : '6rem';
});
exports.RootCSS = RootCSS;