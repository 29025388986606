import _ from 'lodash';
import fetchData from './fetchData';
import config from './apiConfig';
import { safeLocalStorage } from '../../utils/safeStorage';

export default ({ userData }) => {
    console.log('getSupplierContractData :: userData :: ', userData)
    if(userData.Network) {
        const userToken = userData.Network[userData.activeNetworkIndex].UserToken;
        return new Promise((resolve, reject) => {
            const options = {
                ...config.getSupplierContractData,
                url: `${config.getSupplierContractData.url}/${userToken}/CorporateOfficeContract`,
            };
            return fetchData(options)
                .then(data => {
                    if (data && !data.ErrorCode) {
                        const stateObject = {
                            ...data,
                            fetchError: !!(data && data.ErrorCode),
                        };
    
                        if (_.has(data, 'Suppliers')) {
                            stateObject.Suppliers = _.sortBy(data.Suppliers, 'Name');
                            window.localStorage.setItem('suppliers', JSON.stringify(stateObject.Suppliers));
                        }
                        resolve(stateObject);
                    } else {
                        reject({ fetchError: true, details: data });
                    }
                })
                .catch(error => {
                    reject(error);
                });
        });
    } else return new Promise((resolve, reject) => {
        const existingSuppliers = safeLocalStorage.getJSONItem('suppliers');
        resolve({Suppliers: existingSuppliers})
    });
};
