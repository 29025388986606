const checkStatus = response => {
    if (response.ok) {
        return Promise.resolve(response);
    } else {
        return Promise.reject(new Error(response.statusText));
    }
};

const getJson = response => response.json();

export const webFetch = async (url, options, requestingFunctionName) =>
    fetch(url, options)
        .then(checkStatus)
  .then(getJson)
        .then(data => data)
        .catch(error => new Error(`webFetch :: ${requestingFunctionName} :: `, error.ErrorMessage));

export const fetchData = {
    setUserSettings: async (userToken, body) => {
        
        const bearerToken = window.localStorage.getItem('token')
        const url = `${window.env.apiRoot}/v1/User/${userToken}/Settings`;
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': `${window.env.apiKey}`,
                'Authorization': `bearer ${bearerToken}`,
            },
            body: JSON.stringify(body),
        };
        return webFetch(url, options, 'setUserSettings');
    },
};

export default webFetch;
