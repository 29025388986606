import styled from 'styled-components';

const HeaderContainer = styled.header`
    display: flex;
    width: 100%;
    height: 3rem;
    align-items: center;
    background: ${({ theme }) => theme.auroraThemeBackgroundColor};
    color: rgb(${({ theme }) => theme.auroraThemeTextColor});
    z-index: 10000;
    position: relative;

    a.marketplace-icon {
        display: flex;
        margin-left: 1rem;
        margin-right: auto;

        img {
            height: 1rem;
            object-fit: contain;
            margin-right: 0.325rem;
        }
        p {
            color: #172d87;
            font-family: Montserrat;
            font-size: 0.75rem;
            font-weight: 600;
            height: 1rem;
            letter-spacing: 0.5px;
            line-height: 1rem;
        }
    }

    .button {
        display: flex;
        align-items: center;
        border: none;
        margin: auto 0.25rem;
        background-color: transparent;
        position: relative;

        &.user {
            color: rgb(${({ theme }) => theme.auroraThemeTextColor}, 0.7);
        }

        &.active {
            p,
            i {
                position: relative;
                color: white;
                z-index: 1;
            }

            &:before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                background: rgba(57, 99, 254, 0.5);
                mix-blend-mode: multiply;
                z-index: 1;
                border-radius: 8px;
            }
        }

        .cart-count {
            display: none;

            &.active {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 4px;
                margin-right: 4px;
                text-align: center;
                height: 16px;
                min-width: 16px;
                opacity: 1;
                background-color: #ffffff;
                color: #3963fe;
                border-radius: 8px;
                font-size: 11px;
                line-height: 16px;
                font-weight: bold;
                box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.14), 0px 2px 2px 0px rgba(0, 0, 0, 0.12),
                    0px 1px 3px 0px rgba(0, 0, 0, 0.2);
            }
        }
    }

    .mini-cart-component {
        position: fixed;
        z-index: 99;
        top: 2.5rem;
        right: 1rem;
    }

    .dropdown {
        margin: auto 1rem;

        .dropdown-items {
            left: 4px;
            right: initial;
        }
    }
`;

export default { HeaderContainer };
