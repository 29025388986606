"use strict";

require("core-js/modules/es.regexp.to-string");

require("core-js/modules/es.string.split");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getOS = exports.getBrowser = exports.getBrowserVersion = exports.getModel = void 0;

const getModel = () => {
  if (window.navigator.userAgent.indexOf("iPhone") !== -1) return "iPhone";
  if (window.navigator.userAgent.indexOf("iPad") !== -1) return "iPad";
  if (window.navigator.userAgent.indexOf("iPod") !== -1) return "iPod";
  if (window.navigator.userAgent.indexOf("Android") !== -1) return "Android";
  return "Desktop";
};

exports.getModel = getModel;

const getBrowserVersion = browser => {
  if (browser === "IE" && window.navigator.userAgent.split("MSIE ")[1]) return window.navigator.userAgent.split("MSIE ")[1].split(";")[0];
  if (window.navigator.userAgent.split("".concat(browser, "/"))[1]) return window.navigator.userAgent.split("".concat(browser, "/"))[1].split(" ")[0];
  return "Unknown";
};

exports.getBrowserVersion = getBrowserVersion;

const getBrowser = () => {
  let isOpera = !!window.opr && !!window.opr.addons || !!window.opera || window.navigator.userAgent.indexOf(" OPR/") >= 0;
  let isFirefox = typeof InstallTrigger !== "undefined";

  let isSafari = /constructor/i.test(window.HTMLElement) || function (p) {
    return p.toString() === "[object SafariRemoteNotification]";
  }(!window["safari"] || typeof window.safari !== "undefined" && window.safari.pushNotification);

  let isIE =
  /*@cc_on!@*/
  false || !!document.documentMode;
  let isEdge = !isIE && !!window.StyleMedia;
  let isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
  let isEdgeChromium = isChrome && navigator.userAgent.indexOf("Edg") != -1;
  let isBlink = (isChrome || isOpera) && !!window.CSS;
  if (isOpera) return {
    name: "Opera",
    version: null
  };
  if (isFirefox) return {
    name: "Firefox",
    version: getBrowserVersion("Firefox")
  };
  if (isSafari) return {
    name: "Safari",
    version: getBrowserVersion("Safari")
  };
  if (isIE) return {
    name: "IE",
    version: getBrowserVersion("IE")
  };
  if (isEdge) return {
    name: "Edge",
    version: getBrowserVersion("Edge")
  };
  if (isChrome) return {
    name: "Chrome",
    version: getBrowserVersion("Chrome")
  };
  if (isEdgeChromium) return {
    name: "EdgeChromium",
    version: getBrowserVersion("Edge")
  };
  if (isBlink) return {
    name: "isBlick",
    version: null
  };
  return {
    name: "Unknown",
    version: null
  };
};

exports.getBrowser = getBrowser;

const getOS = () => {
  const isBrowser = typeof window !== "undefined" && window.navigator;

  if (isBrowser) {
    if (/iPad|iPhone|iPod/.test(window.navigator.userAgent)) {
      return "iOS";
    }

    if (/Android/.test(window.navigator.userAgent)) {
      return "Android";
    }

    if (window.navigator.platform.indexOf("Mac") !== -1) {
      return "Mac OS";
    }

    if (window.navigator.platform.indexOf("Win") !== -1) {
      return "Windows";
    }

    if (window.navigator.platform.indexOf("Xll") !== -1) {
      return "UNIX";
    }

    if (window.navigator.platform.indexOf("Linux") !== -1) {
      return "Linux";
    }

    return "Unknown";
  }

  return "Unknown";
};

exports.getOS = getOS;