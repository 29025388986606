import _ from 'lodash'
const getDefaultCheckout = (userData) => {
  if (_.has(userData, 'activeUserSettings.DefaultCheckout')) {
    if (!_.isEmpty(userData.activeUserSettings.DefaultCheckout)) {
      //the user level setting takes priority over the company level
      return userData.activeUserSettings.DefaultCheckout
    }
  }

  if (_.has(userData, 'activeCorpSettings.DefaultCheckout')) {
    if (!_.isEmpty(userData.activeCorpSettings.DefaultCheckout)) {
      return userData.activeCorpSettings.DefaultCheckout
    }
  }

  if (_.has(userData, 'activeNetwork.CompanySetting')) {
    if (!_.isEmpty(JSON.parse(userData.activeNetwork.CompanySetting))) {
      return JSON.parse(userData.activeNetwork.CompanySetting)
    }
  }

  const activeNetwork = userData.Network.find((corp) => corp.Id === userData.activeNetworkId) || { CompanySetting: '' }
  if (
    activeNetwork.CompanySetting &&
    typeof activeNetwork.CompanySetting === 'string' &&
    activeNetwork.CompanySetting.length > 0 &&
    JSON.parse(activeNetwork.CompanySetting).DefaultCheckout === 'raiven'
  ) {
    return 'raiven'
  }

  return 'eql'
}

export default getDefaultCheckout
