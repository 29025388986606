import _ from 'lodash';
import React, { Fragment } from 'react';

import SupplierCartItem from './cartItem';
import ComponentStyles from './styles';

const { SupplierCartsContainer } = ComponentStyles;

const SupplierCart = props => {
    let { addresses, cart, loader, history } = props;
    const addressUnVerifiedForSuppliers = (addresses.Shipping.UnVerifiedForSuppliers || []).concat(
        addresses.Billing.UnVerifiedForSuppliers || [],
    );
    return (
        <SupplierCartsContainer>
        <div className="checkout-header">
            <h3>Review Items and Shipping</h3>
            </div>
        {_.map(cart.CartRequisitions || [], (req, i) => (
                <Fragment key={i}>
            {!_.isNull(req) && (
                    <SupplierCartItem
                            history={history}
                          key={i}
                          supplier={{ ...req.Supplier, AccountNumber: req.Requisitions[0].AccountNumber, AccountLabel: req.Requisitions[0].AccountLabel }}
                          items={req.Requisitions}
                          totalAmount={req.TotalAmount}
                            unableToCheckout={_.includes(addressUnVerifiedForSuppliers, req.SupplierId)}
                          loader={loader}
                        />
                    )}
          </Fragment>
            ))}
      </SupplierCartsContainer>
    );
};

export default SupplierCart;
