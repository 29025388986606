import React, { useEffect, useState } from 'react'
import { getAddressCheckout } from '../../../apis'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import { safeLocalStorage } from '../../../../utils/safeStorage'
import { ADDRESS_VERIFICATION_STATUS, isVerifiedAddressForSuppliers } from '../../Cart/helpers'
import Modal from '../../../components/Modal'
import './styles.css'

function EditAddressModal({
  isOpen,
  setIsSelectAddressModalOpen,
  userData,
  setSelectedAddress,
  supplierIds,
  showAddAddressModal,
  isAddressAdded,
  ...props
}) {
  const [addresses, setAddresses] = useState([])
  const [searchInput, setSearchInput] = useState('')
  const [filteredAddresses, setFilteredAddresses] = useState([])

  const user = safeLocalStorage.getJSONItem('userData')
  const isUserAdmin = user && user.activeUserSettings && user.activeUserSettings.Role === 'Admin' ? true : false

  const getAddresses = async (isResetSelectedAddress = true) => {
    const data = await getAddressCheckout(userData)
    let billingAddresses = []
    let shippingAddresses = []
    let defaultShipping = []
    let defaultBilling = []

    data.addresses.forEach((Address) => {
      if (Address.AddressType === 'Billing_Shipping') {
        billingAddresses.push(Address)
        shippingAddresses.push(Address)
      }
      if (Address.AddressType === 'Billing') {
        billingAddresses.push(Address)
      }
      if (Address.AddressType === 'Shipping') {
        shippingAddresses.push(Address)
      }
      if (Address.IsDefaultShippingAddress === true) {
        defaultShipping = Address
      }
      if (Address.IsDefaultBillingAddress === true) {
        defaultBilling = Address
      }
    })

    setAddresses(data.addresses)
    setFilteredAddresses(data.addresses)
  }

  function filterData(searchInput) {
    setSearchInput(searchInput)
    const searchWords = searchInput.toLowerCase().split(' ')

    let filteredData = addresses.filter((item) => {
      return searchWords.every((word) => {
        return Object.values(item).some((value) => {
          if (typeof value === 'string' && value.toLowerCase().includes(word)) {
            return true
          }
          return false
        })
      })
    })

    setFilteredAddresses(filteredData)
  }

  function handleSelectAddress(address) {
    setSelectedAddress(address)
    setIsSelectAddressModalOpen(false, undefined, false)
  }

  useEffect(() => {
    getAddresses()
  }, [isAddressAdded])

  return (
    <Modal
      title="Select Address"
      setEnabled={isOpen}
      darkBackground
      height="auto"
      setToggle={() => {
        setIsSelectAddressModalOpen(false, undefined, false)
      }}
      maxWidth="600px"
      cardClassName="addressModal"
    >
      <div style={{ position: 'relative', width: '100%', marginTop: 16, marginBottom: 16 }}>
        <input
          placeholder="Search your addresses..."
          value={searchInput}
          onChange={(e) => filterData(e.target.value)}
          className="address-input"
        />
        <FontAwesomeIcon icon={faSearch} className="search-icon" />
      </div>
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          height: 'auto',
          maxHeight: 484,
          overflowY: 'scroll',
        }}
      >
        {filteredAddresses.length > 0 &&
          filteredAddresses.map((address, i) => {
            const verificationStatus = isVerifiedAddressForSuppliers(addresses, supplierIds, address.Id)

            return (
              <div
                key={address.Id}
                style={{
                  display: 'flex',
                  paddingTop: 8,
                  paddingBottom: 8,
                  borderTop: i !== 0 ? '1px solid #DADDE1' : 'none',
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <h5 className="address-title">{address.Name}</h5>
                  <p>
                    {address.Street1}, {address.Street2}
                  </p>
                  <p>
                    {address.City}, {address.State} {address.PostalCode}
                  </p>
                  <span className="address">{address.DeliverTo2 ? `ID #${address.DeliverTo2}` : null}</span>
                  {!!verificationStatus && (
                    <div className="addressVerification">
                      <i className="far fa-exclamation-circle"></i>
                      <p>
                        {verificationStatus === ADDRESS_VERIFICATION_STATUS.ALL_UNVERIFIED ||
                        verificationStatus === ADDRESS_VERIFICATION_STATUS.SOME_UNVERIFIED
                          ? 'Pending verification by suppliers'
                          : ''}
                      </p>
                    </div>
                  )}
                </div>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                  <button
                    className="select-button"
                    onClick={() => handleSelectAddress(address)}
                    disabled={!!verificationStatus}
                  >
                    Select
                  </button>
                </div>
              </div>
            )
          })}
      </div>
      {isUserAdmin ? (
        <div>
          <button className="add-address-button" onClick={() => showAddAddressModal()}>
            <i className="fas fa-plus"></i>
            Add new address
          </button>
        </div>
      ) : null}
    </Modal>
  )
}

export default EditAddressModal
