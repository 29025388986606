import styled from 'styled-components';

const CorporateLogoStyle = styled.div`
    .company-avatar {
        border-radius: 0.5rem;
        height: 32px;
        width: 32px;
    }

    .company-avatar p {
        color: #b9bbc1;
        font-size: 1rem;
    }

    .company-avatar.withImage {
        background: none;
    }

    .company-avatar.withImage img {
        border-radius: 0.5rem;
    }

    .company-avatar.noImage p {
        display: block;
        text-align: center;
        color: #b9bbc1;
        margin-right: 0px;
    }
`;

export default { CorporateLogoStyle };
