"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledComponents = _interopRequireWildcard(require("styled-components"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var defaultAvatar = (0, _styledComponents.css)(["display:flex;justify-content:center;align-items:center;background:linear-gradient(-180deg,rgb(244,245,246) 0%,rgb(237,238,239) 100%);color:rgb(185,187,193);border-radius:0px;font-family:Montserrat;font-weight:bold;text-align:center;p{text-transform:uppercase;}img{display:flex;object-fit:cover;width:100%;height:100%;}"]);

var AvatarStyles = _styledComponents["default"].div.withConfig({
  displayName: "styles__AvatarStyles",
  componentId: "sc-1kkhhq5-0"
})(["", ";", " ", ""], defaultAvatar, function (_ref) {
  var type = _ref.type;

  if (type === 'person') {
    return 'border-radius: 50%; img { border-radius: 50%; }';
  } else if (type === 'company') {
    return 'border-radius: 0; img { border-radius: 0; }';
  }
}, function (_ref2) {
  var size = _ref2.size;
  return "\n        height: ".concat(size * 2.5, "rem;\n        width: ").concat(size * 2.5, "rem;\n        \n        p {\n            font-size: ").concat(size, "rem;\n        }\n    ");
});

var _default = AvatarStyles;
exports["default"] = _default;