import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import ComponentStyles from './styles';
import ItemAccordian from './itemAccordian';
import postAnalytics from '../../../../../utils/postAnalytics.js';

const { SubmittedOrdersContainer, AllOrders } = ComponentStyles;

export class SubmittedOrders extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      direction: 'desc',
    };
  }

  render() {
    return (
      <section style={{ flexGrow: '1', marginRight: '1.5rem', width: '100%' }}>
        {!_.isNil(this.props.recentOrders) && (
          <div>
            <h2>Submitted Orders</h2>
          </div>
        )}

        <SubmittedOrdersContainer>
          <div
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <div className='order-detail-header'>
              {/* <li className="li Submit">Submitted<i onClick={() => this.sortByDate(this.props.recentOrders)} className="far fa-arrow-down" /></li> */}
              <li className='li Submit'>Created On</li>
              <li className='li Status'>Status</li>
              <li className='li Order'>P.O. Number</li>
              <li className='li Supplier'>Supplier</li>
              <li className='li Items'>Items</li>
              <li className='li Subtotal'>Subtotal</li>

              {/* <img alt='supplier-logo' src={blobUrl + '/' + order.Supplier.IconLogo} />
                            <h3>{order.Supplier.Name}</h3> */}
              {/* <p className='viewButton'>View order detail</p> */}
            </div>
          </div>

          {!_.isNil(this.props.recentOrders) &&
            _.map(this.props.recentOrders, (order, index) => (
              <ItemAccordian order={order} index={index} key={index} />
            ))}
        </SubmittedOrdersContainer>
        {!this.props.getAllRecords && (
          <section
            style={{
              padding: '0 1.5rem 4rem 0',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <AllOrders>
              <Link
                to="/submittedOrders"
                className='all-order'
                onClick={() => postAnalytics('pm-home-submittedOrdersFooter-click-allOrders')}
              >
                All orders
              </Link>
            </AllOrders>
          </section>
        )}
      </section>
    );
  }
}

export default SubmittedOrders;
