import React from 'react';
import styles from './style.module.css';

function EmpyRequests() {
  return (
    <div className={styles.container}>
      <img
        src='/images/relax.png'
        alt='relax'
        style={{ width: 120, height: 120, marginBottom: 48 }}
      />
      <h3 className={styles.header}>Looks like there’s nothing here...</h3>
      <p className={styles.text}>You deserve a break anyway!</p>
    </div>
  );
}

export default EmpyRequests;
