"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.handleChange = exports.handleBlur = void 0;

var _react = _interopRequireDefault(require("react"));

var _validators = _interopRequireDefault(require("../constants/validators"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var setErrorState = function setErrorState(props, message) {
  var setError = props.setError,
      setErrorMessage = props.setErrorMessage,
      onError = props.onError;
  setError(message ? true : false);
  setErrorMessage(message);
  if (message && _.has(props, 'onError')) onError({
    error: true,
    message: message
  });
};

var handleValidation = function handleValidation(value, props) {
  var optional = props.optional,
      optionalTag = props.optionalTag,
      charLength = props.charLength,
      charLimit = props.charLimit,
      type = props.type;

  if (!optionalTag && !optional && _validators["default"].required(value)) {
    return setErrorState(props, "This field can't be blank");
  }

  if (_.has(props, 'validate')) {
    var response = props.validate(value);

    if (_.has(response, 'errorMessage')) {
      return setErrorState(props, response.errorMessage);
    }
  }

  return setErrorState(props, null);
};

var handleBlur = function handleBlur(event, props) {
  var value = event.target.value;
  handleValidation(value, props);
};

exports.handleBlur = handleBlur;

var handleChange = function handleChange(event, props) {
  var value = event.target.value,
      numberOnlyValue = value.replace(/[^\d]/g, ''),
      mask = props.mask;
  var i = 0,
      lastReplacedIndex = -1;
  var filledMask = mask.replace(/#/g, function (_, j) {
    if (i >= numberOnlyValue.length) {
      return '#';
    }

    lastReplacedIndex = j;
    return numberOnlyValue[i++];
  });
  props.setFormattedValue(filledMask.substring(0, lastReplacedIndex + 1));

  if (_.has(props, 'validateOnChange') && props.validateOnChange === true) {
    handleValidation(value, props);
  }

  props.onChange(event);
};

exports.handleChange = handleChange;