"use strict";

require("core-js/modules/es.number.to-fixed");

require("core-js/modules/es.promise");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.handleBrokenProductImage = exports.handleUndo = exports.deleteItem = exports.handleRemoveClick = exports.handleQuantityBlur = exports.handleQuantityChange = exports.calcTotal = exports.roundTotal = void 0;

var _axios = _interopRequireDefault(require("axios"));

var _apis = require("../apis");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

const CancelToken = _axios.default.CancelToken;
let cancel;

const roundTotal = total => {
  return Math.round((total + Number.EPSILON) * 100) / 100;
};

exports.roundTotal = roundTotal;

const calcTotal = items => {
  let total = 0.0;
  if (items.length === 1) return roundTotal(items[0].UnitPrice * (items[0].Quantity || 0)).toFixed(2);
  items.forEach(item => {
    if (!item.deleted) total = roundTotal(total + item.UnitPrice * (items[0].Quantity || 0));
  });
  return total.toFixed(2);
};

exports.calcTotal = calcTotal;

const updateCartQuantity = (_ref) => {
  let {
    value,
    cartReqIndex,
    reqIndex,
    cart,
    onCartUpdate,
    setNumOfItems,
    setCart,
    isBlur
  } = _ref;

  const newCart = _objectSpread({}, cart);

  let quantity = 0;
  let item = newCart.CartRequisitions[cartReqIndex].Requisitions[reqIndex];
  item.Quantity = value;
  cart.CartRequisitions.forEach(cartReq => {
    cartReq.Requisitions.forEach(req => {
      if (!req.deleted) quantity = quantity + req.Quantity;
    });
  });
  newCart.CartItemTotal = quantity;

  if (isBlur) {
    onCartUpdate(newCart);
    setNumOfItems(quantity);
  }

  setCart(_objectSpread({}, newCart));
};

const handleQuantityChange = (e, state) => {
  const value = e.target.value ? parseInt(e.target.value) : null;

  if (value > 0 || !value) {
    updateCartQuantity(_objectSpread({
      value
    }, state));
  }
};

exports.handleQuantityChange = handleQuantityChange;

const handleQuantityBlur = (e, state) => {
  if (cancel) cancel();
  const cancelToken = new CancelToken(token => cancel = token);
  const {
    testMode,
    cart,
    cartReqIndex,
    reqIndex,
    env
  } = state;
  let item = cart.CartRequisitions[cartReqIndex].Requisitions[reqIndex];
  let value = parseInt(e.target.value);

  if (value > 0 || !value) {
    if (value === 0 || !value) value = 1;
    updateCartQuantity(_objectSpread(_objectSpread({
      value
    }, state), {}, {
      isBlur: true
    }));
  }

  item.Quantity = value;

  if (!testMode) {
    (0, _apis.postItemQuantity)({
      item,
      partNumber: item.SupplierPartId,
      cancelToken,
      env
    });
  }
};

exports.handleQuantityBlur = handleQuantityBlur;

const handleRemoveClick = (_ref2) => {
  let {
    item,
    setPendingItem,
    pendingItem,
    setTimeoutFunc,
    timeout,
    setCurDeletingItem
  } = _ref2;
  console.log("~~~~~~~~~ Remove Clicked for item ".concat(item.Id, " ~~~~~~~~~~"));

  if (pendingItem) {
    clearTimeout(timeout);
    setTimeoutFunc(null);
    setCurDeletingItem(pendingItem);
  }

  const newTimeout = setTimeout(() => {
    console.log("Removing item ".concat(item.Id, " from cart..."));
    setCurDeletingItem(item);
  }, 2500);
  setTimeoutFunc(newTimeout);
  setPendingItem(item);
};

exports.handleRemoveClick = handleRemoveClick;

const deleteItem = async (_ref3) => {
  let {
    item,
    setDeletedItem,
    testMode,
    env
  } = _ref3;
  console.log("Deleting item ".concat(item.Id, "..."));

  if (testMode) {
    setTimeout(() => {
      setDeletedItem(item);
    }, 2500);
  } else {
    await (0, _apis.deleteCartItem)({
      partId: item.SupplierPartId,
      supplierId: item.SupplierId,
      env
    }).then(() => {
      setDeletedItem(item);
    });
  }
};

exports.deleteItem = deleteItem;

const handleUndo = (_ref4) => {
  let {
    timeout,
    setTimeoutFunc,
    setPendingItem
  } = _ref4;
  console.log('Undo remove item.');
  clearTimeout(timeout);
  setTimeoutFunc(null);
  setPendingItem(null);
};

exports.handleUndo = handleUndo;

const handleBrokenProductImage = e => {
  e.target.src = "".concat(env.blobUrl, "/procurement/images/No-Product-Image.png");
};

exports.handleBrokenProductImage = handleBrokenProductImage;