import React, { PureComponent } from 'react';
import ComponentStyles from './styles';
import _ from 'lodash';
import { Button } from '../../../lib/@q-core/react-components';
import AddPCardModal from './components/features/AddPcardModal';
import SelectCardModal from './components/features/PcardSelectModal';
import moment from 'moment';
import visaLogo from './img/finance-creditcard-visa.png';
import amexLogo from './img/finance-creditcard-amex.png';
import mastercardLogo from './img/finance-creditcard-mastercard.png';
import discoverLogo from './img/finance-creditcard-discover.png';

const { PCardWrapper, DefaultWrapper } = ComponentStyles;

class PCard extends PureComponent {
    constructor(props) {
        super(props);

        let defaultCard = _.find(props.cards, { IsDefault: true });

        this.state = {
            openAddModal: false,
            openFirstModal: false,
            openSelectModal: false,
            cards: _.isEmpty(props.cards) ? [] : props.cards,
            default: !_.isEmpty(defaultCard) ? defaultCard : !_.isEmpty(props.cards[0]) ? props.cards[0] : '',
            userData: props.userData,
        };
    }

    componentDidMount() {
        if (!_.isEmpty(this.state.default)) {
            this.props.setPaymentCardId(this.state.default.Id);
        }
    }

    setAddModal = value => {
        let bool = !value;
        this.setState({
            openAddModal: bool,
        });
    };

    setSelectModal = (value, openAdd) => {
        let bool = !value;
        this.setState({
            openSelectModal: bool,
        });
        if (openAdd) {
            setTimeout(() => {
                this.setAddModal(this.state.openAddModal);
            }, 100);
        }
    };

    setTypeImg = Type => {
        let logo;
        switch (Type) {
            case 'Visa':
                logo = visaLogo;
                break;
            case 'Amex':
                logo = amexLogo;
                break;
            case 'Mastercard':
                logo = mastercardLogo;
                break;
            case 'Discover':
                logo = discoverLogo;
                break;
            default:
                break;
        }
        return logo;
    };

    toLocalFromUTC(params) {
        const { utcDate, utcFormat } = params;
        let { format } = params,
            formattedUTCDate = '';

        if (!format) {
            format = 'YYYY-MM-DD';
        }

        if (utcFormat) {
            formattedUTCDate = moment(utcDate, utcFormat).format('YYYY-MM-DDTHH:mm');
        }

        if (formattedUTCDate === 'Invalid date' || formattedUTCDate === '') {
            formattedUTCDate = moment(utcDate.replace(/\+00:00$/, '')).format('YYYY-MM-DDTHH:mm');
        }
        return moment
            .utc(formattedUTCDate)
            .local()
            .format(format);
    }

    setCard(card) {
        if (card.Id) {
            this.props.setPaymentCardId(card.Id);
        }
        this.setState({
            default: card,
        });
        this.setSelectModal('false');
    }

    declareSelected = (newDefault, updatedCards) => {
        this.setState({
            default: newDefault,
            cards: updatedCards,
        });
        this.props.setPaymentCardId(newDefault.Id);
    };

    expirationCheck = value => {
        let active_time = moment.utc(value, 'YYYY-MM-DD[T]HH:mm[Z]');
        let d = new Date(); //compare to right now date object
        let validDate = active_time.isAfter(d);
        if (validDate) {
            this.props.setExpiredCard(false);
            return true;
        } else {
            this.props.setExpiredCard(true);
            return false;
        }
    };

    render() {
        return (
            <div>
                {_.isEmpty(this.state.default) && (
                    <PCardWrapper>
                        <div className='iconWrapper'>
                            <i className='fal fa-credit-card' />
                        </div>
                        <p className='addCardxyz'>Add a Visa, Mastercard, Discover, or American Express</p>
                        <p className='addCardxyz'>card for easy checkout.</p>
                        <Button
                            type='indigoVibrant'
                            shape='square'
                            title='Add card'
                            onClick={() => this.setAddModal(this.state.openAddModal)}
                        />
                    </PCardWrapper>
                )}
                {!_.isEmpty(this.state.default) && (
                    <DefaultWrapper>
                        <div className='cardObject'>
                            <div className='withChange'>
                                <p className='defaultName'>{this.state.default.Name}</p>
                                {this.state.default && (
                                    <p
                                        className='changeLabel'
                                        onClick={() => {
                                            this.setSelectModal(this.state.setSelectModal);
                                        }}
                                    >
                                        Change
                                    </p>
                                )}
                            </div>
                            <div className='infoContainer'>
                                <img
                                    className='CCType'
                                    alt='qmerit-wordmark'
                                    src={this.setTypeImg(this.state.default.Type)}
                                />
                                <div className='textContainer'>
                                    <p className='defaultInfo'>
                                        {this.state.default.Type} ending in {this.state.default.Number}
                                    </p>
                                    <p className='defaultInfo'>
                                        {this.expirationCheck(this.state.default.ExpirationDate) === false
                                            ? 'Expired'
                                            : 'Expires'}{' '}
                                        {this.toLocalFromUTC({
                                            utcDate: this.state.default.ExpirationDate,
                                            format: 'MMM-YYYY',
                                        })}
                                        {this.expirationCheck(this.state.default.ExpirationDate) === false ? (
                                            <i className='far fa-exclamation-circle' />
                                        ) : (
                                            ''
                                        )}
                                    </p>
                                </div>
                            </div>
                        </div>
                        {this.state.openSelectModal && (
                            <SelectCardModal
                                cards={this.state.cards}
                                setSelectModal={this.setSelectModal}
                                setCard={card => this.setCard(card)}
                                setAddModal={this.setAddModal}
                                toLocalFromUTC={params => this.toLocalFromUTC(params)}
                                updateCards={updatedCards => this.updateCards(updatedCards)}
                            />
                        )}
                    </DefaultWrapper>
                )}
                {this.state.openAddModal && (
                    <AddPCardModal
                        userData={this.state.userData}
                        setAddModal={this.setAddModal}
                        updateCards={this.props.updateCards}
                        declareSelected={this.declareSelected}
                    />
                )}
            </div>
        );
    }
}

export default PCard;
