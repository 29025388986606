import React, { useState } from 'react';
import _ from 'lodash';
import { getVerifiedCart, calcTotalItems } from '../../../Cart/helpers';
import { Button } from '../../../../../../lib/@q-core/react-components';
import { postCheckout } from '../../../../apis';
import ComponentStyles from './styles';
import LoginErrorModal from '../../LoginErrorModal';

const { CartSummaryWrapper, CartSummaryContainer } = ComponentStyles;

const isBrowser = typeof window !== 'undefined' && window.env;
const blobUrl = isBrowser
  ? window.env.blobUrl
  : process.env.RAZZLE_RUNTIME_BLOB_URL;


const checkOut = async (
  cart,
  addresses,
  userData,
  formValues,
  errors,
  history,
  setLoader,
  setShowLoginErrorModal
) => {
  if (_.isArray(errors) && _.isEmpty(errors)) {
    if (!_.isNil(setLoader) && _.isFunction(setLoader)) {
      setLoader(true);
    }
    const result = await postCheckout(cart, addresses, userData, formValues);

    if (result && result?.error?.response?.status === 409) {
      setLoader(false);
      setShowLoginErrorModal(true);
      return;
    }

    if (result && !result.fetchError) {
      history.push('/');
    }
  }
};

const CartSummary = (props) => {
  const {
    cart,
    undoSuppliers,
    addresses,
    userData,
    formValues,
    expiredCard,
    shippingAddress,
    billingAddress,
  } = props;
  
  const [submitted, setSubmitted] = useState(false);
  const [showLoginErrorModal, setShowLoginErrorModal] = useState(false);
  const verifiedCart = getVerifiedCart(cart, addresses);
  const hasEmptyAddress = !shippingAddress || !shippingAddress.Id || !billingAddress || !billingAddress.Id

  const addressUnVerifiedForSuppliers = (
    shippingAddress.UnVerifiedForSuppliers || []
  ).concat(billingAddress.UnVerifiedForSuppliers || []);
  return (
    <CartSummaryWrapper>
      <LoginErrorModal
        show={showLoginErrorModal}
        onClose={() => setShowLoginErrorModal(false)}
       />
      <CartSummaryContainer>
        <div className='cart-summary-header'>
          <h3>Checkout</h3>
        </div>
        <div className='cart-suppliers-container'>
          {cart.CartRequisitions.map((req, i) => {
            let totalItems = calcTotalItems(req.Requisitions);
            const isAddressVerificationPending = _.includes(
              addressUnVerifiedForSuppliers,
              req.Supplier.Id
            );
            return (
              <div
                className={
                  'supplier-summary' +
                  (req.Requisitions.length === 0 ? ' removed' : '') +
                  (isAddressVerificationPending ? ' unable-to-checkout' : '')
                }
                key={i}
              >
                <div className='supplier-items-summary '>
                  <img
                    alt='supplier-logo'
                    src={`${blobUrl}/${req.Supplier.IconLogo}`}
                  />
                  <p className='small lite'>
                    {totalItems} item{totalItems > 1 && 's'}
                  </p>
                </div>
                <p className='supplier-total'>
                  {(_.includes(undoSuppliers, req.Supplier.Id) ||
                    isAddressVerificationPending) && (
                    <i className='far fa-exclamation-circle' />
                  )}
                  <span>${!_.isNil(req.TotalAmount) && req.TotalAmount}</span>
                </p>
              </div>
            );
          })}
        </div>
        <div className='cart-summary-details'>
          <ul>
            <li>
              <p className='small'>Items subtotal:</p>
              <p className='supplier-total'>${verifiedCart.CartTotal}</p>
            </li>
          </ul>
        </div>
        <div className='cart-summary-total'>
          <div className='cart-total'>
            <h5>order total</h5>
            <p className='total currency'>${verifiedCart.CartTotal}</p>
          </div>
          <Button
            type='indigoVibrant'
            shape='square'
            title='Place Order'
            className='place-order'
            disabled={
              !verifiedCart.CartItemTotal ||
              _.isEmpty(props.formValues.phone) ||
              props.formValues.phone.length < 11 ||
              hasEmptyAddress ||
              ((props.paymentMethod === 'CCard' ||
                props.paymentMethod === 'PONumber') &&
                _.isEmpty(shippingAddress) &&
                _.isEmpty(billingAddress) &&
                (_.isNull(props.formValues.paymentCardId) ||
                  expiredCard === true))
            }
            onClick={() => {
              if (
                !submitted &&
                ((props.paymentMethod === 'CCard' &&
                  !_.isNull(props.formValues.paymentCardId)) ||
                  props.paymentMethod === 'PONumber')
              ) {
                setSubmitted(true);
                checkOut(
                  verifiedCart,
                  addresses,
                  userData,
                  formValues,
                  props.errors,
                  props.history,
                  props.setLoader,
                  setShowLoginErrorModal
                );
              }
            }}
          />
        </div>
      </CartSummaryContainer>
    </CartSummaryWrapper>
  );
};

export default CartSummary;
