import { useCartContext } from '../../../../../hooks/useCartContext'
import { safeLocalStorage } from '../../../../../utils/safeStorage'
import deleteCartItems from '../../../../apis/deleteCartItems'
import Button from '../../../../components/Button'
import { BulkPanelContainer } from './styles'

const BulkPanel = ({setDeletedItems, setShowDeleteModal}) => {
  const userData = safeLocalStorage.getJSONItem('userData')
  const { state, clearSelectedItem, showModal, setCart, showDeleteItemsModal } = useCartContext()
  const { selectedItems, availableContractCodes } = state

  const onClear = () => {
    clearSelectedItem()
  }

  const onDeleteItems = async () => {
    const updatedCart = await deleteCartItems({ userData, items: selectedItems })

    setCart(updatedCart)
    onClear()
  }

  const showContractCodes = !!availableContractCodes.length

  return (
    <BulkPanelContainer>
      <div className="panel">
        <div className="panel-leading">
          <h3 className="options-text">Options</h3>
          <div className="actions-container">
            <div className="items-count-container">
              <i className="fas fa-times close-icon" onClick={onClear} />
              <p className="selected-items">{selectedItems.length} Selected</p>
            </div>
            {showContractCodes && <Button text={'Select contract code'} variant={'primary'} onClick={showModal} />}
            <Button
              styles={{ background: 'rgba(0,0,0,0)', color: '#FFF' }}
              text={'Delete'}
              variant={'reject'}
              onClick={showDeleteItemsModal}
            />
          </div>
        </div>
      </div>
    </BulkPanelContainer>
  )
}

export default BulkPanel
