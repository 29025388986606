import styled from 'styled-components'

export const DeleteItemsModalContainer = styled.div`
  display: flex;
  flex-direction: column;

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 16px 20px;
    min-width: 350px;

    h3 {
      color: rgba(0, 0, 0, 1);
      font-weight: 700;
      font-family: 'Poppins';
      font-size: 18px;
    }
  }

  .close-icon {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.25);
  }

  p {
    padding: 0 24px;
    font-size: 14px;
    font-family: 'Rubik';
    margin-top: 14px;

    b {
      font-weight: 700;
    }
  }

  .buttons-wrapper {
    display: flex;
    gap: 16px;
    justify-content: space-between;
    padding: 16px 24px;
    margin-top: 48px;

    button {
      flex: 1;
      height: 36px;
      background: #fff;
      color: rgba(0, 0, 0, 0.5);
      font-family: 'Rubik';
      font-size: 14px;
      font-weight: 500;
      border-radius: 4px;
    }

    button:hover {
      background: rgba(0, 0, 0, 0.05)
    }

    button:active {
      background: rgba(0, 0, 0, 0.12)
    }

    .delete-btn {
      border: 2px solid rgba(238, 35, 0, 1);
      color: rgba(0, 0, 0, 1);
    }

    .delete-btn:hover {
      background: rgba(255, 158, 128, 1);
    }e

    .delete-btn:active {
      background: rgba(255, 110, 64, 1);
    }
  }
`
