import gsap from 'gsap'
import _ from 'lodash'
import { Fragment, useEffect, useState } from 'react'
import { useCartContext } from '../../../hooks/useCartContext'
import { safeLocalStorage } from '../../../utils/safeStorage'
import { Loader, Wrapper } from '../../components'
import SsoIframe from '../../components/EQL/SsoIframe'
import { CartSummary, EmptyCart, SupplierCart } from './components'
import BulkPanel from './components/BulkPanel'
import CartHeader from './components/CartHeader'
import ContractCodesModal from './components/ContractCodesModal'
import { handleCheckout, handleUndoState } from './helpers'
import FeatureStyles from './styles'
import Attachments from '../../components/Attachments'
import Toasts from '../../components/Toasts'
import DeleteItemsModal from './components/DeleteItemsModal'

const { CartPageContainer, SupplierCartsContainer, CartBodyContainer } = FeatureStyles

const inIframe = () => {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

const isBrowser = typeof window !== 'undefined' && window.env

const eqlRoot = isBrowser ? window.env.eqlRoot : process.env.RAZZLE_RUNTIME_EQL_ROOT

export const Cart = (props) => {
  const { state, setCart, setLoading, setCartItemTotal, setUndo, setLoadingItem } = useCartContext()
  const { cart, eqlCartCount, loading, cartItemTotal, undo, loadingItems, selectedItems, showModal, showDeleteItemsModal } = state
  const [toastMessage, setToastMessage] = useState([]);
  const { userData, history } = props

  // const deletedItemsTotalCount = deletedItems.reduce((sum, item) => sum + item.Quantity, 0);

  useEffect(() => {
    if (inIframe()) window.top.location.href = window.self.location.href
  }, [])

  useEffect(() => {
    if (cart && eqlCartCount >= 0) {
      setLoading(false)
      setCartItemTotal(cart.CartItemTotal)
      safeLocalStorage.setJSONItem('cart', cart)
    }
  }, [cart, eqlCartCount])

  //todo:undo
  // useEffect(() => {
  //   if (cart && undo.active) {
  //     setUndo({
  //       popup: false,
  //       active: false,
  //       supplierIds: undo.supplierIds,
  //     })
  //   }
  // }, [undo.active])

  // useEffect(() => {
  //   if (cart) {
  //     if (undo.popup) {
  //       gsap.to('.undo-popup', { bottom: 0, ease: true, duration: 0.2 })
  //     } else {
  //       gsap.to('.undo-popup', { bottom: '-5rem', ease: true, duration: 0.2 })
  //     }
  //   }
  // }, [undo.popup])

  const handleSetLoadingItem = (supplierId) => {
    setLoadingItem((prevState) => {
      const updatedState = [...prevState]
      updatedState.push(supplierId)
      return updatedState
    })
  }

  const handleUnSetLoadingItem = (supplierId) => {
    setLoadingItem((prevState) => {
      let updatedState = [...prevState]
      updatedState = updatedState.filter((value) => value !== supplierId)
      return updatedState
    })
  }

  return (
    <Wrapper {...props} cartItemTotal={cartItemTotal}>
      <CartPageContainer>
        {loading ? (
          <Loader className="easeIn" message="Loading your cart..." />
        ) : (
          <Fragment>
            {cart && cartItemTotal === 0 && eqlCartCount > 0 && (
              <SsoIframe eql forwardUrl={`${eqlRoot}/shopping_carts/active`} />
            )}
            {cart && cartItemTotal === 0 && eqlCartCount === 0 && (
              <EmptyCart defaultCheckout={props.defaultCheckout} userData={props.userData} />
            )}
            {cart && cartItemTotal > 0 && cart.CartRequisitions.length > 0 && (
              <Fragment>
                <CartBodyContainer>
                  <SupplierCartsContainer>
                    <CartHeader />
                    {cart.CartRequisitions.length > 0 &&
                      _.map(cart.CartRequisitions, (req, i) => (
                        <Fragment key={i}>
                          {!_.isNull(req) && (
                            <SupplierCart
                              key={i}
                              supplier={{
                                ...req.Supplier,
                                AccountNumber: req.Requisitions[0].AccountNumber,
                                AccountLabel: req.Requisitions[0].AccountLabel,
                              }}
                              items={req.Requisitions}
                              userData={userData}
                              loadingSupplierItems={loadingItems}
                              //todo:undo
                              // onDeletingItem={(supplierId) =>
                              //   handleUndoState({
                              //     action: 'remove',
                              //     supplierId,
                              //     setUndo,
                              //     undo,
                              //   })
                              // }
                              cart={cart}
                              setCart={setCart}
                              TotalAmount={req.TotalAmount}
                              //undo={undo.active}
                              onSetSupplierLoading={handleSetLoadingItem}
                              onUnSetSupplierLoading={handleUnSetLoadingItem}
                              history={props.history}
                            />
                          )}
                        </Fragment>
                      ))}
                  </SupplierCartsContainer>
                  <div>
                    <div style={{ display: 'flex', flexDirection: "column", width: 270, position: 'sticky', top: 0 }}>
                      <CartSummary
                        onCheckoutClick={() => handleCheckout({ cart, history })}
                        defaultCheckout={props.defaultCheckout}
                        userData={userData}
                      />
                      <Attachments userData={userData} attachments={cart?.Attachments} />
                    </div>
                  </div>
                </CartBodyContainer>
                {selectedItems.length > 0 && <BulkPanel />}
                {showModal && <ContractCodesModal />}
                {showDeleteItemsModal && <DeleteItemsModal setToastMessage={setToastMessage} />}
              </Fragment>
            )}
            
            <Toasts
                position="center"
                toastlist={toastMessage}
                setList={() => setToastMessage([])}
              />
            {/* todo: implement undo functionality */}
            {/* <div className="undo-popup">
                  <div className="message">
                    <i className="far fa-exclamation-circle" />
                    <p className="small">Item deleted.</p>
                  </div>
                  <button className="undo-btn" onClick={() => setUndo({ ...undo, active: true })}>
                    UNDO
                  </button>
                </div> */}
          </Fragment>
        )}
      </CartPageContainer>
    </Wrapper>
  )
}

export default Cart
