import React, { PureComponent, Fragment } from 'react';
import { SsoIframe } from '../../components/EQL/SsoIframe';
import Header from '../../components/Header';
import parseUrl from '../../../utils/parseUrl';
export class PoRedirects extends PureComponent {

    extensionSupportsPunchoutHeader = () => {
        const supplierId = parseUrl(window.location.href, 'supplier');
        return this.props.hasExtension && (supplierId === 2 || supplierId === 31 || supplierId === 49);
    };

    render() {
        return (
            <Fragment>
                <Header {...this.props} punchOut={true} />
                {this.props.hasExtension !== undefined && (
                    <section id='product-view'>
                        <SsoIframe
                            history={this.props.history}
                            poForward={false}
                            fullScreen={this.extensionSupportsPunchoutHeader()}
                            hasExtension={this.props.hasExtension}
                        />
                    </section>
                )}
            </Fragment>
        );
    }
}

export default PoRedirects;
