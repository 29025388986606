import { FormatCart } from '../../../lib/@q-core/raiven-utils'
import { safeLocalStorage } from '../../utils/safeStorage'
import config from './apiConfig'
import { cancelableFetchData } from './fetchData'

export default ({ AccountNumber, item, userData, partNumber, cancelToken, supplierId, qty, contractCodeId }) => {
  const userToken = userData.Network[userData.activeNetworkIndex].UserToken
  return new Promise((resolve, reject) => {
    const options = {
      ...config.postItemQuantity,
      cancelToken,
      url: `${config.postItemQuantity.url}/${userToken}/UpdateQuantity`,
      body: {
        UserToken: userToken,
        WebsiteId: 0,
        URL: window.location.href,
        StorId: undefined,
        SupplierPartNumber: partNumber,
        SupplierId: parseInt(supplierId, 10),
        Quantity: qty,
        ContractCodeId: contractCodeId || item.ContractCodeId,
        AccountNumber,
        Id: item.Id,
        Product: {
          SupplierPartNumber: partNumber,
          Quantity: item.Quantity,
          SupplierId: parseInt(supplierId, 10),
          ContractCodeId: contractCodeId || item.ContractCodeId
        },
        Analytics: {
          Action: 'cartUpdate',
          Website: window.location.host,
          Url: window.location.href,
          SupplierPartNumber: partNumber,
          Quantity: item.Quantity,
          ContractId: parseInt(supplierId, 10),
          TimeStamp: new Date(),
        },
      },
    }
    return cancelableFetchData(options)
      .then((data) => {
        if (data && !data.ErrorCode) {
          let stateObject = {
            ...data,
            fetchError: !!(data && data.ErrorCode),
          }
          stateObject = FormatCart.cart(stateObject)
          safeLocalStorage.setJSONItem('cart', stateObject)
          resolve(stateObject)
        } else {
          reject({ fetchError: true, details: data })
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}
