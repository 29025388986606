// import _ from 'lodash';
const _ = require('lodash');

const model = {
    jobId: '',
    Shipping: {
        Address: {},
    },
    Billing: {
        Address: {},
    },
    PurchaseOrderComment: '',
    InternalPurchaseOrderComment: '',
    PaymentCardId: '',
    CartRequisitions: [],
};

export const formatCheckout = (cart, addresses, formValues) => {
    let checkoutData = model;
    checkoutData = formatAddresses(addresses, checkoutData);
    checkoutData.CartRequisitions = formatCart(cart);
    checkoutData.JobId = _.has(formValues, 'jobId') ? formValues.jobId : '';
    checkoutData.PurchaseOrderComment = _.has(formValues, 'purchaseOrderComment')
        ? formValues.purchaseOrderComment
        : '';
    checkoutData.InternalPurchaseOrderComment = _.has(formValues, 'internalComment') ? formValues.internalComment : '';
    checkoutData.PaymentCardId = _.has(formValues, 'paymentCardId') ? formValues.paymentCardId : '';
    return checkoutData;
};

const formatAddresses = (addresses, data) => ({
    ...data,
    ...addresses,
});

export const formatCart = (cart, options) => {
    let formattedCart = null;
    let reqIdArray = [];

    if (_.has(cart, 'CartRequisitions') && _.isArray(cart.CartRequisitions) && !_.isEmpty(cart.CartRequisitions)) {
        formattedCart = _.map(cart.CartRequisitions, (item, index) => {
            let req = {
                Id: item.Id,
                RequisitionIds: [],
                PurchaseOrderNumber: item.PurchaseOrderNumber,
            };

            req.RequisitionIds = _.map(item.Requisitions, (reqItem, index) => {
                if (_.has(options, 'idsOnly')) {
                    reqIdArray.push(reqItem.Id);
                }
                return reqItem.Id;
            });

            return req;
        });
    }

    if (_.has(options, 'idsOnly')) {
        formattedCart = reqIdArray;
    }

    return formattedCart;
};

export default {
    formatCheckout,
    formatCart,
};
