import React from 'react';
export const RaivenLogo = ({ height = 20, width = 80, opacity = 0.25 }) => <svg style={{ alignSelf: 'center', opacity }} height={height} width={width} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 2048 512">
<g id="Raiven_Wordmark_-_Black">
	<g>
		<path d="M935.8,400l-57.2-82c-2.4,0.3-6,0.4-10.8,0.4h-63.2V400h-52V120h115.2c24.3,0,45.4,4,63.4,12s31.8,19.5,41.4,34.4
			c9.6,14.9,14.4,32.7,14.4,53.2c0,21.1-5.1,39.2-15.4,54.4c-10.3,15.2-25,26.5-44.2,34l64.4,92H935.8z M917,178.4
			c-11.7-9.6-28.9-14.4-51.6-14.4h-60.8v111.6h60.8c22.7,0,39.9-4.9,51.6-14.6c11.7-9.7,17.6-23.5,17.6-41.4S928.7,188,917,178.4z"
			/>
		<path d="M1189,206.6c16.7,15.1,25,37.8,25,68.2V400h-47.2v-26c-6.1,9.3-14.9,16.5-26.2,21.4c-11.3,4.9-25,7.4-41,7.4
			s-30-2.7-42-8.2s-21.3-13.1-27.8-22.8c-6.5-9.7-9.8-20.7-9.8-33c0-19.2,7.1-34.6,21.4-46.2c14.3-11.6,36.7-17.4,67.4-17.4h55.2
			V272c0-14.9-4.5-26.4-13.4-34.4s-22.2-12-39.8-12c-12,0-23.8,1.9-35.4,5.6c-11.6,3.7-21.4,8.9-29.4,15.6l-19.6-36.4
			c11.2-8.5,24.7-15.1,40.4-19.6c15.7-4.5,32.4-6.8,50-6.8C1148.3,184,1172.3,191.5,1189,206.6z M1143.4,357.8
			c9.7-5.7,16.6-13.9,20.6-24.6v-24.8h-51.6c-28.8,0-43.2,9.5-43.2,28.4c0,9.1,3.6,16.3,10.8,21.6c7.2,5.3,17.2,8,30,8
			C1122.5,366.4,1133.7,363.5,1143.4,357.8z"/>
		<path d="M1260,186.4h50V400h-50V186.4z"/>
		<path d="M1579.6,186.4L1488,400h-51.6l-91.6-213.6h52l66.4,158.4l68.4-158.4H1579.6z"/>
		<path d="M1801.8,309.2h-167.6c2.9,15.7,10.6,28.2,23,37.4c12.4,9.2,27.8,13.8,46.2,13.8c23.5,0,42.8-7.7,58-23.2l26.8,30.8
			c-9.6,11.5-21.7,20.1-36.4,26c-14.7,5.9-31.2,8.8-49.6,8.8c-23.5,0-44.1-4.7-62-14s-31.7-22.3-41.4-39
			c-9.7-16.7-14.6-35.5-14.6-56.6c0-20.8,4.7-39.5,14.2-56.2s22.6-29.7,39.4-39s35.7-14,56.8-14c20.8,0,39.4,4.6,55.8,13.8
			s29.2,22.1,38.4,38.8c9.2,16.7,13.8,35.9,13.8,57.8C1802.6,297.9,1802.3,302.8,1801.8,309.2z M1654,238.2
			c-11.1,9.5-17.8,22.1-20.2,37.8H1755c-2.1-15.5-8.7-28-19.6-37.6s-24.5-14.4-40.8-14.4C1678.6,224,1665.1,228.7,1654,238.2z"/>
		<path d="M2023.6,207.6c16.3,15.7,24.4,39.1,24.4,70V400h-50V284c0-18.7-4.4-32.7-13.2-42.2c-8.8-9.5-21.3-14.2-37.6-14.2
			c-18.4,0-32.9,5.5-43.6,16.6s-16,27-16,47.8v108h-50V186.4h47.6V214c8.3-9.9,18.7-17.3,31.2-22.4c12.5-5.1,26.7-7.6,42.4-7.6
			C1985.7,184,2007.3,191.9,2023.6,207.6z"/>
	</g>
	<g id="Round_Tittle_-_Black">
		<circle cx="1285" cy="120" r="36"/>
	</g>
	<g id="R_-_Black_1_">
		<path d="M468.5,354c31.2-16.1,55.1-37.8,71.7-65.1c16.6-27.3,24.9-59.2,24.9-95.8c0-39-9.5-73.1-28.5-102.4
			c-19-29.2-46.1-51.7-81.2-67.3C420.3,7.9,379,0,331.8,0H139.4h-54H0l85.3,125.9c59.5-33.4,153.6-19.7,181.6,9.4
			c28.3,29.5,35.4,31.5,66.1,45.9c30.7,14.4,54,32.7,62.9,56.3c7.6,20.1-0.7,6.9-38.1,6.9c-19.4,0-52.7,0-82.8,3
			c-23.8,2.3-99.3,11.6-142.2,66.8c-0.3,0.3-0.5,0.6-0.8,1c-30.3,39.1-46.7,87.3-46.7,136.8v60h172.6V383.3h46.8l87,128.7H576
			L468.5,354z"/>
	</g>
</g>
</svg>

export const RLogo = () => {
	return <svg width="135" height="120" viewBox="0 0 135 120" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g id="raiven-logo">
			<path id="Logo" d="M110.305 82.9688C117.617 79.1953 123.219 74.1094 127.109 67.7109C131 61.3125 132.945 53.8359 132.945 45.2578C132.945 36.1172 130.719 28.125 126.266 21.2578C121.812 14.4141 115.461 9.14062 107.234 5.48438C99.0078 1.85156 89.3281 0 78.2656 0H33.1719H20.5156H0.5L20.4922 29.5078C34.4375 21.6797 56.4922 24.8906 63.0547 31.7109C69.6875 38.625 71.3516 39.0938 78.5469 42.4688C85.7422 45.8438 91.2031 50.1328 93.2891 55.6641C95.0703 60.375 93.125 57.2812 84.3594 57.2812C79.8125 57.2812 72.0078 57.2812 64.9531 57.9844C59.375 58.5234 41.6797 60.7031 31.625 73.6406C31.5547 73.7109 31.5078 73.7812 31.4375 73.875C24.3359 83.0391 20.4922 94.3359 20.4922 105.938V120H60.9453V89.8359H71.9141L92.3047 120H135.5L110.305 82.9688Z" fill="url(#paint0_linear_1793_684)"/>
		</g>
		<defs>
			<linearGradient id="paint0_linear_1793_684" x1="12.6615" y1="-16.1051" x2="120.415" y2="137.782" gradientUnits="userSpaceOnUse">
				<stop stopColor="#00CDFF"/>
				<stop offset="1" stopColor="#081CFD"/>
			</linearGradient>
		</defs>
	</svg>
}
