import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faTrashAlt, faWarning } from '@fortawesome/pro-regular-svg-icons'
import { useDropdown } from '../../../../../hooks/useDropdown'
import { Dropdown } from '../../../../components/Dropdown'

function OrderEditSubitem({
    index,
    secondIndex,
    subitem,
    contractCodes,
    handleEditOrder,
    handleInputBlur,
    handleRemoveItem,
    setShowWarningMessage,
    hasOneItemLeft,
    onSelect,
    ...props
}) {
    const dropdownState = useDropdown()


    function selectContractCode(option) {
        console.log('option: ', option)
        console.log('index: ', index)
        console.log('secondIndex: ', secondIndex)
        onSelect(option, index, secondIndex);
        dropdownState.setSelectedOption(option)
        dropdownState.setDropdownState((prev) => ({
            ...prev,
            show: false,
        }))
    }

    return (
        <div
            key={subitem.Id}
            style={{
                display: 'flex',
                alignItems: 'center',
                gap: 16,
                padding: 16,
            }}
        >
            <img
                src={subitem.ImageUrl}
                alt="product default"
                style={{ width: 60, height: 60 }}
                onError={(e) => {
                    e.currentTarget.src = '/images/product-default.png'
                }}
            />
            <div style={{ flex: 1 }}>
                <p>{subitem.PartName}</p>
                {contractCodes.length > 0 ? (
                    <div className="contracts-dropdown-container">
                        <Dropdown
                            label="Select contract code..."
                            dropdownState={subitem.ContractCodeId ?
                                {
                                    ...dropdownState,
                                    selectedOption: {
                                        id: subitem.ContractCodeId,
                                        label: `${subitem.ContractCodeName}${subitem.ContractCodeDescription ? ` - ${subitem.ContractCodeDescription}` : ''}`
                                    }
                                } : dropdownState
                            }
                            options={contractCodes.map((item) => ({
                                id: item.Id,
                                label: `${item.Name}${item.Description ? ` - ${item.Description}` : ''}`,
                            }))}
                            onSelect={(option) => selectContractCode(option)}
                        />
                    </div>
                ) : null}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                    <p>Qty</p>
                    <input
                        name="Quantity"
                        type="number"
                        min="1"
                        className="quantity-input"
                        value={subitem.Quantity}
                        onChange={(e) => handleEditOrder(e.target.name, e.target.value, index, secondIndex)}
                        onBlur={(e) => handleInputBlur(e.target.name, e.target.value, index, secondIndex)}
                    />
                    <button
                        style={{ padding: 0, margin: 0, background: 'white' }}
                        className="remove-item-button"
                        onClick={(e) =>
                            hasOneItemLeft ? setShowWarningMessage(true) : handleRemoveItem(index, secondIndex)
                        }
                    >
                        <FontAwesomeIcon
                            icon={faTrashAlt}
                            style={{
                                fontWeight: '400',
                                fontSize: '16px',
                                lineHeight: '16px',
                                marginLeft: 18,
                                color: 'rgba(0, 0, 0, 0.25)',
                            }}
                        />
                    </button>
                </div>

                <p
                    style={{
                        marginTop: 4,
                        fontFamily: 'Rubik',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '20px',
                        textAlign: 'right',
                        color: '#000000',
                    }}
                >
                    {new Intl.NumberFormat('en-US', {
                        maximumFractionDigits: 2,
                        currency: 'USD',
                        style: 'currency',
                    }).format(subitem.UnitPrice)}
                    /ea
                </p>
            </div>
            <p
                className="bold-label"
                style={{
                    width: 120,
                    textAlign: 'right',
                    color: '#000000',
                }}
            >
                {isNaN(subitem.Quantity * subitem.ListPrice)
                    ? '-'
                    : new Intl.NumberFormat('en-US', {
                        maximumFractionDigits: 2,
                        currency: 'USD',
                        style: 'currency',
                    }).format(subitem.Quantity * subitem.ListPrice)}
            </p>
        </div>
    )
}

export default OrderEditSubitem