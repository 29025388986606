"use strict";

require("core-js/modules/es.promise");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _fetchData = _interopRequireDefault(require("./fetchData"));

var _apiConfig = _interopRequireDefault(require("./apiConfig"));

var _deviceDataFunctions = require("./deviceDataFunctions");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

const setData = (action, analyticsData, env) => {
  const browser = (0, _deviceDataFunctions.getBrowser)();
  return {
    data: {
      Browser: browser.name,
      BrowserVersion: browser.version,
      OperatingSystem: (0, _deviceDataFunctions.getOS)(),
      UserToken: env.userToken,
      Platform: 'Qmerit-Mini-Cart',
      Url: window.location.href,
      Timestamp: new Date().toISOString(),
      Action: action,
      Device: (0, _deviceDataFunctions.getModel)(),
      Data: analyticsData
    }
  };
};

var _default = (action, data, env) => {
  return new Promise((resolve, reject) => {
    const body = setData(action, data, env);
    const url = "".concat((0, _apiConfig.default)(env).postAnalytics.url);

    const options = _objectSpread(_objectSpread({}, (0, _apiConfig.default)(env).postAnalytics), {}, {
      body,
      url
    });

    return (0, _fetchData.default)(options, env).then(data => {
      if (data && !data.ErrorCode) {
        const stateObject = _objectSpread(_objectSpread({}, data), {}, {
          fetchError: data && data.ErrorCode ? true : false
        });

        resolve(stateObject);
      } else {
        reject({
          fetchError: true,
          details: data
        });
      }
    }).catch(error => {
      reject(error);
    });
  });
};

exports.default = _default;