import React, { useState, useEffect, Fragment } from 'react';
import { isLegacyMarket } from '../../../utils/legacyMarket';
import Header from './Header';
import LegacyHeader from '../LegacyHeader';

const isBrowser = typeof window !== 'undefined' && window.env;

const inIframe = () => {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
};

const HeaderSwitch = props => {
    const [activeNetwork, setActiveNetwork] = useState(null);

    useEffect(() => {
        const data = isBrowser ? JSON.parse(window.localStorage.getItem('userData')) : props.userData;
        const activeNetwork = data ? data.Network[data.activeNetworkIndex] : {};

        setActiveNetwork(activeNetwork);
    }, []);

    return activeNetwork && !inIframe() ? (
        isLegacyMarket(activeNetwork) ? (
      <LegacyHeader {...props} />
        ) : (
            <Header {...props} />
        )
    ) : (
        <Fragment />
    );
};

export default HeaderSwitch;
