import { useContext } from 'react'
import { CartContext } from '../providers/CartProvider'
import { CART_ACTIONS } from '../providers/CartProvider/reducer'

export const useCartContext = () => {
  const { state, dispatch } = useContext(CartContext)

  const setCart = (cartData) => {
    dispatch({ type: CART_ACTIONS.SET_CART, payload: cartData })
  }

  const setLoading = (value) => {
    dispatch({ type: CART_ACTIONS.LOADING_TOGGLE, payload: value })
  }

  const setCartItemTotal = (value) => {
    dispatch({ type: CART_ACTIONS.SET_CART_ITEM_TOTAL, payload: value })
  }

  const setCartUniqueItemTotal = (value) => {
    dispatch({ type: CART_ACTIONS.SET_CART_UNIQUE_ITEM_TOTAL, payload: value })
  }

  const setEqlCartCount = (value) => {
    dispatch({ type: CART_ACTIONS.SET_EQL_CART_COUNT, payload: value })
  }

  const setUndo = (values) => {
    dispatch({ type: CART_ACTIONS.SET_UNDO, payload: values })
  }

  const setLoadingItem = (item) => {
    dispatch({ type: CART_ACTIONS.SET_LOADING_ITEM, payload: item })
  }

  const replaceLoadingItem = (items) => {
    dispatch({ type: CART_ACTIONS.REPLACE_LOADING_ITEMS, payload: items })
  }

  const setSupplierPartsUnitPriceLoading = (value) => {
    dispatch({ type: CART_ACTIONS.REPLACE_LOADING_ITEMS, payload: value })
  }

  const setSelectedItem = (item) => {
    dispatch({ type: CART_ACTIONS.ADD_SELECTED_ITEM, payload: item })
  }

  const removeSelectedItem = (item) => {
    dispatch({ type: CART_ACTIONS.REMOVE_SELECTED_ITEM, payload: item })
  }

  const clearSelectedItem = () => {
    dispatch({ type: CART_ACTIONS.CLEAR_SELECTED_ITEM })
  }

  const selectAllItem = (items) => {
    dispatch({ type: CART_ACTIONS.SET_ALL_SELECTED_ITEMS, payload: items })
  }

  const showModal = () => {
    dispatch({ type: CART_ACTIONS.SHOW_CONTRACT_CODES_MODAL })
  }

  const hideModal = () => {
    dispatch({ type: CART_ACTIONS.HIDE_CONTRACT_CODES_MODAL })
  }

  const setModalSelection = (selection) => {
    dispatch({ type: CART_ACTIONS.SET_MODAL_SELECTION, payload: selection })
  }

  const clearModalSelection = () => {
    dispatch({ type: CART_ACTIONS.CLEAR_MODAL_SELECTION })
  }

  const showDeleteItemsModal = () => {
    dispatch({ type: CART_ACTIONS.SHOW_DELETE_ITEMS_MODAL })
  }

  const hideDeleteItemsModal = () => {
    dispatch({ type: CART_ACTIONS.HIDE_DELETE_ITEMS_MODAL })
  }

  const setCartItemContractCodeId = (item, contractCodeId) => {
    dispatch({ type: CART_ACTIONS.SET_CART_ITEM_CONTRACT_CODE, payload: { item, contractCodeId } })
  }

  const toggleShowContractCodesWarning = (newValue) => {
    dispatch({ type: CART_ACTIONS.TOGGLE_CONTRACT_CODES_WARNING, payload: newValue })
  }

  return {
    state,
    dispatch,
    setCart,
    setLoading,
    setCartItemTotal,
    setCartUniqueItemTotal,
    setEqlCartCount,
    setUndo,
    setLoadingItem,
    replaceLoadingItem,
    setSupplierPartsUnitPriceLoading,
    setSelectedItem,
    removeSelectedItem,
    clearSelectedItem,
    selectAllItem,
    showModal,
    hideModal,
    setModalSelection,
    clearModalSelection,
    showDeleteItemsModal,
    hideDeleteItemsModal,
    setCartItemContractCodeId,
    toggleShowContractCodesWarning,
  }
}
