const Validators = {
    required(value) {
        const test = /^\S/i.test(value);
        return !test;
    },
    email(value) {
        const test = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);
        return !test;
    },
    // date(value) {
    //     let valid;

    //     if (moment(value).isValid() && /^\d{4}-\d{2}-\d{2}$/.test(value)) {
    //         valid = false;
    //     } else {
    //         valid = true;
    //     }

    //     return valid;
    // },
    password(value) {
        const errors = {};

        if (/[A-Z]/g.test(value)) {
            errors.capital = true;
        }

        if (/\d/g.test(value)) {
            errors.number = true;
        }

        if (/^\S{8,}$/g.test(value)) {
            errors.length = true;
        }

        return errors;
    },
    number(value) {
        const test = /^\d+$/.test(value);
        return !test;
    },
    numberOrFloat(value) {
        const test = /^\d+\.\d+|^\d+$/.test(value);
        return !test;
    },
};

export default Validators;
