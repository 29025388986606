import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Wrapper } from '../../components';
import ComponentStyles from './styles';
import { safeLocalStorage } from '../../../utils/safeStorage';
import { setOrders } from '../Marketplace/helpers';
import RecentOrders from '../Marketplace/components/SubmittedOrders';
import postAnalytics from '../../../utils/postAnalytics.js';

const { OrderWrapper, OrderContents } = ComponentStyles;

const SubmittedOrders = props => {
    const user = safeLocalStorage.getJSONItem('userData');

    const [recentOrders, setRecentOrders] = useState(null);

    useEffect(() => {
        setOrders({ user, setRecentOrders, showAll: true });
    }, []);

    const handleBackToMarketplaceClick = () => {
        postAnalytics('pm-submittedOrders-header-click-backToMarketplace')
    }

    return (
        <Wrapper {...props}>
            <OrderWrapper>
                <Link to="/marketplace" onClick={handleBackToMarketplaceClick} className="backButton">
                    <i className="fal fa-arrow-left" />
                    Back to marketplace
                </Link>

                <OrderContents>
                    <RecentOrders recentOrders={recentOrders} user={user} getAllRecords />
                </OrderContents>
            </OrderWrapper>
        </Wrapper>
    );
};

export default SubmittedOrders;
