import styled from 'styled-components';

const LoaderContainer = styled.div`
    width: ${({ width }) => width || '100%'};
    height: ${({ height }) => height || '100%'};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ${({ fullScreen }) =>
        fullScreen &&
        `
        width: 100vw;
        height: 100vh;
    `}

    h4 {
        margin-top: 1rem;
    }
`;

const SVGContainer = styled.div`
    width: 8rem;
`;

export default {
  LoaderContainer,
  SVGContainer,
};
