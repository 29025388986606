import styled from 'styled-components'

const SupplierCartsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .checkout-header {
    padding-bottom: 16px;
  }

  .unable-to-checkout {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      // opacity: 0.6;
      width: 100%;
      height: 100%;
      right: 0;
      left: 0;
      height: 100%;
      background-color: #ffffff9e;
    }
    > .pending-verification-alert {
      position: absolute;
      top: 37%;
      right: 0;
      left: 0;
      background-color: #6a6a6a;
      z-index: 1;
      width: 89%;
      margin: 0 auto;
      display: table;
      padding: 7px;
      max-width: 100%;
      @media (max-width: 767px) {
        width: 80%;
      }
      p {
        color: white;
      }
    }
  }

  .contractCodeLabel {
    font-size: 12px;
    line-height: 16px;
    font-family: 'Rubik';
    color: rgba(0, 0, 0, 0.5);
    margin: 4px 1rem 0 1rem;
  }

  .contractCodeLabel span {
    color: rgba(0, 0, 0, 1);
  }

  .pending-verification-alert {
    position: absolute;
    top: 37%;
    right: 0;
    left: 0;
    background-color: #6a6a6a;
    z-index: 1;
    width: 770px;
    margin: 0 auto;
    display: table;
    padding: 7px;

    p {
      color: #fff;
    }
  }
`

export default { SupplierCartsContainer }
