function isValidJSONString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function setCookie(name, value, days, hours, mins) {
  const cookieVal = typeof value === 'object' ? JSON.stringify(value) : value;
  const date = new Date(
    (new Date().setTime(
      new Date().getTime() + ((days || 1) * (hours || 1) * 60 * (mins || 1) * 60 * 1000),
    )
  )).toUTCString()
  const expires = `; expires ${date}`
  const cookieValue = `${name  }=${  cookieVal || ""   }${expires  }; path=/`
  document.cookie = cookieValue;
  return cookieValue;
}

export function deleteCookie(name, days) {
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  }
  document.cookie = `${name  }=;expires=Thu, 01 Jan 1970 00:00:01 GMT`
}

export const getAllCookies = (passedCookies) => {
  const cookies = passedCookies || document.cookie
  let cookieObject = {};
  cookies
        .split(';')
        .map(cookie => cookie.split('='))
        .forEach(cArr => {
            cookieObject[cArr[0].trim()] = isValidJSONString(cArr[1]) ? JSON.parse(cArr[1]) : cArr[1];
        });
  return cookieObject;
};

export const getParsedCookie = (name) => JSON.parse(getRawCookie(name) ? getRawCookie(name).replace(`${name}=`, '') : {})

export function getRawCookie(name) {
  var nameEQ = `${name  }=`;
  var ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    var c = ca[i];
    // eslint-disable-next-line eqeqeq
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    // eslint-disable-next-line eqeqeq
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export function eraseCookie(name) {
  document.cookie = `${name}=; Max-Age=-99999999;`;
}

export default {
  setCookie,
  getAllCookies,
  getParsedCookie,
  getRawCookie,
  eraseCookie,
};
