import React, { useEffect, useState, useContext, Fragment } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import Select from '../Select'
import styles from './style.module.css'
import { MobileLayout } from '../../App'
import FiltersModal from '../../components/FiltersModal'
import Button from '../Button'
import postAnalytics from '../../../utils/postAnalytics.js'

const RequestsFilters = ({
  activeUser,
  isUserApprover,
  vendors,
  approvers,
  requesters,
  selectedStatus,
  setSelectedStatus,
  selectedRequester,
  setSelectedRequester,
  selectedApprover,
  setSelectedApprover,
  selectedVendor,
  setSelectedVendor,
  requestSearchInput,
  setRequestSearchInput,
  activeTab = false,
  displayedStatuses = [],
  resetFilters,
}) => {
  const isMobile = useContext(MobileLayout)
  const [showModal, setShowModal] = useState(false)

  const hasFilterSet =
    selectedStatus.value !== '' ||
    selectedRequester.value !== '' ||
    requestSearchInput !== '' ||
    selectedApprover.value !== '' ||
    selectedVendor.value !== ''

  const statusOptions = [
    { value: '', label: 'All' },
    { value: 'pending', label: 'Pending' },
    { value: 'approved', label: 'Approved' },
    { value: 'rejected', label: 'Rejected' },
  ].filter(
    (so) =>
      displayedStatuses.length == 0 ||
      so.value == '' ||
      displayedStatuses.some((ds) => ds.toLowerCase() == so.value.toLowerCase())
  )

  useEffect(() => {
    resetFilters()
  }, [activeTab])

  return (
    <div className={styles.filtersWrapper}>
      <div className={styles.filtersHeader} onClick={() => setShowModal(true)}>
        {isMobile ? (
          <img src="/images/icon-faFilter.svg" alt="icon" className={styles.filterIcon} />
        ) : (
          <FontAwesomeIcon icon={faFilter} style={{ fontSize: 16, color: '#000000' }} />
        )}
        <h4 className={styles.title}>Filters</h4>
        {!isMobile && hasFilterSet && (
          <Button
            text="Reset"
            variant="default"
            size="sm"
            styles={{ marginLeft: 'auto' }}
            onClick={() => {
              postAnalytics('pm-requests-filter-reset-click')
              resetFilters()
            }}
          />
        )}
      </div>
      {!isMobile && (
        <Fragment>
          {statusOptions.length > 2 && (
            <Fragment>
              <p className={styles.filtersLabel}>Status</p>
              <Select
                placeholder="Select a status"
                value={selectedStatus}
                onChange={setSelectedStatus}
                options={statusOptions}
              />
            </Fragment>
          )}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p className={styles.filtersLabel} style={{ marginTop: 16 }}>
              Request or PO Number
            </p>
            <input
              className={styles.searchInput}
              placeholder=""
              value={requestSearchInput}
              onChange={(e) => setRequestSearchInput(e.target.value)}
            />
          </div>
          <div>
            <p className={styles.filtersLabel} style={{ marginTop: 16 }}>
              Vendor
            </p>
            <Select
              placeholder="Select a vendor"
              value={selectedVendor}
              onChange={setSelectedVendor}
              options={vendors}
            />
          </div>
          {(activeTab === 'all' || activeTab === 'requests') && (
            <div>
              <p className={styles.filtersLabel} style={{ marginTop: 16 }}>
                Approver
              </p>
              <Select
                placeholder="Select an approver"
                value={selectedApprover}
                onChange={setSelectedApprover}
                options={approvers}
              />
            </div>
          )}

          {isUserApprover ? (
            <div>
              <p className={styles.filtersLabel} style={{ marginTop: 16 }}>
                Requester
              </p>
              <Select
                placeholder="Select a requester"
                value={selectedRequester}
                onChange={setSelectedRequester}
                options={requesters}
              />
            </div>
          ) : null}
        </Fragment>
      )}
      {isMobile && showModal && (
        <FiltersModal
          setShowModal={setShowModal}
          isUserApprover={isUserApprover}
          requesters={requesters}
          approvers={approvers}
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
          selectedRequester={selectedRequester}
          setSelectedRequester={setSelectedRequester}
          activeTab={activeTab}
          selectedApprover={selectedApprover}
          setSelectedApprover={setSelectedApprover}
          statusOptions={statusOptions}
        />
      )}
    </div>
  )
}

export default RequestsFilters
