"use strict";

require("core-js/modules/es.promise");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _fetchData = require("./fetchData");

var _apiConfig = _interopRequireDefault(require("./apiConfig"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _default = (_ref) => {
  let {
    item,
    partNumber,
    cancelToken,
    env
  } = _ref;
  return new Promise((resolve, reject) => {
    const options = _objectSpread(_objectSpread({}, (0, _apiConfig.default)(env).postItemQuantity), {}, {
      cancelToken,
      url: "".concat((0, _apiConfig.default)(env).postItemQuantity.url, "/").concat(env.userToken, "/UpdateQuantity"),
      body: {
        UserToken: env.userToken,
        WebsiteId: 0,
        URL: window.location.href,
        StorId: undefined,
        SupplierPartNumber: partNumber,
        SupplierId: parseInt(item.SupplierId, 10),
        Quantity: item.Quantity,
        Product: {
          SupplierPartNumber: partNumber,
          Quantity: item.quantity,
          SupplierId: parseInt(item.SupplierId, 10)
        },
        Analytics: {
          Action: 'cartUpdate',
          Website: window.location.host,
          Url: window.location.href,
          SupplierPartNumber: partNumber,
          Quantity: item.quantity,
          ContractId: parseInt(item.SupplierId, 10),
          TimeStamp: new Date().toISOString
        }
      }
    });

    return (0, _fetchData.cancelableFetchData)(options, env).then(data => {
      if (data && !data.ErrorCode) {
        const stateObject = _objectSpread(_objectSpread({}, data), {}, {
          fetchError: data && data.ErrorCode ? true : false
        });

        resolve(stateObject);
      } else {
        reject({
          fetchError: true,
          details: data
        });
      }
    }).catch(error => {
      reject(error);
    });
  });
};

exports.default = _default;