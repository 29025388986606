"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var ListContainer = _styledComponents["default"].ul.withConfig({
  displayName: "styles__ListContainer",
  componentId: "eqne99-0"
})(["display:flex;flex-direction:column;padding:0.75rem 0;margin:0;li{display:flex;align-items:center;height:4rem;cursor:pointer;&:last-child{.details{box-shadow:none;}}img{height:2.5rem;width:2.5rem;object-fit:contain;margin-right:0.75rem;}.details{display:flex;justify-content:space-between;align-items:center;height:100%;width:100%;box-shadow:inset 0px -1px 0px 0px rgb(237,238,239);.title{margin-right:auto;}i{color:#969ba5;}}}"]);

var _default = {
  ListContainer: ListContainer
};
exports["default"] = _default;