Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = [{
  elementType: 'geometry',
  stylers: [{
    color: '#f5f5f5'
  }]
}, {
  elementType: 'geometry.fill',
  stylers: [{
    color: '#f4f5f6'
  }]
}, {
  elementType: 'labels.icon',
  stylers: [{
    visibility: 'off'
  }]
}, {
  elementType: 'labels.text.fill',
  stylers: [{
    color: '#616161'
  }]
}, {
  elementType: 'labels.text.stroke',
  stylers: [{
    color: '#f5f5f5'
  }]
}, {
  featureType: 'administrative.land_parcel',
  elementType: 'labels',
  stylers: [{
    visibility: 'off'
  }]
}, {
  featureType: 'administrative.land_parcel',
  elementType: 'labels.text.fill',
  stylers: [{
    color: '#bdbdbd'
  }]
}, {
  featureType: 'administrative.neighborhood',
  elementType: 'labels',
  stylers: [{
    visibility: 'off'
  }]
}, {
  featureType: 'landscape.man_made',
  elementType: 'geometry.fill',
  stylers: [{
    color: '#f4f5f6'
  }]
}, {
  featureType: 'landscape.natural.terrain',
  stylers: [{
    visibility: 'off'
  }]
}, {
  featureType: 'poi',
  elementType: 'geometry',
  stylers: [{
    color: '#eeeeee'
  }]
}, {
  featureType: 'poi',
  elementType: 'geometry.fill',
  stylers: [{
    color: '#edeeef'
  }]
}, {
  featureType: 'poi',
  elementType: 'labels.text',
  stylers: [{
    visibility: 'off'
  }]
}, {
  featureType: 'poi',
  elementType: 'labels.text.fill',
  stylers: [{
    color: '#757575'
  }]
}, {
  featureType: 'poi.park',
  elementType: 'geometry',
  stylers: [{
    color: '#e5e5e5'
  }]
}, {
  featureType: 'poi.park',
  elementType: 'geometry.fill',
  stylers: [{
    color: '#dcedc8'
  }]
}, {
  featureType: 'poi.park',
  elementType: 'labels.text.fill',
  stylers: [{
    color: '#9e9e9e'
  }]
}, {
  featureType: 'road',
  elementType: 'geometry',
  stylers: [{
    color: '#ffffff'
  }]
}, {
  featureType: 'road.arterial',
  elementType: 'labels.text.fill',
  stylers: [{
    color: '#757575'
  }]
}, {
  featureType: 'road.highway',
  elementType: 'geometry',
  stylers: [{
    color: '#dadada'
  }]
}, {
  featureType: 'road.highway',
  elementType: 'geometry.fill',
  stylers: [{
    color: '#dedfe2'
  }]
}, {
  featureType: 'road.highway',
  elementType: 'labels.icon',
  stylers: [{
    saturation: -100
  }, {
    visibility: 'on'
  }]
}, {
  featureType: 'road.highway',
  elementType: 'labels.text.fill',
  stylers: [{
    color: '#616161'
  }]
}, {
  featureType: 'road.local',
  elementType: 'labels',
  stylers: [{
    visibility: 'off'
  }]
}, {
  featureType: 'road.local',
  elementType: 'labels.text.fill',
  stylers: [{
    color: '#9e9e9e'
  }]
}, {
  featureType: 'transit.line',
  elementType: 'geometry',
  stylers: [{
    color: '#e5e5e5'
  }]
}, {
  featureType: 'transit.station',
  elementType: 'geometry',
  stylers: [{
    color: '#eeeeee'
  }]
}, {
  featureType: 'transit.station',
  elementType: 'geometry.fill',
  stylers: [{
    color: '#edeeef'
  }]
}, {
  featureType: 'water',
  elementType: 'geometry',
  stylers: [{
    color: '#c9c9c9'
  }]
}, {
  featureType: 'water',
  elementType: 'geometry.fill',
  stylers: [{
    color: '#c3cfee'
  }]
}, {
  featureType: 'water',
  elementType: 'labels.text.fill',
  stylers: [{
    color: '#9e9e9e'
  }]
}];
exports["default"] = _default;