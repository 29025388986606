import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInboxFull, faFileInvoiceDollar } from '@fortawesome/pro-regular-svg-icons'
import styles from './style.module.css'

function Toggle({ activeTab, action, activeUser, isTableLoading }) {
  if (!activeTab || !activeUser.isActive || (!activeUser.isApprover && !(activeUser.isAdmin || activeUser.isReadOnly)))
    return null

  return (
    <div className={styles.toggleWrapper}>
      {(activeUser.isAdmin || activeUser.isReadOnly) && (
        <div
          onClick={() => (isTableLoading ? undefined : action('all'))}
          className={`${styles.toggleOption} ${
            activeTab === 'all' ? styles.toggleOptionSelected : styles.toggleOptionUnselected
          }`}
        >
          <FontAwesomeIcon icon={faFileInvoiceDollar} />
          <span>All Requests</span>
        </div>
      )}

      {activeUser.isApprover && (
        <div
          onClick={() => (isTableLoading ? undefined : action('approver'))}
          className={`${styles.toggleOption} ${
            activeTab === 'approver' ? styles.toggleOptionSelected : styles.toggleOptionUnselected
          }`}
        >
          <FontAwesomeIcon icon={faInboxFull} />
          <span>My Approvals</span>
        </div>
      )}

      <div
        onClick={() => (isTableLoading ? undefined : action('requests'))}
        className={`${styles.toggleOption} ${
          activeTab === 'requests' ? styles.toggleOptionSelected : styles.toggleOptionUnselected
        }`}
      >
        <FontAwesomeIcon icon={faFileInvoiceDollar} />
        <span>My Requests</span>
      </div>
    </div>
  )
}

export default Toggle
