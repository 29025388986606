import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import styles from './style.module.css'
import { MobileLayout } from '../../App'

const RequestsFooter = ({ activePage, totalPages, setActivePage, setLoadMore }) => {
  const isMobile = useContext(MobileLayout)

  const changePage = (pageNumberChange) => {
    window.scrollTo(0, 0)
    setActivePage(pageNumberChange)
  }

  return (
    <div className={styles.footer}>
      {isMobile && activePage !== totalPages ? (
        <div
          className={styles.loadMoreBtn}
          onClick={() => {
            setLoadMore(true)
            changePage(activePage + 1)
          }}
        >
          <p>Load more</p>
        </div>
      ) : (
        !isMobile && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              gap: 4,
              justifyContent: isMobile ? 'center' : 'flex-start',
              alignItems: 'center',
              padding: isMobile ? '16px 0' : '16px 40px',
            }}
          >
            <div
              onClick={() => {
                if (activePage > 1) changePage(activePage - 1)
              }}
              style={{
                width: 32,
                height: 32,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
            >
              <FontAwesomeIcon icon={faChevronLeft} color="rgba(0, 0, 0, 0.25)" />
            </div>
            <div
              onClick={() => {
                if (activePage < totalPages) changePage(activePage + 1)
              }}
              style={{
                width: 32,
                height: 32,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
            >
              <FontAwesomeIcon icon={faChevronRight} color="rgba(0, 0, 0, 0.25)" />
            </div>
            <div
              style={{
                width: 44,
                height: 36,
                backgroundColor: '#F5F6F7',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 4,
              }}
            >
              {activePage}
            </div>
            <span>of {totalPages} pages</span>
          </div>
        )
      )}
    </div>
  )
}

export default RequestsFooter
