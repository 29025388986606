"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var SelectContainer = _styledComponents["default"].div.withConfig({
  displayName: "styles__SelectContainer",
  componentId: "sc-1i90kuw-0"
})(["display:flex;flex-direction:column;position:absolute;height:100vh;z-index:99;bottom:0;top:0;left:0;right:0;background-color:#f4f5f6;"]);

var Header = _styledComponents["default"].div.withConfig({
  displayName: "styles__Header",
  componentId: "sc-1i90kuw-1"
})(["display:flex;margin:2.75rem 1rem 1rem;p{color:rgba(62,65,72,0.7);}i{margin-right:0.25rem;color:#6e727c;}.button-container{display:flex;align-items:center;&:hover{opacity:0.8;}}"]);

var Title = _styledComponents["default"].div.withConfig({
  displayName: "styles__Title",
  componentId: "sc-1i90kuw-2"
})(["display:flex;flex-direction:column;padding:1rem;padding-bottom:2rem;min-height:2rem;"]);

var LocationsContainer = _styledComponents["default"].div.withConfig({
  displayName: "styles__LocationsContainer",
  componentId: "sc-1i90kuw-3"
})(["display:flex;flex-direction:column;padding:0.75rem;overflow-y:auto;padding-bottom:10rem;.location-container{display:flex;justify-content:space-between;align-items:center;cursor:pointer;padding:0.75rem 0;min-height:3rem;&:hover{opacity:0.8;}&:first-child{padding-top:0;}.location{.address{font-size:0.875rem;color:rgba(62,65,72,0.7);overflow:hidden;white-space:nowrap;text-overflow:ellipsis;max-width:20.5rem;}}i{color:#3963fe;}}"]);

var ButtonContainer = _styledComponents["default"].div.withConfig({
  displayName: "styles__ButtonContainer",
  componentId: "sc-1i90kuw-4"
})(["display:flex;padding:1rem;position:fixed;bottom:0;left:0;right:0;min-height:2.5rem;backdrop-filter:blur(8px);background-color:rgba(244,245,246,0.85);button{width:100%;background:linear-gradient(-180deg,rgb(57,99,254) 0%,rgb(47,85,254) 100%);color:#ffffff;text-transform:none;border-radius:0.5rem;border:1px solid rgb(47,85,254);}@supports (padding-bottom:constant(safe-area-inset-bottom)){--safe-area-inset-bottom:constant(safe-area-inset-bottom);margin-bottom:var(--safe-area-inset-bottom);}@supports (padding-bottom:env(safe-area-inset-bottom)){--safe-area-inset-bottom:env(safe-area-inset-bottom);margin-bottom:var(--safe-area-inset-bottom);}"]);

var _default = {
  SelectContainer: SelectContainer,
  Header: Header,
  Title: Title,
  LocationsContainer: LocationsContainer,
  ButtonContainer: ButtonContainer
};
exports["default"] = _default;