import { createContext, useEffect, useReducer } from 'react'
import { getAllItemsIds, getContractCodes, retrieveCartData } from '../../common/features/Cart/helpers'
import { getRawCookie } from '../../utils/cookies'
import { safeLocalStorage } from '../../utils/safeStorage'
import { CART_ACTIONS, cartReducer } from './reducer'

export const CartInitialValue = {}

export const CartContext = createContext(CartInitialValue)

export const CartProvider = ({ children }) => {
  const userData = safeLocalStorage.getJSONItem('userData')
  const [state, dispatch] = useReducer(cartReducer, {
    cart: null,
    loading: true,
    cartItemTotal: null,
    cartUniqueItemTotal: null,
    eqlCartCount: null,
    undo: {
      popup: false,
      active: false,
      supplierIds: [],
    },
    loadingItems: [],
    supplierPartsUnitPriceLoading: [],
    selectedItems: [],
    availableContractCodes: [],
    showModal: false,
    modalSelection: null,
    showContractRequiredWarning: false,
  })

  useEffect(() => {
    retrieveCartData({
      userData,
      setCart: (cartPayload) => {
        dispatch({ type: CART_ACTIONS.SET_CART, payload: cartPayload })
        dispatch({
          type: CART_ACTIONS.SET_CART_UNIQUE_ITEM_TOTAL,
          payload: getAllItemsIds(cartPayload).length,
        })
      },
      setEqlCartCount: (value) => {
        dispatch({ type: CART_ACTIONS.SET_EQL_CART_COUNT, payload: value })
      },
    })
  }, [])

  useEffect(() => {
    ;(async () => {
      const getCompanyId = () => {
        return getRawCookie('companyId')
      }
      const response = await getContractCodes(getCompanyId())

      const mappedContracCodes = response.map((contractCode) => ({
        id: contractCode.Id,
        label: `${contractCode.Name} ${contractCode.Description ? ` - ${contractCode.Description}` : ''}`,
      }))

      dispatch({ type: CART_ACTIONS.SET_AVAILABLE_CONTRACT_CODES, payload: mappedContracCodes })
    })()
  }, [])

  return <CartContext.Provider value={{ state, dispatch }}>{children}</CartContext.Provider>
}
