import { cancelableFetchData } from '../fetchData';
import config from '../apiConfig';
import { formatCheckout } from './format';
import getDefaultCheckout from '../../../utils/getDefaultCheckout';

const postCheckout = (cart, addresses, userData, formValues) => {
  const formattedCart = formatCheckout(cart, addresses, formValues);
  const userToken = userData.Network[userData.activeNetworkIndex].UserToken;
  const checkout = getDefaultCheckout(JSON.parse(localStorage.getItem('userData')));

  const url = checkout === 'raiven'
    ? `${config.postCheckout.url}/${userToken}/ApprovalCheckOut`
    : `${config.postCheckout.url}/${userToken}/Checkout`;

  const options = {
    ...config.postCheckout,
    url,
    body: { ...formattedCart },
  };

  return cancelableFetchData(options)
    .then((data) => {
      if (data && !data.ErrorCode) {
        return { ...data, fetchError: !!(data && data.ErrorCode) };
      } else {
        return { fetchError: true, details: data }
      }
    })
    .catch((error) => {
      return { ...error, fetchError: true };
    });
};

export default postCheckout;
