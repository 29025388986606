import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ComponentStyles from './styles';

const {
    PopupContent,
    PopupLi,
    PopupContainer,
    SelectULStyle,
    SelectLIStyle,
    DropDownContent,
    DropDownLi,
    DropDownLiSelected,
    DropDownItemsContainer,
    MultiSectionHeader,
    LabelDiv,
    SelectContainer,
} = ComponentStyles;

export const SelectField = props => {
    const [arrowup, setarrow] = useState(true);
    const {
        selectOptions,
        placeholder,
        background,
        width,
        radius,
        shadow,
        itemPadding,
        fontSize,
        helperText,
        label,
        optionalTag,
        multiSelectOptions,
    } = props;

    let selectedValue;
    if (!_.isNil(props, 'value')) {
        if (_.isObject(props.value)) {
            selectedValue = props.value;
        } else if (_.isString(props.value)) {
            selectedValue = props.value;
        }
    } else {
        selectedValue = placeholder;
    }

    const [showOptions, setShowOptions] = useState(false);
    const switchShowOptions = value => {
        if (!_.isNil(value)) {
            if (value === true) {
                setTimeout(() => {
                    //without the timeout 'click' was immediately hit causing the drop down
                    //to close before it ever got rendered
                    document.addEventListener('click', pageClick, false);                
                }, 10);
                setarrow(false);
            } else if (value === false) {
                document.removeEventListener('click', pageClick, false);
                setarrow(true);
            }
            setShowOptions(value);
        }
    };
    const [selected, setValue] = useState(selectedValue);
    const changeValue = (e, selected) => {
        switchShowOptions(false);
        setValue(selected);
        props.onChange(selected);
    };

    function pageClick(e, v) {
        switchShowOptions(false);
    }

    useEffect(() => {
        if (props.value !== selected) setValue(props.value);
    }, [props.value]);

    const DropDownOption = ({ option, selected, index }) => {
        if (_.isEqual(selected, option)) {
            return (
                <DropDownLiSelected key={option.id} className='selected' onClick={e => changeValue(e, option)}>
                    <div className='dropdown-content '>
                        {_.isObject(option) && option[props.url] && (
                            <img alt='dropdown' className='dropdown-img' src={option[props.url]}></img>
                        )}
                        {_.isObject(option) && <div>{option[props.name]}</div>}
                    </div>
                    {_.isString(option) && option}
                </DropDownLiSelected>
            );
        }
        return (
            <DropDownLi key={option.id} onClick={e => changeValue(e, option)}>
                <div className='dropdown-content'>
                    {_.isObject(option) && option[props.url] && (
                        <img alt='dropdown' className='dropdown-img' src={option[props.url]} />
                    )}
                    {_.isObject(option) && <div>{option[props.name]}</div>}
                </div>
                {_.isString(option) && option}
            </DropDownLi>
        );
    };
    // const toggleArrow=()=>{
    //     // setarrow(!arrowup)
    // }

    return (
        <Fragment>
            <SelectContainer>
                <div className='label-container'>
                    {label || ''}
                    {optionalTag ? <span>- Optional</span> : ''}
                </div>
                {helperText && <p className='helper-text'>{helperText}</p>}
                {props.mode === 'popup' && (
                    <Fragment>
                        <PopupContainer
                            onClick={() => {
                                switchShowOptions(!showOptions);
                            }}
                        >
                            {props.children}
                            <PopupContent width={width} background={background} radius={radius} shadow={shadow}>
                                {showOptions &&
                                    _.map(selectOptions, (option, index) => (
                                        <PopupLi
                                            itemPadding={itemPadding}
                                            fontSize={fontSize}
                                            key={`value${index}`}
                                            onClick={e => changeValue(e, option)}
                                        >
                                            {_.isObject(option) && option[props.name]}
                                            {_.isString(option) && option}
                                        </PopupLi>
                                    ))}
                            </PopupContent>
                        </PopupContainer>
                    </Fragment>
                )}

                {!props.mode && (
                    <SelectULStyle className='selectInput'>
                        <DropDownContent className='gCSAhH'>
                            <SelectLIStyle onClick={() => switchShowOptions(!showOptions)}>
                                <LabelDiv>
                                    <div className='dropdown-content'>
                                        {_.isObject(selected) && selected[props.url] && (
                                            <img alt='dropdown' className='dropdown-img' src={selected[props.url]} />
                                        )}
                                        {_.isString(selected) && (
                                            <div className={`${selected !== '' ? 'notselected' : ''}`}>{selected}</div>
                                        )}
                                        {_.isNil(selected) && <div className='placeholder'>{props.placeholder}</div>}
                                        {_.isObject(selected) && <div>{selected[props.name]}</div>}
                                    </div>

                                    <i className={arrowup ? 'fa fa-chevron-down' : 'fa fa-chevron-up'} />
                                </LabelDiv>
                            </SelectLIStyle>
                            {showOptions && selectOptions && (
                                <DropDownItemsContainer>
                                    {_.map(selectOptions, (option, index) => (
                                        <DropDownOption key={index} option={option} index={index} selected={selected} />
                                    ))}
                                </DropDownItemsContainer>
                            )}
                            {showOptions && multiSelectOptions && (
                                <DropDownItemsContainer>
                                    {multiSelectOptions.map((section, index) => {
                                        if (section.name === 'default')
                                            return (
                                                <DropDownOption
                                                    key={index}
                                                    option={section.options[0]}
                                                    index={index}
                                                    selected={selected}
                                                />
                                            );

                                        return (
                                            <Fragment>
                                                <MultiSectionHeader>{section.name}</MultiSectionHeader>
                                                {section.options.map((option, index) => (
                                                    <DropDownOption  key={index} option={option} index={index} selected={selected} />
                                                ))}
                                            </Fragment>
                                        );
                                    })}
                                </DropDownItemsContainer>
                            )}
                        </DropDownContent>
                    </SelectULStyle>
                )}
            </SelectContainer>
        </Fragment>
    );
};

SelectField.defaultProps = {
    id: 'id',
    name: 'name',
    url: 'url',
    placeholder: 'Select an option...',
};

SelectField.propTypes = {
    label: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
    placeholder: PropTypes.string,
    optional: PropTypes.bool,
    optionalTag: PropTypes.bool,
    disabled: PropTypes.bool,
    helperText: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    iconLeft: PropTypes.string,
    onIconLeftClick: PropTypes.func,
    iconRight: PropTypes.string,
    onIconRightClick: PropTypes.func,
    rounded: PropTypes.bool,
    onError: PropTypes.func,
    className: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    background: PropTypes.string,
    shadow: PropTypes.string,
    itemPadding: PropTypes.string,
    fontSize: PropTypes.string,
    selectOptions: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.arrayOf(PropTypes.object)]),
    value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object.isRequired]),
};

export default SelectField;
