"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

// import companyIcon from './Image.png';
function CustomMarker(latlng, map, args) {
  this.latlng = latlng;
  this.args = args;
  return new google.maps.Marker(this);
}

CustomMarker.prototype = new google.maps.OverlayView();

CustomMarker.prototype.draw = function () {
  var self = this;
  var args = this.getArgs();
  var div = this.div;

  if (!div) {
    div = this.div = document.createElement('div');

    if (_lodash["default"].has(args, 'className')) {
      div.className = args.className;
    } else {
      div.className = 'marker';
    }

    var image = document.createElement('img');
    var icon = args.icon,
        markerClick = args.markerClick;
    image.src = icon;
    div.append(image);

    if (typeof self.args.marker_id !== 'undefined') {
      div.dataset.marker_id = self.args.marker_id;
    }

    if (_lodash["default"].has(args, 'markerClick')) {
      google.maps.event.addDomListener(div, 'click', function (event) {
        markerClick(args);
      });
    }

    if (_lodash["default"].has(args, 'dataAttributeName')) {
      div.setAttribute(args.dataAttributeName, "map-marker-".concat(args.title));
    }

    var panes = this.getPanes();
    panes.overlayImage.appendChild(div);
  }

  var point = this.getProjection().fromLatLngToDivPixel(this.latlng);

  if (point) {
    div.style.left = point.x + 'px';
    div.style.top = point.y + 'px';
  }
};

CustomMarker.prototype.remove = function () {
  if (this.div) {
    this.div.parentNode.removeChild(this.div);
    this.div = null;
  }
};

CustomMarker.prototype.getPosition = function () {
  return this.latlng;
};

CustomMarker.prototype.getArgs = function () {
  return this.args;
};

var _default = CustomMarker;
exports["default"] = _default;