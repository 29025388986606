import _ from 'lodash';

export const isLegacyMarket = activeNetwork => {
    if (!activeNetwork || activeNetwork.length < 1) return false;
  const companySettings = activeNetwork.CompanySetting ? JSON.parse(activeNetwork.CompanySetting) : undefined;

    let userSettings =
        activeNetwork && activeNetwork.Setting && activeNetwork.Setting.length > 0 ? activeNetwork.Setting : undefined;
    if (typeof userSettings === 'string') userSettings = JSON.parse(userSettings);

    // if(!companySettings) console.log('isLegacyMarket :: return true')
  // if(companySettings.DefaultQplace) console.log('isLegacyMarket :: return false')
  //    if(!companySettings) return true
  //    if(companySettings.DefaultQplace) return false
    if (_.has(companySettings, 'DefaultCheckout') && companySettings.DefaultCheckout === 'equallevel') return false;
    if (_.has(userSettings, 'DefaultCheckout') && userSettings.DefaultCheckout === 'equallevel') return false;
    if (!_.has(userSettings, 'DefaultCheckout') && !_.has(companySettings, 'DefaultCheckout')) return false;
  //    if(!_.has(userSettings, 'DefaultCheckout') )
  // console.log('isLegacyMarket :: userSettings :: ', userSettings)
    // if(userSettings.LegacyMarket === undefined) {
  //    console.log('isLegacyMarket :: return true')
    // } else {
  //    console.log('isLegacyMarket :: return :: ', userSettings && userSettings.LegacyMarket)
    // }
  //    if(!userSettings) return true
  //    if(userSettings.LegacyMarket === true) return true
  // if((!_.has(userSettings, 'DefaultCheckout')) || (!_.has(companySettings, 'DefaultCheckout'))) return true
    return userSettings && false;
};

export default isLegacyMarket;
