import _ from 'lodash';
import Validators from '../constants/validators';

const setErrorState = (props, message) => {
    const { setError, setErrorMessage, onError } = props;
    setError(!!message);
    setErrorMessage(message);
    if (message && _.has(props, 'onError')) onError({ error: true, message });
};

const handleValidation = (value, props) => {
    const { optional, optionalTag } = props;

    if (!optionalTag && !optional && Validators.required(value)) {
        return setErrorState(props, "This field can't be blank");
    }

    if (_.has(props, 'validate')) {
        const response = props.validate(value);

        if (_.has(response, 'errorMessage')) {
            return setErrorState(props, response.errorMessage);
        }
    }

    return setErrorState(props, null);
};

export const handleBlur = (event, props) => {
    const { value } = event.target;
    handleValidation(value, props);
};

export const handleChange = (event, props) => {
    const { value } = event.target;
    const numberOnlyValue = value.replace(/[^\d]/g, '');
    const { mask } = props;

    let i = 0;
  let lastReplacedIndex = -1;
    const filledMask = mask.replace(/#/g, (_, j) => {
        if (i >= numberOnlyValue.length) {
            return '#';
        }
        lastReplacedIndex = j;
        return numberOnlyValue[i++];
    });

    props.setFormattedValue(filledMask.substring(0, lastReplacedIndex + 1));

    if (_.has(props, 'validateOnChange') && props.validateOnChange === true) {
        handleValidation(value, props);
    }

    props.onChange(event);
};
