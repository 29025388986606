import React, { useState, useEffect } from 'react'
import ComponentStyles from './styles'
import Modal from '../../../../components/Modal'
import SelectField from '../../../../components/SelectField'
import cityList from './cityList'
import DeleteAddressModal from './deleteAddressModal'

const regEx = /^([a-zA-Z0-9 _-]+)$/
const { AddressModalContainer } = ComponentStyles
const EditAddressModal = ({ closeModal, saveAddress, selectedAddress, deleteAddress, modalType }) => {
  const [city, setCityValue] = useState(null)
  const [cityError, setCityError] = useState(false)
  const [state, setStateValue] = useState()
  const [zip, setZip] = useState(null)
  const [zipError, setZipError] = useState(false)
  const [company, setCompany] = useState(null)
  const [companyError, setCompanyError] = useState(false)
  const [street, setStreet] = useState(null)
  const [streetError, setStreetError] = useState(false)
  const [addressLine2, setAddressLine2] = useState(null)
  const [DeliverTo2, setDeliverTo2] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)
  useEffect(() => {
    if (selectedAddress) {
      setCityValue(selectedAddress.cityState.split(',')[0] || '')
      setStateValue(
        (selectedAddress.cityState.split(',')[1] && selectedAddress.cityState.split(',')[1].trim().split(' ')[0]) || ''
      )
      setZip(
        (selectedAddress.cityState.split(',')[1] && selectedAddress.cityState.split(',')[1].trim().split(' ')[1]) || ''
      )
      setCompany(selectedAddress.company || '')
      setStreet(selectedAddress.street.split(',')[0] || '')
      setAddressLine2(selectedAddress.street.split(',')[1] || '')
      setDeliverTo2(selectedAddress.DeliverTo2 || '')
    }
  }, [selectedAddress])

  const setLocationName = (value) => {
    if (value.length > 64) {
      setCompanyError(true)
    } else {
      setCompanyError(false)
    }
    setCompany(value)
  }
  const setStreetName = (value) => {
    if (!value || !regEx.test(street) || value.length > 64) {
      setStreetError(true)
    } else {
      setStreetError(false)
    }
    setStreet(value)
  }
  const setAddressLine2Name = (value) => {
    setAddressLine2(value)
  }
  const setCityName = (value) => {
    if (!value || !regEx.test(city) || value.length > 64) {
      setCityError(true)
    } else {
      setCityError(false)
    }
    setCityValue(value)
  }
  const setZipName = (value) => {
    let zipCodeRegEx = /^[0-9]+$/
    if (!value || !zipCodeRegEx.test(zip) || value.length > 9) {
      setZipError(true)
    } else {
      setZipError(false)
    }
    setZip(value)
  }
  const saveCompleteAddress = async () => {
    if (!zipError && !cityError && !streetError) {
      saveAddress(zip, city, state, addressLine2, DeliverTo2, street, company)
    }
  }
  return (
    <AddressModalContainer>
      <Modal
        title={
          modalType === 'Shipping'
            ? selectedAddress
              ? 'Edit Shipping Address'
              : 'Add Shipping Address'
            : selectedAddress
            ? 'Edit Billing Address'
            : 'Add Billing Address'
        }
        setEnabled
        darkBackground
        maxHeight="500px"
        setToggle={closeModal}
        maxWidth="600px"
        cardClassName={selectedAddress ? 'editaddressModal' : 'editaddressModalWithoutDelete'}
      >
        <div className="modal-wrapper">
          <div className="modal-field-wrapper">
            <div className="form-groups">
              <label htmlFor="location" className="location">
                Location Name
              </label>
              <input
                type="text"
                id="location"
                className={companyError ? 'modal-input error' : 'modal-input'}
                value={company}
                onChange={(e) => setLocationName(e.target.value)}
                onBlur={(e) => setLocationName(e.target.value)}
              />
              {companyError && (
                <div className="error-text">
                  <i className="far fa-exclamation-circle " />
                  <label>This field is not valid</label>
                </div>
              )}
            </div>

            <div className="form-groups">
              <label htmlFor="address">Address</label>
              <input
                type="text"
                id="address"
                className={streetError ? 'modal-input error' : 'modal-input'}
                value={street}
                onChange={(e) => setStreetName(e.target.value)}
                onBlur={(e) => setStreetName(e.target.value)}
              />
              {streetError && (
                <div className="error-text">
                  <i className="far fa-exclamation-circle " />
                  <label>Enter a valid street address</label>
                </div>
              )}
            </div>

            <div className="form-groups">
              <label htmlFor="address2" className="address2">
                Address Line2
              </label>
              <span className="addlin2-txt">Apt, Ste, PO box, etc...</span>
              <input
                type="text"
                id="address2"
                className="modal-input"
                value={addressLine2}
                onChange={(e) => setAddressLine2Name(e.target.value)}
                onBlur={(e) => setAddressLine2Name(e.target.value)}
              />
            </div>

            <div className="form-groups">
              <label htmlFor="deliveryTo2">ID (DeliverTo2)</label>
              <input
                type="text"
                id="deliveryTo2"
                className="modal-input"
                value={DeliverTo2}
                onChange={(e) => setDeliverTo2(e.target.value)}
                onBlur={(e) => setDeliverTo2(e.target.value)}
              />
            </div>

            <div className="form-groups">
              <label htmlFor="city">City</label>
              <input
                type="text"
                id="city"
                className={cityError ? 'modal-input error' : 'modal-input'}
                value={city}
                onChange={(e) => setCityName(e.target.value)}
                onBlur={(e) => setCityName(e.target.value)}
              />
              {cityError && (
                <div className="error-text">
                  <i className="far fa-exclamation-circle " />
                  <label>Enter a valid city</label>
                </div>
              )}
            </div>

            <div className="form-groups zip-code-outer">
              <div className="flexrow abb-select">
                <div className="flexcol-6 mr-8">
                  <label htmlFor="state">State</label>
                  <div>
                    <SelectField
                      // label="State"
                      id="abbreviation"
                      name="abbreviation"
                      placeholder=""
                      onChange={function noRefCheck(e) {
                        setStateValue(e.abbreviation)
                      }}
                      selectOptions={cityList}
                      value={state}
                      itemPadding="-2px"
                    />
                  </div>
                </div>
                <div className="flexcol-6 ml-8">
                  <label htmlFor="zip">ZIP Code</label>
                  <input
                    type="text"
                    id="zip"
                    className={zipError ? 'modal-input error' : 'modal-input'}
                    value={zip}
                    onChange={(e) => setZipName(e.target.value)}
                    onBlur={(e) => setZipName(e.target.value)}
                  />
                  {zipError && (
                    <div className="error-text">
                      <i className="far fa-exclamation-circle " />
                      <label>Enter a valid ZIP Code</label>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {selectedAddress && (
              <div className="form-groups delete-add-wrapper">
                <button
                  type="button"
                  className="delete-add"
                  onClick={() => {
                    setDeleteModal(true)
                  }}
                >
                  Delete address
                </button>
              </div>
            )}
            <div className="form-groups btn-outer">
              <div className="flexrow ">
                <div className="flexcol-6 mr-8">
                  <button type="button" className="cancel-btn" onClick={closeModal}>
                    Cancel
                  </button>
                </div>
                <div className="flexcol-6 ml-8">
                  <button
                    type="button"
                    className={zip && city && street && state ? 'save-btn' : 'save-btn disable'}
                    disabled={!(zip && city && street && state)}
                    onClick={() => saveCompleteAddress()}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {deleteModal && <DeleteAddressModal setDeleteModal={setDeleteModal} deleteAddress={deleteAddress} />}
      </Modal>
    </AddressModalContainer>
  )
}
export default EditAddressModal
