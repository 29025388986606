import React, { PureComponent, Fragment } from 'react';
import Header from '../../components/Header';
import { SsoIframe } from '../../components/EQL/SsoIframe';
// import Modal from '../../components/Modal';

// const isBrowser = typeof window !== 'undefined' && window.env;
// const blobUrl = isBrowser ? window.env.blobUrl : process.env.RAZZLE_RUNTIME_BLOB_URL;

export class EqlPages extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            fullscreen: false,
        };
    }

    // componentDidMount() {
    //     if (this.props.hasExtension) this.setState({ fullscreen: true });
    // }

    render() {
        console.log('EQL :: render :: forwardUrl :: ', this.props.eqlUrl);
        return (
            <Fragment>
                <Header {...this.props} />
            <main role="main" className="flex-row product-view">
          <section id="product-view">
                <SsoIframe fullScreen={this.state.fullscreen} eql forwardUrl={this.props.eqlUrl} />
              </section>
                </main>
          </Fragment>
        );
    }
}

export default EqlPages;
