import moment from 'moment';

const isToday = (date) => {
  const today = new Date();

  if (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getYear() === today.getYear()
  )
    return true;
  return false;
};

const isYesterday = (date) => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  if (yesterday.toDateString() === date.toDateString()) return true;
  return false;
};

export const formatRequestDate = (date) => {
  const momentDate = moment
    .utc(date)
    .local()
    .toDate();

  if (isToday(momentDate)) return 'Today';
  if (isYesterday(momentDate)) return 'Yesterday';

  let options = {
    month: 'short',
    day: 'numeric',
  };

  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(
    momentDate
  );

  return formattedDate;
};

export default formatRequestDate;
