import React, { PureComponent, Fragment } from 'react';
import "./styles.css";
import CorporateLogo from '../CorporateLogo';
import postAnalytics from '../../../utils/postAnalytics.js';

class Dropdown extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            toggle: false,
        }
    }

    handleMouseToggle = (event, value) => {
        if (this.state.toggle) {
            let toggle = this.state.toggle;
            console.log("event.target.className.includes('current-item')", event.target.className.includes('current-item'), "event.target.className", event.target.className)

            if (event.target.className === 'locationDropdownLabel' || event.target.className === 'current-item' || event.target.className.includes("arrow fas")) {
                toggle = true;
            } else {
                toggle = false;
            }

            this.setState({
                toggle,
            })
        }
    }

    handleButtonToggle = (event) => {
        this.setState({ toggle: !this.state.toggle })
    }

    handleItemClick = (event) => {
        this.setState({ toggle: false })
        this.props.onItemClick(event)
    }

    render() {
        const { items, selected, className } = this.props
        if(items && items.length > 1) {
            return (
                <div className={"dropdown " + className + " active"}>
                    <button type="button" className="current-item"  data-aurora-automation="header-active-corp" 
                    onClick={(event) => { this.handleButtonToggle(event)}}
                     tabIndex="1" 
                     onMouseLeave={(event) => this.handleMouseToggle(event, false)}
                     >
                        {/* <CorporateLogo account={this.props.activeUser} /> */}
                        <h3 className="locationDropdownLabel">{items[selected].DBAName}</h3>
                        {/* {checkImageUrl(items[selected].ImageUrl, "company", "", items[selected].DBAName)} */}
                        <i className={`arrow fas ${this.state.toggle ? 'fa-caret-up' : 'fa-caret-down'} fa-fw`} />
                    </button>
                    {this.state.toggle &&
                        <div className="dropdown-items" data-aurora-automation="header-change-corp-list" 
                        onMouseLeave={(event) => this.handleMouseToggle(event, false)}
                        >
                            {items.map((item, i) => {
                                return (
                                    <Fragment key={i}>
                                        {i !== 0 && <div className="line"/>}
                                        <div key={i}>

                                            <button key={i} value={i} className="dropdown-item" data-aurora-automation="header-change-corp-profile" onClick={(e) => this.handleItemClick(e)}>
                                                <p>
                                                    {/* <CorporateLogo account={item} /> */}
                                                    {item.DBAName}
                                                </p>
                                                {parseInt(selected, 10) === i &&
                                                    <i className="far fa-check selected" />
                                                }
                                            </button>
                                        </div>
                                    </Fragment>
                                )
                            })}
                        </div>
                    }
                </div>
            )
        } else {
            return (
                <div className="user-account dropdown network-dropdown" onClick={() => {
                        postAnalytics('pm-header-navigation-click-activeUserAccount', this.props.activeUser);
                        this.props.onUserClick();
                    }
                }>
                    <CorporateLogo account={this.props.activeUser} />
                </div>
            )
        }
    }
}

export default Dropdown;
