export const CART_ACTIONS = {
  SET_CART: 'SET_CART',
  LOADING_TOGGLE: 'LOADING_TOGGLE',
  SET_CART_ITEM_TOTAL: 'SET_CART_ITEM_TOTAL',
  SET_CART_UNIQUE_ITEM_TOTAL: 'SET_CART_UNIQUE_ITEM_TOTAL',
  SET_EQL_CART_COUNT: 'SET_EQL_CART_COUNT',
  SET_UNDO: 'SET_UNDO',
  SET_LOADING_ITEM: 'SET_LOADING_ITEM',
  REPLACE_LOADING_ITEMS: 'REPLACE_LOADING_ITEMS',
  SET_SUPPLIER_PARTS_UNIT_PRICE_LOADING: 'SET_SUPPLIER_PARTS_UNIT_PRICE_LOADING',
  ADD_SELECTED_ITEM: 'ADD_SELECTED_ITEM',
  REMOVE_SELECTED_ITEM: 'REMOVE_SELECTED_ITEM',
  CLEAR_SELECTED_ITEM: 'CLEAR_SELECTED_ITEM',
  SET_ALL_SELECTED_ITEMS: 'SET_ALL_SELECTED_ITEMS',
  SET_AVAILABLE_CONTRACT_CODES: 'SET_AVAILABLE_CONTRACT_CODES',
  SHOW_CONTRACT_CODES_MODAL: 'SHOW_CONTRACT_CODES_MODAL',
  HIDE_CONTRACT_CODES_MODAL: 'HIDE_CONTRACT_CODES_MODAL',
  SET_MODAL_SELECTION: 'SET_MODAL_SELECTION',
  CLEAR_MODAL_SELECTION: 'CLEAR_MODAL_SELECTION',
  SHOW_DELETE_ITEMS_MODAL: 'SHOW_DELETE_ITEMS_MODAL',
  HIDE_DELETE_ITEMS_MODAL: 'HIDE_DELETE_ITEMS_MODAL',
  SET_CART_ITEM_CONTRACT_CODE: 'SET_CART_ITEM_CONTRACT_CODE',
  TOGGLE_CONTRACT_CODES_WARNING: 'TOGGLE_CONTRACT_CODES_WARNING',
}

const defaultState = {
  cart: null,
  loading: true,
  cartItemTotal: null,
  cartUniqueItemTotal: null,
  eqlCartCount: null,
  undo: {
    popup: false,
    active: false,
    supplierIds: [],
  },
  loadingItems: [],
  supplierPartsUnitPriceLoading: [],
  selectedItems: [],
  availableContractCodes: [],
  showModal: false,
  showDeleteItemsModal: false,
  modalSelection: null,
  showContractRequiredWarning: false,
}

export const cartReducer = (state = defaultState, action) => {
  switch (action.type) {
    case CART_ACTIONS.SET_CART:
      return {
        ...state,
        cart: action.payload,
      }
    case CART_ACTIONS.LOADING_TOGGLE: {
      return {
        ...state,
        loading: action.payload,
      }
    }
    case CART_ACTIONS.SET_CART_ITEM_TOTAL: {
      return {
        ...state,
        cartItemTotal: action.payload,
      }
    }
    case CART_ACTIONS.SET_CART_UNIQUE_ITEM_TOTAL: {
      return {
        ...state,
        cartUniqueItemTotal: action.payload,
      }
    }
    case CART_ACTIONS.SET_EQL_CART_COUNT: {
      return {
        ...state,
        eqlCartCount: action.payload,
      }
    }
    case CART_ACTIONS.SET_UNDO: {
      return {
        ...state,
        undo: action.payload,
      }
    }
    case CART_ACTIONS.SET_LOADING_ITEM: {
      return {
        ...state,
        loadingItems: [...state.loadingItems, action.payload],
      }
    }
    case CART_ACTIONS.REPLACE_LOADING_ITEMS: {
      return {
        ...state,
        loadingItems: action.payload,
      }
    }
    case CART_ACTIONS.SET_SUPPLIER_PARTS_UNIT_PRICE_LOADING: {
      return {
        ...state,
        supplierPartsUnitPriceLoading: [...state.supplierPartsUnitPriceLoading, action.payload],
      }
    }
    case CART_ACTIONS.ADD_SELECTED_ITEM: {
      return {
        ...state,
        selectedItems: [...state.selectedItems, action.payload],
      }
    }
    case CART_ACTIONS.REMOVE_SELECTED_ITEM: {
      return {
        ...state,
        selectedItems: state.selectedItems.filter(
          (item) =>
            `${item.SupplierId}${item.AccountNumber}${item.SupplierPartNumber}${item.Id}` !==
            `${action.payload.SupplierId}${action.payload.AccountNumber}${action.payload.SupplierPartNumber}${item.Id}`
        ),
      }
    }
    case CART_ACTIONS.CLEAR_SELECTED_ITEM: {
      return {
        ...state,
        selectedItems: [],
      }
    }
    case CART_ACTIONS.SET_ALL_SELECTED_ITEMS: {
      return {
        ...state,
        selectedItems: action.payload,
      }
    }
    case CART_ACTIONS.SET_AVAILABLE_CONTRACT_CODES: {
      return {
        ...state,
        availableContractCodes: action.payload,
      }
    }
    case CART_ACTIONS.SHOW_CONTRACT_CODES_MODAL: {
      return {
        ...state,
        showModal: true,
      }
    }
    case CART_ACTIONS.HIDE_CONTRACT_CODES_MODAL: {
      return {
        ...state,
        showModal: false,
      }
    }
    case CART_ACTIONS.SET_MODAL_SELECTION: {
      return {
        ...state,
        modalSelection: action.payload,
      }
    }
    case CART_ACTIONS.CLEAR_MODAL_SELECTION: {
      return {
        ...state,
        modalSelection: null,
      }
    }
    case CART_ACTIONS.SHOW_DELETE_ITEMS_MODAL: {
      return {
        ...state,
        showDeleteItemsModal: true,
      }
    }
    case CART_ACTIONS.HIDE_DELETE_ITEMS_MODAL: {
      return {
        ...state,
        showDeleteItemsModal: false,
      }
    }
    case CART_ACTIONS.SET_CART_ITEM_CONTRACT_CODE: {
      return {
        ...state,
        cart: {
          ...state.cart,
          CartRequisitions: state.cart.CartRequisitions.map((cartRequisition) =>
            cartRequisition.AccountNumber === action.payload.item.AccountNumber
              ? {
                  ...cartRequisition,
                  Requisitions: cartRequisition.Requisitions.map((requisition) =>
                    requisition.SupplierPartId === action.payload.item.SupplierPartNumber
                      ? {
                          ...requisition,
                          ContractCodeId: action.payload.contractCodeId,
                        }
                      : requisition
                  ),
                }
              : cartRequisition
          ),
        },
      }
    }
    case CART_ACTIONS.TOGGLE_CONTRACT_CODES_WARNING: {
      return {
        ...state,
        showContractRequiredWarning: action.payload,
      }
    }
    default:
      return state
  }
}
