import React from 'react';
import Modal from '../../../../components/Modal';
import { ADDRESS_VERIFICATION_STATUS, isVerifiedAddress } from '../../../Cart/helpers';

const OrderDelayedModal = ({
closeOrderDelayedModal, saveNewAddress, selectedAddress, addressOptions, cart, isUpdating
}) => {
    const verificationStatus = selectedAddress
        ? isVerifiedAddress(addressOptions, cart, selectedAddress.Id)
        : ADDRESS_VERIFICATION_STATUS.ALL_UNVERIFIED;
  const displayMessage =
        verificationStatus !== ADDRESS_VERIFICATION_STATUS.NO_UNVERIFIED ? (
            <p>
                New addresses need to be verifed by certain suppliers.{' '}
                <b>Orders can be placed for those suppliers once the new address has been reviewed.</b>
            </p>
        ) : (
            'Your address has been updated successfully. Would you like to use this address?'
        );
  return (
        <Modal
      title={verificationStatus !== ADDRESS_VERIFICATION_STATUS.NO_UNVERIFIED ? 'Some items cannot be checked out' : isUpdating ? 'Your address has been updated successfully' : 'Your address has been added successfully'}
            setEnabled
            darkBackground
            maxHeight="500px"
            setToggle={closeOrderDelayedModal}
            maxWidth="600px"
      cardClassName="editaddressModaldelay">

      <div className='Modaldelay'>
                <form>
              <div className="modal-field-wrapper">
                  <div className="modaldelayicon">
                            {/* <i class="fal fa-truck"></i> */}
              {
                verificationStatus !== ADDRESS_VERIFICATION_STATUS.NO_UNVERIFIED
                ? <i className="fal fa-exclamation-circle info-icon" />:
                                <i class='fad fa-check-circle info-icon'></i>
                            }
                        </div>


                  <p>{displayMessage}</p>

                  <div className="form-group dont-show">
                  <input type="checkbox" className="check" />
                            <p>Don’t show this again</p>
                        </div>
                    </div>
              <div className="flexrow bottomsec">
                  <div className="flexcol-6 mr-8">
                      <button type="button" className="diff-address" onClick={closeOrderDelayedModal}>Choose a different address</button>
                        </div>
                  <div className="flexcol-6 ml-8">
                      <button type="button" className="same-address" onClick={saveNewAddress}>Use this address</button>
                        </div>
                    </div>
                </form>
            </div>
        </Modal>
    );
};
export default OrderDelayedModal;
