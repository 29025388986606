import React, { useState, useEffect } from 'react'

import { calcTotal } from '../../../Cart/helpers'
import { handleBrokenProductImage } from '../../../Cart/components/SupplierCart/helpers'
import ComponentStyles from '../../../Cart/components/SupplierCart/styles'

const { SupplierCartContainer, ItemRow } = ComponentStyles

const isBrowser = typeof window !== 'undefined' && window.env
const blobUrl = isBrowser ? window.env.blobUrl : process.env.RAZZLE_RUNTIME_BLOB_URL

const SupplierCartItem = (props) => {
  let { loader, supplier, items, totalAmount, unableToCheckout, history } = props
  const [supplierCart, setSupplierCart] = useState(items)

  useEffect(() => {
    if (items) {
      setSupplierCart(items)
    }
  }, [items])

  const editHandler = () => {
    history.push('/cart')
  }

  return (
    <SupplierCartContainer className={unableToCheckout ? 'unable-to-checkout' : ''}>
      <div className="supplier-cart-header">
        <div className="supplier-logo-outer">
          <img alt="supplier-logo" src={`${blobUrl}/${supplier.Logo}`} />
          <h4>{supplier.Name}</h4>
        </div>

        <div className="supplier-logo-outer">
          <div>
            <p style={{ fontSize: '14px' }}>Account </p>
            <p style={{ color: '#3e4148' }}>
              {supplier.AccountNumber}{' '}
              <span style={{ color: 'grey' }}>{supplier.AccountLabel && `"${supplier.AccountLabel}"`}</span>
            </p>
          </div>
          <div className="edit-cart-header" onClick={editHandler}>
            Edit
          </div>
        </div>
      </div>

      {supplierCart.map((item, i) => (
        <ItemRow key={item.Id} className={`item-row  partId-${item.SupplierPartId}`}>
          <img alt="product" src={item.ImageUrl} onError={(e) => handleBrokenProductImage(e)} />
          <div>
            <p className="item-name">{item.PartName}</p>
            {item.ContractCodeName ? (
              <p className="contractCodeLabel">
                Contract code:{' '}
                <span>
                  {item.ContractCodeName}
                  {item.ContractCodeDescription ? ` - ${item.ContractCodeDescription}` : null}
                </span>
              </p>
            ) : null}
          </div>
          <div className="item-details">
            <div className="item-quantity-unit">
              <div className="quantity-container">
                <p className="small lite">Qty:</p>
                <input type="number" value={item.Quantity || ''} disabled />
              </div>
              <p className="unit-price small lite">
                ${item.UnitPrice}/{item.UnitOfMeasure.toLowerCase()}
              </p>
            </div>
            <div className="item-price currency">{'$' + calcTotal(item.UnitPrice, item.Quantity)}</div>
          </div>
        </ItemRow>
      ))}

      <div className="supplier-cart-total">
        <h5>Subtotal</h5>
        <div className="supplier-total currency">${totalAmount}</div>
      </div>

      {!loader && unableToCheckout && (
        <div className="pending-verification-alert">
          <p>
            The selected address is pending verification by the supplier before an order can be placed. Select a
            verified address if you'd like to place an order now.
          </p>
        </div>
      )}
    </SupplierCartContainer>
  )
}

export default SupplierCartItem
