import React, { useState, useEffect } from 'react';
import SpendbridgeIframe from './iframe';
import Wrapper from '../../components/Wrapper';
import ComponentStyles from './styles';

const { SpendbridgeMain } = ComponentStyles;

const Spendbridge = props => {
    const [pageUrl, setPageUrl] = useState(false);

    useEffect(() => {
        let pagePath = props.location.pathname;

        if (pagePath.indexOf('history') !== -1) {
            setPageUrl('history');
        } else if (pagePath.indexOf('approvals') !== -1) {
            setPageUrl('approvals');
        } else if (pagePath.indexOf('checkout') !== -1) {
            setPageUrl('checkout');
        }
    });

    return (
        <SpendbridgeMain>
            <Wrapper {...props}>
            <section id="product-view">
                    <SpendbridgeIframe fullScreen pageUrl={pageUrl} userData={props.userData} />
              </section>
          </Wrapper>
      </SpendbridgeMain>
    );
};

export default Spendbridge;
