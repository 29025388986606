import _ from 'lodash';
import fetchData from './fetchData';
import config from './apiConfig';
import { formatCart } from './postCheckout/format';
import { getSpendbridgeUrls } from '../../utils/getSpendbridgeUrls';

const getSpendbridgeLinks = async (userData, sbUrl) => {
    const data = await getSpendbridgeUrls(userData, sbUrl);
    if (document) {
        if (!_.isEmpty(data)) {
            if (_.has(data, 'cart')) {
                document.location = data.cart;
                document.location.href = data.cart;
            }
        }
    }
};

export default async (userData, cart, callback) => {
    const userToken = userData.Network[userData.activeNetworkIndex].UserToken;
    const reqIds = formatCart(cart, { idsOnly: true });

    const options = {
        ...config.postSpendbridgeCheckout,
        url: `${config.getSpendbridgeUrl.url}/${userToken}/cart`,
        body: reqIds,
    };

    await fetchData(options)
        .then(data => {
            if (data && !data.ErrorCode) {
                const spendBridgeLoader = document.getElementById('spendBridgeLoader');
                if (spendBridgeLoader) {
                    spendBridgeLoader.style.zIndex = 1000000;
                    spendBridgeLoader.style.visibility = 'visible';
                }
                getSpendbridgeLinks(userData, { sbUrl: data.URL });
            } else {
                throw { fetchError: true, details: data };
            }
        })
        .catch(error => {
            if (error.response.status === 400 || error.response.status === 409) {
                callback();
            }
        });
};

