const isBrowser = typeof window !== 'undefined' && window.sessionStorage ? true : false
const runtimeConfig = isBrowser ? window.__NEXT_DATA__?.props?.pageProps?.publicRuntimeConfig : {}

const checkStatus = (response) => {
    if (response.ok) {
        return Promise.resolve(response)
    } else {
        return Promise.reject(response.statusText)
    }
}

const processBody = (response) => {
    switch (response.headers.Accept) {
        case 'application/json':
            response.json()
            break
        case 'text/html':
            response.text()
            break
        default:
            response.text()
            break
    }
    response.status === 200 ? response.json() : response.ok
}

export const genericFetch = async (uri = '/', options = {}, authToken) => {
    return fetchData(uri, options, authToken)
        .then(checkStatus)
        .then(processBody)
        .then((data) => data)
        .catch((error) => {
            return { res: `genericFetch :: ${error.ErrorMessage ? error.ErrorMessage : error}` }
        })
}

export const jsonFetch = async (uri = '/', options = {}, authToken) => {
    const jsonOptions = { ...options, headers: { 'Content-Type': 'application/json; charset=utf-8', ...options.headers } }
    return fetchData(uri, jsonOptions, authToken)
        .then(checkStatus)
        .then((response) => response.json())
        .then((data) => data)
        .catch((error) => {
            // console.error('error:', error)
            return { error: `jsonFetch :: ${error.ErrorMessage ? error.ErrorMessage : error}` }
        })
}

export const jsonNoBodyFetch = async (uri = '/', options = {}, accessToken, props) => {
    const jsonOptions = { ...options, headers: { 'Content-Type': 'application/json; charset=utf-8', ...options.headers } }
    return fetchData(uri, jsonOptions, accessToken, props)
        .then(checkStatus)
        .then((response) => (response.ok ? true : response))
        .catch((error) => {
            return { error: new Error(`jsonNoBodyFetch :: `, error.ErrorMessage ? error.ErrorMessage : error) }
        })
}

export const textFetch = async (uri = '/', options = {}, authToken) => {
    const jsonOptions = { ...options, headers: { 'Content-Type': 'application/json; charset=utf-8', ...options.headers } }
    return fetchData(uri, jsonOptions, authToken)
        .then(checkStatus)
        .then((response) => response.text())
        .then((data) => data)
        .catch((error) => {
            return { error: new Error(`textFetch :: `, error.ErrorMessage ? error.ErrorMessage : error) }
        })
}

export const fetchData = async (uri = '/no-path-provided', options = {}, authToken) => {

    if (authToken) {
        const authValue = `Bearer ${authToken}`
        options.headers.Authorization = authValue
    }

    try {
        return await fetch(uri, options)
    } catch (error) {
        return { error }
    }
}

export default jsonFetch
