Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _default = env => ({
  postAnalytics: {
    root: env.apiRoot,
    method: 'post',
    url: 'v1/Procurement/Magneto'
  },
  getCartData: {
    root: env.apiRoot,
    method: 'get',
    url: 'v1/Procurement/Carts/UserToken'
  },
  getCheckoutData: {
    root: env.apiRoot,
    method: 'get',
    url: 'v1/Cart/EqualLevel/User'
  },
  postItemQuantity: {
    root: env.apiRoot,
    method: 'put',
    url: 'v1/Procurement/Cart/Token'
  },
  deleteCartItem: {
    root: env.apiRoot,
    method: 'delete',
    url: 'v1/Procurement/Cart/Token'
  }
});

exports.default = _default;