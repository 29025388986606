import styled, { css } from 'styled-components';

const MaskInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: ${({ width }) => width || '100%'};

    .label-container {
        display: flex;
        font-family: 'Lato';
        font-size: 14px;
        letter-spacing: 0.25px;
        line-height: 20px;
        ${({ helperText }) =>
            !helperText &&
            css`
                margin-bottom: 4px;
            `}

        span {
            font-style: italic;
            color: #b9bbc1;
            margin-left: 4px;
        }
        ${({ toolTip }) =>
            toolTip &&
            css`
                justify-content: space-between;
            `}
    }

    .phoneSection {
        display: flex;
        flex-direction: row;
        .areaWrapper {
            background: rgb(250, 250, 250);
            border-radius: 4px;
            border: 1px solid rgb(222, 223, 226);
            width: 66px;
            margin-right: 0.5rem;
            justify-content: center;
            display: flex;
            flex-direction: row;
            padding-top: 12px;

            .plusCode {
                height: 20px;
                color: rgba(62, 65, 72, 0.35);
                font-size: 16px;
                font-family: Lato;
                font-weight: normal;
                text-align: center;
                line-height: 20px;
                margin-right: 0.5rem;
            }
            .areaCode {
                color: rgb(62, 65, 72);
                font-family: Lato;
                font-size: 16px;
                font-weight: normal;
                height: 20px;
                line-height: 20px;
                text-align: center;
            }
        }
    }

    p {
        margin: 0 0 4px;
        font-family: 'Lato';
        font-size: 14px;
        font-style: italic;
        color: #6e727c;
        letter-spacing: 0.25px;
        line-height: 20px;
    }

    .details-container {
        display: flex;
        justify-content: space-between;
        margin-top: 4px;
        ${({ type }) =>
            type === 'password' &&
            css`
                margin-top: 16px;
            `}

        .char-limit {
            display: flex;
            justify-self: flex-end;
            margin-left: auto;

            .limit-reached {
                color: #ff5722;
                font-weight: bold;
            }
        }
    }

    .icon-left,
    .icon-right {
        color: rgba(62, 65, 72, 0.35);
        margin: -32px 0 12px 0;
        font-size: 16px;
        font-weight: normal;
        height: 20px;
        line-height: 20px;
        width: 20px;
    }
    .icon-left {
        margin-left: 12px;
    }
    .icon-right {
        margin: -32px 12px 0 auto;
    }
    .image-right {
        margin: -38px 12px 0 auto;
        height: 24px;
    }

    ${({ error }) => error && ErrorStyle}
`;

const MaskInputStyle = styled.input`
    ${() => BasicStyles}
    ${({ rounded }) => {
    return rounded ? css`
                border-radius: 22px;
            ` : css`
            border-radius: 4px;
        `
  }}
    ${({ iconLeft, iconRight }) => {
        if (iconLeft)
            {return css`
                padding: 12px 12px 12px 36px;
            `;}
        if (iconRight)
            {return css`
                padding: 12px 36px 12px 12px;
            `;}
        return css`
                padding: 12px;xw
            `;
    }}

    ${({ imageRight }) => {
        if (imageRight) {
            return css`
                width: 38px;
                height: 24px;
            `;
        }
    }}

    &:focus {
        ${({ rounded }) => {
    return rounded ? css`
                    border-radius: 22px;
                ` : css`
                border-radius: 4px;
            `
  }}
    }
`;

const ErrorStyle = css`
    textarea,
    input {
        background-color: #fbe9e7;
        border: 1px solid #ff5722;

        &::placeholder {
            color: rgba(255, 87, 34, 0.5);
        }

        &:focus {
            border: 1px solid rgba(255, 87, 34, 0.5);
        }
    }

    .error-field {
        display: flex;
        align-items: center;
        color: #ff5722;

        i {
            font-size: 13.4px;
            margin-right: 4px;
        }
    }
`;

const BasicStyles = css`
    display: flex;
    flex-direction: column;
    background-color: #fafafa;
    border: 1px solid #dedfe2;
    width: 100vw - 24;
    height: ${({ height }) => height || '20px'};
    cursor: pointer;
    font-size: 16px;
    color: #3e4148;
    font-family: 'Lato';
    line-height: 20px;

    &::placeholder {
        color: rgba(62, 65, 72, 0.35);
        font-family: 'Lato';
        font-size: 16px;
        line-height: 20px;
    }

    &:focus {
        outline: none;
        border: 1px solid #3963fe;
        box-shadow: 0px 0px 4px 0px #3963fe;
    }

    &:disabled {
        background: #dedfe2;
        border: 1px solid #dedfe2;
    }
`;

export default {
    MaskInputStyle,
    MaskInputContainer,
};
