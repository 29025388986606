"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledComponents = _interopRequireWildcard(require("styled-components"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var SearchContainer = _styledComponents["default"].div.withConfig({
  displayName: "styles__SearchContainer",
  componentId: "awovji-0"
})(["display:flex;flex-direction:column;width:", ";p{margin:0 0 4px;font-family:'Lato';font-size:14px;font-style:italic;color:#6e727c;letter-spacing:0.25px;line-height:20px;}.icon{display:flex;justify-content:center;align-items:center;cursor:pointer;color:rgba(62,65,72,0.35);margin:-41px 0 0 6px;font-size:16px;font-weight:normal;height:36px;line-height:20px;width:36px;border-radius:50%;&:hover{opacity:0.9;}&.clear-icon{display:none;opacity:0;margin:-36px 48px 0px auto;font-size:13.4px;height:36px;line-height:36px;text-align:center;width:16px;}}"], function (_ref) {
  var width = _ref.width;
  return width ? width : '100%';
});

var SearchStyle = _styledComponents["default"].input.withConfig({
  displayName: "styles__SearchStyle",
  componentId: "awovji-1"
})(["", " border-radius:22px;padding:12px 12px 12px 40px;padding-right:", ";text-overflow:ellipsis;white-space:nowrap;overflow:hidden;&:focus{border-radius:22px;}"], function () {
  return BasicStyles;
}, function (_ref2) {
  var value = _ref2.value;
  return value && '4.25rem';
});

var BasicStyles = (0, _styledComponents.css)(["display:flex;flex-direction:column;background-color:#fafafa;border:1px solid #dedfe2;width:100vw - 24;height:", ";cursor:pointer;font-size:16px;color:#3e4148;font-family:'Lato';line-height:20px;&::placeholder{color:rgba(62,65,72,0.35);font-family:'Lato';font-size:16px;line-height:20px;}&:focus{outline:none;border:1px solid #3963fe;box-shadow:0px 0px 4px 0px #3963fe;}&:disabled{background:#dedfe2;border:1px solid #dedfe2;}i:disabled{background:#dedfe2;}"], function (_ref3) {
  var height = _ref3.height;
  return height ? height : '20px';
});
var _default = {
  SearchStyle: SearchStyle,
  SearchContainer: SearchContainer
};
exports["default"] = _default;