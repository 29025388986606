import { useRef } from 'react'
import { useOnClickOutside } from '../../../hooks/useOnClickOutside'
import { DropdownBody } from './DropdownBody'
import { DropdownContainer } from './styles'

export const Dropdown = ({ label = 'Select...', options = [], dropdownState, onSelect }) => {
  const dropdownRef = useRef()
  const { toggleShow, show, setDropdownState, selectedOption } = dropdownState
  useOnClickOutside(dropdownRef, () => {
    setDropdownState((prev) => ({
      ...prev,
      show: false,
    }))
  })

  return (
    <DropdownContainer ref={dropdownRef}>
      <div className="dropdown-button" onClick={toggleShow}>
        <p className={selectedOption ? 'selected-label-text' : 'label-text'}>
          {selectedOption ? selectedOption.label : label}
        </p>
        <i className={`fas ${show ? 'fa-angle-up' : 'fa-angle-down'} mutted-text`} />
      </div>
      {show && <DropdownBody position="absolute" options={options} onSelect={onSelect} selected={selectedOption} />}
    </DropdownContainer>
  )
}
