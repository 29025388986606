'use strict';

var _interopRequireDefault = require('@babel/runtime/helpers/interopRequireDefault');

Object.defineProperty(exports, '__esModule', {
    value: true,
});
exports['default'] = void 0;

var _defineProperty2 = _interopRequireDefault(require('@babel/runtime/helpers/defineProperty'));

var _apiConfig = _interopRequireDefault(require('./apiConfig'));

var _fetchData = _interopRequireDefault(require('./fetchData'));

var _raivenUtils = require('../../../raiven-utils');

var _lodash = _interopRequireDefault(require('lodash'));

function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly)
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        keys.push.apply(keys, symbols);
    }
    return keys;
}

function _objectSpread(target) {
    for (var i = 1; i < arguments.length; i++) {
        var source = arguments[i] != null ? arguments[i] : {};
        if (i % 2) {
            ownKeys(Object(source), true).forEach(function(key) {
                (0, _defineProperty2['default'])(target, key, source[key]);
            });
        } else if (Object.getOwnPropertyDescriptors) {
            Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
        } else {
            ownKeys(Object(source)).forEach(function(key) {
                Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
            });
        }
    }
    return target;
}

var _default = function _default(_ref) {
    var item = _ref.item,
        contractId = _ref.contractId,
        env = _ref.env,
        state = _ref.state;
    var apiKey = env.apiKey,
        apiUrl = env.apiUrl,
        bearerToken = env.bearerToken,
        userToken = env.userToken;
    var setAdded = state.setAdded,
        setAdding = state.setAdding;
    var config = (0, _apiConfig['default'])(apiUrl);
    return new Promise(function(resolve, reject) {
        var url = ''.concat(config.addToCart.url, '/').concat(userToken, '/Item');

        var options = _objectSpread(
            _objectSpread({}, config.addToCart),
            {},
            {
                url: url,
                body: {
                    ContractId: contractId,
                    SupplierPartNumber: item.SupplierPartNumber,
                    Name: item.Name,
                    ListPrice: parseFloat(item.Price.replace('$', '')),
                    Price: parseFloat(item.Price.replace('$', '')),
                    ImageUrl: item.Image,
                    Quantity: 1,
                },
                bearerToken: bearerToken,
                apiKey: apiKey,
            },
        );

        return (0, _fetchData['default'])(options)
            .then(function(data) {
                if (data && !data.ErrorCode && _lodash['default'].has(data, 'fetchedData')) {
                    setAdding(false);
                    setAdded(true);

                    var stateObject = _objectSpread(
                        _objectSpread({}, data),
                        {},
                        {
                            fetchError: data && data.ErrorCode ? true : false,
                        },
                    );

                    stateObject = _raivenUtils.FormatCart.cart(stateObject.fetchedData);
                    resolve(stateObject);
                } else {
                    reject({
                        fetchError: true,
                        details: data,
                    });
                }
            })
            ['catch'](function(error) {
                reject(error);
            });
    });
};

exports['default'] = _default;
