import React, { PureComponent } from 'react';
import ComponentStyles from './styles';
import { Button, Search } from '../../../../../../lib/@q-core/react-components';
import Modal from '../../../../components/Modal';
import { formatAddress } from '../helpers';
import _ from 'lodash';
import EditAddressModal from './editAddressModal';
import OrderDelayedModal from './OrderDelayedModal';
import { deleteAddress, postAddress } from '../../../../apis';
import { safeLocalStorage } from '../../../../../utils/safeStorage';
import { updateAddress } from '../../../../apis';
import { ADDRESS_VERIFICATION_STATUS, checkAddressVerificationStatus, isVerifiedAddress } from '../../../Cart/helpers';

const { AddressModalContainer, AddressModalStyles } = ComponentStyles;

class AddressModal extends PureComponent {
    constructor(props) {
        super(props);
        let formattted = [],
            addressOptions;

        if (props.modalType === 'Shipping') {
            addressOptions = props.shippingAddressOptions;
        } else {
            addressOptions = props.billingAddressOptions;
        }
        if (!_.isNil(addressOptions) && _.isArray(addressOptions)) {
            formattted = _.map(addressOptions, function(item, index) {
                let address = formatAddress(item);
                if (_.has(item, 'Id')) {
                    return {
                        ...address,
                        Id: item.Id,
                    };
                }
            });
        }

        this.state = {
            searchValue: '',
            formattted,
            filtered: formattted,
            addressOptions,
            openEditAddressModal: false,
            openOrderDelayedModal: false,
            selectedAddress: null,
            zip: null,
            city: null,
            addressLine2: null,
            street: null,
            company: null,
            state: null,
            updatedAddress: null,
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const updatedOptions =
            nextProps.modalType === 'Shipping' ? nextProps.shippingAddressOptions : nextProps.billingAddressOptions;
        const prevOptions = prevState.addressOptions;

        if (
            updatedOptions.length !== prevOptions.length ||
            JSON.stringify(updatedOptions) !== JSON.stringify(prevOptions)
        ) {
            let formattted = [],
                addressOptions = updatedOptions;

            // if (nextProps.modalType === 'Shipping') {
            //     addressOptions = nextProps.shippingAddressOptions;
            // } else {
            //     addressOptions = nextProps.billingAddressOptions;
            // }
            if (!_.isNil(addressOptions) && _.isArray(addressOptions)) {
                formattted = _.map(addressOptions, function(item, index) {
                    let address = formatAddress(item);
                    if (_.has(item, 'Id')) {
                        return {
                            ...address,
                            Id: item.Id,
                        };
                    }
                });
            }

            return {
                formattted,
                filtered: formattted,
                addressOptions,
            };
        }

        return {};
    }

    selectAddress = (address, type) => {
        if (_.has(address, 'Id')) {
            let ogAddress = _.find(this.state.addressOptions, { Id: address.Id });

            if (!_.isNil(ogAddress)) {
                this.props.setAddress(ogAddress, type);
            }
        }
    };

    filteredItems = value => {
        this.setState({
            filtered: value,
        });
    };

    onClear = value => {
        if (_.isEmpty(value)) {
            this.setState({
                filtered: this.state.formattted,
            });
        }
    };
    closeModal = () => {
        this.setState({ openEditAddressModal: false });
    };

    saveAddress = (zip, city, state, addressLine2, deliveryTo2, street, company) => {
        this.setState(
            {
                openEditAddressModal: false,
                zip,
                city,
                addressLine2,
                deliveryTo2,
                street,
                company,
                state,
            },
            () => {
                this.saveNewAddress(newSelectedAddress => {
                    const cartRequisitions = this.props.cart ? this.props.cart.CartRequisitions : [];
                    const verificationStatus = checkAddressVerificationStatus(
                        newSelectedAddress.UnVerifiedForSuppliers || [],
                        cartRequisitions.map(x => x.SupplierId),
                    );
                    this.setState({
                        openOrderDelayedModal: verificationStatus !== ADDRESS_VERIFICATION_STATUS.NO_UNVERIFIED,
                        updatedAddress: newSelectedAddress,
                    });
                }, false);
            },
        );
    };

    closeOrderDelayedModal = () => {
        this.setState({ openOrderDelayedModal: false });
    };

    deleteAddress = () => {
        const { selectedAddress } = this.state;
        const user = safeLocalStorage.getJSONItem('userData');
        let userToken = user.Network[user.activeNetworkIndex].UserToken;
        if (selectedAddress && selectedAddress.Id) {
            deleteAddress(userToken, selectedAddress.Id)
                .then(res => {
                    this.setState({ openOrderDelayedModal: false });
                    this.props.setModal(false);
                })
                .catch(e => console.log(e, 'error'));
        }
    };

    selectAndSaveNewAddress = () => {
        if (this.state.updatedAddress) {
            this.props.setAddress(this.state.updatedAddress, this.props.modalType, () => {
                this.setState({
                    updatedAddress: null,
                    openOrderDelayedModal: false,
                });
            });
        } else {
            this.closeOrderDelayedModal();
        }
    };

    selectAndSaveUpdatedAddress = () => {
        this.props.setAddress(this.state.updatedAddress, this.props.modalType, () => {
            this.setState({
                updatedAddress: null,
                openOrderDelayedModal: false,
            });
        });
    };

    saveNewAddress = (callback, isCloseAddressModal = true) => {
        const { zip, city, state, addressLine2, street, company, selectedAddress, deliveryTo2 } = this.state;
        const addressType = this.props.modalType;
        const user = safeLocalStorage.getJSONItem('userData');
        let userToken = user.Network[user.activeNetworkIndex].UserToken;
        let CorporateOfficeUserId = user.Network[user.activeNetworkIndex].CorporateOfficeUserId;

        if (selectedAddress) {
            updateAddress(
                userToken,
                selectedAddress.Id,
                zip,
                city,
                addressLine2,
                state,
                street,
                company,
                addressType,
                selectedAddress.externalId,
                deliveryTo2,
            )
                .then(res => {
                    this.props.setModal(!isCloseAddressModal, undefined, false);
                    if (callback && res) {
                        const newSelectedAddress = res;
                        console.log('props', this.props);
                        if (this.props.shippingAddress.Id === res.Id && this.props.modalType === 'Shipping') {
                            this.props.updatedAddressCall(res, this.props.modalType);
                        }
                        if (this.props.billingAddress.Id === res.Id && this.props.modalType === 'Billing') {
                            this.props.updatedAddressCall(res, this.props.modalType);
                        }
                        if (this.props.shippingAddress.Id === res.Id && this.props.modalType === 'Billing') {
                            this.props.updatedAddressCall(res, 'Shipping');
                        }
                        if (this.props.billingAddress.Id === res.Id && this.props.modalType === 'Shipping') {
                            this.props.updatedAddressCall(res, 'Billing');
                        }

                        callback(newSelectedAddress);
                    }
                })
                .catch(e => console.log(e, 'error'));
        } else {
            postAddress({
                userToken,
                zip,
                city,
                addressLine2,
                state,
                street,
                company,
                addressType,
                CorporateOfficeUserId,
            })
                .then(res => {
                    this.props.setModal(!isCloseAddressModal, undefined, false);

                    if (callback && res) {
                        const newSelectedAddress = res;
                        callback(newSelectedAddress);
                    }
                })
                .catch(e => {
                    console.log(e, 'error');
                });
        }
    };

    // isVerifiedAddress = (id) => {
    //     const { addressOptions } = this.state;
    //     const selectedAddress = addressOptions.find(address => id === address.Id);
    //     let verificationStatus = ADDRESS_VERIFICATION_STATUS.NO_UNVERIFIED;
    //     if (selectedAddress) {
    //         verificationStatus = checkAddressVerificationStatus(selectedAddress.UnVerifiedForSuppliers,this.props.cart);
    //     }
    //    return verificationStatus;
    // }

    render() {
        const {
            filtered,
            addressOptions,
            formattted,
            openEditAddressModal,
            openOrderDelayedModal,
            selectedAddress,
            updatedAddress,
        } = this.state;
        const user = safeLocalStorage.getJSONItem('userData');
        const activeUserIsAdmin =
            user && user.activeUserSettings && user.activeUserSettings.Role === 'Admin' ? true : false;
        return (
            <AddressModalContainer>
                <Modal
                    title='Select Address'
                    setEnabled={true}
                    darkBackground
                    setToggle={() => {
                        this.props.setModal(false, undefined, false);
                    }}
                    maxWidth='600px'
                    cardClassName='addressModal'
                    height="auto"
                    maxHeight="auto"
                >
                    <AddressModalStyles>
                        <Search
                            className='filterAddress'
                            onError={function noRefCheck() {}}
                            onSubmit={value => this.onClear(value)}
                            placeholder='Search your addresses&hellip;'
                            value={this.state.searchValue}
                            filterBy={['company', 'street', 'cityState', 'DeliverTo2']}
                            filtered={value => this.filteredItems(value)}
                            source={formattted}
                        />
                        <ul className={`optionContainer ${activeUserIsAdmin ? '' : 'notAdmin'}`}>
                            {_.map(filtered, (address, index) => {
                                const { company, street, cityState, DeliverTo2: deliverTo2} = address;
                                const { cart } = this.props;
                                const verificationStatus = isVerifiedAddress(addressOptions, cart, address.Id);
                                return (
                                    <li key={index}>
                                        <div className='addressInfo'>
                                            {company && <p className='company'>{company}</p>}

                                            {street && <p>{street}</p>}

                                            {cityState && <p>{cityState}</p>}
                                            {deliverTo2 && <p><span className='addressInfoJobNumber'>ID# {deliverTo2}</span></p>}
                                            {!!verificationStatus && (
                                                <div className='addressVerification'>
                                                    <i className='far fa-exclamation-circle'></i>
                                                    <p>
                                                        {verificationStatus ===
                                                            ADDRESS_VERIFICATION_STATUS.ALL_UNVERIFIED ||
                                                        verificationStatus ===
                                                            ADDRESS_VERIFICATION_STATUS.SOME_UNVERIFIED
                                                            ? 'Pending verification by supplier in checkout'
                                                            : ''}
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                        <div className='selectButton'>
                                            <Button
                                                type='indigoVibrant'
                                                shape='square'
                                                title='Select'
                                                // className='select-btn'
                                                onClick={() => {
                                                    this.selectAddress(address, this.props.modalType);
                                                }}
                                            />
                                            {activeUserIsAdmin && (
                                                <button
                                                    className='edit-btn'
                                                    onClick={() => {
                                                        this.setState({
                                                            openEditAddressModal: true,
                                                            selectedAddress: address,
                                                        });
                                                    }}
                                                >
                                                    Edit
                                                </button>
                                            )}
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                        {activeUserIsAdmin && (
                            <button
                                className='add-address-btn'
                                onClick={() => {
                                    this.setState({ openEditAddressModal: true, selectedAddress: undefined });
                                }}
                            >
                                <i className='fas fa-plus'></i>Add new address
                            </button>
                        )}
                    </AddressModalStyles>
                </Modal>
                {openEditAddressModal && (
                    <EditAddressModal
                        setAddress={this.props.setAddress}
                        closeModal={() => this.closeModal()}
                        saveAddress={(zip, city, state, addressLine2, deliveryTo2, street, company) =>
                            this.saveAddress(zip, city, state, addressLine2, deliveryTo2, street, company)
                        }
                        selectedAddress={selectedAddress}
                        deleteAddress={() => this.deleteAddress()}
                        modalType={this.props.modalType}
                    />
                )}
                {openOrderDelayedModal && (
                    <OrderDelayedModal
                        closeOrderDelayedModal={() => this.closeOrderDelayedModal()}
                        selectedAddress={selectedAddress || updatedAddress}
                        saveNewAddress={() => this.selectAndSaveNewAddress()}
                        addressOptions={addressOptions}
                        cart={this.props.cart}
                        isUpdating={!!selectedAddress}
                    />
                )}
            </AddressModalContainer>
        );
    }
}

export default AddressModal;
