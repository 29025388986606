import React, { useState, Fragment, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse, faGlassesRound, faClockRotateLeft, faMemoCircleCheck, faGear } from '@fortawesome/pro-solid-svg-icons'
import { getSpendbridgeUrls } from '../../../../utils/getSpendbridgeUrls'
import ComponentStyles from './styles'
import postAnalytics from '../../../../utils/postAnalytics.js'
import {useDefaultCheckout} from '../../../../hooks/useDefaultCheckout'

const { SubnavContainer } = ComponentStyles

const Subnav = (props) => {
  const [active] = useState('home')
  const history = useHistory()

  const isAdmin = props.userData && props.userData.activeUserSettings &&  props.userData.activeUserSettings.Role === 'Admin' 
  const isRaivenAndSpendbridgeCheckout = props.userData && useDefaultCheckout(props.userData) === 'raivenAndSpendbridge'

  const AdminNavItem = ({ isAdmin, userData }) => {
    return isAdmin && (
      <li className={`${window.location.pathname.includes('/admin-portal') ? 'active' : ''}`}>
        <Link
          to="/admin-portal"
          onClick={() => {
            postAnalytics(
              `pm-header-navigation-click-admin-portal`,
              userData
            )
          }}
        >
          <FontAwesomeIcon icon={faGear} style={{ marginRight: 8 }} />
          Admin
        </Link>
      </li>
    );
  };
  
  const getSpendbridgeLinks = async (userData, link, defaultCheckout) => {
    if (defaultCheckout === 'spendbridge' || defaultCheckout === 'raivenAndSpendbridge') {
      const data =  await getSpendbridgeUrls(userData)
      if (!_.isEmpty(data)) {
        if (_.has(data, link)) {
          document.location.href = data[link]
        }
      }
    } else if(link === 'admin-portal') {
      history.push('/admin-portal')
    } else {
      history.push('/requests')
    }
  }

  const showCheckoutLinks = props.defaultCheckout === 'spendbridge' 
                            || props.defaultCheckout === 'raivenAndSpendbridge' 
                            || props.defaultCheckout === 'raiven' 
  //only show sub navbar if we have more than the home button to include
  const showNavBar = isAdmin || showCheckoutLinks
                     

  return showNavBar && (
    <SubnavContainer>
      {isRaivenAndSpendbridgeCheckout ? (
        <Fragment>
          <ul>
            <li className={`${active === 'home' ? 'active' : ''}`}>
              <Link to="/marketplace" onClick={() => postAnalytics('pm-header-navigation-click-home')}>
                <FontAwesomeIcon icon={faHouse} style={{ marginRight: 8 }} />
                Home
              </Link>
            </li>
            <li className={`${active === 'requests' ? 'active' : ''}`}>
              <a
                onClick={async () => {
                  getSpendbridgeLinks(props.userData, 'approvals', 'raiven')
                  await postAnalytics(`pm-header-navigation-click-requests`, props.userData)
                }}
              >
                <FontAwesomeIcon icon={faMemoCircleCheck} style={{ marginRight: 8 }} />
                Requests
              </a>
            </li>
            <li className={`${active === 'approvals' ? 'active' : ''}`}>
              <a
                onClick={async () => {
                  getSpendbridgeLinks(props.userData, 'approvals', 'spendbridge')
                  await postAnalytics(`pm-header-navigation-click-approvals`, props.userData)
                }}
              >
                <FontAwesomeIcon icon={faGlassesRound} style={{ marginRight: 8 }} />
                Approvals
              </a>
            </li>
            <AdminNavItem isAdmin={isAdmin} userData={props.userData} />

            <li className={`${active === 'history' ? 'active' : ''}`}>              
              <a onClick={() => getSpendbridgeLinks(props.userData, 'history', 'spendbridge')}>
                <FontAwesomeIcon icon={faClockRotateLeft} style={{ marginRight: 8 }} />
                History
              </a>
            </li>
          </ul>
        </Fragment>
      ) : (
        <Fragment>
          {
            
            <ul>
              {(<li className={`${window.location.pathname === '/' || window.location.pathname === '/marketplace' ? 'active' : ''}`}>
                <Link to="/marketplace" onClick={() => postAnalytics('pm-header-navigation-click-home')}>
                  <FontAwesomeIcon icon={faHouse} style={{ marginRight: 8 }} />
                  Home
                </Link>
              </li>)}

              {showCheckoutLinks && (<li className={`${window.location.pathname.includes('approvals') ? 'active' : ''}`}>
                <a
                  onClick={async () => {
                    getSpendbridgeLinks(props.userData, 'approvals', props.defaultCheckout)
                    await postAnalytics(
                      `pm-header-navigation-click-${props.defaultCheckout === 'raiven' ? 'requests' : 'approvals'}`,
                      props.userData
                    )
                  }}
                >
                  <FontAwesomeIcon icon={faGlassesRound} style={{ marginRight: 8 }} />
                  {props.defaultCheckout === 'raiven' ? 'Requests' : 'Approvals'}
                </a>
              </li>)}

              <AdminNavItem isAdmin={isAdmin} userData={props.userData} />

              {props.defaultCheckout === 'spendbridge' && (
                <li className={`${active === 'history' ? 'active' : ''}`}>
                  <a
                  onClick={async () => {
                    getSpendbridgeLinks(props.userData, 'history', props.defaultCheckout)
                  }}>
                    <FontAwesomeIcon icon={faClockRotateLeft} style={{ marginRight: 8 }} />
                    History
                  </a>
                </li>
              )}
            </ul>
          }
        </Fragment>
        )}
    </SubnavContainer>
  )
}

export default Subnav
