import React, { useCallback, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faXmarkCircle,
} from "@fortawesome/pro-regular-svg-icons";
import styles from "./styles.module.css";
import postAnalytics from "../../../utils/postAnalytics";

const Toasts = ({ toastlist, position, setList }) => {
  const deleteToast = useCallback(
    (id) => {
      const toastListItem = toastlist.filter((e) => e.id !== id);
      setList(toastListItem);
    },
    [toastlist, setList]
  );

  const undoToastAction = (i) => {
    toastlist[i].undoAction = true;
    deleteToast(toastlist[i].id);
  };

  const selectIcon = (type) => {
    switch (type) {
      case "success":
        return <FontAwesomeIcon icon={faCheckCircle} color="#00C853" />;
      case "danger":
        return <FontAwesomeIcon icon={faXmarkCircle} color="#EE2300" />;
      default:
        return null;
    }
  };

  useEffect(() => {
    const interval = setTimeout(() => {
      if (toastlist.length) {
        deleteToast(toastlist[0].id);
        if (!toastlist[0].undoAction) {
          toastlist[0].action();
        } else {
          postAnalytics("pm-aprovers-toast-undo-click", {
            toastlist,
            position,
            setList,
          });
        }
      }
    }, 3000);

    return () => {
      clearTimeout(interval);
    };
  }, [toastlist, deleteToast]);

  return (
    <div className={`${styles.container} ${styles[position]}`}>
      {toastlist.map((toast, i) => (
        <div
          key={i}
          className={`${styles.notification} ${styles.toast} ${
            styles[toast.type]
          } ${styles.fadeInBottom}`}
        >
          <div style={{ display: "flex", flexDirection: "column", gap: 6 }}>
            <div style={{ display: "flex", alignItems: "center", gap: 6 }}>
              {selectIcon(toast.type)}
              <p className={styles.title}>{toast.title}</p>
            </div>
            {toast.undoAction !== null ? (
              <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                <button
                  onClick={() => undoToastAction(i)}
                  className={styles.undoButton}
                >
                  Undo
                </button>
              </div>
            ) : null}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Toasts;
