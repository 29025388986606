"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _default = function _default(apiUrl, apiVersion) {
  return {
    getSearchItems: {
      root: apiUrl,
      method: 'post',
      url: "".concat(apiVersion || 'v1', "/Supplier/Store/Search"),
      query: undefined
    },
    postAnalytics: {
      root: apiUrl,
      method: 'post',
      url: 'v1/Procurement/Magneto',
      query: undefined
    },
    getSuppliers: {
      root: apiUrl,
      method: 'get',
      url: 'v2/Procurement/User',
      query: undefined
    },
    addToCart: {
      root: apiUrl,
      method: 'post',
      url: 'v1/Procurement/Cart/Token',
      query: undefined
    },
    getItemDetails: {
      root: apiUrl,
      method: 'post',
      url: 'v1/Supplier/Store/Item/Details',
      query: undefined
    }
  };
};

exports["default"] = _default;