import styled from 'styled-components';

const EmptyCartContainer = styled.div`
    display: flex;
    height: 100%;
    width: 100%;

    img {
        margin-bottom: 1.5rem;
        width: 5.5rem;
    }

    h2 {
        font-size: 1rem;
        margin-bottom: 1rem;
    }

    .column {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        flex: 1;

        p {
            color: rgba(62, 65, 72, 0.7);
            font-size: 1rem;
            max-width: 25rem;

            .spendbridgeCart {
                margin-top: 1.5rem;
            }
        }
    }
`;

const SpendbridgeCart = styled.p`
    margin-top: 1.5rem;
    text-align: center;

    a {
        color: #3963fe;
        font-weight: normal;
    }
`;

export default { EmptyCartContainer, SpendbridgeCart };
