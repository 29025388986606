import { useState } from 'react'

/**
 * options shape: { id: any; label: string; }
 **/
export const useDropdown = (show = false) => {
  const [dropdownState, setDropdownState] = useState({ show, selectedOption: null })

  const toggleShow = () => {
    setDropdownState((prev) => ({
      ...prev,
      show: !prev.show,
    }))
  }

  const setSelectedOption = (option) => {
    setDropdownState((prev) => ({
      ...prev,
      selectedOption: option,
    }))
  }

  return {
    show: dropdownState.show,
    setDropdownState,
    toggleShow,
    selectedOption: dropdownState.selectedOption,
    setSelectedOption,
  }
}
