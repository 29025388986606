import { useEffect, useState } from 'react'
import { useCartContext } from '../../../../../hooks/useCartContext'
import { useDropdown } from '../../../../../hooks/useDropdown'
import { Checkbox } from '../../../../components'
import { Dropdown } from '../../../../components/Dropdown'
import { calcTotal } from '../../helpers'
import DotLoader from './dotLoader'
import { handleBrokenProductImage } from './helpers'

import { useDebounce } from '../../../../../hooks/useDebounce'
import { postItemQuantity } from '../../../../apis'
import ComponentStyles from './styles'

const { ItemRow } = ComponentStyles

const CartItem = ({ item, userData, supplier }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { setSelectedItem, removeSelectedItem, state, setCart } = useCartContext()
  const { selectedItems, availableContractCodes, showContractRequiredWarning } = state
  const dropdownState = useDropdown()
  const { debounceValue, setDebounceValue } = useDebounce(item.Quantity, async (qty) => {
    const newQty = parseInt(qty)
    if (item.Quantity === qty){
      return;
    } 

    if (newQty > 0) {
      setIsLoading(true)
      const updatedCart = await postItemQuantity({
        AccountNumber: currentItem.AccountNumber,
        partNumber: currentItem.SupplierPartNumber,
        supplierId: currentItem.SupplierId,
        userData,
        id: item.Id,
        item,
        qty: parseInt(qty),
      })
      setCart(updatedCart)
      setIsLoading(false)
    }
  })

  const currentItem = {
    SupplierId: supplier.Id,
    AccountNumber: supplier.AccountNumber,
    SupplierPartNumber: item.SupplierPartId,
    Quantity: item.Quantity,
    Id: item.Id
  }

  const isChecked = selectedItems.find(
    (item) =>
      item.SupplierId === currentItem.SupplierId &&
      item.AccountNumber === currentItem.AccountNumber &&
      item.SupplierPartNumber === currentItem.SupplierPartNumber &&
      item.Id === currentItem.Id
  )

  useEffect(() => {
    if (item.ContractCodeId) {
      const selectedContractCode = availableContractCodes.find((cc) => cc.id === item.ContractCodeId)
      !!selectedContractCode && dropdownState.setSelectedOption(selectedContractCode)
    }
  }, [item])

  const itemHasContractCode = !!dropdownState.selectedOption
  const showContractCodes = !!availableContractCodes.length

  const showWarning = showContractCodes && showContractRequiredWarning && !itemHasContractCode

  const toggleSelect = () => {
    ;(isChecked ? removeSelectedItem : setSelectedItem)(currentItem)
  }

  const onSelect = async (option) => {
    const contractCodeId = option.id
    const updatedCart = await postItemQuantity({
      AccountNumber: currentItem.AccountNumber,
      partNumber: currentItem.SupplierPartNumber,
      supplierId: currentItem.SupplierId,
      userData,
      item,
      id: currentItem.Id,
      qty: item.Quantity,
      contractCodeId,
    })
    dropdownState.setDropdownState((prev) => ({
      ...prev,
      selectedOption: option,
      show: false,
    }))

    setCart(updatedCart)
  }

  return (
    <div>
      <ItemRow className={`item-row  partId-${item.SupplierPartId}`}>
        <Checkbox checked={isChecked} onClick={toggleSelect} />
        <div>
          <img alt="product" src={item.ImageUrl} onError={(e) => handleBrokenProductImage(e)} onClick={toggleSelect} />
        </div>
        <div className="item-description">
          <p className="item-name" onClick={toggleSelect}>
            {item.PartName}
          </p>
          {showContractCodes && (
            <div className="contracts-dropdown-container">
              <Dropdown
                label="Select contract code..."
                dropdownState={dropdownState}
                options={availableContractCodes}
                onSelect={onSelect}
              />
              {showWarning && (
                <div className="contract-code-warning">
                  <i className="far fa-exclamation-circle" />
                  <span>Contract code required</span>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="item-details">
          <div className="item-quantity-unit">
            <div className="quantity-container">
              <p className="small lite" onClick={toggleSelect}>
                Qty:
              </p>
              <input type="number" value={debounceValue} onChange={({ target }) => setDebounceValue(target.value)} />
            </div>
            {isLoading ? (
              <DotLoader />
            ) : (
              <p className="unit-price small lite" onClick={toggleSelect}>
                ${item.UnitPrice}/{item.UnitOfMeasure.toLowerCase()}
              </p>
            )}
          </div>
          <div className="item-price currency" onClick={toggleSelect}>
            {isLoading ? <DotLoader /> : `$${calcTotal(item.UnitPrice, item.Quantity)}`}
          </div>
        </div>
      </ItemRow>
    </div>
  )
}

export default CartItem
