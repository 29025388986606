import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ComponentStyles from './styles';

const { TooltipContainer, TooltipContent } = ComponentStyles;

const Tooltip = props => {
    const { content, className, label } = props;

    const [iconClass, setIconClass] = useState('far');

    return (
        <TooltipContainer
            onMouseOver={() => setIconClass('fas')}
        onMouseOut={() => setIconClass('far')}
            className={`tooltip-container ${className}`}
        data-automation={`tooltip-${label}`}
      >
        <i className={`${iconClass} fa-info-circle`}>
              <TooltipContent className="tooltipContent">{content}</TooltipContent>
            </i>
      </TooltipContainer>
    );
};

Tooltip.propTypes = {
    content: PropTypes.string,
    position: PropTypes.string,
    icon: PropTypes.string,
    className: PropTypes.string,
};

export default Tooltip;
