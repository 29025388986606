import styled from 'styled-components'
import SquareMinusIcon from './SquareMinusIcon'

const Checkbox = ({ className, checked, onClick, partial }) => {
  const checkedIcon = partial ? <SquareMinusIcon /> : <i className="fas fa-check-square" />
  return (
    <CheckBoxStyle
      className={`checkbox ${className}`}
      type="checkbox"
      checked={checked}
      onClick={(e) => onClick(e.target.value)}>
      {checked ? checkedIcon : <i className="far fa-square" />}
    </CheckBoxStyle>
  )
}

export default Checkbox

const CheckBoxStyle = styled.div`
  cursor: pointer;
  .fa-square {
    color: #b9bbc1;
  }
  .fa-check-square {
    color: #3963fe;
  }
`
