import React, { useState, useEffect } from "react";
import { playAnimation } from "./helpers";
import ComponentStyles from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Button from "../Button";

const { ModalContainer, Card, TitleContainer } = ComponentStyles;

export default (props) => {
  const {
    setEnabled = false,
    toggle,
    children,
    blurBackground = true,
    closeIcon = true,
    containerClassName,
    cardClassName,
    title,
    height,
    width,
    maxHeight,
    maxWidth,
    setToggle,
    darkBackground,
    isRedirect = false,
  } = props;
  const [loaded, setLoaded] = useState(false);
  const containerName = `modal-container ${containerClassName}`;
  const cardName = `modal-card ${cardClassName}`;
  const redirectOverlay = (
    <div style={{ padding: '40px 22px 0px', display: 'flex', flexDirection: 'column', gap: '64px', alignItems: 'center' }}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '24px', alignItems: 'center' }}>
        <img src="/images/bookmark_icon.svg" style={{ width: '156px', height: '156px' }} />
        <h1 style={{ fontFamily: 'Poppins', lineHeight: '32px', fontWeight: '700' }}>Website URL Update!</h1>
        <p style={{ fontFamily: 'Rubik', fontSize: '16px', fontWeight: '400', color: 'rgba(0, 0, 0, 0.75)' }}>We're excited to inform you that our website has moved to a new domain to better serve you and enhance your experience with us.
          <br/><br/>
          <b style={{ color: 'rgba(0, 0, 0, 0.35)' }}>Old URL:</b><a style={{ color: 'rgba(0, 0, 0, 0.35', fontWeight: '400', cursor: 'text' }}> https://marketplace.qmerit.com</a>
          <br/>
          <b>New URL:</b> https://marketplace.raiven.com
          <br/><br/>
          Please update your bookmarks and records with our new address.
        </p>
      </div>
      <Button disabled={false} onClick={() => setToggle(false)} text="Ok" variant="primary" size="lg" styles={{width: '300px'}} />
    </div>
  )

  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (loaded) {
      if (setEnabled) {
        setTimeout(() => {
          playAnimation({
            toggle: true,
            blurBackground,
            setToggle,
            darkBackground,
          });
        }, 500);
      } else {
        playAnimation({
          toggle,
          blurBackground,
          setToggle,
          darkBackground,
        });
      }
    }
  }, [toggle, loaded]);

  return (
    <ModalContainer className={containerName} darkBackground={darkBackground}>
      <Card
        className={cardName}
        height={height}
        width={width}
        maxHeight={maxHeight}
        maxWidth={maxWidth}
      >
        <TitleContainer style={{ justifyContent: isRedirect && 'end' }}>
          {title && <h3>{title}</h3>}
          {closeIcon && (
            <span
              data-scorpius-automation="popupcard-closeicon"
              onClick={() => setToggle(false)}
              style={{ cursor: "pointer", opacity: 0.5 }}
            >
              <FontAwesomeIcon icon={faTimes} color="#000000" />
            </span>
          )}
        </TitleContainer>
        <div className="modal-children" style={{ display: "flex" }}>
          {isRedirect ? redirectOverlay : children}
        </div>
      </Card>
    </ModalContainer>
  );
};
