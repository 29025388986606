"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function createFloat(valueArray, _ref) {
  var _ref$parts = _ref.parts,
      parts = _ref$parts === void 0 ? true : _ref$parts,
      _ref$plain = _ref.plain,
      plain = _ref$plain === void 0 ? false : _ref$plain;
  var pieces = {
    whole: ''
  };

  var _final = _lodash["default"].map(valueArray, function (item, i) {
    if (item.type === 'integer') {
      pieces.whole += item.value;
    }

    if (item.type === 'group' && !plain) {
      pieces.whole += item.value;
    }

    if (item.type !== 'integer' || item.type !== 'group') {
      pieces[item.type] = item.value;
    }
  });

  if (parts === true) {
    return pieces;
  } else {
    return pieces.whole + pieces.decimal + pieces.fraction;
  }
}

var _default = function _default(value, options) {
  var defaults = {
    locale: 'en',
    decimal: 2,
    currency: false,
    plain: false,
    parts: false,
    currencyType: 'USD'
  },
      config = _objectSpread(_objectSpread({}, defaults), options);

  var locale = config.locale,
      decimal = config.decimal,
      currency = config.currency,
      currencyType = config.currencyType,
      plain = config.plain,
      parts = config.parts;
  var formatted = value,
      currencyFormat = currencyType || 'USD',
      decimalPlaces = decimal || 2,
      formatOptions = {};

  if (decimal === 0) {
    decimalPlaces = 0;
  }

  if (decimalPlaces > 0) {
    formatOptions = {
      maximumFractionDigits: decimalPlaces,
      minimumFractionDigits: decimalPlaces
    };
  }

  if (currency === true) {
    formatOptions = {
      style: 'currency',
      currency: currencyFormat
    };
  }

  if (parts === true || plain === true) {
    formatted = Intl.NumberFormat(locale, formatOptions).formatToParts(value);
    formatted = createFloat(formatted, {
      parts: parts,
      plain: plain
    });
  } else {
    formatted = Intl.NumberFormat(locale, formatOptions).format(value);
  }

  return formatted;
};

exports["default"] = _default;