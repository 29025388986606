const isBrowser = typeof window !== 'undefined' && window.env
const apiKey = isBrowser ? window.env.apiKey : process.env.RAZZLE_RUNTIME_API_KEY
const apimRoot = isBrowser ? window.env.apiRoot : process.env.RAZZLE_RUNTIME_API_ROOT
export const getOrder = async (token) => {
  const bearerToken = window.localStorage.getItem('token')
  try {
    const response = await fetch(`${apimRoot}/v1/PurchaseOrder/edit/${token}`, {
      method: 'GET',
      headers: {
        'Ocp-Apim-Subscription-Key': apiKey,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `bearer ${bearerToken}`,
      },
    })

    if (!response.ok) {
      throw new Error('Network response was not OK')
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error('Error fetching data:', error.message)
    throw error
  }
}

export const updateOrder = async (userToken, orderId, token, orderItems, selectedAddress) => {
  const formattedBody = orderItems.map((item) => {
    const address = selectedAddress ? selectedAddress : item.Shipping
    return {
      Id: item.Id,
      SupplierId: item.Supplier.Id,
      AccountNumber: item.AccountNumber,
      PurchaseOrderNumber: item.PurchaseOrderNumber,
      OrderId: item.OrderId,
      JobId: item.JobId,
      ShippingAddress: {
        Name: address.Name,
        Description: address.Description,
        Street1: address.Street1,
        Street2: address.Street2,
        City: address.City,
        State: address.State,
        PostalCode: address.PostalCode,
        Country: address.Country,
        Attention: address.Attention,
        DeliverTo1: address.DeliverTo1,
        DeliverTo2: address.DeliverTo2,
        Email: address.Email,
        Phone: address.Phone,
        Fax: address.Fax,
        Url: address.Url,
        AddressTypeId: 0,
        CorporateOfficeId: 0,
        ExternalId: address.Id,
        IsActive: true,
      },
      PurchaseOrderItem: item.Items.map((subitem) => {
        return {
          Id: subitem.Id,
          PurchaseOrderId: subitem.PurchaseOrderId,
          SupplierPartId: subitem.SupplierPartId,
          SupplierPartAuxiliaryId: subitem.SupplierPartAuxiliaryId,
          ManufacturerPartId: subitem.ManufacturerPartId,
          ManufacturerName: subitem.ManufacturerName,
          PartName: subitem.PartName,
          Description: subitem.Description,
          ShippingAmount: subitem.ShippingAmount,
          ShippingDescription: subitem.ShippingDescription,
          TaxAmount: subitem.TaxAmount,
          TaxDescription: subitem.TaxDescription,
          Quantity: subitem.Quantity,
          UnitOfMeasure: subitem.UnitOfMeasure,
          Classification: subitem.Classification,
          ClassificationDomain: subitem.ClassificationDomain,
          ListPrice: subitem.ListPrice,
          UnitPrice: subitem.UnitPrice,
          IsActive: true,
          ContractId: 0,
          SupplierId: item.Supplier.Id,
          ImageUrl: subitem.ImageUrl,
          ContractCodeId: subitem.ContractCodeId,
          ContractCodeName: subitem.ContractCodeName,
          ContractCodeDescription: subitem.ContractCodeDescription,
        }
      }),
    }
  })

  try {
    const bearerToken = window.localStorage.getItem('token')
    const response = await fetch(`${apimRoot}/v1/PurchaseOrder/OrderEdit/${orderId}/${token}/${userToken}`, {
      method: 'POST',
      headers: {
        'Ocp-Apim-Subscription-Key': apiKey,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `bearer ${bearerToken}`,
      },
      body: JSON.stringify(formattedBody),
    })

    if (!response.ok) {
      throw new Error('Network response was not OK')
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error('Error fetching data:', error.message)
    throw error
  }
}
