"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledComponents = _interopRequireWildcard(require("styled-components"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var defaultBtn = (0, _styledComponents.css)(["background:linear-gradient(-180deg,#f4f5f6 0%,#edeeef 100%);border:1px solid #edeeef;color:#6e727c;cursor:pointer;font-family:Lato;font-size:12px;font-weight:bold;letter-spacing:1px;line-height:16px;outline:none;min-width:200px;padding:12px 16px;text-align:center;text-transform:uppercase;&:active{background:#dedfe2;border-color:#edeeef;}&:disabled{background:#f4f5f6;border-color:#edeeef;color:#dedfe2;cursor:auto;}&:hover{background:linear-gradient(-180deg,#f4f5f6 0%,#dedfe2 100%);border-color:#dedfe2;&:disabled{background:#f4f5f6;border-color:#edeeef;color:#dedfe2;}&:active{background:#dedfe2;}}"]);
var Floating = (0, _styledComponents.css)(["box-shadow:0px 2px 4px 0px rgba(0,0,0,0.14),0px 3px 4px 0px rgba(0,0,0,0.12),0px 1px 5px 0px rgba(0,0,0,0.2);&:active{box-shadow:0px 0px 2px 0px rgba(0,0,0,0.14),0px 2px 2px 0px rgba(0,0,0,0.12),0px 1px 3px 0px rgba(0,0,0,0.2);}&:disabled{box-shadow:0px 2px 4px 0px rgba(0,0,0,0.14),0px 3px 4px 0px rgba(0,0,0,0.12),0px 1px 5px 0px rgba(0,0,0,0.2);}&:hover{box-shadow:0px 3px 3px 0px rgba(0,0,0,0.14),0px 3px 4px 0px rgba(0,0,0,0.12),0px 1px 8px 0px rgba(0,0,0,0.2);&:disabled{box-shadow:0px 2px 4px 0px rgba(0,0,0,0.14),0px 3px 4px 0px rgba(0,0,0,0.12),0px 1px 5px 0px rgba(0,0,0,0.2);}}}"]);
var Indiqo = (0, _styledComponents.css)(["background:linear-gradient(-180deg,#3862c5 0%,#3359ba 100%);border-color:#3359ba;color:#fff;&:hover{background:linear-gradient(-180deg,#3862c5 0%,#2b4dac 100%);border-color:#2b4dac;&:active{background:#2b4dac;border-color:#2f55fe;}&:disabled{background:#c3cfee;color:#9bb1e2;}}&:active{background:#2b4dac;border-color:#2f55fe;}&:disabled{background:#c3cfee;color:#9bb1e2;}"]);
var Action = (0, _styledComponents.css)(["background:linear-gradient(-180deg,#00bcd4 0%,#00acc1 100%);border-color:#00acc1;color:#fff;&:hover{background:linear-gradient(-180deg,#00bcd4 0%,#0097a7 100%);border-color:#0097a7;&:active{background:#0097a7;}&:disabled{background:#b2ebf2;border-color:#edeeef;color:#80deea;}}&:active{background:#0097a7;}&:disabled{background:#b2ebf2;border-color:#edeeef;color:#80deea;}"]);
var Success = (0, _styledComponents.css)(["background:linear-gradient(-180deg,#8bc34a 0%,#7cb342 100%);border-color:#7cb342;color:#fff;&:hover{background:linear-gradient(-180deg,#8bc34a 0%,#689f38 100%);border-color:#689f38;&:active{background:#689f38;}&:disabled{background:#dcedc8;color:#c5e1a5;}}&:active{background:#689f38;}&:disabled{background:#dcedc8;color:#c5e1a5;}"]);
var Alert = (0, _styledComponents.css)(["background:linear-gradient(-180deg,#ffc107 0%,#ffb300 100%);border-color:#ffb300;color:#fff;&:hover{background:linear-gradient(-180deg,#ffc107 0%,#ffa000 100%);border-color:#ffa000;&:active{background:#ffa000;}&:disabled{background:#ffecb3;color:#ffd54f;}}&:active{background:#ffa000;}&:disabled{background:#ffecb3;color:#ffd54f;}"]);
var Danger = (0, _styledComponents.css)(["background:linear-gradient(-180deg,#ff5722 0%,#f4511e 100%);border-color:#f4511e;color:#fff;&:hover{background:linear-gradient(-180deg,#ff5722 0%,#e64a19 100%);border-color:#e64a19;&:active{background:#e64a19;}&:disabled{background:#ffccbc;color:#ffab91;}}&:active{background:#e64a19;}&:disabled{background:#ffccbc;color:#ffab91;}"]);
var IndigoVibrant = (0, _styledComponents.css)(["background:linear-gradient(-180deg,#3963fe 0%,#2f55fe 100%);border-color:#2f55fe;color:#fff;&:hover{background:linear-gradient(-180deg,#3963fe 0%,#2b4dac 100%);border-color:#2b4dac;&:active{background:#2f55fe;}&:disabled{background:#8aa3ff;color:#4f75fe;}}&:active{background:#2f55fe;}&:disabled{background:#8aa3ff;color:#4f75fe;}"]);
var XL = (0, _styledComponents.css)(["font-size:24px;letter-spacing:1.5px;line-height:32px;padding:20px 24px;"]);
var Large = (0, _styledComponents.css)(["font-size:16px;line-height:20px;padding:16px 24px;"]);
var Medium = (0, _styledComponents.css)(["font-size:12px;line-height:16px;padding:12px 16px;"]);
var Small = (0, _styledComponents.css)(["font-size:12px;line-height:16px;padding:8px 12px;"]);
var Round = (0, _styledComponents.css)(["border-radius:100px;"]);
var buttonType, buttonSize, buttonDisabled;

var ButtonStyle = _styledComponents["default"].button.withConfig({
  displayName: "style__ButtonStyle",
  componentId: "sc-1febhip-0"
})(["", ";", ";", ";", ";", ";"], defaultBtn, function (_ref) {
  var type = _ref.type;
  var buttonType;

  switch (type) {
    case 'indiqo':
      buttonType = Indiqo;
      break;

    case 'action':
      buttonType = Action;
      break;

    case 'success':
      buttonType = Success;
      break;

    case 'alert':
      buttonType = Alert;
      break;

    case 'danger':
      buttonType = Danger;
      break;

    case 'indigoVibrant':
      buttonType = IndigoVibrant;
      break;

    default:
      buttonType = defaultBtn;
      break;
  }

  return buttonType && "".concat(buttonType, ";");
}, function (_ref2) {
  var size = _ref2.size;
  var buttonSize;

  switch (size) {
    case 'xl':
      buttonSize = XL;
      break;

    case 'large':
      buttonSize = Large;
      break;

    case 'medium':
      buttonSize = Medium;
      break;

    case 'small':
      buttonSize = Small;
      break;

    default:
      break;
  }

  return buttonSize && "".concat(buttonSize, ";");
}, function (_ref3) {
  var _float = _ref3["float"];
  var buttonFloat;

  if (_float) {
    buttonFloat = Floating;
  }

  return buttonFloat && "".concat(buttonFloat, ";");
}, function (_ref4) {
  var shape = _ref4.shape,
      size = _ref4.size;
  var buttonShape = Round;

  if (shape && shape === 'square') {
    switch (size) {
      case 'xl':
        buttonShape = 'border-radius: 16px';
        break;

      case 'large':
        buttonShape = 'border-radius: 12px';
        break;

      case 'medium':
        buttonShape = 'border-radius: 8px';
        break;

      case 'small':
        buttonShape = 'border-radius: 8px';
        break;

      default:
        break;
    }
  }

  return buttonShape && "".concat(buttonShape, ";");
});

var _default = ButtonStyle;
exports["default"] = _default;