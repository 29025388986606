import styled from 'styled-components'

const CartPageContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 30px;

  .undo-popup {
    display: flex;
    flex-direction: column;
    z-index: 99;
    position: fixed;
    bottom: -5rem;
    left: 0;
    right: 0;
    width: 15rem;
    margin: auto auto 1rem;
    margin-top: calc(auto + 5rem);
    background-color: rgba(255, 236, 179, 0.95);
    padding: 0.5rem 0.75rem;

    .message {
      display: flex;
      align-items: center;
      i {
        color: #ffc107;
        margin-right: 0.5rem;
      }
      p {
        color: #ff6f00;
      }
    }
    button {
      padding: 0;
      margin: 0.5rem 0 0 auto;
      width: fit-content;
      color: #ff6f00;
      background-color: transparent;

      &:focus {
        outline: none;
      }
    }
  }
`

const SupplierCartsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 1.5rem 0 1.5rem 0;
  height: fit-content;
`

const CartBodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
`

export default { CartPageContainer, SupplierCartsContainer, CartBodyContainer }
