import { getRawCookie } from "../../../../utils/cookies.js"

const isBrowser = typeof window !== 'undefined' && window.env
const apiKey = isBrowser ? window.env.apiKey : process.env.RAZZLE_RUNTIME_API_KEY
const apimRoot = isBrowser ? window.env.apiRoot : process.env.RAZZLE_RUNTIME_API_ROOT
const approvalAPI = apimRoot + '/approval'

const getCompanyId = () => {
  return getRawCookie('companyId')
}

export const getApprovals = async ({ token, status, requester, page = 1, perPage = 100 }) => {
  const myHeaders = new Headers()
  myHeaders.append('Ocp-Apim-Subscription-Key', apiKey)
  myHeaders.append('Authorization', `Bearer ${token}`)

  const companyId = getRawCookie('companyId')

  if (companyId) {
    myHeaders.append('company-id', companyId)
  }

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  }

  let url = new URL(`${approvalAPI}/v1/Requests/users/current/approvals`)
  let params = new URLSearchParams(url.search)

  status && params.append('status', status)
  requester && params.append('requester', requester)
  page && params.append('page', page)
  perPage && params.append('perpage', perPage)

  try {
    const response = await fetch(`${url}?${params}`, requestOptions)
      .then((response) => response.json())
      .then((result) => result)

    return response
  } catch (error) {
    return { error }
  }
}

export const getApprovalsSummary = async ({
  token,
  status,
  statuses,
  requesterId,
  approverId,
  supplierId,
  searchString,
  sortDescending = true,
  page,
  perPage = 100,
  adminView,
  statusForCurrentUser
}) => {
  const myHeaders = new Headers()
  myHeaders.append('Ocp-Apim-Subscription-Key', apiKey)
  myHeaders.append('Authorization', `Bearer ${token}`)

  const companyId = getCompanyId()

  if (companyId) {
    myHeaders.append('company-id', companyId)
  }

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  }

  let url = new URL(`${approvalAPI}/v2/Requests/users/current/approvals/summary`)
  let params = new URLSearchParams(url.search)

  status && params.append('status', status)
  statuses && statuses.forEach((status) => params.append('statuses', status));
  requesterId && params.append('RequesterId', requesterId)
  approverId && params.append('ApproverId', approverId)
  supplierId && params.append('SupplierId', supplierId)
  searchString && params.append('SearchString', searchString)
  page && params.append('Page', page)
  perPage && params.append('PerPage', perPage)
  adminView && params.append('AdminView', adminView)
  statusForCurrentUser && params.append('StatusForCurrentUser', statusForCurrentUser)
  sortDescending && params.append('SortDescending', sortDescending)

  try {
    const response = await fetch(`${url}?${params}`, requestOptions)
      .then((response) => response.json())
      .then((result) => result)

    return response
  } catch (error) {
    return { error }
  }
}

export const getRequests = async ({ token, status, page = 1, perPage = 100 }) => {
  const myHeaders = new Headers()
  myHeaders.append('Ocp-Apim-Subscription-Key', apiKey)
  myHeaders.append('Authorization', `Bearer ${token}`)

  const companyId = getCompanyId()

  if (companyId) {
    myHeaders.append('company-id', companyId)
  }


  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  }

  let url = new URL(`${approvalAPI}/v1/Requests/users/current`)
  let params = new URLSearchParams(url.search)

  status && params.append('status', status)
  page && params.append('page', page)
  perPage && params.append('perpage', perPage)

  try {
    const response = await fetch(`${url}?${params}`, requestOptions)
      .then((response) => response.json())
      .then((result) => result)

    return response
  } catch (error) {
    return { error }
  }
}

export const getRequestsSummary = async ({
  token,
  status,
  requesterId,
  approverId,
  supplierId,
  searchString,
  sortDescending = true,
  page = 1,
  perPage = 100,
  adminView,
  statuses,
}) => {
  const myHeaders = new Headers()
  myHeaders.append('Ocp-Apim-Subscription-Key', apiKey)
  myHeaders.append('Authorization', `Bearer ${token}`)

  const companyId = getCompanyId()

  if (companyId) {
    myHeaders.append('company-id', companyId)
  }

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  }

  let url = new URL(`${approvalAPI}/v2/Requests/users/current/summary`)
  let params = new URLSearchParams(url.search)

  status && params.append('status', status)  
  statuses && statuses.forEach((status) => params.append('statuses', status));
  requesterId && params.append('requesterId', requesterId)
  approverId && params.append('approverId', approverId)
  supplierId && params.append('supplierId', supplierId)
  searchString && params.append('searchString', searchString)
  page && params.append('page', page)
  perPage && params.append('perpage', perPage)
  adminView && params.append('adminView', adminView)
  sortDescending && params.append('sortDescending', sortDescending)

  try {
    const response = await fetch(`${url}?${params}`, requestOptions)
      .then((response) => response.json())
      .then((result) => result)

    return response
  } catch (error) {
    return { error }
  }
}

export const getRequestDetails = async ({ token, orderId }) => {
  const myHeaders = new Headers()
  myHeaders.append('Ocp-Apim-Subscription-Key', apiKey)
  myHeaders.append('Authorization', `Bearer ${token}`)

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  }

  let url = new URL(`${apimRoot}/v1/PurchaseOrder/${orderId}`)

  try {
    const response = await fetch(url, requestOptions)
      .then((response) => response.json())
      .then((result) => result)

    return response
  } catch (error) {
    return { error }
  }
}

export const processRequestAction = async (params, body) => {
  const myHeaders = new Headers()
  myHeaders.append('Ocp-Apim-Subscription-Key', apiKey)
  myHeaders.append('accept', 'text/plain; x-api-version=1.0')
  myHeaders.append('Authorization', `Bearer ${params.token}`)
  myHeaders.append('Content-Type', 'application/json; x-api-version=1.0')

  const companyId = getCompanyId()

  if (companyId) {
    myHeaders.append('company-id', companyId)
  }

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    redirect: 'follow',
    body: JSON.stringify(body),
  }

  try {
    const response = await fetch(`${approvalAPI}/v1/Approvals/process`, requestOptions)
      .then((response) => response.json())
      .then((result) => result)

    return response
  } catch (error) {
    return { error }
  }
}

export const getRequesters = async (params) => {
  const myHeaders = new Headers()
  myHeaders.append('Ocp-Apim-Subscription-Key', apiKey)
  myHeaders.append('Authorization', `Bearer ${params.token}`)

  const companyId = getCompanyId()

  if (companyId) {
    myHeaders.append('company-id', companyId)
  }

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  }

  try {
    const response = await fetch(`${approvalAPI}/v1/Users/purchasers`, requestOptions)
      .then((response) => response.json())
      .then((result) => result)

    return response
  } catch (error) {
    return []
  }
}

export const getApprovers = async ({ token }) => {
  const myHeaders = new Headers()
  myHeaders.append('Ocp-Apim-Subscription-Key', apiKey)
  myHeaders.append('Authorization', `Bearer ${token}`)

  const companyId = getCompanyId()

  if (companyId) {
    myHeaders.append('company-id', companyId)
  }

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  }

  try {
    const response = await fetch(`${approvalAPI}/v1/Users/approvers`, requestOptions)
      .then((response) => response.json())
      .then((result) => result)

    return response
  } catch (error) {
    return { error }
  }
}

export const getUserInfo = async (companyID) => {
  const token = localStorage.getItem('token')
  const myHeaders = new Headers()
  myHeaders.append('Ocp-Apim-Subscription-Key', apiKey)
  myHeaders.append('Authorization', `Bearer ${token}`)

  const companyId = companyID || getCompanyId()

  if (companyId) {
    myHeaders.append('company-id', companyId)
  }

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  }

  let url = `${approvalAPI}/v1/Users/current`

  try {
    const response = await fetch(`${url}`, requestOptions)
      .then((response) => response.json())
      .then((result) => result)

    return response
  } catch (error) {
    return { error }
  }
}

export const getSuppliers = async ({ token }) => {
  const myHeaders = new Headers()
  myHeaders.append('Ocp-Apim-Subscription-Key', apiKey)
  myHeaders.append('Authorization', `Bearer ${token}`)

  const userData = JSON.parse(localStorage.getItem('userData'))
  const userToken = userData.Network[userData.activeNetworkIndex].UserToken

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  }

  try {
    const response = await fetch(`${apimRoot}/v2/Procurement/User/${userToken}/CorporateOfficeContract`, requestOptions)
      .then((response) => response.json())
      .then((result) => result)

    return response
  } catch (error) {
    return { error }
  }
}

export const triggerActionFromEmail = async (token, body) => {
  const myHeaders = new Headers()
  myHeaders.append('Ocp-Apim-Subscription-Key', apiKey)
  myHeaders.append('accept', 'text/plain; x-api-version=1.0')
  myHeaders.append('Authorization', `Bearer ${token}`)
  myHeaders.append('Content-Type', 'application/json; x-api-version=1.0')

  const companyId = getCompanyId()

  if (companyId) {
    myHeaders.append('company-id', companyId)
  }

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    redirect: 'follow',
    body: JSON.stringify(body),
  }

  let url = new URL(`${approvalAPI}/v2/Approvals/process`)

  try {
    const response = await fetch(url, requestOptions)
      .then((response) => response.json())
      .then((result) => result)

    return response
  } catch (error) {
    return { error }
  }
}

export default getRequests
