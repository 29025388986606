import gsap from 'gsap';
import _ from 'lodash';
import { deleteCartItem, getCartData } from '../../../../apis';
import { safeLocalStorage } from '../../../../../utils/safeStorage';

const isBrowser = typeof window !== 'undefined' && window.env;
const blobUrl = isBrowser ? window.env.blobUrl : process.env.RAZZLE_RUNTIME_BLOB_URL;

export const updateCartQuantity = (e, state) => {
    const { index, onQuantityUpdate, supplier, isBlur, supplierCart, setSupplierCart, item, setLoadingItem } = state;
    let value = e.target.value ? parseInt(e.target.value, 10) : null;

    const newCart = [...supplierCart];
    newCart[index].Quantity = value;
    setSupplierCart(newCart);

    if (isBlur) {
        if (!value || value <= 0) {
            value = 1;
            newCart[index].Quantity = value;
            setSupplierCart(newCart);
        }

        onQuantityUpdate({
            value,
            supplierId: supplier.Id,
            itemId: supplierCart[index].Id,
            isBlur,
            item,
        });
    } else {
        setLoadingItem(item.Id, supplier.Id);
    }
};

export const getSupplierCartItem = (supplierItems, item) => {
    let supplierItem;
    supplierItems.forEach(i => {
        if (i.id === item.Id) supplierItem = i;
    });
    return supplierItem;
};

export const handlePendingItem = (
    e,
    {
        supplierCart,
        partId,
        setSupplierCart,
        onPendingDelete,
        setCurPendingItem,
        setPendingItemsCount,
        pendingItemsCount,
        supplier,
        setCart,
    },
) => {
    const cart = [...supplierCart];
    let pendingItem = null;

    setPendingItemsCount(pendingItemsCount + 1);

    cart.forEach(item => {
        if (item.SupplierPartId === partId) {
            item.pendingDelete = true;
            pendingItem = item;
        }
    });
    setPendingDeleteAnim(document.getElementsByClassName(`partId-${partId}`)[0], 'out');
    setCurPendingItem(pendingItem);
    setSupplierCart(cart);
    onPendingDelete(supplier.Id);
};

export const setPendingDeleteAnim = (target, fade) => {
    if (target) {
        const itemName = target.getElementsByClassName('item-name')[0];
        const itemPrice = target.getElementsByClassName('item-price')[0];

        if (fade === 'out') {
            gsap.to(itemName, { opacity: 0.35, ease: true, duration: 0.1 });
            gsap.to(itemPrice, { opacity: 0.35, ease: true, duration: 0.1 });
        } else if (fade === 'in') {
            gsap.to(itemName, { opacity: 1, ease: true, duration: 0.1 });
            gsap.to(itemPrice, { opacity: 1, ease: true, duration: 0.1 });
        } else if (fade === 'out-completely') {
            gsap.to(target, { opacity: 0, ease: true, duration: 0.1 });
            gsap.to(target, {
                height: 0,
                padding: 0,
                margin: 0,
                ease: true,
                duration: 0.2,
                delay: 0.1,
            });
            gsap.to(target, { display: 'none', delay: 0.3 });
        }
    }
};

const setDeleteFunction = async ({
    curPendingItem,
    userData,
    supplier,
    setDeletedItem,
    setCart,
    onSetSupplierLoading,
    onUnSetSupplierLoading,
}) => {
    const accountNumberFromCartData = await getCartData({ userData });
    const accountNum = accountNumberFromCartData.CartRequisitions.filter(x =>
        x.SupplierId === supplier.Id ? x.AccountNumber : null,
    )[0].AccountNumber;

    onSetSupplierLoading(supplier.Id);

    await deleteCartItem({
        AccountNumber: accountNum,
        partId: curPendingItem.SupplierPartId,
        supplierId: supplier.Id,
        userData,
    }).then(cart => {
        safeLocalStorage.setJSONItem('cart', cart);
        setCart(cart);
        setPendingDeleteAnim(
            document.getElementsByClassName(`partId-${curPendingItem.SupplierPartId}`)[0],
            'out-completely',
        );
        onUnSetSupplierLoading(supplier.Id);
        setTimeout(() => {
            setDeletedItem(curPendingItem);
        }, 1000);
    });
};

export const onCurPendingItemUpdate = props => {
    const {
        curPendingItem,
        userData,
        supplier,
        setTimeoutFunc,
        setDeletedItem,
        setIsDeletingItem,
        setCart,
        onSetSupplierLoading,
        onUnSetSupplierLoading,
    } = props;

    const deleteFunction = function() {
        setIsDeletingItem(true);
        setDeleteFunction({
            curPendingItem,
            userData,
            supplier,
            setDeletedItem,
            setCart,
            onSetSupplierLoading,
            onUnSetSupplierLoading,
        });
    };

    const newTimeout = setTimeout(deleteFunction, 2500);
    setTimeoutFunc(newTimeout);
};

export const handleUndo = props => {
    const {
        timeout,
        supplierCart,
        setSupplierCart,
        setCurPendingItem,
        curPendingItem,
        pendingItemsCount,
        setPendingItemsCount,
    } = props;

    clearTimeout(timeout);
    setTimeout(null);
    const cart = [...supplierCart];
    cart.forEach(item => {
        if (item.pendingDelete && item.id === curPendingItem.id) {
            item.pendingDelete = false;
            setPendingDeleteAnim(document.getElementsByClassName(`partId-${curPendingItem.partId}`)[0], 'in');
            setPendingItemsCount(pendingItemsCount - 1);
            setCurPendingItem(null);
        }
    });
    setSupplierCart(cart);
};

export const filterSupplierCart = ({ deletedItem, supplierCart, setSupplierCart }) => {
    const cart = _.filter(supplierCart, item => {
        if (item.partId !== deletedItem.partId) return item;
    });
    setSupplierCart(cart);
};

// export const formatItems = (items) => {
//   return items.map((item) => ({
//     id: item.Id,
//     partId: item.SupplierPartId,
//     price: item.ListPrice,
//     quantity: item.Quantity,
//     active: item.IsActive,
//     imageUrl: item.ImageUrl,
//     name: item.PartName,
//     unitPrice: item.UnitPrice,
//   }));
// };

export const handleItemCheck = ({ item, supplierCart, setSupplierCart, active }) => {
    const newCart = [...supplierCart];
    newCart.forEach(cartItem => {
        if (cartItem.id === item.id) cartItem.active = active;
    });
    setSupplierCart(newCart);
};

export const handleSupplierCheck = ({ supplierCart, setSupplierCart, active }) => {
    const newCart = [...supplierCart];
    newCart.forEach(cartItem => {
        cartItem.active = !active;
    });
    setSupplierCart(newCart);
};

export const updateCartLength = ({ supplierCart }) => {
    let cartLength = 0;
    supplierCart &&
        supplierCart.forEach(item => {
            if (!item.deleted) {
                cartLength += item.Quantity;
            }
        });
    return cartLength;
};

export const handleBrokenProductImage = e => {
    e.target.src = `${blobUrl}/procurement/images/No-Product-Image.png`;
};
