"use strict";

require("core-js/modules/es.promise");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.cancelableFetchData = exports.getFetchOptions = void 0;

var _axios = _interopRequireDefault(require("axios"));

var _lodash = _interopRequireDefault(require("lodash"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

const isBrowser = typeof window !== 'undefined' && window.env;
const apiKey = isBrowser ? window.env.apiKey : process.env.RAZZLE_RUNTIME_API_KEY;
const urlController = {
  url: '',
  validUrl: false,
  buildUrl: function buildUrl(options) {
    this.url = options.root ? options.root + '/' : '';
    if (options.version && options.version.length > 0) this.url += options.version + '/';
    if (options.url) this.url += options.url;
    if (options.query && options.query.length > 0) this.url += '?' + options.query;
    return this;
  },
  validateUrl: function validateUrl() {
    // from: https://gist.github.com/dperini/729294
    this.validUrl = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(this.url);
    return this;
  }
};

const getFetchOptions = (options, env) => {
  let newOptions = {};

  if (_lodash.default.has(options, 'body')) {
    newOptions.data = options.body;
  }

  if (_lodash.default.has(options, 'method')) {
    newOptions.method = options.method;
  }

  newOptions.headers = {
    'Ocp-Apim-Subscription-Key': "".concat(env.apiKey)
  };

  if (_lodash.default.has(env, 'bearerToken')) {
    newOptions.headers.Authorization = 'bearer ' + env.bearerToken;
  }

  return newOptions;
};

exports.getFetchOptions = getFetchOptions;

const fetchData = (options, env) => {
  return new Promise((resolve, reject) => {
    const url = urlController.buildUrl(options).validateUrl().url;
    const formattedOptions = getFetchOptions(options, env);
    (0, _axios.default)(_objectSpread({
      url
    }, formattedOptions)).then(response => {
      if (response.data) resolve(_objectSpread({}, response.data));
    }).catch(fetchError => {
      console.log('fetchError: ', fetchError);
      reject(_objectSpread({
        ErrorCode: 500
      }, fetchError));
    });
  });
};

const cancelableFetchData = (options, env) => {
  return new Promise((resolve, reject) => {
    const url = urlController.buildUrl(options).validateUrl().url;
    const formattedOptions = getFetchOptions(options, env);
    return (0, _axios.default)(_objectSpread({
      url,
      cancelToken: options.cancelToken
    }, formattedOptions)).then(response => {
      if (response.data) resolve(_objectSpread({}, response.data));
    }).catch(err => {
      if (_axios.default.isCancel(err)) {
        console.log('Original request canceled');
      } else {
        console.log(err);
      }

      reject({
        error: err
      });
    });
  });
};

exports.cancelableFetchData = cancelableFetchData;
var _default = fetchData;
exports.default = _default;