import styled, { css } from 'styled-components'

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width || '100%'};

  .selectInput {
    position: relative;
    width: 100% !important;
    box-sizing: border-box;
    background-color: #fafafa !important;
    margin: 0;
    padding: 0 12px;
    height: 44px;
    border: 1px solid #dedfe2;
    align-items: center;
    .gKlNrd {
      overflow-y: auto;
      min-height: 400px;
      @media (max-width: 1153px) {
        min-height: 300px;
      }
    }
    .gCSAhH {
      width: 100%;
    }
  }

  .label-container {
    display: flex;
    font-family: 'Lato';
    font-size: 14px;
    letter-spacing: 0.25px;
    line-height: 20px;
    ${({ helperText }) =>
      !helperText &&
      css`
        margin-bottom: 4px;
      `}

    span {
      font-style: italic;
      color: #b9bbc1;
      margin-left: 4px;
    }
  }

  .dropdown-content {
    display: flex;
    > div {
      padding-left: 12px;
      line-height: 24px;
      // color: rgba(62,65,72,0.35) !important;
    }

    .dropdown-img {
      width: 22px;
      height: 22px;
      margin-right: 12px;
      + {
        div {
          line-height: 24px;
          color: rgb(62, 65, 72) !important;
        }
      }
    }
  }

  .rotate {
    -moz-transition: all 0.5s linear;
    -webkit-transition: all 0.5s linear;
    transition: all 0.5s linear;
  }
  .rotate.down {
    -moz-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  .helper-text {
    margin: 0 0 4px;
    font-family: 'Lato';
    font-size: 14px;
    font-style: italic;
    color: #6e727c;
    letter-spacing: 0.25px;
    line-height: 20px;
  }
`

const BasicStyles = css`
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
  border: 1px solid #dedfe2;
  width: 100vw - 24;
  height: ${({ height }) => height || '20px'};
  cursor: pointer;
  font-size: 16px;
  color: #3e4148;
  font-family: 'Lato';
  line-height: 20px;

  &::placeholder {
    color: rgba(62, 65, 72, 0.35);
    font-family: 'Lato';
    font-size: 16px;
    line-height: 20px;
  }

  &:focus {
    outline: none;
    border: 1px solid #3963fe;
    box-shadow: 0px 0px 4px 0px #3963fe;
  }

  &:disabled {
    background: #dedfe2;
    border: 1px solid #dedfe2;
  }
`

const LIStyle = styled.li`
  ${() => BasicStyles}
  border: 0;
  &:hover {
    background-color: #dedfe2;
  }
`

const PopupContainer = styled.div`
  position: relative;
`

const PopupContent = styled.ul`
  z-index: 1;
  border: 0;
  padding: 0px;
  margin: 0px;
  border-radius: ${({ radius }) => (radius ? `${radius}px` : '4px')};
  display: flex;
  flex-direction: column;
  position: absolute;
  width: ${({ width }) => width || '100%'};
  background-color: ${({ background }) => background || '#FFF'};
  box-shadow: ${({ shadow }) =>
    shadow ||
    '0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12), 0px 3px 5px 0px rgba(0, 0, 0, 0.2)'};
`

const PopupLi = styled(LIStyle)`
  ${() => PopupBasicStyles}
  margin-bottom: 0;
  display: inline-block;
  &:hover ${PopupContent} {
    display: block;
  }
  border-radius: 0;
  border: 0;
  font-size: ${({ fontSize }) => fontSize || '14px'};
  padding: ${({ itemPadding }) => itemPadding || '8px 12px'};
`

const PopupBasicStyles = css`
  cursor: pointer;
  font-size: 16px;
  color: #3e4148;
  font-family: 'Lato';
  line-height: 20px;
`

const SelectULStyle = styled.ul`
  ${() => DropDownBasicStyles}
  list-style-type: none;
  height: 18px;
  width: 176px;
  margin: 0;
  padding: 12px;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid rgb(222, 223, 226);
  background-color: #ffffff;
  border-radius: 4px;
`

const SelectLIStyle = styled.li`
  ${() => DropDownBasicStyles}
  border: 0;
  &:hover {
    color: #3963fe;
  }
  margin-bottom: 12px;
`

const LabelDiv = styled.div`
  ${() => DropDownBasicStyles}
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 0;
  width: 100%;
  i {
    color: rgba(62, 65, 72, 0.35);
    font-size: 16px;
    font-weight: normal;
    height: 20px;
    line-height: 20px;
    width: 20px;
  }
  .notselected {
    color: #3e4148;
    opacity: 0.35;
  }
`

const DropDownContent = styled.div`
  ${() => DropDownBasicStyles}
  min-width: 176px;
  z-index: 1;
  border: 0;
  border-radius: 4px;
`

const DropDownLi = styled(SelectLIStyle)`
  ${() => DropDownBasicStyles}
  padding: 12px 16px;
  display: inline-block;
  &:hover {
    color: #3963fe;
  }
  &:last-child {
    border-radius: 0 0 8px 8px;
  }
  &:first-child {
    border-radius: 8px 8px 0 0;
  }
  border-radius: 0;
  border: 0;
  margin: 0;
`

const DropDownLiSelected = styled(DropDownLi)`
  color: #3963fe;
`

const DropDownItemsContainer = styled.div`
  position: absolute;
  height: 200px;
  overflow: scroll;
  width: 100%;
  top: 44px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  margin: 4px 0 0 -12px;
  border-radius: 8px;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12),
    0px 3px 5px 0px rgba(0, 0, 0, 0.2);

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`

const DropDownBasicStyles = css`
  display: flex;
  flex-direction: column;
  height: 20px;
  cursor: pointer;
  font-size: 14px;
  color: #3e4148;
  font-family: 'Lato';
  line-height: 20px;

  &::placeholder {
    color: rgba(62, 65, 72, 0.35);
    font-family: 'Lato';
    font-size: 16px;
    line-height: 20px;
  }

  &:focus {
    outline: none;
    border: 1px solid rgb(0, 188, 212);
    box-shadow: 0px 0px 4px 0px rgb(77, 208, 225);
  }

  &:disabled {
    background: #dedfe2;
    border: 1px solid #dedfe2;
  }
`

const MultiSectionHeader = styled.h4`
  color: #b9bbc1;
  font-size: 16px;
  font-weight: bold;
  height: 20px;
  line-height: 20px;
  padding: 8px 0 4px;
  box-shadow: inset 0px 1px 0px 0px rgb(222, 223, 226);
  cursor: default;
  margin: 0 12px;
`

export default {
  LIStyle,
  PopupContainer,
  PopupContent,
  PopupLi,
  SelectULStyle,
  SelectLIStyle,
  LabelDiv,
  DropDownContent,
  DropDownLi,
  DropDownLiSelected,
  DropDownItemsContainer,
  MultiSectionHeader,
  SelectContainer,
}
