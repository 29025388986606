import React, { useState, useContext } from 'react'
import Button from '../Button'
import RequestModal from '../../components/RequestModal'
import { MobileLayout } from '../../App';
import Select from '../Select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import styles from "./style.module.css";

function FiltersModal({ 
  setShowModal, 
  isUserApprover, 
  requesters, 
  approvers, 
  selectedStatus, 
  setSelectedStatus, 
  selectedRequester, 
  setSelectedRequester, 
  activeTab = false, 
  selectedApprover = false, 
  setSelectedApprover = false,
  statusOptions = []
}) {
  const isMobile = useContext(MobileLayout)
  const [selectedStatusItem, setSelectedStatusItem] = useState( statusOptions.find(x => x.value == selectedStatus.value).value)
  const [mobileSelectedOption, setMobileSelectedOption] = useState()

  const closingModal = () => {
    const modalOverlay = document.querySelector('div.src-common-components-RequestModal-__style-module___mobileModalOverlay')
    const modalDiv = document.querySelector('div.src-common-components-RequestModal-__style-module___modal.src-common-components-RequestModal-__style-module___fadeInBottom.src-common-components-RequestModal-__style-module___mobileStyleModal')
    modalDiv.classList.remove('src-common-components-RequestModal-__style-module___fadeInBottom')
    modalDiv.classList.add('src-common-components-RequestModal-__style-module___fadeOutBottom')
    modalOverlay && (modalOverlay.style.transition = "250ms")
    modalOverlay && (modalOverlay.style.backgroundColor = "unset")
    setTimeout(() => {
        setMobileSelectedOption(false)
        setShowModal(false)
    }, 800)
  }

  const applyFilters = () => {
    selectedStatusItem && setSelectedStatus(statusOptions.find(x => x.value == selectedStatusItem))
    mobileSelectedOption && activeTab === 'requests' ? setSelectedApprover(mobileSelectedOption) : mobileSelectedOption && setSelectedRequester(mobileSelectedOption)
    closingModal()
  }

  return (
    <RequestModal
      width={600}
      height={statusOptions.length > 2 ?  '586px' : '374px'} 
      onClose={() => {}}
      header={
        <h3
          style={{
            flex: 1,
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 800,
            fontSize: 24,
            lineHeight: '32px',
            color: '#000000',
            height: isMobile && '44px',
            display: 'inline-flex',
            alignItems: 'center',
          }}
        >
          Filters
        </h3>
      }
      footer={
        <div style={{ display: 'flex', gap: 16, padding: isMobile ? '16px 24px 34px 24px' : '16px 24px' }}>
          <Button onClick={() => closingModal()} styles={{ fontSize: isMobile && '16px', lineHeight: isMobile && '20px', letterSpacing: isMobile && '0.02em' }} fullWidth text="Cancel" resize="lg" variant="default" />
          <Button
            onClick={() => applyFilters()}
            fullWidth
            styles={{ fontSize: isMobile && '16px', lineHeight: isMobile && '20px', letterSpacing: isMobile && '0.02em' }}
            text="Apply"
            variant="primary"
            size="lg"
          />
        </div>
      }
      showMobileStyleModal={isMobile}
      reactToOutsideClick={setShowModal}
    >
      <div
        style={{
          paddingTop: isMobile ? 16 : 24,
          paddingBottom: isMobile ? 96 : 28,
          paddingLeft: 24,
          paddingRight: 24,
        }}
      >
        { statusOptions.length > 2 &&
        <div className={styles.statusSection}>
            <h4 className={styles.sectionTitle}>Status</h4>
            <div className={styles.menuContainer}>
            {statusOptions.map((option) => (
              <div
                key={option.value}
                onClick={() => setSelectedStatusItem(option.value)}
                className={`${styles.menuItem} ${
                  selectedStatusItem === option.value ? styles.active : ''
                }`}
              >

                <p>{option.label}</p>
                {selectedStatusItem === option.value && (
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{
                      fontWeight: '400',
                      fontSize: '16px',
                      lineHeight: '16px',
                      color: '#00C853',
                    }}
                  />
                )}
        </div>
      ))}
            </div>
        </div>
}
        {isUserApprover && (
            <div>
                <h4 className={styles.sectionTitle}>Requester</h4>
                <Select
                    placeholder="Select a requester"
                    value={selectedRequester}
                    onChange={setMobileSelectedOption}
                    options={requesters}
                />
            </div>
        )}
        {activeTab === 'requests' && (
          <div>
            <h4 className={styles.sectionTitle}>Approver</h4>
            <Select
                placeholder="Select an approver"
                value={selectedApprover}
                onChange={setMobileSelectedOption}
                options={approvers}
            />
          </div>
        )}
      </div>
    </RequestModal>
  )
}

export default FiltersModal
