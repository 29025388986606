import styled from 'styled-components';

const OrderWrapper = styled.div`
    max-width: 73.5rem;
    width: 100%;

    .backButton {
        color: rgba(62, 65, 72, 0.7);
        font-size: 0.875rem;
        line-height: 1.25rem;
        &:hover {
            opacity: 0.8;
        }

        i {
            color: #969ba5;
            font-size: 1rem;
            margin-right: 0.5rem;
            line-height: 1.25rem;
        }
    }
`;

const OrderContents = styled.div`
    margin-top: 1.5rem;

    h2 {
        margin-bottom: 1rem;
    }
`;

export default {
    OrderWrapper,
    OrderContents,
};
