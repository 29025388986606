import React from 'react';
import Header from '../Header';
import ComponentStyles from './styles';

const { WrapperStyles } = ComponentStyles;

const Wrapper = (props) => {
  const {
    cartItemTotal,
    children,
    userData,
    history,
    currentView,
    getCartTotal,
    onCartUpdate,
    defaultCheckout,
  } = props;

  return (
    <WrapperStyles className='wrapper'>
      <Header
        userData={userData}
        history={history}
        currentView={currentView}
        cartItemTotal={cartItemTotal}
        getCartTotal={getCartTotal}
        onCartUpdate={onCartUpdate}
        defaultCheckout={defaultCheckout}
      />
      <div className='wrapper-children'>{children}</div>
    </WrapperStyles>
  );
};

export default Wrapper;
