Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _static;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _default = {
  base: {
    zoomControl: false,
    zoom: 14,
    gestureHandling: 'auto',
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false
  },
  "static": (_static = {
    zoomControl: false,
    zoom: 14,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
    disableDoubleClickZoom: true,
    draggable: false,
    keyboardShortcuts: false
  }, _defineProperty(_static, "scaleControl", false), _defineProperty(_static, "rotateControl", false), _defineProperty(_static, "scrollwheel", false), _static)
};
exports["default"] = _default;