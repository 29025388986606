import React, { useState, useEffect, Fragment } from 'react'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartShopping, faUserGear } from '@fortawesome/pro-regular-svg-icons'
import { faCartShopping as faCartShoppingSolid, faUserGear as faUserGearSolid } from '@fortawesome/pro-solid-svg-icons'
import MiniCart from '../../../../lib/@q-core/mini-cart'
import { handleUpdateActiveNetwork, handleNavigation, getLocalCart } from './helpers'
import { safeLocalStorage } from '../../../utils/safeStorage'
import NetworkDropdown from '../NetworkDropdown'
import ComponentStyles from './styles'
import { postSpendbridgeCheckout } from '../../apis'
import SubNavigation from './Subnav'
import { getSpendbridgeUrls } from '../../../utils/getSpendbridgeUrls'
import { calcTotal } from '../../features/Cart/helpers'
import {hasRaivenCheckout, useDefaultCheckout} from '../../../hooks/useDefaultCheckout'
import postAnalytics from '../../../utils/postAnalytics.js'
import { setCookie } from '../../../utils/cookies.js'

const { HeaderContainer, HeaderWrapper, SpendbridgeEmptyCart } = ComponentStyles
const isBrowser = typeof window !== 'undefined' && window.env
const apiRoot = isBrowser ? window.env.apiRoot : process.env.RAZZLE_RUNTIME_API_ROOT
const apiKey = isBrowser ? window.env.apiKey : process.env.RAZZLE_RUNTIME_API_KEY
const blobUrl = isBrowser ? window.env.blobUrl : process.env.RAZZLE_RUNTIME_BLOB_URL

const getSpendbridgeLinks = async (userData, link) => {
  const data = await getSpendbridgeUrls(userData)

  if (!_.isEmpty(data)) {
    if (_.has(data, link)) {
      document.location.href = data[link]
    }
  }
}

const Header = (props) => {
  const { cartUpdateDone, currentView, history, onCartUpdate, punchOut, defaultCheckout, onResetCartUpdate } = props

  const bearerToken = safeLocalStorage.getItem('token')
  const localCart = safeLocalStorage.getJSONItem('cart')
  const localUserData = safeLocalStorage.getJSONItem('userData')
  const [rendered, setRendered] = useState(false)
  const [userData, setUserData] = useState(localUserData)
  const [cart, setCart] = useState(localCart)
  const [cartToggle, setCartToggle] = useState(false)
  const [cartClick, setCartClick] = useState(false)
  const [cartItemTotal, setCartItemTotal] = useState(null)
  const [checkoutUrl, setCheckoutUrl] = useState('/checkout')
  const [accountHasContractCodes, setAccountHasContractCodes] = useState(false)

  useEffect(() => {
    getLocalCart({ userData, setCart })
    setRendered(true)
  }, [])

  useEffect(() => {
    if (Number.isFinite(props.cartItemTotal) && cartItemTotal !== props.cartItemTotal) {
      let localCart = safeLocalStorage.getJSONItem('cart')
      if (localCart && rendered) {
        localCart.CartItemTotal = props.cartItemTotal
        setCart(localCart)
      }
    }
  }, [cartItemTotal, rendered, props.cartItemTotal])

  useEffect(() => {
    if (props.defaultCheckout === 'spendbridge') {
      setCheckoutUrl('/spendbridge/checkout')
    } else {
      setCheckoutUrl('/checkout')
    }
  }, [props.defaultCheckout])

  useEffect(() => {
    if (cart && cart.CartItemTotal >= 0) {
      setCartItemTotal(cart.CartItemTotal)
      if (onCartUpdate && !cartUpdateDone) onCartUpdate(cart)
      if (onResetCartUpdate && cartUpdateDone) onResetCartUpdate()
    }
    if (rendered) {
      safeLocalStorage.setJSONItem('cart', { ...cart })
    }
  }, [cart])

  useEffect(() => {
    handleCartToggle()
  }, [cartToggle])

  useEffect(() => {
    ;(currentView === '/cart' || currentView === '/checkout') && setCartClick(true)

    const officeId = props.userData.Network[props.userData.activeNetworkIndex].Id

    setCompanyId(officeId)
  }, [])

  const handleMiniCartUpdate = (cart) => {
    setCart({ ...cart })
  }

  const handleCartToggle = () => {
    if (currentView === '/marketplace' || currentView === '/account') {
      cartToggle ? setCartClick(true) : setCartClick(false)
    }
  }

  const handleCartClick = () => {
    if (currentView === '/marketplace' || currentView === '/account') {
      setCartClick(!cartClick)
    }
  }

  const setCompanyId = async(officeId) => {
    try {
      const isBrowser = typeof window !== 'undefined' && window.env
      const url = isBrowser ? window.env.apiRoot : process.env.RAZZLE_RUNTIME_API_ROOT
      const apiKey = isBrowser ? window.env.apiKey : process.env.RAZZLE_RUNTIME_API_KEY
      const token = localStorage.getItem('token')

      const myHeaders = new Headers()
      myHeaders.append('Ocp-Apim-Subscription-Key', apiKey)
      myHeaders.append('Authorization', `Bearer ${token}`)

      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      }

      const request = await fetch(`${url}/v1/CorporateOffice?AdminPortalCorporateOfficeId=${officeId}`, requestOptions)
      const response = await request.json()

      const companyID = `${response[0].Id}`

      setCookie('companyId', companyID)
      getCompanyContractCodes(response[0].Id)
    } catch (error) {
      console.error(`setCompanyId :: ${error}`);
    }
  }

  const getCompanyContractCodes = (corporateOfficeId) => {

    if(!hasRaivenCheckout(userData)) {
      setAccountHasContractCodes(false)
      return;
    }

    const isBrowser = typeof window !== 'undefined' && window.env
    const url = isBrowser ? window.env.apiRoot : process.env.RAZZLE_RUNTIME_API_ROOT
    const apiKey = isBrowser ? window.env.apiKey : process.env.RAZZLE_RUNTIME_API_KEY
    const token = localStorage.getItem('token')

    const myHeaders = new Headers()
    myHeaders.append('Ocp-Apim-Subscription-Key', apiKey)
    myHeaders.append('Authorization', `Bearer ${token}`)

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }


    fetch(`${url}/v1/Company/${corporateOfficeId}/ContractCodes`, requestOptions)
      .then((response) => response.json())
      .then((result) => {

        
        setAccountHasContractCodes(result.length > 0)
      })
      .catch((error) => console.error('error', error))
  }

  return (
    <HeaderWrapper>
      <HeaderContainer className="actions-header">
        <a
          className="marketplace-icon"
          onClick={async () => {
            await postAnalytics('pm-header-navigation-click-headerIcon', props)
            handleNavigation('/marketplace', props)
          }}
        >
          <img
            className="qmerit-wordmark"
            alt="qmerit-wordmark"
            src={`${blobUrl}/raiven/raiven-marketplace-white-black.png`}
          />
        </a>
        {userData && userData.Network && (
          <NetworkDropdown
            className="network-dropdown"
            data-aurora-automation="header-corporate-dropdown"
            activeUser={userData.Network[userData.activeNetworkIndex]}
            items={userData.Network}
            selected={userData.activeNetworkIndex}
            onItemClick={(event) => handleUpdateActiveNetwork(event, setUserData)}
            onUserClick={() => {
              history.push('/account')
            }}
          />
        )}
        {userData && cart && currentView !== '/cart' && !punchOut && currentView !== checkoutUrl && (
          <MiniCart
            className="marketplace-mini-cart"
            popup
            cart={cart}
            accountHasContractCodes={accountHasContractCodes}
            isLoadingCart={false}
            onBlur={() => {
              setCartToggle(false)
              if (cartClick === true) {
                setCartClick(false)
              }
            }}
            onFocus={() => {
              setCartToggle(true)
              if (cartClick === false) {
                setCartClick(true)
              }
            }}
            toggle={cartToggle}
            setToggle={setCartToggle}
            onCartUpdate={handleMiniCartUpdate}
            checkoutClick={() => {
              if (defaultCheckout === 'spendbridge') {
                setCartToggle(false)
                postSpendbridgeCheckout(props.userData, props.cart)
              } else {
                history.push(checkoutUrl)
              }
            }}
            calcTotal={calcTotal}
            env={{
              apiRoot,
              apiKey,
              bearerToken,
              blobUrl,
              userToken: userData.Network[userData.activeNetworkIndex].UserToken,
              marketplaceRoot: '',
              userData,
            }}
            isPunchout={punchOut}
            emptyCartIcon={`${blobUrl}/procurement/images/Empty-Cart-Illustration.svg`}
            emptyCartExtraText={
              <Fragment>
                {_.has(props, 'defaultCheckout') && props.defaultCheckout === 'spendbridge' && (
                  <SpendbridgeEmptyCart>
                    You can{' '}
                    <a onClick={() => getSpendbridgeLinks(props.userData, 'cart')}>go to your active checkout</a> if you
                    left items in progress.
                  </SpendbridgeEmptyCart>
                )}
              </Fragment>
            }
          />
        )}
        {!punchOut && (
          <a
            className={`cart-icon ${
              currentView === '/cart' || currentView === '/checkout' ? 'button active' : 'button'
            }`}
            onClick={() => {
              postAnalytics('pm-header-navigation-click-cart', { cartToggleBeforeClick: cartToggle })
              setCartToggle(!cartToggle)
              handleCartClick()
            }}
            tabIndex="3"
            onMouseLeave={(event) => {
              if (cartToggle === true) {
                setCartToggle(false)
              }
            }}
          >
            {cartItemTotal > 0 ? (
              <p className="cart-count active easeIn">{cartItemTotal}</p>
            ) : (
              <p className="cart-count"></p>
            )}
            {/* <i className={'fas fa-shopping-cart' + (cartToggle ? ' active' : '')} /> */}
            <div className="header-shopping-cart">
              {!cartClick && <FontAwesomeIcon icon={faCartShopping} style={{ color: 'rgba(255, 255, 255, 0.7)' }} />}

              {cartClick && <FontAwesomeIcon icon={faCartShoppingSolid} style={{ color: 'rgb(255, 255, 255)' }} />}
            </div>

            {/* <MarketplaceCart/> */}
          </a>
        )}
        <a
          className={`user ${currentView === '/account' ? 'button active' : 'button'}`}
          data-aurora-automation="header-account-link"
          onClick={() => {
            postAnalytics('pm-header-navigation-click-accountManage', userData)
            history.push('/account')
          }}
        >
          {currentView !== '/account' && <FontAwesomeIcon icon={faUserGear} />}
          {currentView === '/account' && <FontAwesomeIcon icon={faUserGearSolid} />}
        </a>
      </HeaderContainer>
      <SubNavigation
          location={props.history?.location}
          checkoutUrl={checkoutUrl}
          userData={userData}
          defaultCheckout={userData && useDefaultCheckout(userData)}
        />
    </HeaderWrapper>
  )
}

export default Header
