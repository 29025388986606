Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var Validators = {
  required: function required(value) {
    var test = /^\S/i.test(value);
    return !test;
  },
  email: function email(value) {
    var test = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);
    return !test;
  },
  // date(value) {
  //     let valid;
  //     if (moment(value).isValid() && /^\d{4}-\d{2}-\d{2}$/.test(value)) {
  //         valid = false;
  //     } else {
  //         valid = true;
  //     }
  //     return valid;
  // },
  password: function password(value) {
    var errors = {};

    if (/[A-Z]/g.test(value)) {
      errors.capital = true;
    }

    if (/\d/g.test(value)) {
      errors.number = true;
    }

    if (/^\S{8,}$/g.test(value)) {
      errors.length = true;
    }

    return errors;
  },
  number: function number(value) {
    var test = /^\d+$/.test(value);
    return !test;
  },
  numberOrFloat: function numberOrFloat(value) {
    var test = /^\d+\.\d+|^\d+$/.test(value);
    return !test;
  }
};
var _default = Validators;
exports["default"] = _default;