import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios';
import { safeLocalStorage } from '../../../utils/safeStorage';
import { downloadFile, getFileIcon } from '../../../utils/fileStorage';
import './styles.css';
import {hasRaivenCheckout} from '../../../hooks/useDefaultCheckout'

const Attachments = ({userData, attachments = []}) => {
  const [files, setFiles] = useState(attachments);
  const [error, setError] = useState('');
  const fileInputRef = useRef(null);

  const userToken = userData.Network[userData.activeNetworkIndex].UserToken;

  const showAttachments = hasRaivenCheckout(userData)
  
  const token = safeLocalStorage.getItem('token');
  const isBrowser = typeof window !== "undefined" && window.env;
  const apiRoot = isBrowser ? window.env.apiRoot : process.env.RAZZLE_RUNTIME_API_ROOT;
  const apiKey = isBrowser
  ? window.env.apiKey
  : process.env.RAZZLE_RUNTIME_API_KEY;

  const fileDownloadPath = `${apiRoot}/v1/Procurement/Cart/Token/${userToken}/DownloadAttachment`
  const deleteFile = (fileName) => async () => {
    try {
      const response = await axios.delete(`${apiRoot}/v1/Procurement/Cart/Token/${userToken}/DeleteAttatchment`, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            "Ocp-Apim-Subscription-Key": `${apiKey}`,
        },
        data: { FileName: fileName } });
      setFiles(files.filter(file => file.FileName !== fileName));
      safeLocalStorage.setJSONItem('cart', response.data)

    } catch (error) {
      console.error('Error deleting file:', error);
    }
  }




  const uploadFiles = async (e) => {
    e.preventDefault();
    setError('');
  
    const selectedFiles = Array.from(e.target.files);
    const remainingSelectableFiles = 5 - files.length;

    const formData = new FormData();
  
    for (let i = 0; i < remainingSelectableFiles && i < selectedFiles.length; i++) {
      formData.append('file', selectedFiles[i], selectedFiles[i].name);
    }
  
    try {
      const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
        'Ocp-Apim-Subscription-Key': apiKey,
      };
  
      const response = await axios.post(
        `${apiRoot}/v1/Procurement/Cart/Token/${userToken}/AttatchFile`,
        formData,
        { headers }
      ).then(res => res.data);
  
      setFiles([...response.Attachments]);
      safeLocalStorage.setJSONItem('cart', response)
    } catch (error) {
      console.error('Error uploading files:', error);
    }
  };

  const maxSelectableFiles = 5;
  const isMaxFilesReached = files.length >= maxSelectableFiles;

  const addFiles = () => {  
    if (isMaxFilesReached) {
      return;
    }
  
    const remainingSelectableFiles = maxSelectableFiles - files.length;
  
    fileInputRef.current.click();
    fileInputRef.current.setAttribute('multiple', remainingSelectableFiles.toString());
  };

  if(!showAttachments){
    return (null);
  }
  return (
    <div className='attachments-wrapper'>
        <div className='attachments-header'>
          <div className="attachments-title">
            <h4>Attachments</h4>
            <input
                type="file"
                multiple
                accept=".pdf, .csv, .xls, .xlsx, .doc, .docx"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={uploadFiles}
            />
            <button className='add-file-button' disabled={isMaxFilesReached} onClick={addFiles}>Add</button>
            </div>
          <div className="max-text">Maximum: 5</div> 
        </div>
        
      {error && <div style={{ color: 'red' }}>{error}</div>}
     {files.length> 0 ? <div className='attachments-content'>
        {files.map((file, index) => (
            <div className='file-item' key={index}>              
                  <FontAwesomeIcon className='attachments-icon' icon={getFileIcon(file.FileName)}  onClick={() => downloadFile(file.FileName, fileDownloadPath)}/>
                  <div style={{ flex: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      <span className='file-name'  onClick={() => downloadFile(file.FileName, fileDownloadPath)}>{file.FileName}{file.type}</span>
                  </div>
                <i className="fal fa-trash trash-icon" onClick={deleteFile(file.FileName)}></i>
            </div>
        ))}
      </div> : null}
    </div>
  );
};

export default Attachments;
