"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var ItemContainer = _styledComponents["default"].div.withConfig({
  displayName: "styles__ItemContainer",
  componentId: "sc-149ssrc-0"
})(["display:flex;flex-direction:column;.supplier-wide-logo{height:2rem;width:4.375rem;object-fit:contain;margin-right:0.5rem;}a.link-wrapper{text-decoration:none;height:fit-content;.item-header{display:flex;img{height:5.5rem;width:5.5rem;object-fit:contain;margin:0.5rem 0.5rem 0 0.5rem;}.item-name{display:-webkit-box;-webkit-line-clamp:4;-webkit-box-orient:vertical;margin:0.5rem;max-width:9.375rem;height:5rem;text-overflow:ellipsis;overflow:hidden;text-align:left;}}}.item-availability{display:block;font-size:0.75rem;color:#6e727c;padding:0.5rem 0.5rem 0;text-align:left;line-height:1rem;height:1rem;text-overflow:ellipsis;overflow:hidden;white-space:nowrap;.availability{height:1.25rem;}.details{font-size:0.75rem;line-height:0.875rem;height:1rem;&:hover{overflow:visible;white-space:normal;}}.available{color:#8bc34a;}.backordered{color:#f4511e;}}.price-container{display:flex;align-items:center;justify-content:space-between;button{margin:0 0.5rem 0 0;}.item-price{display:flex;font-weight:bold;color:#6e727c;font-size:14px;padding:0.25rem 0.5rem;align-items:flex-start;width:fit-content;.dollars{margin-top:0;font-size:2rem;line-height:2rem;color:#3e4148;}.cents,.money-symbol{margin-top:2px;color:#3e4148;}.money-symbol{color:#6e727c;}}}.tags-container{display:flex;padding:0.5rem 0.5rem 0.25rem 0.5rem;min-height:1.625rem;flex-wrap:wrap;p{background-color:#edeeef;border:1px solid #dedfe2;border-radius:0.5rem;padding:0.25rem 0.5rem;font-size:0.75rem;line-height:1rem;margin-right:0.25rem;margin-bottom:0.25rem;white-space:nowrap;&.available{background-color:#dcedc8;border:1px solid #c5e1a5;color:#33691e;}}}", " ", ""], function (_ref) {
  var mobile = _ref.mobile;
  return mobile && (0, _styledComponents.css)([".item-availability{font-size:0.75rem;.availability{height:1rem;}}.item-price{padding:0.25rem 0;.dollars{font-size:1.25rem;line-height:1.25rem;}.cents,.money-symbol{font-size:0.75rem;margin-top:-1px;}}.item-header{.item-name{height:2.5rem;font-size:0.875rem;-webkit-line-clamp:2;margin-bottom:0;margin-left:0;width:9.5rem;}img{height:4.25rem;width:4.25rem;}}"]);
}, function (_ref2) {
  var loading = _ref2.loading;
  return loading && (0, _styledComponents.css)([".img-container{background-color:#fafafa;height:6rem;width:6rem;border-top-left-radius:0.5rem;}.name-container{padding:1rem 0.5rem;.row2,.row1{height:1rem;border-radius:0.25rem;background-color:#fafafa;}.row1{width:9.875rem;margin-bottom:0.5rem;}.row2{width:6rem;}}.availability-container{margin:0.5rem;background-color:#fafafa;height:1rem;width:15.875rem;border-radius:0.25rem;}.price-atc-container{justify-content:space-between;.atc-container,.price-container{border-radius:0.25rem;height:2rem;margin:0 0.5rem;}.price-container{background-color:#fafafa;width:5rem;}.atc-container{background-color:#e7ecf8;width:6rem;}}.tags-container{margin-top:0.6rem;.tag{background-color:#fafafa;border-radius:0.25rem;height:1rem;width:3.375rem;margin-right:0.5rem;}}"]);
});

var _default = {
  ItemContainer: ItemContainer
};
exports["default"] = _default;