import fetchData from './fetchData';
import config from './apiConfig';

export default (token, id, SupplierContractId, AccountNumber, Label) => {
    console.log(token, id, SupplierContractId, AccountNumber, Label, 'editCorporateOfficeContract');
    return new Promise((resolve, reject) => {
        const options = {
            ...config.editCorporateOfficeContract,
            url: `${config.editCorporateOfficeContract.url}/${token}/Contract/${id}`,
            body: {
                SupplierContractId,
                AccountNumber: AccountNumber,
                Label: Label,
            },
        };
        return fetchData(options)
            .then(data => {
                if (data && !data.ErrorCode) {
          resolve(data);
                } else {
                    reject({ fetchError: true, details: data });
                }
            })
            .catch(error => {
                reject(error);
            });
    });
};
