import React, { useEffect, useState, useContext } from 'react'
import Button from '../Button'
import RequestModal from '../RequestModal'
import Select from '../Select'
import { getApprovers } from '../../features/Approvals/api'
import { MobileLayout } from '../../App'
import styles from './style.module.css'

function RequestReassignModal({ onClose, onReassign, selectedRequest }) {
  const isMobile = useContext(MobileLayout)
  const [comment, setComment] = useState('')

  const [selectedApprover, setSelectedApprover] = useState(null)
  const [approvers, setApprovers] = useState([])

  const closingFunction = (isCancel = false) => {
    if (isMobile) {
      const modalOverlay = document.querySelector(
        'div.src-common-components-RequestModal-__style-module___mobileModalOverlay'
      )
      const modalDiv = document.querySelector(
        'div.src-common-components-RequestModal-__style-module___modal.src-common-components-RequestModal-__style-module___fadeInBottom.src-common-components-RequestModal-__style-module___mobileStyleModal'
      )
      modalDiv && modalDiv.classList.remove('src-common-components-RequestModal-__style-module___fadeInBottom')
      modalDiv && modalDiv.classList.add('src-common-components-RequestModal-__style-module___fadeOutBottom')
      modalOverlay && (modalOverlay.style.transition = '250ms')
      modalOverlay && (modalOverlay.style.backgroundColor = 'unset')
      setTimeout(() => {
        isCancel
          ? onClose('hideReassignModal')
          : onReassign('reassignRequest', {
              ...selectedRequest,
              comment,
              approver: {
                name: selectedApprover.label,
                id: selectedApprover.value,
              },
            })
      }, 800)
    } else {
      isCancel
        ? onClose('hideReassignModal')
        : onReassign('reassignRequest', {
            ...selectedRequest,
            comment,
            approver: {
              name: selectedApprover.label,
              id: selectedApprover.value,
            },
          })
    }
  }

  const collectApprovers = async () => {
    const approversList = await getApprovers({
      token: localStorage.getItem('token'),
    })

    const approversListFormatted = approversList.map((approver) => ({
      value: approver.id,
      label: approver.name,
      subLabel: approver.email,
      isActive: approver.isActive
    }))

    const firstName = JSON.parse(localStorage.getItem('userData')).FirstName || ''
    const lastName = JSON.parse(localStorage.getItem('userData')).LastName || ''

    const filteredApproversList = approversListFormatted
    .filter((object) => {
      return object.isActive && (object.label !== `${firstName} ${lastName}`)
    })

    setApprovers(filteredApproversList)
  }

  useEffect(() => {
    collectApprovers()
  }, [])

  return (
    <RequestModal
      width={600}
      onClose={() => onClose('hideReassignModal')}
      header={
        <h3
          style={{
            flex: 1,
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 800,
            fontSize: 24,
            lineHeight: '32px',
            color: '#000000',
            height: isMobile && '44px',
            display: 'inline-flex',
            alignItems: 'center',
          }}
        >
          Reassign
        </h3>
      }
      
      headerRightInfo={
        <div
          style={{
            marginRight: 40,
            fontFamily: 'Rubik',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 14,
            lineHeight: '20px',
            color: 'rgba(0, 0, 0, 0.5)'
          }}
        >
          Request #{selectedRequest.externalId}
        </div>
      }
      footer={
        <div style={{ display: 'flex', gap: 16, padding: isMobile ? '16px 24px 34px 24px' : '16px 24px' }}>
          <Button
            onClick={() => closingFunction(true)}
            styles={{
              fontSize: isMobile && '16px',
              lineHeight: isMobile && '20px',
              letterSpacing: isMobile && '0.02em',
            }}
            fullWidth
            variant="default"
            size="lg"
            text="Cancel"
          />
          <Button
            onClick={() => closingFunction()}
            fullWidth
            styles={{
              fontSize: isMobile && '16px',
              lineHeight: isMobile && '20px',
              letterSpacing: isMobile && '0.02em',
            }}
            text="Reassign"
            variant="primary"
            size="lg"
            disabled={!selectedApprover}
          />
        </div>
      }
      showMobileStyleModal={isMobile}
    >
      <div
        style={{
          paddingTop: isMobile ? 16 : 24,
          paddingBottom: isMobile ? 12 : 28,
          paddingLeft: 24,
          paddingRight: 24,
        }}
      >
        <label style={{ display: 'block', marginBottom: 4 }}>Approver</label>
        <Select
          placeholder="Select an approver"
          value={selectedApprover}
          onChange={setSelectedApprover}
          options={approvers}
        />
      </div>
      <div
        style={{
          paddingBottom: isMobile ? 12 : 28,
          paddingLeft: 24,
          paddingRight: 24,
        }}
      >
        <label
          style={{
            display: 'flex',
            gap: 4,
            alignItems: 'center',
            marginBottom: 4,
          }}
        >
          Comment
          <span style={{ color: 'rgba(0, 0, 0, 0.5)' }}> — Optional</span>
        </label>
        <textarea
          className={styles.textArea}
          placeholder="Visible to the requester"
          value={comment}
          maxLength="250"
          cols="2"
          onChange={(e) => {
            setComment(e.target.value)
          }}
        ></textarea>
      </div>
    </RequestModal>
  )
}

export default RequestReassignModal
