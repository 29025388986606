import gsap from 'gsap';

const openAnimation = ({ blurBackground, darkBackground }) => {
  const blurElement = { a: 0, b: 0 };

  const cardAnimation = gsap.timeline({
    paused: true,
    onComplete: () => {
      gsap.killTweensOf('.modal-container');
    },
    onStart: () => {
      gsap.set('.modal-container', {
        display: 'flex',
      });
      gsap.set('.modal-card', { opacity: 0 });
    },
  });

  if (blurBackground) {
    cardAnimation.to(blurElement, 0.3, {
      a: 9,
      b: 0.5,
      onUpdate: () => {
        gsap.set('.modal-container', {
          'backdrop-filter': `blur(${blurElement.a}px)`,
                    'background-color': darkBackground ? `rgba(0, 0, 0, ${blurElement.b})` : 'rgba(237, 238, 239, 0.5)',
        });
      },
    });
  }
  cardAnimation.to('.modal-card', { opacity: 1, duration: 0.3 });

  return cardAnimation;
};

const closeAnimation = ({ blurBackground, setToggle }) => {
  let blurElement = { a: 8 };

  const cardAnimation = gsap.timeline({
    paused: true,
    onComplete: () => {
      gsap.set('.modal-container', { display: 'none' });
      gsap.killTweensOf('.modal-container');
      if (setToggle) setToggle(false);
    },
    onStart: () => {
      gsap.set('.modal-card', { opacity: 1 });
      gsap.set('.modal-container', { display: 'flex' });
    },
  });

  cardAnimation.to('.modal-card', { opacity: 0, duration: 0.3 });
  if (blurBackground) {
    cardAnimation.to(blurElement, 0.3, {
      a: 0,
      onUpdate: () => {
        gsap.set('.modal-container', {
          'backdrop-filter': `blur(${blurElement.a}px)`,
          backgroundColor: 'rgba(237, 238, 239, 0.5)',
        });
      },
    });
  }

  return cardAnimation;
};

export const playAnimation = ({
 toggle, blurBackground, setToggle, darkBackground 
}) => {
  if (toggle) openAnimation({ blurBackground, darkBackground }).play();
  else closeAnimation({ blurBackground, setToggle, darkBackground }).play();
};
