import fetchData from './fetchData';
import config from './apiConfig';

export default ({ userData }) => {
  const userToken = userData.Network[userData.activeNetworkIndex].UserToken;

    return new Promise((resolve, reject) => {
        const options = {
            ...config.getActivePC,
            url: `${config.getActivePC.url}/${userToken}/Card`,
        };
        return fetchData(options)
            .then(data => {
                if (data && !data.ErrorCode) {
                    resolve(data);
                } else {
                    reject({ fetchError: true, details: data });
                }
            })
            .catch(error => {
                reject(error);
            });
    });
};
