import _ from 'lodash';

const model = {
    City: '',
    State: '',
    PostalCode: '',
    Name: '',
    Street1: '',
    Street2: '',
};

export const formatAddress = address => {
    let company;
    let street;
    let cityState;
    let externalId;

    const formattedAddress = {
        ...model,
        ...address,
    };

  const {
 City, State, PostalCode, Name, Street1, Street2, ExternalId 
} = formattedAddress;

    if (!_.isEmpty(Name)) {
        company = Name;
    }

    if (!_.isEmpty(Street1)) {
        street = Street1;
        if (!_.isEmpty(Street2)) {
            street += `, ${Street2}`;
        }
    }

    if (!_.isEmpty(City)) {
        cityState = City;
    }
    if (!_.isEmpty(State)) {
        cityState += `, ${State}`;
    }

    if (!_.isEmpty(PostalCode)) {
        cityState += ` ${PostalCode}`;
    }
    // if (!_.isEmpty(ExternalId)) {
    externalId = `${ExternalId}`;
    // }

    return {
        ...address,
        company,
        street,
        cityState,
        externalId,
    };
};
