import React, { PureComponent, Fragment } from 'react'
import Header from '../../components/Header'

export class Error extends PureComponent {
  constructor(props) {
    super(props)
    const withRaivenDomain = props.featureFlags?.f6853QmeritRedirect || window.raivenff?.f6853QmeritRedirect  || false
    this.state = {
      emailAddress: props.emailOverride
        ? props.emailOverride
        : withRaivenDomain
        ? ' support@raiven.com'
        : ' support@qmerit.com',
    }
  }

  render() {
    return (
      <Fragment>
        {!this.props.removeHeader && <Header {...this.props} />}

        <main role="main" className="flex-row">
          <article>
            <section id="cart-sent-tab" className="tab-content">
              <svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g id="d-P2---Aurora---Login-(Success)" transform="translate(-688.000000, -276.000000)">
                    <g id="Check-Green-Thick" transform="translate(688.000000, 276.000000)">
                      <rect id="Rectangle" fill="#FFFFFF" opacity="0.01" x="0" y="0" width="48" height="48" />
                      <path
                        d="M40.8749025,7.78133158 C41.0624021,7.59383203 41.312402,7.50008225 41.6249008,7.50008225 C41.9373995,7.50008225 42.2186489,7.59383203 42.4686487,7.78133158 L45.0936425,10.5000751 C45.3436424,10.6875747 45.4686416,10.9375745 45.4686416,11.2500733 C45.4686416,11.5625721 45.3436424,11.8438214 45.0936425,12.0938213 L16.9687095,40.2187542 C16.78121,40.4062538 16.5312101,40.5000036 16.2187113,40.5000036 C15.9062126,40.5000036 15.6249632,40.4062538 15.3749633,40.2187542 L2.90624307,27.6562842 C2.65624319,27.4687846 2.53124397,27.2187848 2.53124397,26.906286 C2.53124397,26.5937872 2.65624319,26.3125379 2.90624307,26.062538 L5.53123681,23.4375443 C5.78123669,23.1875444 6.06248602,23.0625452 6.3749848,23.0625452 C6.68748358,23.0625452 6.93748346,23.1875444 7.12498301,23.4375443 L16.2187113,32.5312726 L40.8749025,7.78133158 Z"
                        id="check"
                        fill="#8BC34A"
                      />
                    </g>
                  </g>
                </g>
              </svg>
              <h1>Opps, we've ran into an issue!</h1>
              <p>There seems to be a problem with your account configuration.</p>
              <p>
                Please reach out to
                <a href={'mailto:' + this.state.emailAddress}>{this.state.emailAddress}</a> for additional help.
              </p>
            </section>
          </article>
        </main>
      </Fragment>
    )
  }
}

export default Error
