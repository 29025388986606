import fetchData from './fetchData';
import config from './apiConfig';

export default ({ params }) => {
    const { Number, ExpirationDate } = params;

    const userToken = params.userData.Network[params.userData.activeNetworkIndex].UserToken;
    return new Promise((resolve, reject) => {
        const options = {
            ...config.postAddPCard,
            body: {
                userToken,
                Number,
                ExpirationDate,
            },
        };
        return fetchData(options)
            .then(data => {
                if (data && !data.ErrorCode) {
                    resolve(data);
                } else {
                    reject({ fetchError: true, details: data });
                }
      })
            .catch(error => {
                reject(error);
            });
    });
};
