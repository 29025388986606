"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _apiConfig = _interopRequireDefault(require("./apiConfig"));

var _safeStorage = require("./safeStorage");

var _fetchData = _interopRequireDefault(require("./fetchData"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var _default = function _default(env) {
  var apiKey = env.apiKey,
      apiUrl = env.apiUrl,
      bearerToken = env.bearerToken,
      userToken = env.userToken;
  var config = (0, _apiConfig["default"])(apiUrl);
  return new Promise(function (resolve, reject) {
    var url = "".concat(config.getSuppliers.url, "/").concat(userToken, "/CorporateOfficeContract");

    var options = _objectSpread(_objectSpread({}, config.getSuppliers), {}, {
      url: url,
      apiKey: apiKey,
      bearerToken: bearerToken
    });

    return (0, _fetchData["default"])(options).then(function (data) {
      if (data && !data.ErrorCode) {
        var stateObject = _objectSpread(_objectSpread({}, data), {}, {
          fetchError: data.fetchedData && data.fetchedData.ErrorCode ? true : false
        });

        resolve(stateObject);
      } else {
        reject({
          fetchError: true,
          details: data
        });
      }
    })["catch"](function (error) {
      reject(error);
    });
  });
};

exports["default"] = _default;