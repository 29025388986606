import fetchData from './fetchData';
import config from './apiConfig';

export default userData => {
    const userToken = userData.Network[userData.activeNetworkIndex].UserToken;

    return new Promise((resolve, reject) => {
        const options = {
            ...config.getSpendbridgeUrl,
            url: `${config.getSpendbridgeUrl.url}/${userToken}`,
        };

        return fetchData(options)
            .then(data => {
                if (data && !data.ErrorCode) {
                    const stateObject = {
                        ...data,
                    };
                    resolve(stateObject);
                } else {
                    reject({ fetchError: true, details: data });
                }
            })
            .catch(error => {
                reject(error);
            });
    });
};
