"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var Title = _styledComponents["default"].h2.withConfig({
  displayName: "styles__Title",
  componentId: "sc-1qasfkm-0"
})(["font-family:Montserrat;font-size:1.125rem;font-weight:bold;height:1.5rem;line-height:1.5rem;margin:0;width:100%;"]);

var TitleContainer = _styledComponents["default"].div.withConfig({
  displayName: "styles__TitleContainer",
  componentId: "sc-1qasfkm-1"
})(["display:flex;width:100%;i{color:#969ba5;margin-right:0.25rem;margin-left:auto;cursor:pointer;}.matts-style{justify-content:center;align-items:center;display:flex;background:rgba(0,0,0,0.5);height:1.5rem;width:1.5rem;border-radius:0.75rem;z-index:99;margin:-1.75rem -2.25rem 1.25rem auto;color:#ffffff;font-weight:300;}"]);

var ModalContainer = _styledComponents["default"].div.withConfig({
  displayName: "styles__ModalContainer",
  componentId: "sc-1qasfkm-2"
})(["display:none;align-items:center;justify-content:center;position:absolute;height:100%;width:100%;top:0;bottom:0;left:0;right:0;backdrop-filter:blur(8px);z-index:99;"]);

var Card = _styledComponents["default"].div.withConfig({
  displayName: "styles__Card",
  componentId: "sc-1qasfkm-3"
})(["display:flex;flex-direction:column;height:", ";max-height:70vh;width:", ";max-width:", ";max-height:", ";background:rgb(255,255,255);box-shadow:0px 2px 4px 0px rgba(0,0,0,0.14),0px 4px 5px 0px rgba(0,0,0,0.12),0px 1px 10px 0px rgba(0,0,0,0.2);border-radius:0.5rem;padding:1rem 1.5rem;margin:1.5rem;.modal-children{display:flex;flex-direction:column;height:100%;}"], function (_ref) {
  var height = _ref.height;
  return height ? height : '50%';
}, function (_ref2) {
  var width = _ref2.width;
  return width ? width : '50%';
}, function (_ref3) {
  var maxWidth = _ref3.maxWidth;
  return maxWidth && maxWidth;
}, function (_ref4) {
  var maxHeight = _ref4.maxHeight;
  return maxHeight && maxHeight;
});

var _default = {
  Title: Title,
  TitleContainer: TitleContainer,
  ModalContainer: ModalContainer,
  Card: Card
};
exports["default"] = _default;